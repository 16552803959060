<template>
  <modal size="lg" :show.sync="modal">
    <template slot="header">
      <h5 class="modal-title p-0 m-0">
        <img height="25" src="/img/icons/autograph.png" alt="autograph" />
        {{ title }}
      </h5>
    </template>
    <div class="p-3">
      <div class="d-flex align-items-center">
          <img width="25" height="25" src="/img/icons/info-squared-yellow.png" alt="info-squared"/>
          <span class="ml-2 mr-3 font-weight-bold">informação</span>
          <div class="dropdown-divider" style="width: -webkit-fill-available;" />
      </div>
      <!-- habilitar/desabilitar -->
      <div class="d-flex justify-content-between align-items-center mt-2">
        <label>
          Habilitar assinatura digital
        </label>
        <div>
          <base-switch
            v-model="config.enable_digital_signature"
            type="primary"
            offText="Não"
            onText="Sim"
            class="success" />
        </div>
      </div>
      <!-- habilitar/desabilitar -->
      <div v-if="config.enable_digital_signature" class="d-flex justify-content-between align-items-center mt-2 ml-4">
        <label>
          Exigir contrato assinado para realizar entregas?
        </label>
        <div>
          <base-switch
            v-model="config.require_signed_contract_for_delivery"
            type="primary"
            offText="Não"
            onText="Sim"
            class="success" />
        </div>
      </div>
      <div v-if="config.enable_digital_signature" class="d-flex justify-content-between align-items-center mt-1 ml-4">
        <label>
          limite de assinaturas mensais
        </label>
        <div style="width: 15%;">
          <input-limit
            :start_value="config.monthly_signature_limit"
            :options="{
                min: 1,
                max: 9999,
                decimals: 0
              }"
            @value="config.monthly_signature_limit = $event"
          />
        </div>
      </div>
      <!-- Assinatura padrão -->
      <div class="d-flex align-items-center mt-2">
        <img width="25" height="25" src="/img/icons/pin-danger.png" alt="pin--v1"/>
          <span class="ml-2 mr-3 font-weight-bold text-nowrap">ASSINATURA PADRÃO</span>
          <div class="dropdown-divider" style="width: -webkit-fill-available;" />
        </div>
      <div v-if="config.enable_digital_signature" class="d-flex justify-content-between align-items-center mt-2">
        <label>
          Habilitar assinatura padrão para contratado
        </label>
        <div>
          <base-switch
            v-model="config.default_signatory_company_plant"
            type="primary"
            offText="Não"
            onText="Sim"
            class="success" />
        </div>
      </div>
      <div v-if="config.default_signatory_company_plant" class="d-flex align-items-center mb-3 mt-2">
        <div class="px-3 py-1 py-sm-1 mt-1 mt-sm-0 col-12 pointer button justify-content-center"
          @click.prevent="handleShowModal({ ref: 'ModalDefaultSignatoryPlant', data: null })"
          style="min-width: 0 !important;">
          <img class="mr-2" width="20" src="/img/icons/chemical-plant-black.png" alt="chemical-plant"/>
          <span class="pr-2" style="font-size: 12px; max-width: 250px;">configuração por central</span>
        </div>
      </div>
      <!-- Propsota -->
      <div v-if="config.enable_digital_signature" class="d-flex align-items-center mt-2">
        <img width="25" height="25" src="/img/icons/terms-and-conditions-success.png" alt="info-squared"/>
        <span class="ml-2 mr-3 font-weight-bold">Proposta</span>
        <div class="dropdown-divider" style="width: -webkit-fill-available;" />
      </div>
      <div v-if="config.enable_digital_signature" class="d-flex justify-content-between align-items-center mt-2">
        <label>
          Habilitar assinatura digital
        </label>
        <div>
          <base-switch
            v-model="config.proposal"
            type="primary"
            offText="Não"
            onText="Sim"
            class="success" />
        </div>
      </div>
      <div v-if="config.enable_digital_signature && config.proposal" class="d-flex justify-content-between align-items-center mt-1">
        <label>
          mínimo de assinaturas do contratado
        </label>
        <div style="width: 15%;">
          <input-limit
            :start_value="config.min_contracted_signatures_proposal"
            :options="{
                min: 1,
                max: 99,
                decimals: 0
              }"
            @value="config.min_contracted_signatures_proposal = $event"
          />
        </div>
      </div>
      <div v-if="config.enable_digital_signature && config.proposal" class="d-flex justify-content-between align-items-center mt-1">
        <label>
          Mínimo de assinaturas do contratante
        </label>
        <div style="width: 15%;">
          <input-limit
            :start_value="config.min_contractor_signatures_proposal"
            @value="config.min_contractor_signatures_proposal = $event"
            :options="{
                min: 1,
                max: 99,
                decimals: 0
              }"
          />
        </div>
      </div>
    </div>
    <!-- Contrato -->
    <div v-if="config.enable_digital_signature" class="px-3">
      <div class="d-flex align-items-center">
        <img width="25" height="25" src="/img/icons/agreement-primary.png" alt="info-squared"/>
        <span class="ml-2 mr-3 font-weight-bold">CONTRATO</span>
        <div class="dropdown-divider" style="width: -webkit-fill-available;" />
      </div>
      <div class="d-flex justify-content-between align-items-center mt-2">
        <label>
         Habilitar assinatura digital
        </label>
        <div>
          <base-switch
            v-model="config.contract"
            type="primary"
            offText="Não"
            onText="Sim"
            class="success" />
        </div>
      </div>
      <div v-if="config.enable_digital_signature && config.contract" class="d-flex justify-content-between align-items-center mt-1">
        <label>
          Mínimo de assinaturas do contratado
        </label>
        <div style="width: 15%;">
          <input-limit
            :start_value="config.min_contracted_signatures"
            @value="config.min_contracted_signatures = $event"
            :options="{
                min: 1,
                max: 99,
                decimals: 0
              }"
          />
        </div>
      </div>
      <div v-if="config.enable_digital_signature && config.contract" class="d-flex justify-content-between align-items-center mt-1">
        <label>
          Mínimo de assinaturas do contratante
        </label>
        <div style="width: 15%;">
          <input-limit
            :start_value="config.min_contractor_signatures"
            @value="config.min_contractor_signatures = $event"
            :options="{
                min: 1,
                max: 99,
                decimals: 0
              }"
          />
        </div>
      </div>
      <!-- Testemunhas -->
      <div v-if="config.enable_digital_signature && config.contract" class="d-flex justify-content-between align-items-center mt-1">
        <label>
          Mínimo de assinaturas de testemunhas
        </label>
        <div style="width: 15%;">
          <input-limit
            :start_value="config.min_witness_signatures"
            @value="config.min_witness_signatures = $event"
            :options="{
                min: 0,
                max: 99,
                decimals: 0
              }"
          />
        </div>
      </div>
      <!-- Fiador -->
      <!-- <div v-if="config.enable_digital_signature && config.contract" class="d-flex justify-content-between align-items-center mt-1">
        <label>
          MÍNIMO DE ASSINATURAS DO FIADOR
        </label>
        <div style="width: 15%;">
          <input-limit
            :start_value="config.min_guarantor_signatures"
            @value="config.min_guarantor_signatures = $event"
            :options="{
                min: 0,
                max: 99,
                decimals: 0
              }"
          />
        </div>
      </div> -->
    </div>

    <div class="modal-footer pt-4">
      <base-button type="danger" @click="closeModal()">
        <img width="22" src="/img/icons/cancel.png" alt="info-squared" class="mr-1" />
        Cancelar
      </base-button>
      <base-button :disabled="!config" @click.prevent="save()" type="success" native-type="submit" :loading="loading">
      <img width="22" src="/img/icons/save.png" alt="info-squared" class="mr-1" />
        Salvar
      </base-button>
    </div>
    <ModalDefaultSignatoryCompanyPlant ref="ModalDefaultSignatoryPlant" />
  </modal>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import InputLimit from "@/components/Utils/InputLimit.vue";
import {quillEditor} from 'vue-quill-editor';
import ModalDefaultSignatoryCompanyPlant from './_ModalDefaultSignatoryCompanyPlant.vue';

export default {
  name: "ModalDigitalSignatureConfigurations",
  components: {
    quillEditor,
    InputLimit,
    ModalDefaultSignatoryCompanyPlant
  },
  data() {
    return {
      modal: false,
      loading: false,
      title: 'Configuração de assinatura digital',
      config: null,
    }
  },
  methods: {
    handleCreateModal(a) {
      let loader = this.$loading.show()
      this.$store.dispatch('digitalSignatureConfiguration/show')
        .then((response) => {
          this.config = response.data;
          this.modal = true
        }).finally(() => loader.hide())
    },
    closeModal() {
      this.modal = false
    },
    save() {
      this.$emit("fetch");
      this.loading = true
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      this.$store.dispatch('digitalSignatureConfiguration/update', this.config).then((response) => {
        this.closeModal()
        this.$notify({type: response.error_type, message: response.message})
      }).finally(() => {
        this.loading = false
        this.$emit("fetch");
      })
    },
    handleShowModal({ ref, data }) {
      this.$refs[ref].handleCreateModal(data);
    },
  }
}
</script>

<style scoped>
label {
  margin-bottom: 0 !important;
}
.button {
  border-radius: 5px;
  border: 1px solid #DCDFE6;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>

import store from "../../../../../shared/libraries/store";
import { initEquipmentListPagerType, EquipmentListFilterType, EquipmentListPagerType, EquipmentType } from "../types";
import { createSource, findSource, listSources, removeSource, updateSource } from "../../../../../shared/store";
import httpClient from "../../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../../shared/enums";
import { handleUnexpectedStatusCode } from "../../../../../shared/services";

/** URLS */
const urls = Object.freeze({
  BASE: "/configuration/equipment",
});

/** KEYS */
const keys = Object.freeze({
  ITEM: "EQUIPMENT_ITEM",
  LISTED: "EQUIPMENT_LISTED",
  IS_LISTING: "EQUIPMENT_IS_LISTING",
  IS_FINDING: "EQUIPMENT_IS_FINDING",
  IS_SAVING: "EQUIPMENT_IS_SAVING",
  IS_REMOVING: "EQUIPMENT_IS_REMOVING",
  PAGE_STATES: "EQUIPMENT_PAGE_STATE",
});

/** GETTERS */
const getters = {
  /** @returns {?EquipmentType} */
  getItem: () => store.getState(keys.ITEM),

  /** @returns {EquipmentListPagerType} */
  getListed: () => store.getState(keys.LISTED) ?? initEquipmentListPagerType(),

  /** @returns {boolean} */
  getIsListing: () => store.getState(keys.IS_LISTING) ?? false,

  /** @returns {boolean} */
  getIsFinding: () => store.getState(keys.IS_FINDING) ?? false,

  /** @returns {boolean} */
  getIsSaving: () => store.getState(keys.IS_SAVING) ?? false,

  /** @returns {boolean} */
  getIsRemoving: () => store.getState(keys.IS_REMOVING) ?? false,

  /** @returns {{  searchBarFilter: SearchBarFilterType, filter: EquipmentListFilterType }} */
  getPageState: () => store.getState(keys.PAGE_STATES),
}

/** MUTATIONS */
const mutations = {
  /**
   * @param {EquipmentType} item
   * @returns {EquipmentType}
   */
  setItem: (item) => store.commit(keys.ITEM, item),

  /**
   * @param {EquipmentListPagerType} listed
   * @returns {EquipmentListPagerType}
   */
  setListed: (listed) => store.commit(keys.LISTED, listed),

  /**
   * @param {boolean} isListing
   * @returns {boolean}
   */
  setIsListing: (isListing) => store.commit(keys.IS_LISTING, isListing),

  /**
   * @param {boolean} isFinding
   * @returns {boolean}
   */
  setIsFinding: (isFinding) => store.commit(keys.IS_FINDING, isFinding),

  /**
   * @param {boolean} isSaving
   * @returns {boolean}
   */
  setIsSaving: (isSaving) => store.commit(keys.IS_SAVING, isSaving),

  /**
   * @param {boolean} isRemoving
   * @returns {boolean}
   */
  setIsRemoving: (isRemoving) => store.commit(keys.IS_REMOVING, isRemoving),

  /**
   * @param {EquipmentType} created
   * @returns {EquipmentType}
   */
  setNewItemInList: (created) => store.commitNewItemToList(keys, created),

  /**
   * @param {EquipmentListPagerType} listed
   * @returns {EquipmentListPagerType}
   */
  setListMerge: (listed) => store.commitListMerge(keys, listed),

  /**
   * @param {number} id
   */
  setRemovedItem: (id) => store.commitRemovedItem(keys, id),

  /**
   * @param {EquipmentType} updated
   * @returns {EquipmentType}
   */
  setUpdatedItem: (updated) => store.commitUpdatedItem(keys, updated),

  /** @param {{ searchBarFilter: SearchBarFilterType, filter: EquipmentListFilterType }} filters */
  setPageState: (filters) => store.commit(keys.PAGE_STATES, filters),

  /** Resetar chaves de estado */
  resetStates: () => store.resetStates(Object.values(keys)),
}

/** ACTIONS */
const actions = {
  /**
   * Incluir
   *
   * @param {EquipmentType} data
   * @returns {Promise<EquipmentType>}
   */
  create: async (data) => await createSource(data, mutations, urls.BASE),

  /**
   * Localizar por ID
   *
   * @param {number} id
   * @returns {Promise<?EquipmentType>}
   */
  find: async (id) => await findSource(id, mutations, urls.BASE),

  /**
   * Listar
   *
   * @param {EquipmentListFilterType} filter - Filtro de listagem
   * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
   * @returns {Promise<?EquipmentListPagerType>} - Retorno paginado
   */
  list: async (filter, isAccumulateItems = false) => await listSources(filter, isAccumulateItems, mutations, getters, urls.BASE),

  /**
   * Deletar por ID
   *
   * @param {number} id
   * @returns {Promise<void>}
   */
  remove: async (id) => await removeSource(id, mutations, urls.BASE),

  /**
   * Atualizar por ID
   *
   * @param {EquipmentType} data
   * @param {number} id
   * @returns {Promise<EquipmentType>}
   */
  update: async (data, id) => await updateSource(data, id, mutations, urls.BASE),

  /**
   * Atualizar Status por ID
   *
   * @param {number} equipmentId
   * @param {number} situationId
   * @returns {Promise<StockLocationType>}
   */
  updateEquipmentSituation: async (equipmentId, situationId) => {
    try {
      mutations.setIsSaving(true);

      const url = `/configuration/equipment/update-situation/${equipmentId}/${situationId}`
      const res = await httpClient.put(url);
      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }
      
      const updated = res.data.data;
      return mutations.setUpdatedItem(updated);
    } finally {
      mutations.setIsSaving(false);
    }
  },

  storeThirdPartyResponsible: async (payload) => {
    try {
      mutations.setIsSaving(true);
  
      const url = `/configuration/equipment/store-third-party-responsible/${payload.equipment_id}/${payload.entity_id}`;
      const res = await httpClient.put(url, payload);
      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }
  
      const updated = res.data.data;
      return mutations.setUpdatedItem(updated);
    } finally {
      mutations.setIsSaving(false);
    }
  },

  getEquipmentSituations: async () => {

    const url = '/configuration/equipment/situation';
    const res = await httpClient.get(url);
    
    if (res.status !== HttpStatusEnum.HTTP_OK && !res.error) {
      handleUnexpectedStatusCode(res);
      return;
    } else if (res.error) {
      console.error(errorMessage);
    }
    return res.data.data;

  },

}

export default {
  actions,
  getters,
  mutations,
};

<template>
  <div>
    <modal size="lg"
      :show.sync="modal.create"
      :backgroundColor="'#F2F7F3'"
      :showClose="false"
    >
      <template>
        <template slot="header">
          <div class="d-flex align-items-center container-fluid" style="gap: 15px;">
            <div>
              <img src="/img/icons/icons8/ios/plus-math-success.png" alt="plus-math-success.png" width="24px">
            </div>
            <span class="text-title">
              {{ modal.title }}
            </span>
          </div>
        </template>
        <template slot="close-button">
          <AppButton
            icon="close"
            width="auto"
            type="link"
            size="sm"
            :hasOutline="true"
            @click="closeModal()"
          />
        </template>
        <div>
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12" style="display: flex; align-items: center;">
                  <h4 class="m-0 mx-3" style="font-size: 16px; font-weight: 500; color: #2B2D32;">
                      Identificação
                  </h4>
                  <hr class="ml-3" style="width: 100%;">
                </div>
              </div>
              <div class="form-group">
                <div class="form-group row m-0 p-0">
                  <div class="col-md-5 my-1 pr-4">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                        COMPÕE O DRE
                    </div>
                  </div>
                  <div v-if="account.dre === true" class="col-md-7 mb-2 pr-4">
                    <span class="d-flex float-right align-items-center button-dre-success py-2 px-4" @click="account.dre = false;">
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </span>
                  </div>
                  <div v-if="account.dre === false" class="col-md-7 mb-2 pr-4">
                    <span class="d-flex float-right align-items-center button-dre-danger py-2 px-4" @click="account.dre = true;">
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="12px">
                      Inativo
                    </span>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2">
                  <div class="col-md-5 my-1 pr-4">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                        PLANO DE CONTAS
                    </div>
                  </div>
                  <div class="col-md-7 mb-2 pr-4">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                          <PuzlSelect
                            v-model="account.acc_chart_account_parent_uuid"
                            :items="grouped_accounts"
                            customKey="uuid"
                            @change="getLastCode"
                            :disableBoxShadow="true"
                          />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2">
                  <div class="col-md-5 my-1 pr-4">
                    <div class="d-flex">
                      <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                          CÓDIGO
                      </div>
                      <span v-if="ordered_code != 0" class="ml-auto align-self-center account-ordered-code mb-1">
                        {{ ordered_code }}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-7 mb-2 pr-4">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm" :disableBoxShadow="true">
                        <input
                            @keyup="getCodeRealtime"
                            v-model="account.code"
                            type="text"
                            class="form-control form-control-sm"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <div class="col-md-5 my-1 pr-4">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                        NOME
                    </div>
                  </div>
                  <div class="col-md-7 mb-2 pr-4">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm" :disableBoxShadow="true">
                        <input
                            v-model="account.name"
                            type="text"
                            class="form-control form-control-sm"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="modal-footer">
                  <button class="danger button" @click="closeModal">
                    <AppIcon icon="cancel" color="danger" width="18" />
                    Cancelar
                  </button>
                  <button
                    class="success button"
                    type="submit"
                    v-bind:disabled="invalid"
                    @click.prevent="store"
                  >
                    <AppIcon icon="save" color="success" width="18" />
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import PuzlSelect from "@/components/PuzlSelect";
import AppIcon from "../../../../components/AppGlobal/AppIcon/AppIcon.vue";
import { AppButton } from "../../../../components/AppGlobal";

export default {
  name: "ModalCreateAccount",
  components: {
    PuzlSelect,
    AppIcon,
    AppButton,
  },
  data() {
    return {
      modal: {
        title: "Adicionar Plano de Conta",
        create: false,
      },
      account: {
        name: null,
        status: 1, //0-inativo | 1-ativo
        dre: true,
        type: 0,
        code: null,
        acc_chart_account_parent_uuid: 0, // 0 = null - para mandar este parâmetro por url.
      },
      ordered_code: "",
      grouped_accounts: [],
    }
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    handleCreateModal() {
      this.modal.create = true;
      this.refreshFields();
      this.loadGroupedAccounts();
      this.getLastCode();
    },
    refreshFields() {
      this.account = {
        name: null,
        status: 1, //0-inativo | 1-ativo
        dre: true,
        type: 0,
        code: null,
        acc_chart_account_parent_uuid: null,
      };
      this.ordered_code = "";
      this.grouped_accounts = [{
        id: 0,
        uuid: null,
        name: 'INICIAL',
        code: 0,
        acc_chart_account_parent_uuid: null
      }];
    },
    async loadGroupedAccounts() {
      await this.$store.dispatch('accountingChartAccount/getGrouped',{}).then((response) => {
        response.data.forEach(item => this.grouped_accounts.push(item));
      })
    },
    getCodeRealtime(event) {

      const code = event.key;

      // verifica se tecla pressionada é um número, ignorando a tecla backspace
      if(!this.isValidCode(code) && (code !== 'Backspace' || event.keyCode !== 8)) {
        event.preventDefault();
        this.account.code = this.account.code.substring(0, this.account.code.length - 1);
        this.$notify({
          type: "danger",
          message: "Favor Informar Apenas Números!"
        });
      }

      this.$store.dispatch('accountingChartAccount/getOrderedCode', {
        uuid: this.account.acc_chart_account_parent_uuid,
        code: this.account.code
      }).then((response) => {
        this.ordered_code = response.data
      }).catch((error) => {
        this.ordered_code = "";
      });

    },
    getLastCode() {
      this.$store.dispatch('accountingChartAccount/getLastCode', {
        uuid: this.account.acc_chart_account_parent_uuid,
      }).then((response) => {
        this.account.code = response.data
        this.getCodeRealtime();
      }).catch((error) => {
        this.ordered_code = "";
      });
    },
    store() {
      this.$Progress.start();
      this.$store
        .dispatch('accountingChartAccount/add', this.account)
        .then((response) => {
          this.$notify({
            type: "success",
            message: response.message
          });
          this.closeModal();
          this.$emit('init');
        })
        .catch((error) => {
          if(error.response.status === 406) {
            this.$notify({ type: "danger", message: error.response.data.message });
          } else if(error.response.status === 422) {
            this.$notify({ type: "danger", message: error.response.data.message });
          }
        }).finally(() => {
          this.$Progress.finish();
        });
    },
    isValidCode(code) {
      // validação regex para permitir apenas números
      const regex = /^[0-9]*$/;
      return regex.test(code);
    },
  }
}
</script>
<style scoped>
  .text-title {
    font-size: 18px;
    font-weight: 500;
    color: #2B2D32;
  }
  .button-dre-success {
    background-color: #F2F7F3;
    border-radius: 8px;
    cursor: pointer;
    gap: 5px;
    font-size: 12px;
    color: #149E57;
    font-weight: 400;
  }
  .button-dre-danger {
    background-color: #F4C7C3;
    border-radius: 8px;
    cursor: pointer;
    gap: 5px;
    font-size: 12px;
    color: #DB4539;
    font-weight: 400;
  }
  .account-ordered-code {
    padding: 3px 10px;
    background-color: #E8E8E8;
    border: 0.5px solid #CFCFCF;
    border-radius: 4px;
    color: #606062;
  }
  .button-cancel {
    cursor: pointer;
    border: 0.5px solid #DB4539;
    border-radius: 8px;
    color: #DB4539;
    padding: 4px 20px;
  }
  .button-save {
    cursor: pointer;
    border: 0.5px solid #149E57;
    border-radius: 8px;
    color: #149E57;
    padding: 4px 30px;
  }
</style>
import { InvoiceGetSyncBankBilletInstallmentsType } from "./InvoiceGetSyncBankBilletInstallmentsType";

/**
 * Type para Boletos do app de terceiros [Sync]
 *
 * @typedef {Object} InvoiceGetSyncBankBilletsType
 * @property {number} id
 * @property {string} contract_proposal_id
 * @property {string} contract_proposal_uuid
 * @property {string} contract_proposal_code
 * @property {number} entity_id
 * @property {string} entity_name
 * @property {string} entity_document
 * @property {string} construction_uuid
 * @property {string} construction_name
 * @property {string} customer_construction_customer_name
 * @property {string} nfse_number
 * @property {?number} payment_term_id
 * @property {string} payment_term_name
 * @property {string} pending_billets_url
 * @property {InvoiceGetSyncBankBilletInstallmentsType[]} installments
 */
export const InvoiceGetSyncBankBilletsType = {};

/**
 * Inicializar InvoiceGetSyncBankBilletsType
 * 
 * @returns {InvoiceGetSyncBankBilletsType}
 */
export const initInvoiceGetSyncBankBilletsType = () => {
  return {
    id: 0,
    contract_proposal_id: '',
    contract_proposal_uuid: '',
    contract_proposal_code: '',
    entity_id: 0,
    entity_name: '',
    entity_document: '',
    construction_uuid: '',
    construction_name: '',
    customer_construction_customer_name: '',
    nfse_number: '',
    payment_term_id: undefined,
    payment_term_name: '',
    pending_billets_url: '',
    installments: []
  }
};
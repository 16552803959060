<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title ml-2">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
            <div>
              <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left mb-2">
                <h5 class="mb-0" slot="title">Detalhes</h5>
              </div>
              <div class="form-group row m-0 p-0">
                <!-- <div class="col-md-12"> -->
                  <label class="col-md-5 mt-1 pt-1 pb-0 col-form-label form-control-label">
                    Início de vigência
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7 mt-1">
                    <validation-provider rules="required" v-slot="{errors}">
                      <el-date-picker
                        v-model="deduction_rules.date_at"
                        style="min-width: 100%"
                        size="mini"
                        disabled
                        type="date"
                        :picker-options="pickerOptions"
                        placeholder="Ínicio de vigência"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>
                    </validation-provider>
                  </div>
                  <label class="col-md-5 mt-1 pt-1 pb-0 col-form-label form-control-label">
                    Regra
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-7 mt-1">
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <el-select
                          filterable
                          disabled
                          v-model="deduction_rules.rule"
                          placeholder="Selecione"
                        >
                            <el-option v-for="rule in rules"
                            :key="rule.id"
                            :label="rule.text"
                            :value="rule.text">
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <div>
                <div class="card-header p-2 grid-col--fixed-left mb-2">
                  <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left mb-2">
                    <h5 class="mb-0" slot="title">Taxas</h5>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1 mt-2"v-if="deduction_rules.rule == 'Dedução'">
                    <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label">
                      Deduzir custo de MCC
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-7">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-switch
                          v-model="deduction_rules.deduct_mcc_cost"
                          type="success"
                          disabled
                          offText="Não"
                          onText="Sim"
                          class="success"
                        ></base-switch>
                      </validation-provider>
                    </div>
                    <label class="col-md-5 mt-1 pt-1 pb-0 col-form-label form-control-label" v-if="deduction_rules.deduct_mcc_cost">
                      Condição
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-7 mt-1" v-if="deduction_rules.deduct_mcc_cost">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                          <el-select
                            v-model="deduction_rules.condition"
                            filterable
                            disabled
                            placeholder="Selecione"
                          >
                            <el-option v-for="condition in conditions"
                              :key="condition.id"
                              :label="condition.text"
                              :value="condition.text">
                            </el-option>
                          </el-select>
                        </base-input>
                      </validation-provider>
                    </div>
                    <label class="col-md-5 mt-1 pt-1 pb-0 col-form-label form-control-label">
                      Dedução
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-7 mt-1">
                      <validation-provider rules="required" v-slot="{errors}">
                        <div class="input-group input-group-sm">
                            <input maxlength="3"
                              v-on:blur="baseValueOnExit()"
                              v-model="deduction_rules.deduct"
                              type="text"
                              disabled
                              inputmode="numeric"
                              class="form-control form-control-sm"
                            />
                          <div class="input-group-prepend"><span class="input-group-text"><small>%</small></span>
                          </div>
                        </div>
                      </validation-provider>
                    </div>
                    <label class="col-md-5 mt-1 pt-1 pb-0 col-form-label form-control-label">
                      Base de cálculo
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-7 mt-1">
                      <validation-provider rules="required" v-slot="{errors}">
                        <div class="input-group input-group-sm">
                          <input maxlength="5"
                            v-on:blur="deductValueOnExit()"
                            v-model="deduction_rules.base"
                            type="text"
                            disabled
                            inputmode="numeric"
                            class="form-control form-control-sm"
                          />
                          <div class="input-group-prepend"><span class="input-group-text"><small>%</small></span>
                          </div>
                        </div>
                      </validation-provider>
                    </div>
                    <label class="col-md-5 mt-1 pt-1 pb-0 col-form-label form-control-label">
                      Alíquota total
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-7 mt-1">
                      <validation-provider rules="required" v-slot="{errors}">
                        <div class="input-group input-group-sm">
                          <input maxlength="5"
                            v-model="deduction_rules.total_rate"
                            type="text"
                            disabled
                            v-on:input="deduction_rules.total_rate = formatString($event.target.value, '0', '100.0', '2')"
                            inputmode="numeric"
                            class="form-control form-control-sm"
                          />
                          <div class="input-group-prepend"><span class="input-group-text"><small>%</small></span>
                          </div>
                        </div>
                      </validation-provider>
                    </div>
                    <label class="col-md-5 mt-1 pt-1 pb-0 col-form-label form-control-label">
                      Alíquota retida
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-7 mt-1">
                      <validation-provider rules="required" v-slot="{errors}">
                        <div class="input-group input-group-sm">
                          <input maxlength="5"
                            v-model="deduction_rules.retained_rate"
                            type="text"
                            disabled
                            v-on:input="deduction_rules.retained_rate = formatString($event.target.value, '0', '100.0', '2')"
                            inputmode="numeric"
                            class="form-control form-control-sm"
                          />
                          <div class="input-group-prepend"><span class="input-group-text"><small>%</small></span></div>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1 mt-2"v-if="deduction_rules.rule == 'Taxa Fixa'">
                    <label class="col-md-5 mt-1 pt-1 pb-0 col-form-label form-control-label">
                      Alíquota total
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-7 mt-1">
                      <validation-provider rules="required" v-slot="{errors}">
                        <div class="input-group input-group-sm">
                          <input maxlength="5"
                            v-model="deduction_rules.total_rate"
                            type="text"
                            v-on:input="deduction_rules.total_rate = formatString($event.target.value, '0', '100.0', '2')"
                            inputmode="numeric"
                            class="form-control form-control-sm"
                          />
                          <div class="input-group-prepend"><span class="input-group-text"><small>%</small></span>
                          </div>
                        </div>
                      </validation-provider>
                    </div>
                    <label class="col-md-5 mt-1 pt-1 pb-0 col-form-label form-control-label">
                      Alíquota retida
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-7 mt-1">
                      <validation-provider rules="required" v-slot="{errors}">
                        <div class="input-group input-group-sm">
                          <input maxlength="5"
                            v-model="deduction_rules.retained_rate"
                            type="text"
                            v-on:input="deduction_rules.retained_rate = formatString($event.target.value, '0', '100.0', '2')"
                            inputmode="numeric"
                            class="form-control form-control-sm"
                          />
                          <div class="input-group-prepend"><span class="input-group-text"><small>%</small></span></div>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1 mt-2">
                    <label class="col-md-5 pb-0 mb-1  col-form-label form-control-label">
                      Observações
                    </label>
                    <div class="col-md-7 mt-1">
                      <base-input input-group-classes="input-group-sm">
                          <textarea
                            v-model="deduction_rules.observation"
                            type="text"
                            rows="2"
                            class="form-control form-control-sm"
                          >
                          </textarea>
                      </base-input>
                    </div>
                  </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()"
              >Cancelar
              </base-button
              >
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingStore"
              >Salvar
              </base-button
              >
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'

export default {
  name: "ModalCreate",
  data() {
    return {
      modal: {
        title: 'Tributos',
        create: false,
      },
      loadingStore: false,
      loadingCities: false,
      deduction_rules: {},
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      type: null,
      tribute: {
        state: '',
        city: '',
      },
      tax: {
        name: '',
        coverage: '',
      },
      Cities: [],
      types: [
        {
          id: 'service',
          text: 'Serviço',
        },
        {
          id: 'product',
          text: 'Produto',
        },
      ],
      rules: [
        {
          id: "DC",
          text: 'Dedução'
        },
        {
          id: "TF",
          text: 'Taxa Fixa'
        }
      ],
      conditions: [
        {
          id: 1,
          text: 'Adotar sempre o MCC',
        },
        {
          id: 2,
          text: 'Adotar sempre maior dedução',
        },
        {
          id: 3,
          text: 'Adotar sempre menor dedução',
        }
      ],
      listService: [],
      loadingService: true,
    }
  },
  computed: {
    ...mapGetters({
      'states': 'localizations/states',
      'cities': 'localizations/cities',
    }),
  },
  mounted() {
    this.$watch('deduction_rules.deduction', function (value) {
      this.deduction_rules.base_tax = 100 - value
    })
  },
  methods: {
    closeModal() {
      this.modal.create = false
    },
    removeConditions() {
      this.deduction_rules.condition = null
    },
    setServiceOrProduct() {
      switch (this.type) {
        default:
          this.getServices()
          break;
      }
    },
    handleCreateModal(deductionRules) {
      this.deduction_rules = {...deductionRules}
      this.tribute = {
        state: '',
        city: '',
      }

      this.modal.create = true
    },
    getCities() {
      this.loadingCities = true
      this.tribute.city = null
      this.Cities = this.cities.filter(item => item.state_id == this.tribute.state)
      this.loadingCities = false
    },
    getServices() {
      this.loadingService = true
      this.$Progress.start();
      this.$axios.get("/configuration/service")
        .then(({data}) => {
          this.loadingService = false
          this.listService = data.data;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loadingService = false
          this.$Progress.finish();
        });
    },
    update() {
      this.deduction_rules.total_rate = this.deduction_rules.total_rate.replace(",",".")
      this.deduction_rules.retained_rate = this.deduction_rules.retained_rate.replace(",",".")
      let params = this.deduction_rules
      this.loadingStore = true
      this.$Progress.start()
      this.$store.dispatch('deductionRules/update', params).then(response => {
        this.loadingStore = false
        this.modal.create = false
        this.$Progress.finish()
        this.$emit('init')
        this.$notify({type: response.error_type, message: response.message})
      }).catch(error => {
        if (error.response.status === 422) {
          let message = formatErrorValidation(error.response.data.errors)
          this.$notify({type: 'danger', message: message})
        } else {
          this.$notify({type: error.data.error_type, message: error.data.message})
        }
        this.$Progress.finish()
        this.loadingStore = false
      })
    },
    baseValueOnExit(){
      this.deduction_rules.base = 100 - this.deduction_rules.deduct
    },
    deductValueOnExit(){
      this.deduction_rules.deduct = 100 - this.deduction_rules.base
    }
  },
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>

<template>
  <div>
    <modal :show.sync="modal" size="lg">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/order-history.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ title }}</h5>
        </div>
      </template>
      <div>
        <div class="container-fluid">
          <div class="row mt-3 mb-3">
            <div class="col-12" style="display: flex; align-items: center;">
              <img src="/img/icons/icons8/ios/info-squared_warning.png" width="20">
              <h4 class="new-default-black-font m-0 mx-3" style="white-space: nowrap;">
                Informação
              </h4>
              <hr class="ml-3 m-0" style="width: 100%;">
            </div>
          </div>
          <div v-if="purchaseOrder" class="row mb-3 align-items-center">
            <div class="col-12">
              <h4 class="new-default-black-font font-weight-normal m-0 mb-1">
                {{ purchaseOrder.code }}
              </h4>
              <h5 class="new-default-gray-font font-weight-normal mb-1">
                {{ purchaseOrder.cost_center_name }}
              </h5>
              <h5 class="new-default-gray-font font-weight-normal mb-1">
                {{ $helper.parseDate(purchaseOrder.deadline, "DD MMM YYYY") }}
              </h5>
              <h5 class="new-default-gray-font font-weight-normal">
                {{ purchaseOrder.input_company_plant_name }}
              </h5>
            </div>
          </div>
          <div class="row mt-2 mb-4">
            <div class="col-12" style="display: flex; align-items: center;">
              <img src="/img/icons/order-history.png" width="20">
              <h4 class="new-default-black-font m-0 mx-3" style="white-space: nowrap;">
                Histórico
              </h4>
            </div>
          </div>
          <div v-if="history.length" class="custom-card-wrapper mb-3 pt-4">
            <div class="container">
              <div class="row card-wrapper px-1">
                <div class="col-lg-12 px-1 mt-3" v-if="history.length" v-for="(item, index) in history" :key="index">
                  <div class="container">
                    <div class="row">
                      <div class="col-12" style="display: flex; align-items: center;">
                        <el-popover trigger="hover" placement="right">
                          <span style="width: 210px;" class="row">
                            <div class="col-12 mb-2" style="display: flex; align-items: center;">
                              <img class="mr-2" src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                              <h4 style="font-size: 14px;" class="p-0 m-0 font-weight-500">Informações</h4>
                            </div>
                            <div class="col-12 mt-0 mb-n2">
                              <h5 class="font-weight-normal fs-12"><b class="fs-13">Usuário: </b>
                                {{ item.user_name }}
                              </h5>
                            </div>
                          </span>
                          <base-button outline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                            <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                          </base-button>
                        </el-popover>
                        <h4 class="new-default-black-font m-0 mx-3" style="white-space: nowrap;">
                          {{  $helper.parseDate(item.created_at, "DD MMM YYYY") }}
                        </h4>
                        <hr class="ml-3 m-0" style="width: 100%;">
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-12">
                        <div class="card main-card mb-3">
                          <div class="pt-3 px-3">
                            <div class="row align-items-center">
                              <div class="col-12" style="display: inline-flex; align-items: center;">
                                <img :src="statusEnum[item.status].img" width="18">
                                <h4 class="m-0 ml-2 new-default-black-font">
                                  {{ statusEnum[item.status].text }}
                                </h4>

                              </div>
                              <span class="ml-3" v-if="item.status == purchaseOrderHistoryStatusEnum.CANCELED">
                                {{ item.status_reason }}
                              </span>
                            </div>
                            <div class="row mt-2">
                              <div class="col-12">
                                <p class="status-reason new-default-black-font mb-0">
                                  {{ purchaseOrder.finance_type_name }}
                                </p>
                              </div>
                              <div class="col-12">
                                <p class="status-reason-description new-default-black-font text-right">
                                  {{ statusEnum[item.status].text }}
                                  <i v-if="purchaseOrderHistoryStatusEnum.PENDING != item.status">
                                    em {{ $helper.parseDate(item.created_at, "DD MMM YYYY") }}
                                  </i>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PuzlEmptyData msg="Não existem operações suficientes para geração de histórico" v-else />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import PuzlEmptyData from "@/components/PuzlEmptyData";

import { PurchaseOrderHistoryStatusEnum } from "@/enum/PurchaseOrderHistoryStatusEnum";

export default {
  components: { PuzlEmptyData },
  name: "ModalConciliationHistory",
  data() {
    return {
      modal: false,
      title: "Histórico de operações",
      purchaseOrder: [],
      history: [],
      purchaseOrderHistoryStatusEnum: PurchaseOrderHistoryStatusEnum,
      statusEnum: {
        0: {
          text: 'Pendente',
          description: "PENDING",
          img: "/img/icons/icons8/ios/folder-invoices.png"
        },
        1: {
          text: 'Aprovado',
          description: "OPEN",
          img: "/img/icons/icons8/ios/thumb-up_success.png"
        },
        2: {
          text: 'Negado',
          description: "DENIED",
          img: "/img/icons/icons8/ios/thumbs-down_danger.png"
        },
        3: {
          text: 'Cancelado',
          description: "CANCELLED",
          img: "/img/icons/icons8/ios/cancel_danger.png"
        },
        4: {
          text: 'Concluido',
          description: "COMPLETED",
          class: "primary",
          img: "/img/icons/icons8/ios/double-tick-primary.png"
        },
        5: {
          text: 'Criado',
          description: "CREATED",
          img: "/img/icons/icons8/ios/folder-invoices.png"
        },
      }
    };
  },
  methods: {
    close() {
      this.modal = false;
    },
    handleCreateModal(purchaseOrder) {
      this.purchaseOrder = purchaseOrder;
      this.getAll(purchaseOrder.id, "purchase_order_id");
    },
    getAll(id) {
      let loader = this.$loading.show();
      this.$store.dispatch("purchaseOrder/getHistory", id)
        .then(response => {
          this.history = response.data;
          loader.hide();
          this.modal = true;
        });
    }
  },
};
</script>

<style scoped>
.gray-divider {
  background-color: #E8E8E8;
  color: #E8E8E8;
  margin: 0;
  border-top: 1px solid #E8E8E8;
}

h1,
h2,
h3,
h4,
h5,
p {
  font-family: "Fredoka", sans-serif;
}

.status-reason {
  font-family: Fredoka;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.status-reason-description {
  font-family: Fredoka;
  font-size: 10px;
  font-weight: 500;
  text-align: left;
}

.status-reason-description i {
  font-style: normal;
  font-size: 10px;
  font-weight: 400;
}

.custom-card-wrapper {
  border: 1px solid #eeeeee;
  background: #1A70B70D;
  border-radius: 16px;
  padding: 16px 12px;
  min-height: 55px;
  max-height: 520px;
  overflow-y: scroll;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07) !important;
}

.card {
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 16px !important;
  border: 1px solid #e2e4e7;
  background: #FFFFFF80;
}
</style>

<template>
  <div>
    <modal size="md" :show.sync="modal">
      <div class="container-fluid">
        <div class="custom-header px-3 py-3 text-white" style="margin-top: -16px; margin-left: -38px;margin-right: -38px;border-top-left-radius: 15px;border-top-right-radius: 15px;">
          <img class="mr-2" src="/img/icons/icons8/ios/info-circle.png" width="25">
          Pendências
          <button type="button"
            class="close text-white" @click="closeModal"
            style="margin-top: -10px"aria-label="Close">
              <i style="font-size: 20px" class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <div v-if="schedule" class="container-fluid mt-4">
        <div class="row align-items-center mb-3">
          <div class="col-6" style="display: flex; align-items: center;">
            <img src="/img/icons/icons8/ios/info-squared_warning.png" width="22">
            <h4 class="new-default-black-font m-0 p-0 ml-2">Identificação</h4>
          </div>
          <div class="col-6">
            <hr class="black-divider">
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h4 class="new-default-black-font m-0 p-0 mr-2" style="font-size: 14px;">
              {{schedule.contract_proposal.code}}
            </h4>
          </div>
          <div class="col-12 new-default-black-font font-weight-400" style="font-size: 13px">
            {{ schedule.contract_proposal.customer_name }}
          </div>
          <div class="col-12 new-default-black-font font-weight-400" style="font-size: 13px">
            {{ schedule.contract_proposal.construction_name }}
          </div>
        </div>
        <div class="row align-items-center mt-4 mb-3">
          <div class="col-6" style="display: flex; align-items: center;">
            <img src="/img/icons/icons8/ios/error--v1_danger.png" width="22">
            <h4 class="new-default-black-font m-0 p-0 ml-2">Pendências</h4>
          </div>
          <div class="col-6">
            <hr class="black-divider">
          </div>
        </div>
        <div class="row mb-2">
          <div class="container px-0">
            <div class="px-4 py-3 mb-1 card-with-box-shadow"
              v-for="(pendencie, index) in schedule.status.pendencies" :key="index">
              <div class="row">
                <div class="col-2 pr-0 align-items-center" style="display: flex;">
                  <div style="border: 1px solid rgb(130, 131, 134); padding: 6px;border-radius: 35px;">
                    <img :src="getPendencieInfo(pendencie).imagem" width="25">
                  </div>
                </div>
                <div class="col-10 pl-0 ml-n2">
                  <h4 class="mb-0 pb-0 new-default-black-font">{{ getPendencieInfo(pendencie).titulo }}</h4>
                  <h4 class="font-weight-400 new-default-black-font" style="font-size: 13px;">{{ getPendencieInfo(pendencie).texto }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

export default {
  components: {},
  name: "ModalPendencies",
  data() {
    return {
      modal: false,
      title: "Pendências",
      schedule: null,
    };
  },
  methods: {
    getPendencieInfo(pendencie)
    {
      const text = pendencie.toLowerCase();

      switch(text){
        case "traço não aprovado":
          return {
            titulo: "Preço de traço abaixo do mínimo",
            texto: "Solicitar aprovação comercial",
            imagem: "/img/icons/icons8/ios/test-tube_primary.png"
          };
        case "limite de crédito insuficiente":
          return {
            titulo: "Cliente sem limite de crédito",
            texto: "Solicitar aprovação financeira",
            imagem: "/img/icons/icons8/ios/plus-2-math_success.png"
          };
        case "cliente bloqueado":
          return {
            titulo: "Cliente bloqueado",
            texto: "Solicitar desbloqueio ao financeiro",
            imagem: "/img/icons/icons8/ios/id-not-verified.png"
          };
        case "central sem traço":
          return {
            titulo: "central sem traço",
            texto: "Solicitar elaboração para a tecnologia",
            imagem: "/img/icons/icons8/ios/physics.png"
          };
        case "traço inativo":
          return {
            titulo: "Traço inativo no contrato",
            texto: "Solicitar ativação para o comercial",
            imagem: "/img/icons/icons8/ios/briefcase-green.png"
          };
        case "serviço pendente":
          return {
            titulo: "Preço de serviço abaixo do mínimo",
            texto: "Solicitar aprovação comercial",
            imagem: "/img/icons/icons8/ios/to-do.png"
          };
       case "pendente aprovação financeira":
          return {
            titulo: "Pendente aprovação financeira",
            texto: "Solicitar aprovação financeira",
            imagem: "/img/icons/finance.png"
          };
       case "pendência de vistoria":
          return {
            titulo: "pendência de vistoria",
            texto: "Editar programação para liberar",
            imagem: "/img/icons/icons8/ios/inspection_primary.png"
          };
       case "aguardando liberação":
          return {
            titulo: "Aguardando liberação do cliente",
            texto: "Editar programação para liberar",
            imagem: "/img/icons/icons8/ios/brick-wall_warning.png"
          };
       case "expiração da data de limite de crédito":
          return {
            titulo: "Expiração da data de limite de crédito",
            texto: "Renovar a data limite de crédito",
            imagem: "/img/icons/finance.png"
          };
        case "pendência de assinatura de contrato":
          return {
            titulo: "Assinatura de contrato",
            texto: "Solicitar validação comercial",
            imagem: "/img/icons/icons8/ios/briefcase-green.png"
          };
      case "pendente aprovação técnica":
          return {
            titulo: "Pendente Aprovação Técnica",
            texto: "Aprovar na tela de aprovação técnica",
            imagem: "/img/icons/icons8/ios/hourglass_warning.png"
          };
      };
    },
    closeModal() {
      this.modal = false;
    },
    openModal(schedule) {
      this.modal = true;
      this.schedule = schedule;
    },
  },
};
</script>

<style scoped>
.custom-header {
  background-color: #f2b532;
}

.black-divider {
  background-color:  #2B2D32;
  color: #2B2D32;
  margin: 0;
  padding-top: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.card-with-box-shadow{
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 15px !important;
}
</style>

<template>
  <div>
    <AppFixedPageTitle
      title="Resumo de Adicionais"
      icon="/img/icons/chemical-plant-white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :showCompanyPlants="true"
          :isLoading="loading"
          @onSearchClick="init({})"
          @onClearClick="clearFilter"
        >
          <AppSearchBarFilterSection
            name="dados"
            icon="/img/icons/icons8/ios/info-squared_gray.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label class="fs-12 font-weight-400"> Adicionais </label>
              <PuzlSelect
                style="width: 100%"
                v-model="filter.additional_ids"
                label="short_description"
                :items="$_additionals"
                :multiple="true"
                :disableBoxShadow="true"
                class="select-sm col-md-12 px-0 new-default-black-font"
              />
            </div>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label class="fs-12 font-weight-400"> NÚMERO DA O.S </label>
              <div class="row">
                <div class="col-6 text-left pr-1">
                  <input
                    v-model="filter.os_range.start"
                    placeholder="DE"
                    class="form-control form-control-sm btn-first-child"
                  />
                </div>
                <div class="col-6 pl-1">
                  <input
                    v-model="filter.os_range.end"
                    placeholder="ATÉ"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label class="fs-12 font-weight-400"> NÚMERO DA NF-E </label>
              <div class="row">
                <div class="col-6 text-left pr-1">
                  <input
                    v-model="filter.nfe_range.start"
                    placeholder="DE"
                    class="form-control form-control-sm btn-first-child"
                  />
                </div>
                <div class="col-6 pl-1">
                  <input
                    v-model="filter.nfe_range.end"
                    placeholder="ATÉ"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <div class="d-flex justify-content-end" style="gap: 12px">
          <div class="card">
            <div class="d-flex">
              <img
                height="24px"
                src="/img/icons/clock--v1.png"
                class="mr-1 mb-1"
              />
              <label
                class="fs-14 font-weight-400 pt-1 ml-1"
                style="color: #606062"
                >A COBRAR</label
              >
            </div>
            <div>
              <h5 class="fs-16 font-weight-400">
                {{ ($_widgets.active || 0) | currency() }}
              </h5>
            </div>
          </div>
          <div class="card">
            <div class="d-flex">
              <img
                height="24px"
                src="/img/icons/cancel-red.png"
                class="mr-1 mb-1"
              />
              <label
                class="fs-14 font-weight-400 pt-1 ml-1"
                style="color: #606062"
                >CANCELADOS</label
              >
            </div>
            <div>
              <h5 class="fs-16 font-weight-400">
                {{ ($_widgets.canceled || 0) | currency() }}
              </h5>
            </div>
          </div>
        </div>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="handleViewTypeChange"
    >
      <AppSelect
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="onSelectOrderBy()"
        variant="text-only"
      />
      <a href="#" @click.prevent="downloadExcel()" style="margin-left: 16px; padding-right: 16px;">
        <img style="width: 20px" src="/img/icons/excel.png" />
      </a>
    </AppTabSelect>
    <div class="container-fluid mt-4">
      <ResumeAdditional
        :$_resumeAdditional="$_resumeAdditional"
        :loadingSkeleton="loadingSkeleton"
        v-if="listType === 'cards'"
      />
      <Table
        :$_resumeAdditional="$_resumeAdditional"
        :loadingSkeleton="loadingSkeleton"
        v-if="listType === 'table'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ResumeAdditional from "./Shared/_List";
import Table from "./Shared/_Table";
import {
  AppFixedPageTitle,
  AppPageHeader,
  initSearchBarFilterType,
  AppSearchBarFilterSection,
  AppSearchBar,
  AppTabSelect,
  AppSelect,
} from "../../../../components/AppGlobal";
import PuzlSelect from "@/components/PuzlSelect";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import { date } from "../../../../helpers";

export default {
  name: "Index",
  mixins: [cursorPaginate],
  components: {
    ResumeAdditional,
    Table,
    AppFixedPageTitle,
    AppPageHeader,
    AppSearchBar,
    AppSearchBarFilterSection,
    PuzlSelect,
    AppTabSelect,
    AppSelect,
    LoadingPagination,
  },
  data() {
    return {
      additionals_filter: [],
      filter: {
        os_range: {
          start: null,
          end: null,
        },
        nfe_range: {
          start: null,
          end: null,
        },
      },
      searchBarFilter: initSearchBarFilterType(),
      currentDate: date.make().format(date.FORMAT.ISO_8601),
      loadingSkeleton: true,
      listType: "cards",
      orderBy: {
        items: [
          {
            id: 0,
            name: "PADRÃO",
            selected_name: "SELECIONE",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: {
              column: null,
              is_desc: null,
            },
          },
          {
            id: 1,
            name: "VALOR MAIOR PARA O MENOR",
            selected_name: "MAIOR VALOR",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: {
              column: "schedule_additionals.price",
              is_desc: 1,
            },
          },
          {
            id: 2,
            name: "VALOR MENOR PARA O MAIOR",
            selected_name: "MENOR VALOR",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: {
              column: "schedule_additionals.price",
              is_desc: 0,
            },
          },
          {
            id: 3,
            name: "REGISTRO MAIS NOVO PARA O MAIS VELHO",
            selected_name: "REGISTRO NOVO",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: {
              column: "schedule_additionals.id",
              is_desc: 1,
            },
          },
          {
            id: 4,
            name: "REGISTRO MAIS VELHO PARA O MAIS NOVO",
            selected_name: "REGISTRO VELHO",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: {
              column: "schedule_additionals.id",
              is_desc: 0,
            },
          },
          {
            id: 5,
            name: "DATA MAIS NOVA PARA A MAIS VELHA",
            selected_name: "DATA NOVA",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: {
              column: "schedule_travels.finished_at",
              is_desc: 0,
            },
          },
          {
            id: 6,
            name: "DATA MAIS VELHA PARA A MAIS NOVA",
            selected_name: "DATA VELHA",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: {
              column: "schedule_travels.finished_at",
              is_desc: 1,
            },
          },
        ],
        selected: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      $_widgets: "resumeAdditional/getWidgets",
      $_resumeAdditional: "resumeAdditional/fetch",
      $_additionals: "additional/additionals",
    }),
    tabSelectItems() {
      return [
        {
          id: null,
          name: "Todos",
          selected: this.filter.status == null,
        },
        {
          id: 0,
          name: "Cobrança",
          selected: this.filter.status === 0,
        },
        {
          id: 1,
          name: "Cancelado",
          selected: this.filter.status === 1,
        },
        {
          id: 2,
          name: "Faturado",
          selected: this.filter.status === 2,
        },
        {
          id: 3,
          name: "Faturado Sem Cobrança",
          selected: this.filter.status === 3,
        },
      ];
    },
  },
  methods: {
    onSelectOrderBy() {
      this.filter.order_by = this.orderBy.items[this.orderBy.selected].filter;
      this.init({});
    },
    handleViewTypeChange(type) {
      this.listType = type;
    },
    downloadExcel() {
      const loader = this.$loading.show();
      this.$store
        .dispatch("scheduleAdditional/getReportExcel", this.filter)
        .then(() => {
          this.$notify({
            type: "success",
            message:
              "Solicitação realizada com sucesso! O relatório será exportado em segundo plano.",
          });
        })
        .catch((error) => {
          const errors =
            error && error.response && error.response.status === 422
              ? formatErrorValidation(error.response.data.data)
              : error.response.data.message;
          this.$notify({ type: "danger", message: errors });
        })
        .finally(() => {
          loader.hide();
        });
    },
    onTabSelectItemClick(item) {
      this.filter.status = item.id;
      this.init(this.filter);
    },
    clearFilter() {
      let searchBarFilterValue = this.defaultSearchBarFilter();
      Object.assign(this.searchBarFilter, searchBarFilterValue);
      this.init(this.filter);
    },
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
        range: {
          start: this.currentDate,
          end: this.currentDate,
        },
      };
    },
    getWidgets() {
      this.loadingSkeletonWidgets = true;
      this.$store
        .dispatch("resumeAdditional/fetchWidgets", {
          filter: this.filter,
        })
        .then((response) => {
          this.loadingSkeletonWidgets = false;
        })
        .catch((error) => {
          this.loadingSkeletonWidgets = false;
        });
    },
    init(filter = null) {
      this.filter.global = this.searchBarFilter.custom_search_values;
      this.filter.range = this.searchBarFilter.range;
      this.startCursor(filter);
      this.getWidgets();
      this.$Progress.start();
      this.$store
        .dispatch("resumeAdditional/fetchItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl,
        })
        .then((response) => {
          this.$Progress.finish();
          this.resolveCursor(response);
        })
        .catch((error) => {
          this.resolveCursor();
          this.$Progress.finish();
        })
        .finally(() => {
          this.loadingSkeleton = false;
        });
    },
  },
  mounted() {
    this.clearFilter();
    this.$store.dispatch("additional/fetchAdditionals");
  },
};
</script>

<style scoped>
* {
  color: #2b2d32;
}

.card {
  border-radius: 10px !important;
  box-shadow: 0px 5px 10px 0px #0000000d !important;
  min-width: 200px;
  height: 75px;
  padding: 10px 16px;
  margin-bottom: 0px;
}
</style>

import store from "../../../../shared/libraries/store";
import { SearchBarFilterType } from "../../../../components/AppGlobal";
import { dreStateKeys } from "./dreStateKeys";
import { initDreListType } from "../types";

/** @returns {?StockLocationViewType} */
const getConfigurationList = () => store.getState(dreStateKeys.DRE_CONFIGURATION);
const getConfigurationImmutable = () => store.getState(dreStateKeys.DRE_CONFIGURATION_IMMUTABLE);

/** @returns {StockLocationListPagerType} */
const getListed = () => store.getState(dreStateKeys.LISTED) ?? initDreListType();

/** @returns {boolean} */
const getIsListing = () => store.getState(dreStateKeys.IS_LISTING) ?? false;

const getIsSaving = () => store.getState(dreStateKeys.IS_SAVING) ?? false;
/**
 * @returns {{
 *   searchBarFilter: SearchBarFilterType,
 *   filter: MdfeListFilterType
 * }}
*/
const getPageState = () => store.getState(dreStateKeys.PAGE_STATES);

export default {
  getListed,
  getIsListing,
  getIsSaving,
  getPageState,
  getConfigurationList,
  getConfigurationImmutable
};

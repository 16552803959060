import { BaseListFilterType, initBaseListFilterType, RangeDateType, RangeValueType } from "../../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} FinancialApprovalListFilterExtType
 * @property {boolean|null} status
 * @property {int|null} type
 * @property {Array|null} bank_accounts
 * @property {boolean|null} pending_card_integration
 * @property {boolean|null} is_nsu_code_duplicated
 * @property {RangeDateType|null} range - Intervalo entre datas
 * @property {RangeValueType|null} range_value
 * @property {?number} company_plant_id
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & FinancialApprovalListFilterExtType)} FinancialApprovalListFilterType
 */
export const FinancialApprovalListFilterType = {};

/**
 * Inicializar FinancialApprovalListFilterType
 *
 * @returns {FinancialApprovalListFilterType}
 */
export const initIntegrationListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    status: null,
    range: {
      start: null,
      end: null,
    },
    company_plant_id: null,
    order_list_by: '',
  };

}
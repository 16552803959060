import { createAxios } from "@/plugins/axios";;
import * as types from './mutation_types'
import {cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
const endPoint = '/equipment/fuel-resume'
export default {
  namespaced: true,
  state: {
    widgets: {},
    totalKmPanelDifference: {},
    ownFleetItems: [],
  },
  getters:{
    getWidgets: state => state.widgets,
    getOwnFleetItems: state => state.ownFleetItems,
    getTotalKmPanelDifference: state => state.totalKmPanelDifference,
  },
  mutations: {
    [types.SET_WIDGETS](state, payload) {
      state.widgets = payload
    },
    [types.SET_OWN_FLEET](state, payload) {
      state.ownFleetItems = payload
    },
    [types.SET_TOTAL_KM_PANEL_DIFFERENCE](state, payload) {
      state.totalKmPanelDifference = payload
    },
  },
  actions: {
    getWidgets({commit}, params) {
      return createAxios().get(`${endPoint}/widgets`, {params: params})
        .then(({data}) => {
          commit(types.SET_WIDGETS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchOwnFleetItemsPaginate({state}, params) {
      return createAxios().get(setUrlWithNextPage(`${endPoint}/own-fleet`, params),
        {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page}, 'ownFleetItems')
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getReportPdf(_, params) {
      const isAsync = 1;
      return createAxios().post(`${endPoint}/report/${isAsync}/pdf`, {
        filter: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getReportExcel(_, params) {
      const isAsync = 1;
      return createAxios().post(`${endPoint}/report/${isAsync}/excel`, {
        filter: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getTotalKmPanelDifference({commit}, params) {
      return createAxios().get(`${endPoint}/total-km-panel-difference/${params.filter.id}`, {params: params})
        .then(({data}) => {
          commit(types.SET_TOTAL_KM_PANEL_DIFFERENCE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}

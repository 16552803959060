<template>
  <modal size="lg" :show.sync="modal" class="pb-5">
    <template slot="header">
      <h5 class="modal-title p-0 m-0">
        <img height="25" src="/img/icons/autograph.png" alt="autograph" />
        {{ title }}
      </h5>
    </template>
    <div class="p-3">
      <!-- Informaçoes sobre o documento-->
      <div class="d-flex align-items-center">
        <img width="25" height="25" src="/img/icons/info-squared-yellow.png" alt="info-squared" />
        <span class="ml-2 mr-3 font-weight-bold">Informação</span>
      </div>
      <div class="mt-3 mb-2 mb-sm-3" style="display: grid;">
        <span style="font-weight: 500;" class="font-600">{{ contract_proposal.code }}</span>
        <span>{{ contract_proposal.entity.entity_name }}</span>
        <span>{{ contract_proposal.construction.construction_name }}</span>
      </div>
      <div class="d-grid d-sm-flex justify-content-around">
        <!-- Ignorar assinaturas -->
        <el-popover v-if="contract_proposal.ignore_signature" trigger="hover" placement="bottom" class="p-0">
          <div class="d-grid">
            <span>Assinaturas ignoradas por:</span>
            <div>{{ contract_proposal.ignored_signature_by_user_name }}</div>
            <div>{{ contract_proposal.ignored_signature_by_user_email }}</div>
            <div>{{ this.$helper.parseDate(contract_proposal.ignored_signature_at, 'DD/MM/YYYY HH:mm') }}</div>
          </div>
          <div slot="reference">
            <div class="px-3 py-1 py-sm-1 mt-1 mt-sm-0 pointer button justify-content-center"
              v-if="config.require_signed_contract_for_delivery && documents.length <= 0"
              @click.prevent="updateContractProposal(contract_proposal)">
              <img class="mr-2" width="20" src="/img/icons/term-warning.png" alt="term-warning" />
              <span class="pr-2" style="font-size: 12px; max-width: 250px;">Assinaturas ignoradas</span>
            </div>
          </div>
        </el-popover>
        <div class="px-3 py-1 py-sm-1 mt-1 mt-sm-0 pointer button justify-content-center"
          v-if="!contract_proposal.ignore_signature && config.require_signed_contract_for_delivery && documents.length <= 0"
          @click.prevent="updateContractProposal(contract_proposal)">
          <img class="mr-2" width="20" src="/img/icons/term-warning.png" alt="term-warning" />
          <span class="pr-2" style="font-size: 12px; max-width: 250px;">
            Ignorar assinaturas
          </span>
        </div>
        <!-- Proposta -->
        <div class="mx-4 py-1 py-sm-1 mt-1 mt-sm-0 pointer button justify-content-center"
          @click.prevent="config.proposal ? handleShowModal({ ref: 'ModalDigitalSignatureCreate', data: { contract_proposal, isContract: false, config, documents } }) : ''"
          :style="!config.proposal ? { backgroundColor: 'rgb(217, 217, 217)', opacity: 1 } : {}" data-toggle="tooltip"
          :class="config.require_signed_contract_for_delivery && documents.length <= 0 ? 'px-3' : 'px-5'"
          :title="!config.proposal ? 'Função desabilitado nas configurações' : ''">
          <img class="mr-2" width="20" src="/img/icons/term-warning.png" alt="term-warning" />
          <span class="pr-2" style="font-size: 12px; max-width: 250px;">nova vigência de proposta</span>
        </div>
        <!-- Contrato -->
        <div class="px-1 py-1 py-sm-1 mt-2 mt-sm-0 pointer button justify-content-center mt-2 mt-sm-0"
          :class="config.require_signed_contract_for_delivery && documents.length <= 0 ? 'px-3' : 'px-5'"
          @click.prevent="config.contract ? handleShowModal({ ref: 'ModalDigitalSignatureCreate', data: { contract_proposal, isContract: true, config, documents } }) : ''"
          :style="!config.contract ? { backgroundColor: 'rgb(217, 217, 217)', opacity: 1 } : {}" data-toggle="tooltip"
          :title="!config.contract ? 'Função desabilitado nas configurações' : ''">
          <img class="mr-2" width="20" src="/img/icons/agreement-primary.png" alt="agreement" />
          <span class="pr-2" style="font-size: 12px; max-width: 250px;">nova vigência de contrato</span>
        </div>
      </div>
    </div>
    <!-- Lista de Documentos (propostas/contratos) -->
    <div v-for="(document, index) in documents" :key="document" class="px-3 mt-3 pb-2">
      <div class="d-flex align-items-center">
        <img v-if="document.name === 'Contrato'" width="25" height="25" src="/img/icons/agreement-primary.png"
          alt="info-squared" />
        <img v-if="document.name === 'Proposta'" width="25" src="/img/icons/term-warning.png" alt="term" />
        <span class="ml-2 mr-3 font-weight-bold text-nowrap">{{ document.name }} - {{ document.created_at |
          parseDate('DD MMM YYYY') }}</span>
        <!-- Download -->
        <div style="position: relative; width: -webkit-fill-available; cursor: pointer; margin-top: 0.8rem;"
          @click="openUrl(document.original_file)">
          <div
            style="background-color: #D9D9D9; width: fit-content; position: absolute; bottom: 0; right: 0; border-radius: 20px 20px 0 0;"
            class="p-1 px-3 d-flex justify-content-center align-itens-center">
            <img width="15" class="mr-1" src="/img/icons/download-primary.png" alt="download--v1" />
            <span style="color: #1A70B7; font-size: 10px;">Download</span>
          </div>
          <div class="dropdown-divider my-0" style="width: -webkit-fill-available;" />
        </div>
      </div>
      <!-- Status no Mobile -->
      <div class="align-self-center status-button my-3 d-sm-none"
        :style="{ backgroundColor: document.deleted ? '#f8dad7' : '#d0ecdd', color: document.deleted ? '#DB4539' : '#149E57' }">
        {{ document.status == "signed" ? 'ASSINADO' : document.deleted ? 'CANCELADO' : 'VIGENTE' }}
      </div>
      <div class="d-flex justify-content-between align-items-center my-2 my-sm-3" style="font-weight: 500;">
        <div class="d-flex align-items-center ">
          <span>
            Serviço de concretagem
          </span>
          <el-popover trigger="click" placement="bottom" class="p-0">
            <div style="font-size: 12px">{{ document.created_by.email }}</div>
            <span slot="reference">
              <img class="ml-3 mr-2 d-none d-sm-block" width="25" src="/img/icons/icons8/ios/user-male-circle--v1.png">
            </span>
          </el-popover>
          <!-- <img v-if="document.deleted" class="d-none d-sm-block" width="25" src="https://img.icons8.com/ios/50/DB4539/user-male-circle--v1.png" alt="user-male-circle--v1"/> -->
          <!-- Status -->
          <div class="align-self-center status-button d-none d-sm-flex mx-3 justify-content-center align-items-center"
            :class="getClass(document.local_status)">
            {{ getStatusText(document.local_status) }}
          </div>
          <el-popover v-if="document.ignore_signature" trigger="hover" placement="right" class="p-0">
            <div class="d-grid">
              <span>Assinaturas ignoradas por:</span>
              <div>{{ document.ignored_by_name }}</div>
              <div>{{ document.ignored_by_email }}</div>
              <div>{{ $helper.parseDate(document.ignored_signature_at, 'DD/MM/YYYY HH:mm') }}</div>
            </div>
            <div slot="reference">
              <div v-if="index === 0" @click="updateIgnoreSignatureStatus(document)" class="default-button mx-2">
                <span>Assinaturas ignoradas</span>
              </div>
            </div>
          </el-popover>
          <div v-if="index === 0 && !document.ignore_signature" @click="updateIgnoreSignatureStatus(document)"
            class="default-button mx-2">
            <span>Ignorar Assinaturas</span>
          </div>

        </div>
        <div class="d-flex align-items-center">
          <base-dropdown class="mr-3" ref="dropdown" :closeOnClick="false" v-if="!document.deleted" menuOnRight
            style="margin-left: 3.7rem;">
            <div slot="title-container" class="dropdown-toggle rounded green-button px-3">
              <i class="fas fa-plus mr-1"></i> Novo
            </div>
            <!-- Criar novo signatario -->
            <div class="p-3" style="min-width: 21rem !important; padding-bottom: 0.6rem !important;">
              <div class="d-flex align-items-center mb-3">
                <img width="26" class="mr-2" src="/img/icons/hand-with-pen-primary.png" alt="hand-with-pen" />
                <span class="text-nowrap">Nova assinatura</span>
              </div>
              <div>
                <!-- Selecione o tipo de signatario -->
                <PuzlSelect class="input-info" v-model="newSigner.qualification" :items="[
                  { id: 'contracted', name: 'Contratado' },
                  { id: 'contractor', name: 'Contratante' },
                  ...(document.name === 'Contrato' ? [{ id: 'witness', name: 'Testemunha' }, { id: 'guarantor', name: 'Fiador' }] : [])
                ]" />
              </div>
              <!-- Nome e Email -->
              <base-input class="col-12 pr-1 mb-1 input-name-email-signatory" name="Name" v-model="newSigner.name"
                placeholder="Nome" :required="true" />
              <base-input class="col-12 pr-1 input-name-email-signatory" name="Email" v-model="newSigner.email"
                placeholder="Email" :required="true" />
              <div class="col-12 d-flex justify-content-end pr-0">
                <base-button :disabled="newSigner.qualification && newSigner.name && newSigner.email ? false : true"
                  @click="addSigner(document.token)" type="success" native-type="submit" :loading="loading"
                  class="mt-2 align-self-end" size="sm">
                  <img width="22" src="/img/icons/save.png" alt="info-squared" class="mr-1" />
                  Salvar
                </base-button>
              </div>
            </div>
          </base-dropdown>
          <div v-if="!document.deleted" class="pointer" @click="cancelDocument(document.token)">
            <img src="/img/icons/icons8/ios/trash_can.png" width="22">
          </div>
        </div>

      </div>
      <el-popover trigger="click" placement="bottom" class="p-0">
        <div style="font-size: 12px">{{ document.created_by }}</div>
        <span slot="reference">
          <img class="mb-2 mr-2 d-sm-none" width="25" src="/img/icons/icons8/ios/user-male-circle--v1.png"
            alt="user-male-circle--v1" />
          <img class="mb-2 d-sm-none" width="25" src="/img/icons/user-male-circle-danger.png"
            alt="user-male-circle--v1" />
        </span>
      </el-popover>

      <!-- Signatarios -->
      <div v-for="signer in document.signers" class="d-md-flex justify-content-between align-items-center mb-2">
        <span>{{
          signer.qualification === 'contracted' ? 'Contratado' :
            signer.qualification === 'contractor' ? 'Contratante' :
              signer.qualification === 'witness' ? 'Testemunha' :
                signer.qualification === 'guarantor' ? 'Fiador' :
                  'Outro'
        }}
        </span>
        <div class="px-3 py-2 py-sm-1 mt-1 mt-sm-0 pointer button button-size d-flex">
          <span class="text-truncate pr-2" style="font-size: 12px; max-width: 250px;">{{ signer.name }}</span>
          <div>
            <!-- Copiar URL -->
            <el-popover trigger="click" placement="bottom" class="p-0">
              <div style="font-size: 12px">Clique para copiar a URL de assinatura</div>
              <span slot="reference">
                <img
                  @click="$helper.copy(signer.sign_url), $notify({ type: 'success', message: 'Código da automação copiado com sucesso!' });"
                  width="20" src="/img/icons/copy-success.png" alt="copy-2" class="ml-3" />
              </span>
            </el-popover>
            <!-- Status -->
            <el-popover trigger="click" placement="bottom" class="p-0">
              <span style="font-size: 12px"> {{
                signer.status === 'new' ? 'Aguardando assinatura' :
                  signer.status === 'link-opened' ? 'Link aberto mas nao assinado' :
                    signer.status === 'signed' ? 'Assinado' :
                      'Outro'
              }}
              </span>
              <span slot="reference">
                <img width="20" :src="'/img/icons/autograph' +
                  (signer.status === 'new' ? '-black' :
                    signer.status === 'link-opened' ? '-warning' :
                      signer.status === 'signed' ? '-primary' : '')
                  + '.png'" alt="copy-2" class="ml-3" />
              </span>
            </el-popover>
            <!-- Email -->
            <el-popover trigger="click" placement="bottom" class="p-0">
              <div style="font-size: 12px">{{ signer.email }}</div>
              <span slot="reference">
                <img class="ml-3" width="20" src="/img/icons/email-primary.png" alt="new-post--v1" />
              </span>
            </el-popover>
            <!-- Excluir -->
            <img v-if="signer.status !== 'signed' && document.signers.length > 1 && canBeDeleted(signer, document)"
              @click="removeSigner(signer.token)" class="ml-3" width="20" src="/img/icons/icons8/ios/cancel_danger.png">
          </div>
        </div>
      </div>
    </div>
    <ModalDigitalSignatureCreate :contractProposal="contract_proposal" @reloadDocuments="getDocuments()"
      ref="ModalDigitalSignatureCreate" />
  </modal>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import InputLimit from "@/components/Utils/InputLimit.vue";
import { quillEditor } from 'vue-quill-editor';
import { v4 as uuidv4 } from 'uuid';
import ModalDigitalSignatureCreate from './_ModalDigitalSignatureCreate.vue';
import PuzlSelect from "@/components/PuzlSelect";
import ContractProposalSignaturesStatusEnum from "../DigitalSignatureResume/enum/ContractProposalSignaturesStatusEnum";
import { mapGetters } from "vuex";

export default {
  name: "ModalDigitalSignatureList",
  components: {
    quillEditor,
    InputLimit,
    ModalDigitalSignatureCreate,
    PuzlSelect
  },
  data() {
    return {
      modal: false,
      loading: false,
      title: 'Assinatura digital',
      config: null,
      contract_proposal: null,
      documents: null,
      newSigner: {
        qualification: '',
        name: '',
        email: '',
        external_id: uuidv4()
      },
      ContractProposalSignaturesStatusEnum
    }
  },
  computed: {
    ...mapGetters({
      $_commercial_permission: "commercialPermission/show",
    }),
  },
  methods: {
    // Modal
    handleCreateModal(data) {
      this.contract_proposal = data.contract_proposal;
      this.config = data.digitalSignatureConfig;
      this.$notify({ type: 'info', message: 'Carregando documentos...' });
      this.getDocuments();
    },
    closeModal() {
      this.$emit('fetch');
      this.modal = false
    },
    handleShowModal({ ref, data }) {
      this.$refs[ref].handleCreateModal(data);
    },
    // Documento/signatario
    // busca os documentos(propostas/contratos) na zapsign associados a esse contract_proposal
    getDocuments() {
      let loader = this.$loading.show();

      const clientName = this.$helper.clientName().substring(0, 49);
      const plantName = this.contract_proposal.plant.name.substring(0, 49);
      const entityName = this.contract_proposal.entity.entity_name.substring(0, 49);
      const code = this.contract_proposal.code.substring(0, 49);

      let params = {
        //cliente/planta/contratante/documento
        folder_path: clientName + '/' + plantName + '/' + entityName + '/' + code,
        with_details: true,
      }
      this.$store.dispatch('digitalSignature/fetchDocuments', params)
        .then((response) => {
          this.documents = response.data.details.reverse();
        }).finally(() => {
          this.loading = false;
          this.modal = true
          loader.hide()
        })
    },
    async addSigner(token) {
      this.loading = true;
      let loader = this.$loading.show();
      this.$notify({ type: 'info', message: 'Salvando novo signatario...' });
      this.newSigner['doc_token'] = token;
      this.$store.dispatch('digitalSignature/addSigner', this.newSigner)
        .then((response) => {
          this.$notify({ type: response.error_type, message: response.message });
          loader.hide();
          this.getDocuments();
        }).finally(() => {
          this.$notify({ type: 'success', message: 'Signatario adicionado com sucesso!' });
          this.newSigner = { qualification: '', name: '', email: '', external_id: uuidv4() };
        });;
    },
    removeSigner($signerId) {
      let loader = this.$loading.show();
      this.$notify({ type: 'info', message: 'Removendo signatario...' });
      this.$store.dispatch('digitalSignature/removeSigner', $signerId)
        .then(() => {
          this.getDocuments();
        }).finally(() => {
          this.$notify({ type: 'success', message: 'Signatario removido com sucesso!' });
          this.modal = true
          loader.hide()
        });
    },
    updateIgnoreSignatureStatus(document) {
      if (!this.$_commercial_permission.ignore_digital_signature) {
        this.$notify({ type: 'warning', message: 'O usuario nao possui permissao!' });
        return
      }
      this.$Swal.confirmAction('Deseja realmente confirmar manualmente as assinaturas?', ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let loader = this.$loading.show();
            let newStatus = ContractProposalSignaturesStatusEnum.keys.IGNORE_SIGNATURE;
            if (document.local_status === ContractProposalSignaturesStatusEnum.keys.IGNORE_SIGNATURE) {
              if (document.status === 'pending') {
                newStatus = ContractProposalSignaturesStatusEnum.keys.PENDING;
              }
            }
            this.$store.dispatch('digitalSignatureResume/updateIgnoreSignatureStatus', {
              external_id: document.external_id,
              status: newStatus,
              ignore_signature: !document.ignore_signature
            })
              .then(() => {
                this.$set(document, 'local_status', ContractProposalSignaturesStatusEnum.keys.IGNORE_SIGNATURE);
                loader.hide();
                this.getDocuments();
                this.$emit('fetch');
              });
          }
        })
    },
    updateContractProposal(contract_proposal) {
      if (!this.$_commercial_permission.ignore_digital_signature) {
        this.$notify({ type: 'warning', message: 'O usuario nao possui permissao!' });
        return
      }
      this.$Swal.confirmAction('Deseja realmente confirmar manualmente as assinaturas?', ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let loader = this.$loading.show();
            const ignore_signature = !contract_proposal.ignore_signature;
            this.$store.dispatch('contractProposal/updateIgnoreSignatureStatus', { id: contract_proposal.id, ignore_signature })
              .then(() => {
                this.$set(this.contract_proposal, 'ignore_signature', !this.contract_proposal.ignore_signature);
                loader.hide();
                this.$emit('fetch');
              });
          }
        })
    },
    canBeDeleted(signer, document) {
      let minSignaturesKey = 'min_' + signer.qualification + '_signatures';
      if (document.name !== 'Contrato' && signer.qualification === 'contractor') {
        minSignaturesKey += '_proposal';
      }
      return document.signers_count[signer.qualification] > this.config[minSignaturesKey];
    },
    cancelDocument(token) {
      this.$Swal.confirmAction('Deseja realmente cancelar o documento?', ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch('digitalSignature/deleteDocument', token)
              .then(() => {
                this.getDocuments();
                this.$notify({ type: 'info', message: 'Documento cancelado com sucesso!' });
              });
          }
        })
    },
    getCommercialPermission() {
      this.$store.dispatch('commercialPermission/getByUserId', this.$user.id);
    },
    openUrl(url) {
      window.open(url, '_blank');
    },
    getStatusText(status) {
      switch (status) {
        case ContractProposalSignaturesStatusEnum.keys.PENDING:
          return 'Vigente';
        case ContractProposalSignaturesStatusEnum.keys.SIGNED:
        case ContractProposalSignaturesStatusEnum.keys.IGNORE_SIGNATURE:
          return 'Assinado';
        case ContractProposalSignaturesStatusEnum.keys.CANCELLED:
          return 'Cancelado';
        default:
          return 'Outro';
      }
    },
    getClass(status) {
      switch (status) {
        case ContractProposalSignaturesStatusEnum.keys.PENDING:
          return 'signed-class';
        case ContractProposalSignaturesStatusEnum.keys.SIGNED || ContractProposalSignaturesStatusEnum.keys.IGNORE_SIGNATURE:
          return 'signed-class';
        case ContractProposalSignaturesStatusEnum.keys.CANCELLED:
          return 'deleted-class';
        default:
          return 'signed-class';
      }
    },
  },
  beforeMount() {
    this.getCommercialPermission();
  }
}
</script>

<style scoped>
label {
  margin-bottom: 0 !important;
}

.green-button {
  border-radius: 10px;
  border: 1px solid #149E57;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  color: #149E57;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-button {
  border-radius: 5px;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 500 !important;
  padding: 0.2rem 0.3rem;
}

.button {
  border-radius: 5px;
  border: 1px solid #DCDFE6;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.default-button {
  border-radius: 5px;
  border: 1px solid #DCDFE6;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  cursor: pointer;
}

.default-button span {
  font-size: 12px !important;
}

.signed-class {
  background-color: #d1e3f1;
  color: #1A70B7;
}

.deleted-class {
  background-color: #f8dad7;
  color: #DB4539;
}

.default-class {
  background-color: #d0ecdd;
  color: #149E57;
}

@media (min-width: 720px) {
  .button-size {
    min-width: 30rem;
  }
}
</style>

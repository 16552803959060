/*jshint esversion: 9 */
import { BaseListFilterType, initBaseListFilterType, RangeValueType, RangeDateType } from "@/shared/types";

/**
 * Objeto de filtro para listagem
 *
 * @typedef {Object} BillPayListFilterExtType
 * @property {string} order_by - Ordem padrão para a listagem
 * @property {RangeDateType} valueRange - Intervalo de valores
 * @property {Array<number>} status - Status dos itens
 * @property {Array<number>} payment_method_ids - Métodos de pagamento
 * @property {RangeDateType} documentRange - Intervalo de documentos
 * @property {RangeValueType} installment_range - Intervalo entre valor das parcelas
 * @property {"default"|"due_date"} filter_date_type - Tipo de data usado no filtro
 * @property {RangeDateType} range - Intervalo de datas
 * @property {Array<number>} bank_accounts - IDs das contas bancárias associadas
 * @property {null|2} advanced - Mostra contas agrupadas
 * @property {Array} global - Array de busca usado para buscas no backend seguindo padrão antigo
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & BillPayListFilterExtType)} BillPayListFilterType
 */
export const BillPayListFilterType = {};

// ARRUMAR FILTROS ESPECÍFICOS

/**
 * Inicializar BillPayListFilterType
 *
 * @returns {BillPayListFilterType}
 */
export const initBillPayListFilterType = () => ({
  ...initBaseListFilterType(),
  company_plant_id: null,
  order_by: "default",
  valueRange: {
    start: null,
    end: null,
  },
  status: [],
  payment_method_ids: [],
  documentRange: {
    start: '',
    end: '',
  },
  installment_range: {
    min: null,
    max: null,
  },
  filter_date_type: 'default',
  range: {
    start: null,
    end: null,
    default_filterable: true
  },
  bank_accounts: [],
  advanced: null,
  global: [],
});

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{style:(_setup.props.listType === 'table' && 'display: grid;')},[(
      _setup.availableStatuses(_setup.props.bill_pay.status.status).length &&
      (!_setup.props.bill_pay.reconciled && !_setup.props.bill_pay.identified) &&
      (_setup.props.bill_pay.status.status === 1
        ? _setup.hasPermission('bill_pay.reverse_installment')
        : true)
    )?_c('base-dropdown',[_c('div',{staticClass:"new-minicard-table",class:_setup.miniCardTypes[`${_setup.props.bill_pay.status.status}`],attrs:{"slot":"title-container"},slot:"title-container"},[_c('div',[_c('img',{attrs:{"src":_setup.statusIcon[`${_setup.props.bill_pay.status.status}`]}}),_c('h4',[_vm._v(" "+_vm._s(_setup.props.bill_pay.status.text)+" ")]),_c('img',{attrs:{"src":_setup.dropdownColors[`${_setup.props.bill_pay.status.status}`],"width":"12"}})])]),_vm._l((_setup.availableStatuses(_setup.props.bill_pay.status.status)),function(status,index){return _c('a',{key:index,staticClass:"dropdown-item",on:{"click":function($event){$event.preventDefault();return _setup.changeInstallmentStatus(_setup.props.bill_pay.id, status.value)}}},[_vm._v(" "+_vm._s(status.text)+" ")])})],2):_c('div',{staticClass:"new-minicard-table",class:_setup.miniCardTypes[`${_setup.props.bill_pay.status.status}`],staticStyle:{"cursor":"auto"},attrs:{"slot":"title-container"},slot:"title-container"},[_c('div',[_c('img',{attrs:{"src":_setup.statusIcon[`${_setup.props.bill_pay.status.status}`]}}),_c('h4',[_vm._v(" "+_vm._s(_setup.props.bill_pay.status.text)+" ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
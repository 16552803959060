const keys = Object.freeze({
    PENDING: 0,
    SIGNED: 1,
    CANCELLED: 2,
    IGNORE_SIGNATURE: 3,
  });
  
  /**
   * 
   * @param {number} value
   * @returns {string}
   */
  const getTitle = (value) => {
    switch (value) {
      case keys.PENDING:
        return "Pendente";
      case keys.SIGNED:
        return "Assinado";
      case keys.CANCELLED:
        return "Cancelado";
      case keys.IGNORE_SIGNATURE:
        return "Assinatura ignorada manualmente";
      default:
        return "";
    }
  };
  
  export default {
    keys,
    getTitle
  };
export function useEntityBillReceiveHook() {
  /**
   * Ordena uma lista de condições de pagamento pelo nome em ordem alfabética.
   * Se os nomes forem iguais, ordena numericamente pelo número antes do "X".
   *
   * @param {Array} paymentTerms - Lista de objetos contendo um campo "name".
   * @returns {Array} - Lista ordenada corretamente.
   */
  function sortPaymentTerms(paymentTerms) {
    return paymentTerms.sort((a, b) => {
      // Função para normalizar strings, removendo acentos e tornando tudo maiúsculo
      const normalize = (str) =>
        str
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase();

      const nameA = normalize(a.name);
      const nameB = normalize(b.name);

      // Função para extrair números antes do "X" para ordenação numérica
      const extractNumber = (name) => {
        const match = name.match(/(\d+)X/i);
        return match ? parseInt(match[1], 10) : null; // Retorna null se não houver número
      };

      const numA = extractNumber(a.name);
      const numB = extractNumber(b.name);

      // Se ambos têm número e são iguais fora o número de parcelas, ordena numericamente
      if (numA !== null && numB !== null && nameA.replace(/[0-9]/g, "") === nameB.replace(/[0-9]/g, "")) {
        return numA - numB;
      }

      // Ordenação alfabética padrão
      return nameA.localeCompare(nameB);
    });
  }

  return {
    sortPaymentTerms,
  };
}

<template>
  <div>
    <ListServicePrice />
    <ModalCreateMixType />
    <ModalCreateMixConcretedPieces />
    <ModalCreateMixPuzlAdmixture />
    <ModalCreateMixSlumpFlow />
    <ModalCreateLevelResponsibility />
  </div>
</template>
<script>
import ListServicePrice from './Shared/_List.vue';
import ModalCreateMixType from './Shared/_ModalCreateMixType.vue';
import ModalCreateMixConcretedPieces from './Shared/_ModalCreateMixConcretedPieces.vue';
import ModalCreateMixPuzlAdmixture from './Shared/_ModalCreateMixPuzlAdmixture.vue';
import ModalCreateMixSlumpFlow from './Shared/_ModalCreateMixSlumpFlow.vue';
import ModalCreateLevelResponsibility from './Shared/_ModalCreateLevelResponsibility.vue'
export default {
  components: {
    ListServicePrice,
    ModalCreateMixType,
    ModalCreateMixConcretedPieces,
    ModalCreateMixPuzlAdmixture,
    ModalCreateMixSlumpFlow,
    ModalCreateLevelResponsibility
  },
};
</script>
<style scoped>
.imgBaseButton {
  margin-bottom: 2px;
  margin-left: -10px;
  margin-right: 5px;
}
</style>

import * as types from './mutation_types'
import {cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";
const endPoint = '/operational/charge-resume'

export default {
  namespaced: true,
  state: {
    widgets: {},
    items: [],
  },
  getters:{
    getWidgets: state => state.widgets,
    fetch: state => state.items,
  },
  mutations: {
    [types.SET_WIDGETS](state, payload) {
      state.widgets = payload
    },
    [types.SET_CONCRETES](state, payload) {
      state.items = payload
    },
  },
  actions: {
    getWidgets({commit}, params) {
      return createAxios().get(`${endPoint}/widgets`, {params: params})
        .then(({data}) => {
          commit(types.SET_WIDGETS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchConcretesItemsPaginate({state}, params) {
      return createAxios().get(setUrlWithNextPage(`${endPoint}/travel`, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getReportExcel(_, params) {
      const isAsync = 1;
      return createAxios().post(`${endPoint}/excel/${isAsync}`, {
        filter: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}

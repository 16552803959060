/**
 * Tipo para período do filtro da barra de pesquisa
 *
 * @typedef {Object} RangeType
 * @property {Array<{id: number, name: string, selected_name: string}>} items - Tipos de período disponíveis
 * @property {?number} selected - Tipo de período selecionado
 * @property {?string} start - Data de início do período
 * @property {?string} end - Data de término do período
 */

/**
 * Tipo para Filtro de barra de pesquisa
 *
 * @typedef {Object} SearchBarFilterType
 * @property {?number} company_plant_selected - Central selecionada
 * @property {RangeType} range - Objeto contendo informações sobre o período de tempo
 * @property {Array<String>|null} custom_search_values - Valores da pesquisa customizada
 */
export const SearchBarFilterType = {};
/**
 * Inicializa um filtro de barra de pesquisa com valores padrão
 *
 * @returns {SearchBarFilterType} - Objeto inicializado para o filtro de barra de pesquisa
 */
export const initSearchBarFilterType = () => {
  return {
    company_plant_selected: localStorage.getItem('company_plant_id'),
    range: {
      items: [],
      selected: null,
      start: new Date(),
      end: new Date(),
    },
    custom_search_values: [],
  }
}

<template>
  <div class="card card-body mt-n2 p-0">
    <!-- Colocar o background dessa cor background-color: #CCCCCC !important; -->
    <div class="row m-0 mt-3" v-if="$_finance_transactions.length">
      <ShadedTable :length="$_finance_transactions.length"
        :headers="headers"
        :customHeaders="has_later_closed_conciliations || isAllReconciled ? [] : [0]"
        :loading="loadingSkeleton">
        <!-- customHeaders start -->
        <template slot="header_0">
          <div @click.prevent="$emit('selectOrDeselectAll')" style="font-size: 11px;" class="pointer text-primary font-weight-normal">
            {{ isAllSelected ? "Desmarcar todos" : "Selecionar Todos" }}
          </div>
        </template>
        <!-- customHeaders end -->
        <template v-if="reconciledItems[`${simpleConciliation.id}`]"
          v-for="(simpleConciliation, simpleConciliationIndex) in $_finance_transactions" :slot="simpleConciliationIndex">
          <ShadedCol align="center" customStyle="width: 100%;">
            <div v-if="!has_later_closed_conciliations && reconciledItems[`${simpleConciliation.id}`]"
              style="width: 100%;"
              @click.prevent="$emit('selectOrDeselectItem', simpleConciliation.id)"
              :class="isReconciled[reconciledItems[`${simpleConciliation.id}`].status].class"
              class="text-center pointer">
              <div style="align-items: center;display: inline-flex;margin-top: 1px;">
                <img class="mr-2" width="16"
                  :src="'/img/icons/icons8/ios/' + isReconciled[reconciledItems[`${simpleConciliation.id}`].status].img">
                <h4 class="font-weight-normal m-0 p-0">
                  {{ isReconciled[reconciledItems[`${simpleConciliation.id}`].status].text }}
                </h4>
              </div>
            </div>
            <div v-else class="new-minicard-red text-center" style="width: 100%;">
              <div style="align-items: center;display: inline-flex;margin-top: 1px;">
                <img class="mr-2" width="16"
                  src="/img/icons/icons8/ios/lock_danger.png">
                <h4 class="font-weight-normal m-0 p-0">
                  Fechado
                </h4>
              </div>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div>
              <el-popover trigger="click" placement="top">
                <span style="width: 230px; overflow: hidden; margin-right: -12px; margin-left: -12px;" class="row">
                  <div class="col-12 mb-2" style="display: flex; align-items: center;">
                    <h4 style="font-size: 14px;" class="p-0 m-0 font-weight-500 new-default-black-font">
                      CPF/CNPJ
                    </h4>
                  </div>
                  <div class="col-12 mb-2 px-0">
                    <hr class="gray-divider m-0">
                  </div>
                  <div class="col-12">
                    <h3 class="m-0 mb-1 font-weight-400 new-default-black-font">
                      {{ simpleConciliation.entity_document }}
                    </h3>
                  </div>
                </span>
                <h5 slot="reference" class="m-0 font-weight-400 new-default-black-font pointer" style="width: fit-content;">
                  {{ getEntityNameIdentifier(simpleConciliation) }}
                </h5>
              </el-popover>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div>
              <el-popover trigger="click" placement="top">
                <span style="width: 230px; overflow: hidden; margin-right: -12px; margin-left: -12px;" class="row">
                  <div class="col-12 mb-2" style="display: flex; align-items: center;">
                    <h4 style="font-size: 14px;" class="p-0 m-0 font-weight-500 new-default-black-font">
                      Documentos
                    </h4>
                  </div>
                  <div class="col-12 mb-2 px-0">
                    <hr class="gray-divider m-0">
                  </div>
                  <div class="col-12">
                    <h3 class="m-0 mb-1 font-weight-400 new-default-black-font">
                      {{ simpleConciliation.document_number }}    
                    </h3>
                  </div>
                </span>
                <h5 slot="reference" class="m-0 font-weight-400 new-default-black-font pointer" style="width: fit-content;">
                  {{ truncateAtDelimiter(simpleConciliation.document_number) }}
                </h5>
              </el-popover>
            </div>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ simpleConciliation.payment_method_name }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ simpleConciliation.finance_type_name }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <div style="align-items: center;display: flex;">
              <div style="display: inline-flex;">
                <el-popover placement="left" trigger="click">
                  <span>{{ getOperationIdentifier(simpleConciliation).description }}</span>
                  <img width="18" slot="reference" class="pointer" :src="getOperationIdentifier(simpleConciliation, true).img" />
                </el-popover>
              </div>
              <div style="width: 100%; text-align: right;">
                <h5 style="display: inline-flex;"
                  :class="simpleConciliation.is_credit ? 'text-success' : 'text-danger'" class="font-weight-500 m-0 mt-1 mr-2">
                  {{ simpleConciliation.amount | currency }}
                </h5>
                <div style="width: fit-content;display: inline-flex;align-items: center;"
                  class="card-with-box-shadow px-2 py-1">
                  <h6 class="new-default-gray-font m-0">
                    {{ simpleConciliation.installment_number }}
                  </h6>
                  <h6 class="new-default-gray-font m-0">
                    /
                  </h6>
                  <h6 class="new-default-gray-font m-0">
                    {{ simpleConciliation.installment_count }}
                  </h6>
                </div>
              </div>
            </div>
          </ShadedCol>
          <ShadedCol :align="simpleConciliationEnum.JUSTIFIED === reconciledItems[`${simpleConciliation.id}`].status ? 'center' : 'right'">
            <h5 v-if="reconciledItems[`${simpleConciliation.id}`].balance != null"
              class="new-default-black-font font-weight-500 m-0 mt-1 text-right">
              {{ reconciledItems[`${simpleConciliation.id}`].balance | currency }}
            </h5>
            <el-popover v-if="simpleConciliationEnum.JUSTIFIED === reconciledItems[`${simpleConciliation.id}`].status"
              trigger="click" placement="bottom" class="p-0">
              <span style="width: 200px; word-break: break-word;text-align: left;"
                class="row">
                <div class="col-12 mb-2" style="display: flex; align-items: center;">
                  <img class="mr-2" src="/img/icons/icons8/ios/speech-bubble-with-dots--v1.png" width="22">
                  <h4 style="font-size: 16px;" class="p-0 m-0">Justificativa</h4>
                </div>
                <div class="col-12 mt-1">
                  <h5 class="font-weight-normal">{{ simpleConciliation.status_reason }}</h5>
                </div>
              </span>
              <img slot="reference"
                  class="pointer mt-1"
                  src="/img/icons/icons8/ios/speech-bubble-with-dots--v1.png"
                  width="22" />
            </el-popover>
          </ShadedCol>
          <ShadedCol align="center">
            <base-dropdown menuOnRight>
              <div slot="title-container" class="dropdown-toggle rounded m-0">
                <img width="22" src="/img/icons/icons8/ios/settings--v1_primary.png" />
              </div>
              <a v-if="!has_later_closed_conciliations
                && ![simpleConciliationEnum.RECONCILED, simpleConciliationEnum.JUSTIFIED, simpleConciliationEnum.LOCKED].includes(reconciledItems[`${simpleConciliation.id}`].status)"
                class="dropdown-item font-weight-500 new-default-black-font"
                style="align-items: center; display: flex"
                @click="$emit('justifyConciliation', simpleConciliation.id)">
                <img width="18" src="/img/icons/create-new.png" />
                Justificar
              </a>
              <div class="dropdown-divider p-0 m-0"
                v-if="!has_later_closed_conciliations
                  && ![simpleConciliationEnum.RECONCILED, simpleConciliationEnum.JUSTIFIED, simpleConciliationEnum.LOCKED].includes(reconciledItems[`${simpleConciliation.id}`].status)">
              </div>
              <a @click="$emit('showHistoryForConciliation', simpleConciliation)"
                class="dropdown-item font-weight-500 new-default-black-font"
                style="align-items: center; display: flex">
                <img width="18" src="/img/icons/icons8/ios/order-history_secondary.png" />
                Histórico
              </a>
            </base-dropdown>
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
</template>
<script>
import SkeletonPuzl from "@/components/SkeletonPuzl";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import { SIMPLE_CONCILIATION_ENUM } from "./Enums/SimpleConciliationEnum";
import { truncateAtDelimiter } from "../../../../../helpers";
import {mapGetters} from "vuex";

const IS_RECONCILED = Object.freeze({
    0: Object.freeze({
        text: "Selecionar",
        class: "new-minicard-gray",
        img: "link_secondary.png"
    }),
    1: Object.freeze({
        text: "Conciliado",
        class: "new-minicard-blue",
        img: "link_primary.png"
    }),
    2: Object.freeze({
        text: "Justificado",
        class: "new-minicard-yellow",
        img: "link_warning.png"
    }),
    3: Object.freeze({
        text: "Desconciliar",
        class: "new-minicard-red",
        img: "link_danger.png"
    }),
    4: Object.freeze({
        text: "Conciliado",
        class: "new-minicard-blue",
        img: "link_primary.png"
    }),
    5: Object.freeze({
        text: "Selecionado",
        class: "new-minicard-green",
        img: "link_success.png"
    }),
    6: Object.freeze({
        text: "Cancelar",
        class: "new-minicard-red",
        img: "link_danger.png"
    }),
});

export default {
  name: "TableSimpleConciliation",
  props: {
    simpleConciliations: Array,
    loadingSkeleton: Boolean,
    reconciledItems: Array,
    isAllSelected: { type: Boolean },
    isAllReconciled: { type: Boolean },
    has_later_closed_conciliations: Boolean,
  },
  data(){
    return {
      headers: ["Conciliar", "Interveniente", "Documento", "Método", "Operação", "Valor", "Saldo", "Ação"],
      isReconciled: IS_RECONCILED,
      simpleConciliationEnum: SIMPLE_CONCILIATION_ENUM,
    }
  },
  components: {
    SkeletonPuzl,
    ShadedTable,
    ShadedCol,
  },
  methods: {
    truncateAtDelimiter,
    getEntityNameIdentifier(simpleConciliation){
      if(simpleConciliation.entity_id){
        return simpleConciliation.entity_name;
      } else {
        return simpleConciliation.operation_note || "N/i";
      }
    },
    getOperationIdentifier(simpleConciliation, print = false){
      if(simpleConciliation.is_reversal){
        switch(simpleConciliation.is_credit){
          case true:
            return {
              img: "/img/icons/icons8/ios/u-turn-to-right_danger.png",
              description: "Estorno"
            }
          case false:
            return {
              img: "/img/icons/icons8/ios/u-turn-to-left_success.png",
              description: "Estorno"
            }
        }
      } else {
        switch(simpleConciliation.is_credit){
          case true:
            return {
              img: "/img/icons/icons8/ios/long-arrow-up--v1_success.png",
              description: "Recebimento"
            }
          case false:
            return {
              img: "/img/icons/icons8/ios/long-arrow-down_danger.png",
              description: "Pagamento"
            }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      $_finance_transactions: "financeTransactions/fetch",
    }),
  }
};
</script>

<style scoped>
.mini-card-green h2 {
  color: #149e57;
}

.card-with-box-shadow {
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 10px !important;
}

.new-cardwithbox-shadow {
  text-align: center;
  box-shadow: 0 5px 10px 0 #f3f3f3;
  display: flex;
  border: 1px solid #E8E8E8;
  border-radius: 5px !important;
  align-items: center;
  padding: 4px 0px;
}

.new-minicard-green {
  background-color: #F2F7F3;
  border-radius: 15px;
  padding: 5px 12px;
  font-size: 12px;
}

.new-minicard-red {
  background-color: #FCF3F3;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-yellow {
  background-color: #FEF9F2;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-blue {
  background-color: #F2F4F9;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-gray {
  background-color: #646e7d2e;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-green h4 {
  color: #149e57;
}

.new-minicard-red h4 {
  color: #db4539;
}

.new-minicard-yellow h4 {
  color: #f2b532;
}

.new-minicard-blue h4 {
  color: #1b6eba;
}

.new-minicard-gray h4 {
  color: #66696c;
}

.new-minicard-red, .new-minicard-green, .new-minicard-yellow, .new-minicard-blue, .new-minicard-gray {
  align-items: center;
  display: block;
  text-align: center;
}

.new-minicard-red img:first-child, .new-minicard-green img:first-child, .new-minicard-yellow img:first-child, .new-minicard-blue img:first-child, .new-minicard-gray img:first-child {
  width: 16px;
}

.new-minicard-red h4, .new-minicard-green h4, .new-minicard-yellow h4, .new-minicard-blue h4, .new-minicard-gray h4 {
  font-size: 12px;
  margin: 0 6px 0 6px;
  font-weight: normal !important;
}
</style>

<template>
  <div id="login" style="background: white !important">
    <div class="container-fluid h-100">
      <div class="row justify-content-center align-items-start h-100">
        <div
          class="d-md-none d-block col-12 d-flex align-items-center flex-column logo-wrapper"
          style="order: 5; padding-top: 24px"
        >
          <img
            src="/img/brand/logo-puzl-horizontal.png"
            class="img-fluid text-center logo"
            alt="logo"
            style="margin-bottom: 4px"
          />
          <img
            class="fireworks d-block d-md-none"
            width="40px"
            src="/img/fireworks.gif"
          />

          <p class="slogan">Controle total, de onde estiver</p>
        </div>

        <div
          class="col-12 col-md-6 d-flex justify-content-center flex-column login-form h100"
        >
          <form @submit.prevent="onSubmit">
            <h1>Bem vindo ao Puzl <span class="blue">Mix</span>!</h1>
            <h2>Entre com o seu e-mail e senha</h2>

            <div v-if="message" class="error-message">
              {{ message }}
            </div>

            <div
              class="puzl-input email"
              :class="{ 'invalid-email': !isEmailValid }"
              style="margin-bottom: 24px"
            >
              <div class="prepend">
                <AppIcon icon="email" width="20" />
              </div>
              <div class="puzl-input-group">
                <label for="email" class="d-none d-md-block">Email</label>
                <input
                  id="email"
                  v-model="form.email"
                  autocomplete="username"
                  name="username"
                  placeholder="Digite um e-mail válido"
                  type="email"
                  @keydown="blockSpace"
                  @paste="sanitizeFields"
                  @blur="sanitizeFields"
                />
              </div>
            </div>

            <!-- <div class="mb-3">
              <label class="form-label">Endereço de e-mail</label>
              <base-input
                v-model="form.email"
                autocomplete="autocomplete"
                name="Email"
                class="mb-3"
                prepend-icon="ni ni-email-83"
                placeholder="Digite um e-mail válido"
              >
              </base-input>
            </div> -->

            <div class="puzl-input senha" style="margin-bottom: 12px">
              <div class="prepend">
                <AppIcon icon="padlock" width="20" />
              </div>
              <div class="puzl-input-group">
                <label for="password" class="d-none d-md-block">Senha</label>
                <input
                  id="password"
                  v-model="form.password"
                  autocomplete="current-password"
                  name="password"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  placeholder="Digite a sua senha"
                  @keydown="blockSpace"
                  @paste="sanitizeFields"
                  @blur="sanitizeFields"
                />
              </div>
              <div class="append pointer" @click="togglePasswordVisibility">
                <AppIcon
                  :icon="isPasswordVisible ? 'eye' : 'closed-eye'"
                  width="20"
                />
              </div>
            </div>

            <!-- <div class="mb-3">
              <label class="form-label">Senha</label>
              <base-input
                v-model="form.password"
                autocomplete="autocomplete"
                name="Password"
                type="password"
                prepend-icon="ni ni-lock-circle-open"
                placeholder="Digite a sua senha"
              >
              </base-input>
            </div> -->

            <div class="d-flex justify-content-between align-items-center">
              <!-- Checkbox -->
              <div class="form-check mb-0">
                <input
                  v-model="form.remember"
                  class="form-check-input me-2"
                  type="checkbox"
                  value=""
                  id="checkbox"
                />
                <label for="checkbox" class="form-check-label">
                  Lembrar-me
                </label>
              </div>
              <router-link
                :to="{
                  path: '/forgot-password',
                  name: 'forgot.password',
                }"
              >
                <a href="#!" class="text-body">Esqueci minha senha</a>
              </router-link>
            </div>

            <base-button
              type="primary"
              :loading="loading"
              native-type="submit"
              :disabled="loading"
              class="w-100 submit"
              @click="sanitizeFields"
            >
              Entrar
            </base-button>
          </form>
          <div
            class="d-flex flex-column align-items-center justify-content-center logo-wrapper"
          >
            <img
              src="/img/brand/logo-puzl-horizontal-cores.png"
              class="img-fluid text-center logo d-none d-md-block"
              alt="logo"
              style="margin-bottom: 6px"
            />
            <img
              class="fireworks d-none d-md-block"
              width="40px"
              src="/img/fireworks.gif"
            />

            <p class="slogan d-none d-md-block">
              Controle total, de onde estiver
            </p>
          </div>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-6 login-img d-none d-md-block"
        ></div>
        <div
          class="col-md-12 col-sm-12 col-lg-6 login-img d-block d-md-none p-0"
        >
          <!-- <img src='/img/login-christmas-1.png' class='img-fluid' alt='' title='' loading='lazy'> -->
        </div>
      </div>
    </div>
  </div>
</template>

<!-- <script setup></script> -->

<script>
import * as Websocket from "@/helpers/ws";
import { AppIcon } from "@/components/AppGlobal";
export default {
  components: {
    AppIcon,
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
        remember: false,
      },
      year: new Date().getFullYear(),
      loading: false,
      message: "",
      isPasswordVisible: false,
    };
  },
  computed: {
    isEmailValid() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.form.email);
    },
  },
  methods: {
    onSubmit() {
      this.sanitizeFields();
      this.loading = true;
      this.message = "";
      this.$store
        .dispatch("auth/login", this.form)
        .then((response) => {
          if (response.error) {
            this.message = response.message;
            return;
          }

          this.$user = response.user;
          Websocket.connect(this.$user.id);
          this.loading = false;
          /* Caso seja motorista redirecionamos para tela de acesso do motorista. */
          if (
            response.user.internal_type === 0 &&
            response.user.is_driver === true
          ) {
            return this.$router.push("/driver/in-course");
          }
          this.$router.push("dashboard");

          // Carrega centrais no login para garantir acesso global rápido sem depender de novas consultas.
          this.$store.dispatch("plant/getPlantsSimplified").then((res) => {
            localStorage.setItem('companyPlants', JSON.stringify(res)); 
          });
        })
        .catch((response) => {
          // if (response.request.status === 429) {
          //   this.message = "Você realizou muitas tentativas de login em um curto período de tempo. Por favor, tente novamente mais tarde."
          // }
          this.message = response.data.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    blockSpace(event) {
      if (event.code === "Space" || event.key === " ") {
        event.preventDefault(); // Block the space key
      }
    },
    sanitizeFields() {
      if (this.form.email) {
        const sanitizedEmail = this.form.email.trim().replace(/\s+/g, "");
        this.form.email = sanitizedEmail;
        document.getElementById("email").value = sanitizedEmail;
      }
      if (this.form.password) {
        const sanitizedPassword = this.form.password.trim().replace(/\s+/g, "");
        this.form.password = sanitizedPassword;
        document.getElementById("password").value = sanitizedPassword;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";
#login {
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden;
  height: 100vh;
  height: 100dvh;
  width: 100%;
  max-height: 100vh;
  max-height: 100dvh;

  .logo-wrapper {
    position: relative;
    .fireworks {
      position: absolute;
      height: 250px;
      width: 250px;
      top: -50px;
      pointer-events: none;

      @media screen and (max-width: 768px) {
        position: absolute;
        height: 175px;
        width: 175px;
        top: -40px;
      }
    }
  }

  .blue {
    color: $primary;
    font-size: inherit !important;
  }

  .h100 {
    @media screen and (min-width: 768px) {
      height: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    overflow-y: scroll;

    .row.align-items-stretch {
      align-items: center !important;
    }
  }

  .container-fluid {
    @media screen and (min-width: 768px) {
      padding-right: 0 !important;
    }
  }

  .app-header {
    background-color: $primary;
    padding: 20px;
    @media screen and (min-width: 768px) {
      padding: 42px;
    }
  }

  .login-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: -11px center;
    @media screen and (max-width: 768px) {
      background-image: url("/img/login-january-25.png");
      order: 2;
      height: 32vh;
      height: 32dvh;
      background-position: center center;
    }

    @media screen and (min-width: 768px) {
      background-image: url("/img/login-january-25-desktop.png");
      height: 100vh;
    }
  }

  .logo {
    max-width: 200px;
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
      max-width: 170px;
    }
  }

  .login-form {
    padding: 24px 40px;
    @media screen and (max-width: 768px) {
      order: 3;
      padding: 20px 20px 0px;
    }

    .slogan {
      font-weight: 500;
      @include rhythm(font-size, 17);
      color: $dark;
      @media screen and (min-width: 768px) {
        margin-bottom: 60px;
      }
    }

    h1 {
      @include rhythm(font-size, 40);
      color: $dark;
      line-height: 1;
      margin-top: 93px;

      @media screen and (max-width: 768px) {
        font-size: 24px;
        margin-top: 0;
      }
    }

    h2 {
      @include rhythm(font-size, 24);
      color: #606062 !important;
      font-weight: 400 !important;
      margin-bottom: 32px;
      @media screen and (max-width: 768px) {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }

    .invalid-email {
      border-color: $danger !important;
    }

    .email {
      @media screen and (max-width: 768px) {
        margin-bottom: 16px !important;
      }
    }

    .puzl-input {
      display: flex;
      justify-content: center;
      align-items: stretch;
      border: 0.5px solid #e8e8e8;
      border-radius: 8px;
      box-shadow: 0px 5px 10px 0px #0000000d;

      .prepend {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 22px 16px;
        position: relative;

        &::after {
          position: absolute;
          content: "";
          right: 0;
          height: 44%;
          width: 1px;
          background-color: #b2b2b2;
        }
      }

      .puzl-input-group {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: start;
        flex-direction: column;
        padding: 12px 16px;

        label {
          color: #606062;
          font-size: 12px;
          margin-bottom: 4px;
        }

        &::placeholder {
          color: #606062;
        }

        &::placeholder,
        input {
          font-size: 18px !important;
          font-weight: 400 !important;
        }

        input {
          color: $dark;
          border: none;
          box-shadow: none !important;
          outline: none;
          width: 100%;
        }
      }

      .append {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 16px;
      }
    }

    .form-check input {
      border-radius: 4px;
      cursor: pointer;
      opacity: 0.4;

      &:checked {
        opacity: 1;
      }
    }

    .submit {
      margin-top: 32px;
      font-size: 16px;
      font-weight: 400;
      box-shadow: 0px 5px 10px 0px #0000001a;
      @media screen and (max-width: 768px) {
        margin-top: 20px;
      }
      @media screen and (min-width: 768px) {
        margin-bottom: 77px;
      }
    }

    .error-message {
      color: $danger;
      background-color: $danger-lighter;
      border: 1px solid $danger;
      padding: 8px 12px;
      border-radius: 4px;
      margin-bottom: 16px;
      font-size: 14px;
    }
  }
}
</style>

/**
 *
 * @typedef {Object} MdfeType
 * @property {?number} id // [V]
 * @property {string} name // [V]
 * @property {number} status // [V]
 * @property {number} xml_travel_id // [V]
 * @property {number} number // [V]
 * @property {number} series // [V]
 * @property {number} protocol // [V]
 * @property {string} original_xml_file_path // [V]
 * @property {string} xml_file_path // [V]
 * @property {string} pdf_file_path // [V]
 * @property {string} chNFe // [V]
 * @property {string} log // [V]
 * @property {number} tpAmb // [V]
 * @property {number} tpEmit // [V]
 * @property {?string} created_at // [V]
 * @property {?string} updated_at // [V]
 */
export const MdfeType = {};

/**
 * Inicializar MdfeType
 *
 * @returns {MdfeType}
 */
export const initMdfeType = () => {
  return {
    id: null,
    status: 0,
    xml_travel_id: 0,
    number: 0,
    name: "",
    series: 0,
    protocol: 0,
    original_xml_file_path: "",
    xml_file_path: "",
    pdf_file_path: "",
    chNFe: "",
    log: "",
    tpAmb: 1,
    tpEmit: 2,
    created_at: null,
    updated_at: null,
  }
};

<template>
  <div class="row" v-if="$_equipment_listed.items.length > 0">
    <div class="col-md-12 table-responsive" style="min-height: 280px;">
      <table class="table table-sm">
        <thead>
          <tr>
            <th class="text-center">Status</th>
            <th>codigo</th>
            <th>Placa</th>
            <th>Categoria</th>
            <th>Tipo de equipamento</th>
            <th class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in $_equipment_listed.items" :key="item.id">
            <!-- Status -->
            <td>
              <base-dropdown menuOnRight class="dropdown-status">
                <div slot="title-container" class="dropdown-toggle"
                  :class="getStatusClass(item.status)">
                  <img class="mr-1" height="16" :src="getStatusIcon(item.equipment_situations_id, item.status)" />
                  <span>{{ item.situation_name }}</span>
                </div>
                <div v-for="(situation, index) in equipmentSituation" :key="index"
                  @click.prevent="onUpdateEquipmentSituationClick(item.id, situation.id)">
                  <a class="dropdown-item">{{ situation.situation_name }}</a>
                  <div v-if="index !== equipmentSituation.length - 1"
                    style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);" />
                </div>
              </base-dropdown>
            </td>
            <!-- Codigo -->
            <td class="d-flex align-items-center justify-content-between">
              <div>{{ item.code || '' }}</div>
              <div class="d-flex py-1">
                <el-popover trigger="click" placement="left" class="p-0 user-details mr-1">
                  <div class="popover-content">
                    <div class="checklist-info">
                      <h4 class="checklist-title mt-2"
                        v-for="(allocation, indexAllocation) in item.company_plants_names.split(',')"
                        :key="indexAllocation">
                        {{ allocation }}
                      </h4>
                    </div>
                  </div>
                  <div slot="reference">
                    <img class="icon-img mr-2" width="22px" src="/img/icons/chemical-plant-black.png" />
                  </div>
                </el-popover>
                <el-popover placement="right" class="p-0">
                  <h5 slot="title" class="mb-0 ">Detalhes</h5>
                  <h5 class="h3 mb-0">
                    {{ item.brand || '' }} &nbsp;({{ item.predominant_color || '' }})
                  </h5>
                  <h5 class="h3 mb-0 small">
                    {{ item.model || '' }} ({{ item.equipment_manufacture_year ||
                      '' }}/{{ item.equipment_year }})
                  </h5>
                  <h5 class="h3 mb-0 mt-2">
                    {{ item.chassis || '' }}
                  </h5>
                  <h5 class="h3 mb-0 small">
                    {{ item.vehicle_permit_code || '' }}
                  </h5>

                  <h5 class="h3 mb-0 mt-2">
                    {{ item.vehicle_license_name || '' }}
                  </h5>
                  <h5 class="h3 mb-0 small">
                    {{ item.vehicle_license_legal_entity || '' }}
                  </h5>
                  <base-button outline slot="reference" size="sm" type="secundary"
                    class="p-0 m-0 shadow-none text-indigo">
                    <img class="mr-1" src="/img/icons/info.png" width="22">
                  </base-button>
                </el-popover>
              </div>
            </td>
            <!-- Placa -->
            <td>
              <div>{{ item.plate || '' }}</div>
            </td>
            <td>
              <div>{{ item.category_name || '' }}</div>
            </td>
            <td>
              <div>{{ item.equipment_type_description || '' }}</div>
            </td>
            <!-- Ações -->
            <td>
              <base-dropdown menuOnRight>
                <div slot="title-container" class="dropdown-toggle rounded m-0 ml-auto">
                  <img width="25" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                </div>
                <router-link :to="{ path: '/equipment/equipment/edit', params: { equipment_uuid: item.uuid } }">
                  <a class="dropdown-item">
                    <img src="/img/icons/icons8/ios/create-new-yellow.png" height="20px"> Editar
                  </a>
                </router-link>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" @click="handleShowModalSetPlant(item.uuid)">
                  <img src="/img/icons/chemical-plant-black.png" height="20px"> Alocação de Central
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" @click.prevent="handleShowModalSearchEntity(item.id)">
                  <img src="/img/icons/salesman.png" height="20px"> Responsável terceiro
                </a>
                <div class="dropdown-divider"></div>
                <router-link :to="{ path: '/equipment/equipment/implements', params: { equipment_uuid: item.uuid } }">
                  <a class="dropdown-item">
                    <img src="/img/icons/icons8/ios/truck.png" height="16px"> Implementos
                  </a>
                </router-link>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" @click.prevent="handleShowModalBuySell(item.uuid)">
                  <img src="/img/icons/icons8/ios/low-price_success.svg" height="20px"> Dados de Compra / Venda
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" @click.prevent="removeEquipment(item.uuid)">
                  <img src="/img/icons/icons8/ios/trash_can.png" height="20px"> Excluir
                </a>
              </base-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-show="$_equipment_is_listing">
        <SkeletonPuzlGrid v-for="index in 3" :key="index" />
      </div>
    </div>
    <ModalSetPlant ref="modalSetPlant" />
    <ModalBuySell ref="modalBuySell" />
    <ModalSearchEntity @selected="storeThirdPartyResponsible" ref="modalSearchEntity" />
  </div>
</template>

<script setup>
//#region Imports
import { computed, defineEmits, ref, onMounted } from "vue";
import equipmentStore from "../../store/equipmentStore";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid.vue";
import equipmentSituationStatusEnum from "../../enums/EquipmentSituationStatusEnum";
import EquipmentStatusEnum from "../../enums/EquipmentStatusEnum";
import ModalSetPlant from './_ModalSetPlant';
import ModalBuySell from './_ModalBuySell';
import ModalSearchEntity from "@/views/Modules/Configuration/Entity/Shared/_ModalSearchEntity";
import { dialogs } from '../../../../../../helpers';
//#endregion

//#region Emits
const emit = defineEmits([
  "onUpdateEquipmentSituationClick",
]);
//#endregion

//#region Data
const equipmentSituation = ref([]);

const modalSetPlant = ref(null);
const modalBuySell = ref(null);
const modalSearchEntity = ref(null);
const equipmentId = ref(null);

//#region Computeds
/** Store Getters */
const $_equipment_listed = computed(() => equipmentStore.getters.getListed());
const $_equipment_is_listing = computed(() => equipmentStore.getters.getIsListing());
//#endregion

//#region Methods

/**
 * Exibe o modal para alocar uma central ao equipamento.
 * @param {string} uuid - UUID do equipamento.
 */
function handleShowModalSetPlant(uuid) {
  modalSetPlant.value.openModal(uuid, 'equipment');
}

/**
 * Exibe o modal para buscar e selecionar um responsável terceiro.
 * @param {number} id - ID do equipamento.
 */
function handleShowModalSearchEntity(id) {
  equipmentId.value = id;
  modalSearchEntity.value.openModal(id);
}

/**
 * Exibe o modal para adicionar ou editar dados de compra/venda.
 * @param {string} uuid - UUID do equipamento.
 */
function handleShowModalBuySell(uuid) {
  modalBuySell.value.openModal(uuid, 'equipment');
}

/**
 * Atualiza a situação do equipamento.
 * @param {number} equipmentId - ID do equipamento.
 * @param {number} situationId - ID da nova situação.
 */
function onUpdateEquipmentSituationClick(equipmentId, situationId) {
  emit("onUpdateEquipmentSituationClick", equipmentId, situationId);
}

/**
 * Carrega as situações disponíveis para os equipamentos.
 */
const loadEquipmentSituation = async () => {
  equipmentSituation.value = await equipmentStore.actions.getEquipmentSituations();
};

/**
 * Remove um equipamento após confirmação.
 * @param {string} uuid - UUID do equipamento a ser removido.
 */
async function removeEquipment(uuid) {
  const isConfirmed = await dialogs.confirmRemove();
  if (isConfirmed) {
    await equipmentStore.actions.remove(uuid);
    dialogs.notifyRemoved();
  }
}

/**
 * Armazena o responsável terceiro associado a um equipamento.
 * @param {Object} entity - Dados da entidade responsável.
 * @param {number} entity.id - ID da entidade.
 */
async function storeThirdPartyResponsible(entity) {
  const payload = {
    equipment_id: equipmentId,
    entity_id: entity.id,
  };
  await equipmentStore.actions.storeThirdPartyResponsible(payload);
  dialogs.notify();
  listItems();
}

/**
 * Retorna a classe CSS correspondente ao status do equipamento.
 * @param {number} status - ID do status do equipamento.
 * @returns {string} - Classe CSS.
 */
 function getStatusClass(status) {
  switch (status) {
    case EquipmentStatusEnum.keys.INACTIVE: return "inactive-status";
    case EquipmentStatusEnum.keys.UNAVAILABLE: return "unavailable-status";
    case EquipmentStatusEnum.keys.ACTIVE: return "active-status";
    default: return "default-status";
  }
}

/**
 * Retorna o ícone correspondente ao status do equipamento.
 * @param {number} status - ID do status do equipamento.
 * @returns {string} - Caminho do ícone.
 */
 function getStatusIcon(situationStatus, equipmentStatus) {
  if (situationStatus === equipmentSituationStatusEnum.keys.RENTED) {
    if (equipmentStatus === EquipmentStatusEnum.keys.ACTIVE) {
      return "/img/icons/icons8/ios/hourglass_success.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.INACTIVE) {
      return "/img/icons/icons8/ios/hourglass_warning.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.UNAVAILABLE) {
      return "/img/icons/icons8/ios/hourglass_danger.png";
    }
  } else if (situationStatus === equipmentSituationStatusEnum.keys.DAMAGED) {
    if (equipmentStatus === EquipmentStatusEnum.keys.ACTIVE) {
      return "/img/icons/icons8/ios/hourglass_success.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.INACTIVE) {
      return "/img/icons/icons8/ios/hourglass_warning.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.UNAVAILABLE) {
      return "/img/icons/icons8/ios/hourglass_danger.png";
    }
  } else if (situationStatus === equipmentSituationStatusEnum.keys.OUT_OF_USE) {
   if (equipmentStatus === EquipmentStatusEnum.keys.ACTIVE) {
      return "/img/icons/icons8/ios/power_success.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.INACTIVE) {
      return "/img/icons/icons8/ios/power_warning.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.UNAVAILABLE) {
      return "/img/icons/icons8/ios/power_danger.png";
    }
  } else if (situationStatus === equipmentSituationStatusEnum.keys.SOLD) {
    if (equipmentStatus === EquipmentStatusEnum.keys.ACTIVE) {
      return "/img/icons/icons8/ios/power_success.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.INACTIVE) {
      return "/img/icons/icons8/ios/power_warning.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.UNAVAILABLE) {
      return "/img/icons/icons8/ios/power_danger.png";
    }
  } else if (situationStatus === equipmentSituationStatusEnum.keys.MAINTENANCE) {
    if (equipmentStatus === EquipmentStatusEnum.keys.ACTIVE) {
      return "/img/icons/icons8/ios/hourglass_success.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.INACTIVE) {
      return "/img/icons/icons8/ios/hourglass_warning.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.UNAVAILABLE) {
      return "/img/icons/icons8/ios/hourglass_danger.png";
    }
  } else if (situationStatus === equipmentSituationStatusEnum.keys.OPERATING) {
    if (equipmentStatus === EquipmentStatusEnum.keys.ACTIVE) {
      return "/img/icons/icons8/ios/ok-grenn.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.INACTIVE) {
      return "/img/icons/icons8/ios/ok_warning.png";
    } else if (equipmentStatus === EquipmentStatusEnum.keys.UNAVAILABLE) {
      return "/img/icons/icons8/ios/ok--v1_danger.png";
    }
    return "/img/icons/icons8/ios/ok-grenn.png";
  }
  
  if (equipmentStatus === EquipmentStatusEnum.keys.ACTIVE) {
    return "/img/icons/icons8/ios/ok-grenn.png";
  } else if (equipmentStatus === EquipmentStatusEnum.keys.INACTIVE) {
    return "/img/icons/icons8/ios/ok_warning.png";
  } else if (equipmentStatus === EquipmentStatusEnum.keys.UNAVAILABLE) {
    return "/img/icons/icons8/ios/ok--v1_danger.png";
  }
  return "/img/icons/icons8/ios/ok-grenn.png";
}

//#endregion

//#region Lifecycle
onMounted(() => {
  loadEquipmentSituation();
});
//#endregion
</script>

<style lang="scss" scoped>
@import "@/style/PuzlCustom/App.scss";

* {
  font-family: Fredoka;
}

.dropdown-toggle {
  border-radius: 16px !important;
  text-align: center;
  font-weight: 400 !important;
  min-width: 145px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}

.dropdown-toggle span {
  font-size: 12px !important;
}

.card-body .card {
  box-shadow: none !important;
  border: none;
}

.dropdown-divider {
  margin: 0;
}

.table {
  $default-table-text-color: #2b2d32;

  border-collapse: separate;
  border-spacing: 2px 2px;

  th,
  td {
    box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15) !important;
    -webkit-box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15) !important;
    color: $default-table-text-color;
    vertical-align: middle;
    border-radius: 5px !important;
    font-size: 12px !important;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    @media screen and (max-width: 768px) {
      min-width: max-content;

      .permission-mobile {
        min-width: 190px;
      }
    }
  }

  th {
    padding-left: 8px;
    padding-right: 8px;
    background: $muted-light;
    font-family: Fredoka !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  th:first-of-type,
  td:last-of-type {
    width: 5% !important;
  }

  td {
    font-weight: 400 !important;
    font-family: Fredoka !important;
    font-size: 8px !important;
    padding-left: 8px;
    padding-right: 8px;
    background-color: white;


    &.p-16 {
      padding-right: 16px !important;
      padding-left: 16px !important;
    }
  }

  .limit-text {
    display: inline-block;
    max-width: 30ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


td>div {
  font-size: 12px !important;
}


/* Status: Indisponível */
.unavailable-status {
  background-color: #FCF3F3;
  color: #DB4539;
}

/* Status: Inativo */
.inactive-status {
  background-color: #fef9f2;
  color: #F2B532;
}

/* Status: Ativo */
.active-status {
  background-color: #f2f7f3;
  color: #149E57;
}
</style>

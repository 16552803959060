import { BaseListFilterType, initBaseListFilterType, RangeValueType, RangeDateType } from "../../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 /**
 * @typedef {Object} BillReceiveListFilterExtType
 * @property {Array<number>} status - Status da fatura
 * @property {?string} order_by - Campo pelo qual ordena os resultados.
 * @property {?string} range_min_value - Valor mínimo da parcela
 * @property {?string} range_max_value - Valor máximo da parcela
 * @property {?string} number_nfse_min - Número mínimo da NFS-e.
 * @property {?string} number_nfse_max - Número máximo da NFS-e.
 * @property {?string} number_invoice_min - Número mínimo da fatura
 * @property {?string} number_invoice_max - Número máximo da fatura
 * @property {RangeDateType} range - Objeto para definir intervalos de data.
 * @property {?number} type - Tipo de filtro (ex.: 1).
 * @property {null|"due_date"|"paid_at|"original_due_date"} date_type - Tipo de data para o filtro (ex.: "paid_at").
 * @property {Array<number>} payment_method_ids - IDs dos métodos de pagamento
 * @property {Array<number>} seller_ids - IDs dos vendedores
 * @property {?number} range_min_invoice - Valor mínimo da faixa de faturas
 * @property {?number} range_max_invoice - Valor máximo da faixa de faturas
 * @property {0|1} compensate - Indica se o item deve ser compensado
 * @property {0|1} pending - Indica se o item está pendente
 * @property {Array<number>} bank_account_id - IDs das contas bancárias
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & BillReceiveListFilterExtType)} BillReceiveListFilterType
 */
export const BillReceiveListFilterType = {};

// ARRUMAR FILTROS ESPECÍFICOS

/**
 * Inicializar BillReceiveListFilterType
 *
 * @returns {BillReceiveListFilterType}
 */
export const initBillReceiveListFilterType = () => ({
  ...initBaseListFilterType(),
  status: [],
  order_by: null,
  range_min_value: null,
  range_max_value: null,
  number_nfse_min: null,
  number_nfse_max: null,
  number_invoice_min: null,
  number_invoice_max: null,
  range: {
    start: null,
    end: null,
    default_filterable: true,
  },
  type: 1,
  date_type: null,
  payment_method_ids: [],
  seller_ids: [],
  range_min_invoice: null,
  range_max_invoice: null,
  compensate: 0,
  pending: 0,
  bank_account_id: [],
});

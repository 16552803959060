<template>
  <base-dropdown
    menuOnRight
    :style="listType === 'cards' ? 'position: absolute; right: 20px;' : ''">
    <div
      slot="title-container"
      class="dropdown-toggle rounded m-0"
    >
      <img
        :width="listType === 'table' ? 25 : 35"
        src="/img/icons/icons8/ios/settings--v1_primary.png"
      >
    </div>
    <a
      class="dropdown-item new-default-black-font"
      style="align-items: center; display: flex"
      @click.prevent="$emit('handleEditBillPay', bill_pay, bill_pay.index)">
      <img
        src="/img/icons/create-new.png"
        width="20"
      />
      Editar
    </a>
    <div class="dropdown-divider p-0 m-0"></div>
    <a
      class="dropdown-item new-default-black-font"
      style="align-items: center; display: flex"
      v-if="[BillReceiveInstallmentEnum.OVERDUE, BillReceiveInstallmentEnum.PENDING].includes(bill_pay.status.status)"
      @click.prevent="$emit('handleModalShareInstallment', bill_pay)">
      <img
        src="/img/icons/icons8/ios/division_success.png"
        width="20"
      />
      Dividir
    </a>
    <div class="dropdown-divider p-0 m-0"
      v-if="[BillReceiveInstallmentEnum.OVERDUE, BillReceiveInstallmentEnum.PENDING].includes(bill_pay.status.status)">
    </div>
    <a
      class="dropdown-item new-default-black-font"
      style="align-items: center; display: flex"
      v-if="![BillReceiveInstallmentEnum.RECEIVED, BillReceiveInstallmentEnum.CANCELED].includes(bill_pay.status.status) && bill_pay.document_quantity > 1"
      @click.prevent="$emit('removeAgroup', bill_pay.bill_pay_id)">
      <img
        src="/img/icons/icons8/ios/puzzle-matching_primary.png"
        width="20"
      />
      Desagrupar
    </a>
    <div class="dropdown-divider p-0 m-0"
      v-if="![BillReceiveInstallmentEnum.RECEIVED, BillReceiveInstallmentEnum.CANCELED].includes(bill_pay.status.status) && bill_pay.document_quantity > 1">
    </div>
    <a
      class="dropdown-item new-default-black-font"
      style="align-items: center; display: flex"
      v-if="bill_pay.status.status !== BillReceiveInstallmentEnum.RECEIVED && bill_pay.status.status !== BillReceiveInstallmentEnum.CANCELED"
      @click.prevent="$emit('handleAgroup', bill_pay)">
      <img
        src="/img/icons/icons8/ios/puzzle-matching_primary.png"
        width="20"
      />
      Agrupar
    </a>
    <div class="dropdown-divider p-0 m-0"
      v-if="bill_pay.status.status !== BillReceiveInstallmentEnum.RECEIVED && bill_pay.status.status !== BillReceiveInstallmentEnum.CANCELED">
    </div>
    <a
      class="dropdown-item new-default-black-font"
      style="align-items: center; display: flex"
      v-if="[BillReceiveInstallmentEnum.RECEIVED, BillReceiveInstallmentEnum.APPROVED].includes(bill_pay.status.status)"
      @click.prevent="$emit('getPaymentAuthorizationProof', bill_pay)">
      <img
        src="/img/icons/print--v1-green-26.png"
        width="20"
      />
      Comprovante
    </a>
    <div class="dropdown-divider p-0 m-0"
      v-if="[BillReceiveInstallmentEnum.RECEIVED, BillReceiveInstallmentEnum.APPROVED].includes(bill_pay.status.status)">
    </div>
    <a
      class="dropdown-item new-default-black-font"
      style="align-items: center; display: flex"
      @click.prevent="$emit('handleHistory', bill_pay)">
      <img
        src="/img/icons/icons8/ios/order-history_secondary.png"
        width="20"
      />
      Histórico
    </a>
    <div class="dropdown-divider p-0 m-0"
      v-if="bill_pay.status.status !== BillReceiveInstallmentEnum.RECEIVED">
    </div>
    <a
      class="dropdown-item new-default-black-font"
      style="align-items: center; display: flex"
      @click.prevent="$emit('handleDeleteBillPayInstallment', bill_pay)"
      v-if="bill_pay.status.status !== BillReceiveInstallmentEnum.RECEIVED">
      <img
        src="/img/icons/icons8/ios/delete_danger.png"
        width="20"
      />
      Excluir
    </a>
  </base-dropdown>
</template>
<script>
import { BillReceiveInstallmentEnum } from "@/enum/BillReceiveInstallmentEnum";

export default {
  name: "SettingOptions",
  props: {
    bill_pay: Object,
    listType: String,
  },
  data() {
    return {
      BillReceiveInstallmentEnum: BillReceiveInstallmentEnum,
    }
  },
  methods: {
  },
};
</script>

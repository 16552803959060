<template>
  <div>
    <modal :show.sync="modal.create" headerClasses="modal-header-class">
      <template slot="header">
        <h5 class="modal-title d-flex align-items-center">
          <img
            src="/img/icons/gear.png"
            width="24px"
            height="24px"
            class="mr-1"
          />{{ modal.title }}
        </h5>
      </template>
      <template slot="close-button">
        <AppButton
          icon="close"
          width="auto"
          type="link"
          size="sm"
          :hasOutline="true"
          @click="closeModal()"
        />
      </template>
      <div>
        <div class="checklist-item-primary ml-3 mb-3">
          <div class="checklist-info">
            <div class="row m-0 p-0 mb-3 d-flex align-items-center">
              <h3 class="title d-flex">
                <img
                  src="/img/icons/info-squared.png"
                  width="19px"
                  height="19px"
                  class="mr-1"
                />
                <small>CONTA</small>
              </h3>
              <hr class="bg-ligth m-0 ml-2" style="width: 80.5%" />
            </div>
            <h3 class="title d-flex">
              <img
                width="19px"
                height="19px"
                class="mr-1"
                :src="
                  '/img/icons/banks/' +
                  getBankCodeImage($_bankAccountSetting.bank_account_name) +
                  '.png'
                "
              />
              <small>{{ $_bankAccountSetting.bank_account_name }}</small>
            </h3>
            <div
              class="numeric mt-3"
              v-if="$_bankAccountSetting.bank_account_name"
            >
              <h3 class="mt-n2">
                <small
                  >AGÊNCIA:
                  {{
                    $_bankAccountSetting.bank_account_number_without_digit_agency
                  }}-{{ $_bankAccountSetting.bank_account_digit_agency }}</small
                >
              </h3>
              <h3 class="mt-n2">
                <small
                  >CONTA:
                  {{
                    $_bankAccountSetting.bank_account_number_without_digit_account
                  }}-{{
                    $_bankAccountSetting.bank_account_digit_account
                  }}</small
                >
              </h3>
            </div>
          </div>
        </div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(submitAndClose)"
            autocomplete="off"
          >
            <!-- CONTAS A RECEBER -->
            <div class="row m-0 p-0 mb-2">
              <div class="col-12 d-flex align-items-center">
                <img
                  src="/img/icons/duration-finance.png"
                  width="19px"
                  height="19px"
                  class="mr-1"
                />
                <b>Permissões</b>
                <hr class="bg-ligth m-0 ml-2" style="width: 60%" />
              </div>
            </div>
            <div class="row m-0 p-0 mb-2 d-flex justify-content-end">
              <div class="col-4 d-flex justify-content-end">
                <span class="text-dark">Baixa manual</span>
              </div>
              <div class="col-4 d-flex justify-content-end">
                <span class="text-dark">Estorno manual</span>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label text-dark">
                CONTAS A RECEBER
              </label>
              <div class="col-md-3 pt-2 pl-0 pr-0 mr-4">
                <base-toggle
                  offText="Inativo"
                  onText="Ativo"
                  v-model="
                    $_bankAccountSetting.manual_receipt_invoice_bill_receive
                  "
                />
              </div>

              <div class="col-md-3 pt-2 pl-0 pr-0">
                <base-toggle
                  offText="Inativo"
                  onText="Ativo"
                  v-model="
                    $_bankAccountSetting.manual_reversal_invoice_bill_receive
                  "
                />
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label text-dark">
                CRÉDITOS E ANTECIPAÇÕES
              </label>
              <div class="col-md-3 pt-2 pl-0 pr-0 mr-4">
                <base-toggle
                  offText="Inativo"
                  onText="Ativo"
                  v-model="
                    $_bankAccountSetting.manual_receipt_receivable_bill_receive
                  "
                />
              </div>

              <div class="col-md-3 pt-2 pl-0 pr-0">
                <base-toggle
                  offText="Inativo"
                  onText="Ativo"
                  v-model="
                    $_bankAccountSetting.manual_reversal_receivable_bill_receive
                  "
                />
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label text-dark">
                Contas a pagar
              </label>
              <div class="col-md-3 pt-2 pl-0 pr-0 mr-4">
                <base-toggle
                  offText="Inativo"
                  onText="Ativo"
                  v-model="$_bankAccountSetting.manual_payment_invoice_bill_pay"
                />
              </div>

              <div class="col-md-3 pt-2 pl-0 pr-0">
                <base-toggle
                  offText="Inativo"
                  onText="Ativo"
                  v-model="
                    $_bankAccountSetting.manual_reversal_receivable_bill_pay
                  "
                />
              </div>
            </div>
            <div class="modal-footer">
              <button class="danger button" @click="closeModal()">
                <AppIcon icon="cancel" color="danger" width="18" />
                Cancelar
              </button>
              <button
                class="success button"
                type="submit"
                v-bind:disabled="invalid"
                :isDisable="invalid"
                :loading="isLoading"
              >
                <AppIcon icon="save" color="success" width="18" />
                Salvar
              </button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppIcon from "../../../../../../components/AppGlobal/AppIcon/AppIcon.vue";
import { AppButton } from "../../../../../../components/AppGlobal";

export default {
  name: "ModalBankAccountSettings",
  components: {
    AppIcon,
    AppButton,
  },
  data() {
    return {
      modal: {
        title: "Configuração da conta",
        create: false,
      },
      bankAccountId: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      $_bankAccountSetting: "bankAccountSetting/show",
    }),
  },
  mounted() {
    // this.$refs.formValidator.validate();
  },
  methods: {
    /**
     * @param {integer} bankAccountId
     */
    openModal(bankAccountId) {
      let loader = this.$loading.show();
      this.bankAccountId = bankAccountId;
      this.modal.create = true;
      this.$store
        .dispatch("bankAccountSetting/getByBankAccountId", bankAccountId)
        .finally(() => loader.hide());
    },
    closeModal() {
      this.modal.create = false;
    },
    submitAndClose() {
      this.$Progress.start();
      this.isLoading = true;
      const payload = { ...this.$_bankAccountSetting };
      payload.bank_account_id = this.bankAccountId;
      this.$store
        .dispatch("bankAccountSetting/updateOrCreate", payload)
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.closeModal();
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        })
        .finally(() => {
          this.$Progress.finish();
          this.isLoading = false;
        });
    },
    getBankCodeImage(bankName) {
      const list = [];
      list[0] = "CAIXA INTERNO";
      list["077"] = "INTER";
      list[104] = "CAIXA";
      list[237] = "BRADESCO";
      list[260] = "NUBANK";
      list[341] = "ITAÚ";
      list[351] = "SANTANDER";
      const key = Object.keys(list).find(
        (key) => list[key].toUpperCase() === bankName.toUpperCase()
      );
      return key || -1;
    },
  },
};
</script>

<style scoped>
h5.modal-title {
  color: #2b2d32;
  font-family: "Fredoka";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
h5.modal-title > img {
  filter: grayscale(100%);
}
div.checklist-info h3.title > small {
  color: #2b2d32;
  font-family: "Fredoka";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
div.numeric > h3 > small {
  color: #606062;
  font-family: "Fredoka";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
label.col-form-label.text-dark {
  white-space: nowrap;
  font-family: "Fredoka";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>

<style>
div.modal-header-class {
  border-radius: 8px 8px 0px 0px !important;
  background: #e8e8e8 !important;
}
div.modal-footer #setting-close-button.btn.base-button,
div.modal-footer #setting-save-button.btn.base-button {
  width: 115px !important;
  height: 24px !important;
  min-height: 24px !important;
  padding: 8px 12px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
}
div.modal-footer #setting-close-button.btn.base-button span,
div.modal-footer #setting-save-button.btn.base-button span {
  font-family: "Fredoka" !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
div.modal-footer #setting-save-button.btn.base-button.btn-success {
  border: 0.5px solid #149e57 !important;
}
div.modal-footer #setting-save-button.btn.base-button.btn-success span {
  color: #149e57;
}
div.modal-footer #setting-close-button.btn.base-button.btn-secondary {
  border: 0.5px solid #db4539 !important;
}
div.modal-footer #setting-close-button.btn.base-button.btn-secondary span {
  color: #db4539;
}
</style>

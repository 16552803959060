<template>
  <div>
    <PageHeader
      title="Conciliação Financeira"
      icon="/img/icons/exchange.png"
      type="warning"
    >
      <PageHeaderButton text="Relatórios"
        @click.prevent="showReportModal"
        icon="/img/icons/icons8/ios/pdf-white.png" />
    </PageHeader>

    <div class="container-fluid">
      <div class="row mt-4 ml-n1 mb-4 mb-md-0">
        <div style="display: flex; align-items: center">
          <base-button @click="handleIsReconciled"
            slot="title-container"
            :class="filter.in_status.includes(0) ? 'btn-danger' : 'btn-outline-danger'"
            class="ml-2 btn text-uppercase colorize-btn-img filter-btn"
            type="button">
            <img v-if="filter.in_status.includes(0)" src="/img/icons/icons8/ios/link.png" width="16px" class="mr-1" />
            <img v-else src="/img/icons/icons8/ios/link_danger.png" width="16px" class="mr-1" />
            Não conciliados
          </base-button>
        </div>
      </div>
      <div>
        <Filters
          @handleFilterDate="handleFilterDate"
          :showSlotOrderBy="true"
          :showSlotStatus="false"
          :showSlotData="true"
          :showSlotBookmark="false"
          @clearFilter="clearFilter()" @fetch="init">
          <template slot="dropdown-instead-input">
            <PuzlSelect
              style="width: 100%"
              :disableBoxShadow="true"
              v-model.lazy="filter.bank_account_id"
              :items="$_bank_accounts"
              placeholder="Contas"
              @change="handleBankAccountChange"
              :multiple="false"
              class="select-xl"
              :loading="loadingBankAccounts"
              :disabled="loadingBankAccounts"/>
          </template>
          <template slot="data">
            <div class="col-md-12 mt-1 mb-2 text-left">
              <label class="form-control-label">
                Método de pagamento
              </label>
              <PuzlSelect class="select-xl new-default-black-font"
                @change="handlePaymentMethod"
                v-model.lazy="filter.payment_method_id"
                placeholder="Método de pagamento"
                :multiple="false"
                :items="payment_methods"
                filterable>
              </PuzlSelect>
            </div>
            <div class="row pr-3 mt-2 pl-3">
              <div class="col-12 text-left">
                <label class="form-control-label">
                  Valor
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <input placeholder="de" @input="handleAmountChange" v-model="filter.range_amount.min"
                     class="form-control btn-first-child"/>
                  </div>
                  <div class="col-6 pl-1">
                    <input placeholder="até" @input="handleAmountChange" v-model="filter.range_amount.max"
                      class="form-control btn-first-child" />
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template slot="order-by">
            <el-popover trigger="click" placement="bottom" class="p-0 float-right pr-0 ml-3">
              <div class="card" style="margin: -14px; border-radius: 4px !important;">
                <div class="pointer px-3 pb-2 pt-3 new-default-black-font font-weight-500"
                  style="font-size: 11px;">
                  <i class="fa-solid fa-angles-down" style="font-size: 12px;margin-right: 5px"></i>
                  Do mais novo para o mais velho
                </div>
                <hr class="gray-divider p-0">
                <div class="pointer px-3 py-2 new-default-black-font font-weight-500"
                  style="font-size: 11px;">
                  <i class="fa-solid fa-angles-up" style="font-size: 12px;margin-right: 5px"></i>
                  Do mais velho para o mais novo
                </div>
              </div>
              <a href="#" slot="reference">
                <h5 slot="reference" class="pt-1 font-weight-normal new-default-gray-font">ORDENAR <i class="fa-solid fa-chevron-down"></i></h5>
              </a>
            </el-popover>
          </template>
        </Filters>
      </div>
      <CalcHeader
        :openingBalance="openingBalance"
        :creditBalance="creditBalance"
        :debitBalance="debitBalance"
        :finalBalance="finalBalance"
        />
      <PuzlEmptyData v-if="!filter.bank_account_id" msg="Selecione uma conta bancária para trazer os dados" />
      <PuzlEmptyData v-if="filter.bank_account_id && !$_finance_transactions.length" />
      <Table v-if="filter.bank_account_id"
        :reconciledItems="reconciledItems"
        :loadingSkeleton="loadingSkeleton"
        :has_later_closed_conciliations="has_later_closed_conciliations"
        @selectOrDeselectAll="selectOrDeselectAll"
        @justifyConciliation="justifyConciliation"
        :isAllSelected="isAllSelected"
        :isAllReconciled="isAllReconciled"
        @selectOrDeselectItem="selectOrDeselectItem"
        @showHistoryForConciliation="showHistoryForConciliation"
      />
      <div v-if="isSomethingSelected" class="bg-white floating-button">
        <div class="card-with-box-shadow container-fluid">
          <div class="row align-items-center py-1" style="white-space: nowrap;">
            <div class="col-6">
              <h6 class="m-0 new-default-black-font">Saldo Final:</h6>
            </div>
            <div class="col-6">
              <h4 class="m-0 new-default-black-font">{{ finalBalance | currency }}</h4>
            </div>
          </div>
        </div>
        <base-button @click="handleEndConciliation(conciliationActionEnum.CONFIRM)" style="border-radius: 0px !important; background: #8bd5af52;"
          block
          class="border-0 text-success">
          <img class="mr-1" src="/img/icons/icons8/ios/checked_success.png" width="20">
          Confirmar operação
        </base-button>
      </div>
      <div v-if="!isSomethingSelected && filter.bank_account_id && !has_later_closed_conciliations" class="bg-white floating-button">
        <div class="card-with-box-shadow container-fluid">
          <div class="row align-items-center py-1" style="white-space: nowrap;">
            <div class="col-6">
              <h6 class="m-0 new-default-black-font">Saldo Final:</h6>
            </div>
            <div class="col-6">
              <h4 class="m-0 new-default-black-font">{{ finalBalance | currency }}</h4>
            </div>
          </div>
        </div>
        <base-button @click="handleEndConciliation(conciliationActionEnum.FINISH)" style="border-radius: 0px !important; background: #4182e52e;"
          block
          class="border-0 text-primary">
          <img class="mr-1" src="/img/icons/icons8/ios/checked_primary.png" width="20">
          Finalizar conciliação
        </base-button>
      </div>
      <ModalSearchEntity @selected="storeGuarantorEntity" ref="modalSearchEntity" />
      <ModalCreateFinance @refresh="init" ref="modalCreateFinance" />
      <ModalConfirmOrFinishConciliation v-if="!has_later_closed_conciliations"
        @refresh="init"
        ref="modalConfirmOrFinishConciliation"
        @updateScreen="updateScreen" />
      <ModalJustifyNonReconciliation @refresh="init"
        ref="modalJustifyNonReconciliation"
        @updateScreen="updateScreen" />
      <ModalConciliationHistory @refresh="init"
        ref="modalConciliationHistory"
        @updateScreen="updateScreen" />
      <ModalConciliationReport
        ref="modalConciliationReport"
        @updateScreen="updateScreen" />
    </div>
	</div>
</template>
<script>
import { PageHeader, PageHeaderButton } from "@/components";
import ModalSearchEntity from "@/views/Modules/Configuration/Entity/Shared/_ModalSearchEntity";
import ModalCreateFinance from "@/views/Modules/Financial/Finance/Shared/_ModalCreateFinance";
import Filters from "./Shared/_Filters";
import PuzlEmptyData from '@/components/PuzlEmptyData';
import Table from "./Shared/_Table.vue";
import ModalConfirmOrFinishConciliation from "./Shared/_ModalConfirmOrFinishConciliation.vue";
import ModalJustifyNonReconciliation from "./Shared/_ModalJustifyNonReconciliation.vue";
import ModalConciliationHistory from "./Shared/_ModalConciliationHistory.vue";
import CalcHeader from "./Shared/_CalcHeader.vue";
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import cursorPaginate from "@/mixins/cursorPaginate";
import ModalConciliationReport from "./Shared/Reports/_ModalConciliationReport.vue";
import moment from "moment";
import { SIMPLE_CONCILIATION_ENUM } from "./Shared/Enums/SimpleConciliationEnum";

const CONCILIATION_ACTION_ENUM = Object.freeze({
  CONFIRM: 0,
  FINISH: 1,
});

export default {
  name: "FinanceTransaction",
  mixins: [cursorPaginate],
  components: {
    PageHeader,
    PageHeaderButton,
    Filters,
    ModalSearchEntity,
    ModalCreateFinance,
    ModalConfirmOrFinishConciliation,
    ModalJustifyNonReconciliation,
    ModalConciliationHistory,
    ModalConciliationReport,
    PuzlEmptyData,
    Table,
    CalcHeader,
    PuzlSelect
  },
  data(){
    return {
      loadingSkeleton: false,
      loadingBankAccounts: true,
      payment_methods: [],
      simpleConciliationEnum: SIMPLE_CONCILIATION_ENUM,
      conciliationActionEnum: CONCILIATION_ACTION_ENUM,
      openingBalance: 0,
      creditBalance: 0,
      debitBalance: 0,
      finalBalance: 0,
      apiOpeningBalance: 0,
      apiCreditBalance: 0,
      apiDebitBalance: 0,
      apiFinalBalance: 0,
      has_later_closed_conciliations: false,
      filter: {
        in_status: [],
        range_discharge_at: {
          start: new Date(),
          end: new Date(),
        },
        range_amount: {
          min: null,
          max: null
        },
        range_reconciled_at: null,
        bank_account_id: null,
        payment_method_id: null
      },
      reconciledItem: {
        id: 0,
        is_reconcilied: 0,
        amount: 0,
      },
      reconciledItems: [
        {

        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      $_finance_transactions: "financeTransactions/fetch",
      $_bank_accounts: "bankAccount/fetch",
    }),
    isSomethingSelected() {
      return this.reconciledItems.some(item => [this.simpleConciliationEnum.SELECTED, this.simpleConciliationEnum.UNRECONCILE, this.simpleConciliationEnum.CANCEL].includes(item.status));
    },
    isAllSelected(){
      return this.reconciledItems.every(item => [this.simpleConciliationEnum.RECONCILED, this.simpleConciliationEnum.SELECTED].includes(item.status));
    },
    isAllReconciled(){
      return this.reconciledItems.every(item => [this.simpleConciliationEnum.RECONCILED].includes(item.status));
    },
  },
  methods: {
    showReportModal(){
      this.$refs.modalConciliationReport.openModal();
    },
    handlePaymentMethod(){
      this.init(this.filter);
    },
    handleAmountChange(){
      if(Number(this.filter.range_amount.max) < Number(this.filter.range_amount.min) && (this.filter.range_amount.min != null && this.filter.range_amount.max != null))
      {
        return;
      }

      this.init(this.filter);
    },
    handleFilterReconciledDate(filter){
      this.filter.range_reconciled_at = {
        start: filter.start,
        end: filter.end
      };

      this.init(this.filter);
    },
    handleBankAccountChange(){
      // this.reconciledItems = [];
      this.init(this.filter);
    },
    verifyIfIsClosed(){
      this.$Progress.start();

      let greaterThanDate = new Date(this.filter.range_discharge_at.start);
      greaterThanDate.setDate(greaterThanDate.getDate() - 1);

      const payload = {
        bankAccountId: this.filter.bank_account_id,
        closure: greaterThanDate.toISOString().split('T')[0],
      }

      this.$store
        .dispatch("accountClosingHistory/isBankingAccountClosed", payload)
        .then((response) => {
          this.has_later_closed_conciliations = response.data.has_later_closed_conciliations;
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        })
        .finally(() => {
          this.$Progress.finish();
        });
    },
    handleIsReconciled(){
      this.filter.in_status = !this.filter.in_status.length ? [0] : [];
      this.init(this.filter);
    },
    handleFilterDate(filter) {
      // this.reconciledItems = [];
      this.filter.range_discharge_at = {
        start: filter,
        end: filter
      };

      this.init(this.filter);
    },
    init(filter = null){
      if(!this.filter.bank_account_id) return;

      this.getInitialBalance();
      this.verifyIfIsClosed();

      this.startCursor(filter);
      this.$store
        .dispatch("financeTransactions/fetchItems", {
          custom_search: this.$helper.customSearchFormatter(["entities.entity_name"], filter?.global),
          page: this.paginate.nextUrl,
          in_status: this.filter.in_status,
          range_discharge_at: this.filter.range_discharge_at,
          range_amount: this.filter.range_amount,
          bank_account_id: this.filter.bank_account_id,
          payment_method_id: this.filter.payment_method_id,
          range_reconciled_at: this.filter.range_reconciled_at,
          order_by: [
            {
              column: "finance_transactions.id"
            },
          ],
        })
        .then(response => {
          this.loadingSkeleton = false;

          this.reconciledItems = [];
          this.formatConciliedItems(response.data.items);
          this.resolveCursor(response);
        })
        .catch(error => {
          this.resolveCursor();
          this.loadingSkeleton = false;
        });
    },
    getInitialBalance(){
      this.$store.dispatch("financeTransactions/getInitialBalance", {
        bank_account_id: this.filter.bank_account_id,
        date: moment(this.filter.range_discharge_at.start).format('YYYY-MM-DD'),
      })
      .then(response => {
        this.apiOpeningBalance = this.openingBalance = response.data.opening_balance;
        this.apiCreditBalance = this.creditBalance = response.data.total_credit_reconciled;
        this.apiDebitBalance = this.debitBalance = response.data.total_debit_reconciled;
        this.apiFinalBalance = this.finalBalance = response.data.final_balance_reconciled;
      })
    },
    updateScreen(){
      // this.reconciledItems = [];
      this.init(this.filter);
    },
    handleEndConciliation(action){
      this.$refs.modalConfirmOrFinishConciliation.openModal(this.reconciledItems, action, this.filter);
    },
    showHistoryForConciliation(reconciledItem){
      this.$refs.modalConciliationHistory.openModal(reconciledItem);
    },
    justifyConciliation(id){
      this.$refs.modalJustifyNonReconciliation.openModal(id);
    },
    selectOrDeselectAll(){
      if(this.isAllSelected){
        this.reconciledItems.map((item, index) => {
          if(item.status == this.simpleConciliationEnum.SELECTED)
          {
            this.updatePosition(index, this.simpleConciliationEnum.SELECT);
          }
        });
      } else {
        this.reconciledItems.map((item, index) => {
          if(item.status == this.simpleConciliationEnum.SELECT)
          {
            this.updatePosition(index, this.simpleConciliationEnum.SELECTED);
          } else if(item.status == this.simpleConciliationEnum.UNRECONCILE) {
            this.updatePosition(index, this.simpleConciliationEnum.RECONCILED);
          }
        });
      }
      this.calcAccumulatedBalance();
    },
    selectOrDeselectItem(index) {
      const statusTransitions = {
        [this.simpleConciliationEnum.SELECT]: this.simpleConciliationEnum.SELECTED,
        [this.simpleConciliationEnum.SELECTED]: this.simpleConciliationEnum.SELECT,
        [this.simpleConciliationEnum.RECONCILED]: this.simpleConciliationEnum.UNRECONCILE,
        [this.simpleConciliationEnum.UNRECONCILE]: this.simpleConciliationEnum.RECONCILED,
        [this.simpleConciliationEnum.JUSTIFIED]: this.simpleConciliationEnum.CANCEL,
        [this.simpleConciliationEnum.CANCEL]: this.simpleConciliationEnum.JUSTIFIED
      };

      const currentStatus = this.reconciledItems[index].status;
      const newStatus = statusTransitions[currentStatus];

      if (newStatus !== undefined) {
        this.updatePosition(index, newStatus);
      }

      this.calcAccumulatedBalance();
    },
    updatePosition(index, value){
      const updatedItem = {
        ...this.reconciledItems[index],
        status: value,
      };

      this.reconciledItems.splice(index, 1, updatedItem);
    },
    formatConciliedItems(data){
      this.$_finance_transactions.map(item => {
        this.reconciledItems[`${item.id}`] = {
          id: item.id,
          status: item.status
        };

        this.updatePosition(item.id, item.status);
      });

      this.calcAccumulatedBalance();
    },
    calcAccumulatedBalance(){
      let rowBalance = this.apiOpeningBalance;
      let creditBalance = this.apiCreditBalance;
      let debitBalance = this.apiDebitBalance;

      this.$_finance_transactions.map(item => {
        if([this.simpleConciliationEnum.SELECTED, this.simpleConciliationEnum.RECONCILED].includes(this.reconciledItems[`${item.id}`].status))
        {
          rowBalance += item.is_credit ? item.amount : (item.amount*-1);

          this.reconciledItems[`${item.id}`].balance = rowBalance.toFixed(2);
        } else {
          this.reconciledItems[`${item.id}`].balance = null ;
        }

        if(this.simpleConciliationEnum.SELECTED === this.reconciledItems[`${item.id}`].status)
        {
          if(item.is_credit){
            creditBalance += item.amount;
          } else {
            debitBalance += item.amount;
          }
        } else if(this.simpleConciliationEnum.UNRECONCILE === this.reconciledItems[`${item.id}`].status){
          if(item.is_credit)
          {
            creditBalance -= item.amount;
          } else {
            debitBalance -= item.amount;
          }
        }
      });

      this.creditBalance = creditBalance;
      this.debitBalance = debitBalance;
      this.finalBalance = ((creditBalance - debitBalance) + this.apiOpeningBalance).toFixed(2);
    },
    clearFilter() {
      this.loadingSkeleton = true;
      this.filter = {
        in_status: [],
        range_discharge_at: {
          start: new Date(),
          end: new Date(),
        },
        range_amount: {
          min: null,
          max: null
        },
        range_reconciled_at: null,
        bank_account_id: null,
        payment_method_id: null
      };
      this.init();
    },
    handleSearchEntity() {
      this.$refs.modalSearchEntity.openModal(null, false);
    },
    storeGuarantorEntity(entity){
      this.$refs.modalCreateBankOperation.openModal(entity);
    },
  },
  mounted() {
    this.$store.dispatch("bankAccount/fetchItems", {filter:{status:true}})
      .then(response => {
        this.loadingBankAccounts = false;
      });

    this.loadingSkeleton = false

    this.$store
      .dispatch("paymentMethod/fetchItems")
      .then((response) => {
        this.payment_methods = response.data.filter(item => item.display_in_anticipation);
      });
  },
};
</script>
<style scoped>
.filter-btn {
  width: 145px !important;
  justify-content: center !important;
  min-height: 28px !important;
  padding: 0 !important;
  font-size: 11px !important;
  display: flex !important;
  align-items: center !important;
}

.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}

.floating-button {
  position: fixed;
  z-index: 3;
  overflow: hidden;
  min-width: 250px;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 16px;
  color: #000000;
}

.floating-button div.card-with-box-shadow {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style>

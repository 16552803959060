import EquipmentListPage from "../views/list/EquipmentListPage";

export default [
  {
    path: '/equipment/equipment',
    name: 'equipment.view',
    component: EquipmentListPage,
    meta: {
      title: 'Equipamento',
      description: 'Equipamento',
      icon: 'fa fa-home',
      permission: 'equipment.equipment.index.view',
      breadcrumbs: [
        {title: 'Dashboard', name: 'equipment.equipment.index'},
        {title: 'Equipamento', name: null},
      ]
    },
  },
];

export const AppIconVariants = Object.freeze({
  defaultVariants: Object.freeze({
    color: "dark",
    icon: "default.svg",
  }),
  variants: Object.freeze({
    color: Object.freeze({
      dark: "dark",
      white: "white",
      primary: "primary",
      success: "success",
      warning: "warning",
      danger: "danger",
      muted: "muted",
      gray: "gray",
    }),
    svg: Object.freeze({
      automation: "automation.svg",
      barcode: 'barcode.svg',
      briefcase: "briefcase.svg",
      calendar: "calendar.svg",
      cancel: "cancel.svg",
      check: "check.svg",
      "chemical-plant": "chemical-plant.svg",
      "chemical-plant-3": "chemical-plant-3.svg",
      close: "close.svg",
      'closed-eye': "closed-eye.svg",
      modal_close: "modal_close.svg",
      "contact-details": "contact-details.svg",
      contact: "contact.svg",
      create: "create.svg",
      email: "email.svg",
      erro: "erro.svg",
      "external-link-squared": "external-link-squared.svg",
      eye: "eye.svg",
      filter: "filter.svg",
      "info-squared": "info-squared.svg",
      keypad: "keypad.svg",
      linkedin: "linkedin.svg",
      "male-user": "male-user.svg",
      "merchant-account": "merchant-account.svg",
      padlock: "padlock.svg",
      phone: "phone.svg",
      pix: 'pix.svg',
      "plus-math": "plus-math.svg",
      printer: "printer.svg",
      "progress-indicator": "progress-indicator.svg",
      registrations: "registrations.svg",
      restart: "restart.svg",
      save: "save.svg",
      search: "search.svg",
      "security-shield": "security-shield.svg",
      settings: "settings.svg",
      shutdown: "shutdown.svg",
      'trash-can': "trash-can.svg",
      "numeric-selection": "numeric.svg",
      website: 'website.svg',
      "circled-right" : "circled-right.svg",
      "vector" : "vector.svg",
      "double-tick" : "double-tick.svg",
    }),
    png: Object.freeze({
      default: "/pngs/default.svg.png", //Deixei so pra caso tenha que fazer com PNG tambem
    }),
  }),
});

/**
 * Type para Parcela/Boleto do app de terceiro [Sync]
 *
 * @typedef {Object} InvoiceGetSyncBankBilletInstallmentsType
 * @property {number} invoice_id
 * @property {number} external_id
 * @property {string} our_number
 * @property {string} expire_at
 * @property {number} amount
 * @property {boolean} is_pending
 * @property {string} base64
 */
export const InvoiceGetSyncBankBilletInstallmentsType = {};

/**
 * Inicializar InvoiceGetSyncBankBilletInstallmentsType
 * 
 * @returns {InvoiceGetSyncBankBilletInstallmentsType}
 */
export const initInvoiceGetSyncBankBilletInstallmentsType = () => {
  return {
    our_number: '',
    expire_at: '',
    amount: 0,
    is_pending: false,
    base64: '',
  }
};
const keys = Object.freeze({
    ALL: null,
    IS_ENTRY_VALUE: 0,
    IS_OUTPUT_VALUE: 1,
  });
  
  const getTitle = (value) => {
    switch (value) {
      case keys.ALL:
        return "Todos";
      case keys.IS_ENTRY_VALUE:
        return "Entradas";
      case keys.IS_OUTPUT_VALUE:
        return "Saídas";
      default:
        return "";
    }
  };
  
  export default {
    keys,
    getTitle,
  };
  
<template>
  <div>
    <AppFixedPageTitle
      title="MDF-e"
      icon="/img/icons/ledger-white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :showCompanyPlants="true"
          :searchBarFilter.sync="searchBarFilter"
          :isLoading="$_mdfe_is_listing"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        >
          <AppSearchBarFilterSection
            name="Dados"
            icon="/img/icons/icons8/ios/info-squared_gray.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                class="form-control-label fs-12 new-default-black-font font-weight-400"
              >
                CNPJ EMISSOR
              </label>
              <PuzlSelect
                style="width: 100%;"
                :disableBoxShadow="true"
                v-model.lazy="filter.company_plant_issuer_id"
                :items="$_company_plant_issuers"
                :multiple="false"
                class="select-xl"
                label="ein"
                :loading="loadingCompanyPlantIssuers"
                :disabled="loadingCompanyPlantIssuers"
              />
            </div>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                class="form-control-label fs-12 new-default-black-font font-weight-400"
              >
                MDF-E
              </label>
              <div class="row">
                <div class="col-6 text-left pr-1">
                  <div class="input-custom-group">
                    <div>DE</div>
                    <input class="form-control form-control-sm" inputmode="numeric"
                           v-model="filter.number_mdfe.min"
                    />
                  </div>
                </div>
                <div class="col-6 pl-1">
                  <div class="input-custom-group">
                    <div>ATÉ</div>
                    <input class="form-control form-control-sm" inputmode="numeric"
                           v-model="filter.number_mdfe.max"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                class="form-control-label fs-12 new-default-black-font font-weight-400"
              >
                NF-E
              </label>
              <div class="row">
                <div class="col-6 text-left pr-1">
                  <div class="input-custom-group">
                    <div>DE</div>
                    <input class="form-control form-control-sm" inputmode="numeric"
                           v-model="filter.number_nfe.min"
                    />
                  </div>
                </div>
                <div class="col-6 pl-1">
                  <div class="input-custom-group">
                    <div>ATÉ</div>
                    <input class="form-control form-control-sm" inputmode="numeric"
                           v-model="filter.number_nfe.max"
                    />
                  </div>
                </div>
              </div>
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
    </AppPageHeader>

    <AppTabSelect
      :items="tabSelectItems"
      :isShowViewTypes="false"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="(type) => listType = type"
    >
      <AppSelect
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems(false)"
        variant="text-only"
      />
    </AppTabSelect>

    <div class="container-fluid mt-4">
      <MdfeListPageCards
        @fetch="listItems()"
      />
      <AppViewTrigger v-if="!$_mdfe_is_listing" @onIntersected="listItems(true)" />
      <PuzlEmptyData v-if="!$_mdfe_is_listing && !$_mdfe_listed.items.length"/>
    </div>
  </div>
</template>

<script setup>
//#region Imports
import {reactive, ref, computed, onMounted, onUnmounted, getCurrentInstance} from 'vue';
import {
  AppFixedPageTitle,
  AppPageHeader,
  initSearchBarFilterType,
  SearchBarFilterType,
  AppSearchBar,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppViewTrigger,
  AppTabSelect,
  AppSelect,
  TabSelectItemType, AppSearchBarFilterSection,
} from '../../../../../components/AppGlobal';
import { initStockLocationListFilterType, MdfeListFilterType } from '../../types';
import { dialogs } from '../../../../../helpers';
import MdfeListPageCards from './MdfeListPageCards.vue';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import { Ref } from 'vue';
import mdfeStore from "../../store/mdfeStore";
import PuzlSelect from "@/components/PuzlSelect.vue";
import {initMdfeListFilterType} from "@/modules/fiscal/mdfe/types/MdfeListFilterType";

//#endregion
const store = getCurrentInstance().proxy.$store;
//#endregion

//#region Data
/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());

const filter = reactive(initMdfeListFilterType())

const listType = ref('cards');

let loadingCompanyPlantIssuers = reactive(false);

const orderBy = reactive({
  items: [
    {
      id: 0,
      name: "A-Z (CLIENTE)",
      selected_name: "A-Z (CLIENTE)",
      filter: [{
        column: "entities.entity_name",
        is_desc: false,
      }
      ],
    },
    {
      id: 1,
      name: "Z-A (CLIENTE)",
      selected_name: "Z-A (CLIENTE)",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [{
        column: "entities.entity_name",
        is_desc: false,
      }],
    },
    {
      id: 2,
      name: "EMISSÃO MAIS NOVA PARA MAIS VELHA",
      selected_name: "MAIS NOVA",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [{
        column: "mdfes.created_at",
        is_desc: false,
      }],
    },
    {
      id: 3,
      name: "EMISSÃO MAIS VELHA PARA MAIS NOVA",
      selected_name: "MAIS VELHA",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [{
        column: "mdfes.created_at",
        is_desc: true,
      }],
    },
  ],
  selected: 0,
});
//#endregion

//#region Computeds
/** Store Getters */
const $_mdfe_listed = computed(() => mdfeStore.getters.getListed());
const $_mdfe_is_listing = computed(() => mdfeStore.getters.getIsListing());
const $_company_plant_issuers = computed(() => store.getters['companyPlantIssuer/fetch'])

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: "Todos",
      selected: filter.status === null,
    },
    {
      id: 0,
      name: "Em andamento",
      selected: filter.status === 1,
    },
    {
      id: 2,
      name: "Rejeitados",
      selected: filter.status === 2,
    },
    {
      id: 1,
      name: "Encerrados",
      selected: filter.status === 3,
    },
    {
      id: 4,
      name: "Cancelados",
      selected: filter.status === 4,
    },
  ];
});
//#endregion

//#region Methods
/**
 * @param {TabSelectItemType} item
 */
 function onTabSelectItemClick(item) {
  if (filter.status !== item.id) {
    filter.status = item.id;
    listItems();
  };
};

/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  let filterValue = defaultFilter();

  const storeFilters = withStoreFilters ? mdfeStore.getters.getPageState() : null;
  if (storeFilters) {
    filterValue = storeFilters.filter;
  }

  Object.assign(filter, filterValue);

  // Listar itens
  if (isRefreshList) {
    listItems();
  }
  store.dispatch("companyPlantIssuer/fetchItems").then(() => {
    loadingCompanyPlantIssuers = false
  });
};

/**
 * Padrão do filtro principal
 * @returns {MdfeListFilterType}
 */
function defaultFilter() {
  return initMdfeListFilterType();
};

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
function listItems(isAccumulateItems = false) {
  prepareFilter();
  mdfeStore.actions.list(filter, isAccumulateItems);
};

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.page = "";
  filter.custom_search.values = searchBarFilter.custom_search_values;
  filter.company_plant_id = searchBarFilter.company_plant_selected;
  filter.range.start = searchBarFilter.range.start;
  filter.range.end =  searchBarFilter.range.end;
  filter.order_by = orderBy.items[orderBy.selected].filter;
};

//#endregion

//#region Lifecycle
onMounted(() => {
  clearFilter(true, true);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  mdfeStore.mutations.resetStates();
  mdfeStore.mutations.setPageState({ filter, searchBarFilter });
});
//#endregion
</script>

<style scoped>
</style>

<template>
  <div>
    <modal size="md"
      :show.sync="isOpen"
      :backgroundColor="'#F2F7F3'"
      :showClose="false"
    >
      <template>
        <template slot="header">
          <div class="d-flex align-items-center container ml-1 w-100" style="gap: 15px;">
            <div>
              <img src="/img/icons/icons8/ios/plus-math-success.png" alt="plus-math-success" width="20px">
            </div>
            <span class="text-title">
              {{ title }}
            </span>
          </div>
        </template>
        <template slot="close-button">
        <AppButton
          icon="close"
          width="auto"
          type="link"
          size="sm"
          :hasOutline="true"
          @click="isOpen = false;"
        />
      </template>
      </template>
      <AdjustmentFinalSlumpForm
        :formData="formData"
        @onCancelClick="closeModal"
        @onSubmitClick="handleSubmitForm"
      />
    </modal>
  </div>
</template>

<script setup>
//#region Imports
import { computed, reactive, ref } from "vue";
import { dialogs, progress } from "../../../../../helpers";
import { AdjustmentFinalSlumpType, initAdjustmentFinalSlumpType } from "../../types";
import adjustmentFinalSlumpStore from "../../store/adjustmentFinalSlumpStore";
import AdjustmentFinalSlumpForm from "../shared/AdjustmentFinalSlumpForm.vue";
import { AppButton } from "../../../../../components/AppGlobal";
//#endregion

//#region Data
const title = "NOVO AJUSTE POR SLUMP FINAL";
const isOpen = ref(false);

/** @type {AdjustmentFinalSlumpType} */
const formData = reactive(initAdjustmentFinalSlumpType());
//#endregion

//#region Methods
/** Abrir modal */
function openModal() {
  setFormData();
  isOpen.value = true;
}

/** Fechar modal */
function closeModal() {
  isOpen.value = false;
}

/** Resetar dados do formulário */
function setFormData() {
  Object.assign(formData, initAdjustmentFinalSlumpType());
}

/** Criar registro */
function handleSubmitForm() {
  progress.start();
  const loader = progress.showLoader();
  adjustmentFinalSlumpStore.actions.create(formData)
    .then(() => {
      dialogs.notify();
      closeModal();
    })
    .finally(() => {
      progress.finish();
      loader.hide();
    });
}
//#endregion

//#region DefineExposes
/**
 * @typedef {Object} AdjustmentFinalSlumpCreateModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
//#endregion
</script>

<style scoped>
</style>
<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title font-weight-semibold text-white">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(store)"
            autocomplete="off"
          >
            <div>
              <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left">
                <h5 class="mb-0" slot="title">Identificação</h5>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 col-form-label form-control-label">
                  Status
                </label>
                <div class="col-md-7 pt-2">
                  <base-switch
                    v-model="additional.status"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                  ></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label">
                  Serviços
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control form-control-sm">
                      <puzl-select
                        v-model="additional.services"
                        :items="services"
                        label="service_name"
                        :multiple="true"
                        :loading="loadingService"
                        :disabled="loadingService" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pt-1 pb-0  col-form-label form-control-label">
                  Descrição Curta
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="additional.short_description"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pt-1 pb-0  col-form-label form-control-label">
                  Descrição Completa
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="additional.description"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 ">
                <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label">
                  Valor Padrão
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <input-limit
                      :disabled="!billing_unit_selected"
                      :start_value="additional.standard_value"
                      :options="{
                            min: billing_unit_selected ? billing_unit_selected.min : 0,
                            max: billing_unit_selected ? billing_unit_selected.max : 0,
                          }" v-model="additional.standard_value"
                      @value="additional.standard_value = $event"
                    >
                    </input-limit>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Unidade de Cobrança
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control form-control-sm">
                      <puzl-select
                        v-model="additional.billing_unit"
                        :items="billingUnits"
                        label="label"
                        @input="setBillingUnit(additional.billing_unit)" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Código externo
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control form-control-sm">
                      <input
                        type="text"
                        v-model="additional.external_code"
                        class="form-control form-control-sm"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="border-header-warning-bottom mt-2 mb-2 p-2">
                <h5 class="mb-0" slot="title">Regras</h5>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-8 pb-1 pb-0 col-form-label form-control-label">
                  Padrão em proposta/contrato
                  <el-popover title="Bloquear qualquer tipo de ajuste." placement="right" class="p-0">
                    <span>Padrão em proposta/contrato.</span>
                    <base-button
                      outiline
                      slot="reference"
                      size="sm"
                      type="secundary"
                      class="p-0 m-0 btn-rounded"
                    >
                      <i class="fas fa-info-circle"></i>
                    </base-button>
                  </el-popover>
                </label>
                <div class="col-md-4 pt-1">
                  <base-switch
                    v-model="additional.default"
                    type="primary"
                    offText="não"
                    onText="sim"
                  ></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-8 pb-1 pb-0 col-form-label form-control-label">
                  Permite edição em proposta/contrato
                  <el-popover title="Bloquear qualquer tipo de ajuste." placement="right" class="p-0">
                    <span>Permite edição em proposta/contrato.</span>
                    <base-button
                      outiline
                      slot="reference"
                      size="sm"
                      type="secundary"
                      class="p-0 m-0 btn-rounded"
                    >
                      <i class="fas fa-info-circle"></i>
                    </base-button>
                  </el-popover>
                </label>
                <div class="col-md-4 pt-1">
                  <base-switch
                    v-model="additional.editable"
                    type="primary"
                    offText="não"
                    onText="sim"
                  ></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-8 pb-1 pb-0 col-form-label form-control-label">
                  Cobrança automática
                  <el-popover title="Bloquear qualquer tipo de ajuste." placement="right" class="p-0">
                    <span>Cobrança automática.</span>
                    <base-button
                      outiline
                      slot="reference"
                      size="sm"
                      type="secundary"
                      class="p-0 m-0 btn-rounded"
                    >
                      <i class="fas fa-info-circle"></i>
                    </base-button>
                  </el-popover>
                </label>
                <div class="col-md-4 pt-1">
                  <base-switch
                    v-model="additional.automatic_payment"
                    type="primary"
                    offText="não"
                    onText="sim"
                  ></base-switch>
                </div>
              </div>

              <div class="border-header-warning-bottom mt-2 mb-2 p-2">
                <h5 class="mb-0" slot="title">Datas</h5>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Datas
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control form-control-sm">
                      <puzl-select
                        v-model="additional.dates"
                        :items="dates"
                        label="label"
                        :multiple="true" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="border-header-danger-bottom mt-2 mb-2 p-2">
                <h5 class="mb-0" slot="title">Prazos e Limites</h5>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Parâmetro A
                </label>
                <div class="col-md-7">
                  <validation-provider :rules="{ is_not: param['b'].param }">
                    <base-input input-classes="form-control form-control-sm">
                      <puzl-select
                        v-model="param['a'].param"
                        :items="params"
                        label="label"
                        @input="setParamA(param['a'].param)" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label">
                  Valor A
                </label>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input-limit
                      :disabled="!param_a_selected"
                      :start_value="param['a'].value"
                      :options="{
                            min: param_a_selected ? param_a_selected.min : 0,
                            max: param_a_selected ? param_a_selected.max : 0,
                            decimals: param_a_selected ? param_a_selected.decimals : 0,
                          }" v-model="param['a'].value"
                      @value="param['a'].value = $event"
                    >
                    </input-limit>
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Parâmetro B
                </label>
                <div class="col-md-7">
                  <validation-provider :rules="{ is_not: param['a'].param }">
                    <base-input input-classes="form-control form-control-sm">
                      <puzl-select
                        v-model="param['b'].param"
                        :items="params"
                        label="label"
                        @input="setParamB(param['b'].param)" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label">
                  Valor B
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required_if:value_b">
                    <base-input input-group-classes="input-group-sm">
                      <input-limit
                        :disabled="!param_b_selected"
                        :start_value="param['b'].value"
                        :options="{
                            min: param_b_selected ? param_b_selected.min : 0,
                            max: param_b_selected ? param_b_selected.max : 0,
                          }" v-model="param['b'].value"
                        @value="param['b'].value = $event"
                      >
                      </input-limit>
                    </base-input>
                  </validation-provider>
                </div>
              </div>


            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()"
              >Cancelar
              </base-button
              >
              <base-button type="success"
                           native-type="submit"
                           v-bind:disabled="invalid"
                           :loading="loadingStore">
                <span v-show="!loadingStore" class="btn-label"><i class="fas fa-save"></i></span>
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import InputLimit from "@/components/Utils/InputLimit";
import PuzlSelect from "@/components/PuzlSelect";
export default {
  name: "Create",
  components: { InputLimit, PuzlSelect },
  data() {
    return {
      modal: {
        title: 'Cadastrar Adicional',
        create: false,
      },
      param: {
        a: {},
        b: {}
      },
      param_a_selected : null,
      param_b_selected : null,
      additional: {
        status: true,
        short_description: '',
        description: '',
        billing_unit: '',
        default: false,
        editable: false,
        automatic_payment: false,
        standard_value: '',
        dates: '',
        external_code: '',
      },
      billing_unit_selected: null,
      loadingStore: false,
      loadingService: false,
      services: [
        {
          id: 1,
          service_name: 'Formulações',
        },
        {
          id: 2,
          service_name: 'Serviços',
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      billingUnits: 'additional/billingUnits',
      params: 'additional/params',
      dates: 'additional/dates',
    }),
  },
  methods: {
    ...mapActions({
      fetchServices: 'service/fetchServices',
      addAdditional: 'additional/addAdditional',
    }),
    closeModal() {
      this.modal.create = false
    },
    setParamB(id) {
      this.param['b'].value = 0
      this.param_b_selected = this.params.find((item) => item.id === id)
    },
    setParamA(id) {
      this.param['a'].value = 0
      this.param_a_selected = this.params.find((item) => item.id === id)
    },
    setBillingUnit(billing_unit_id) {
      this.billing_unit_selected = this.billingUnits.find((item) => item.id === billing_unit_id)
    },
    handleCreateModal() {
      this.additional = {
        status: true,
        short_description: '',
        description: '',
        billing_unit: '',
        default: false,
        editable: false,
        automatic_payment: false,
        standard_value: '',
        dates: '',
      }
      this.param = {
        a: {},
        b: {}
      }
      this.modal.create = true
    },
    store() {
      this.loadingStore = true
      let i = 0;

      for (let obj in this.param) {
        Object.keys(this.param[obj]).length === 0 && i++;
      }
      this.additional.params = this.param
      if (i === 2) {
        this.additional.params = null;
      }
      this.addAdditional(this.additional)
        .then(response => {
          this.loadingStore = false
          this.modal.create = false
          this.$notify({type: response.error_type, message: response.message});
        }).catch(error => {
        if (error.data.error) {
          this.$notify({type: error.data.error_type, message: error.data.message});
        }
        this.loadingStore = false
      })
    },
  },
  created() {
    this.loadingService = true
    this.fetchServices().then(() => this.loadingService = false)
  }
}
</script>

<style scoped>

</style>

<template>
  <div v-if="$_companyPlantIssuer">
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important; color: #606062">
            <img height="30px" src="/img/icons/icons8/ios/settings-3--v1_gray.png" class="mr-3"/>
            Configuração de NFS-E -
            <span v-if="$_companyPlantIssuer.nfse_provider == 0" >ENOTAS</span>
            <span v-else>MIGRATE</span>
          </h2>
        </div>
        <div class="col-md-8 pr-4">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mb-2 col-md-3 px-1">
              <base-button
                block
                size="md"
                type="light"
                class="pb-2 pt-2 text-uppercase"
                @click.prevent="goBack()"
              >
                <img width="19" src="/img/icons/circled-left.png" class="mr-1"/>
                Voltar
              </base-button>
            </div>
          </div>
        </div>
      </div>
      <hr class="blue-divider mt-2 mb-4" />
    </base-header>

    <div class="container-fluid">
      <card>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)">
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <!-- CARD - IDENTIFICAÇÃO -->
                <div class="card p-4">
                  <div class="row">
                    <div class="col-10">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="30px" src="/img/icons/icons8/ios/info-squared_warning.png" class="mr-3"/>
                        Identificação
                      </h5>
                    </div>
                    <div class="col-2 d-flex justify-content-end">
                      <button
                        style="margin: 0;width: 190px; height: 50px; justify-content: center; height: 25px;display: flex; align-items: center;"
                        class="btn btn-outline-warning dropdown-toggle text-uppercase colorize-btn-img"
                        @click.prevent="handleOpenModalTaxRegimesByIbgeCode($_companyPlantIssuer.city, $_companyPlantIssuer.ibge_code)"
                      >
                        <img class="mr-1" src="/img/icons/info.png" width="16px">
                        Regras do Município
                      </button>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Ambiente de Emissão
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <base-input autocomplete="off">
                          <puzl-select
                            v-model="$_companyPlantIssuer.nfse_broadcast_environment"
                            :items="nfseBroadcastEnvironments"
                          />
                        </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        ID do Microserviço
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_companyPlantIssuer.nfse_ms_company_id"
                          @blur="loadTaxRegimesPuzl"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Código do Serviço do Município
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_companyPlantIssuer.municipal_service_code"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Item da Lista de Serviço (LC116)
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_companyPlantIssuer.service_list_item"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        CNAE
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-input group class="mb-3" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_companyPlantIssuer.cnae"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div v-if="$_companyPlantIssuer.nfse_ms_company_id" class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Vincular Logo
                        <el-popover
                          style="margin-top: 30px !important;"
                          trigger="click"
                          placement="top"
                          class="p-0 px-1"
                        >
                          160x130<br>
                          PNG OU JPG<br>
                          <span slot="reference"><img src="/img/icons/info.png" width="20px" height="20px"/></span>
                        </el-popover>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <base-button
                        class="btn btn-outline-warning"
                        block
                        type="secondary"
                        :disabled="loadingLinkNfseLogo"
                        :loading="loadingLinkNfseLogo"
                        @click="$refs.inputNfseLogo.click()"
                      >
                        Vincular Logo
                      </base-button>
                      <small id="emailHelp2" class="form-text text-muted text-right">
                        <span class="badge mr-1 ml-1" style="background-color: #d3d3d3">160X130px</span>
                        <span class="badge mr-1 ml-1" style="background-color: #d3d3d3">PNG</span>
                        <span class="badge mr-1 ml-1" style="background-color: #d3d3d3">JPG</span>
                      </small>
                      <input type="file" ref="inputNfseLogo" @change="linkNfseLogo" accept=".png, .jpg" style="display:none;">
                    </div>
                  </div>
                </div>

                <!-- CARD - REGRAS GERAIS -->
                <div class="card p-4">
                  <div class="row">
                    <div class="col-10">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="30px" src="/img/icons/icons8/ios/administrative-tools_warning.png" class="mr-3"/>
                        Regras Gerais
                      </h5>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Inscrição do Tomador sem Inscrição Municipal?
                      </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end">
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.nfse_without_municipal_registration"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Cancelamento Manual
                      </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end">
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.nfse_allow_manual_cancellation"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Enviar alíquota de ISS quando o município da obra for igual ao do emissor
                      </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end">
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.nfse_send_iss_rate_when_construction_city_equal_issuer"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>

                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Tag's de Observação
                      </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end">
                      <button
                        style="margin: 0;width: 190px; height: 50px; justify-content: center; height: 25px;display: flex; align-items: center;"
                        class="btn btn-outline-primary dropdown-toggle text-uppercase colorize-btn-img"
                        @click.prevent="setObservationNfseDefault()"
                      >
                        Padrão
                      </button>
                    </div>
                    <div class="col-12 col-md-12">
                      <mention
                        style="font-size: 2rem !important;"
                        :default_comment="$_companyPlantIssuer.observation_nfse"
                        :items="$_mentions"
                        v-model.lazy="$_companyPlantIssuer.observation_nfse"
                        rows="8"
                      />
                    </div>
                  </div>
                </div>

                <div class="card p-4">
                  <div class="row">
                    <div class="col-10">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="30px" src="/img/icons/icons8/ios/paste-special_primary.png" class="mr-3"/>
                        Regras Especiais
                      </h5>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Informar Código Ibge da Obra
                      </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end">
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.nfse_load_construction_ibge_code"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Informar Código de Obra
                      </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end">
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.nfse_load_city_hall_construction_code"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Código padrão quando município da obra igual do emissor
                      </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end">
                      <base-input group class="mb-0" autocomplete="off">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="$_companyPlantIssuer.standard_code_for_matching_municipalities"
                          :disabled="!$_companyPlantIssuer.nfse_load_city_hall_construction_code"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-6">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Informar Endereço da Obra
                      </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end">
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.nfse_load_construction_address"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-7">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Bloquear emissão quando prestação dentro da cidade
                      </div>
                    </div>
                    <div class="col-12 col-md-5 d-flex justify-content-end">
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.nfse_block_service_within_the_city"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-7">
                      <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                        Bloquear emissão quando prestação fora da cidade
                      </div>
                    </div>
                    <div class="col-12 col-md-5 d-flex justify-content-end">
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.nfse_block_service_outside_the_city"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <div v-if="$_companyPlantIssuer.nfse_provider == 1">
                    <div class="row mt-3 mb-2 align-items-center">
                      <div class="col-12 col-md-7">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Desconsiderar o envio de dedução quando o município da obra for igual ao do emissor                          
                        </div>
                      </div>
                      <div class="col-12 col-md-5 d-flex justify-content-end">
                        <base-input group class="mb-0" autocomplete="off">
                          <base-switch
                            v-model="$_companyPlantIssuer.nfse_omit_deduction_in_city_hall_xml"
                            type="primary"
                            offText="Não"
                            onText="Sim"
                          ></base-switch>
                        </base-input>
                      </div>
                    </div>
                  </div>
                  <div v-if="$_companyPlantIssuer.nfse_provider == 0">
                    <div class="row mt-3 mb-2 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Informar Base de Cálculo em Metadados
                        </div>
                      </div>
                      <div class="col-12 col-md-6 d-flex justify-content-end">
                        <base-input group class="mb-0" autocomplete="off">
                          <base-switch
                            v-model="$_companyPlantIssuer.nfse_load_calc_base_in_metadata"
                            type="primary"
                            offText="Não"
                            onText="Sim"
                          ></base-switch>
                        </base-input>
                      </div>
                    </div>
                    <div class="row mt-3 mb-2 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Trocar a tag do metadados "Regime Especial de Tributação" por CST
                        </div>
                      </div>
                      <div class="col-12 col-md-6 d-flex justify-content-end">
                        <base-input group class="mb-0" autocomplete="off">
                          <base-switch
                            v-model="$_companyPlantIssuer.nfse_change_tag_regime_to_cst"
                            type="primary"
                            offText="Não"
                            onText="Sim"
                          ></base-switch>
                        </base-input>
                      </div>
                    </div>
                    <div class="row mt-3 mb-2 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Trocar os símbolos de vírgula (,) por pontos (.)
                        </div>
                      </div>
                      <div class="col-12 col-md-6 d-flex justify-content-end">
                        <base-input group class="mb-0" autocomplete="off">
                          <base-switch
                            v-model="$_companyPlantIssuer.nfse_replace_comma_with_dot"
                            type="primary"
                            offText="Não"
                            onText="Sim"
                          ></base-switch>
                        </base-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
              <!-- CARD - REGRAS DE PRESTACAO DE SERVICO -->
                <div class="card p-4">
                  <div class="row">
                    <div class="col-6">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="30px" src="/img/icons/icons8/ios/gears--v1_primary.png" class="mr-3"/>
                        Regras de Prestação de Serviço
                      </h5>
                    </div>
                    <div class="col-6 d-flex justify-content-end">
                      <base-input group class="mb-0" autocomplete="off">
                        <div class="pb-0 mt-n1 mr-2 new-default-black-font mb-1 col-form-label form-control-label">
                          Tributação em Metadados
                        </div>
                        <base-switch
                          class="ml-2"
                          v-model="$_companyPlantIssuer.nfse_send_regime_metadata"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                          @input="getTaxRegimesForMetadata()"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-12">
                      <div class="row pl-2">
                        <h5 class="h3 new-default-black-font p-1 mb-0">
                          <img height="25px" src="/img/icons/icons8/ios/login-rounded-down_success.png" class="mr-3"/>
                          Prestação de Serviço em
                        </h5>
                        <h5 class="h3 new-default-black-font p-1 mb-0 text-primary">
                          {{ $_companyPlantIssuer.city }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-0 mb-2 align-items-center">
                    <div class="col-12 col-md-5">
                      <div class="row pl-3">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Tomador
                        </div>
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label ml-2 text-primary">
                          em
                        </div>
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label ml-2">
                          {{ $_companyPlantIssuer.city }}
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-7">
                      <hr class="new-default-black mt-2 mb-2">
                    </div>
                  </div>
                  <div class="row mt-0 mb-2 align-items-center">
                    <div class="col-12 col-md-5">
                      <div class="h4 text-black"> Reter para Pessoa Jurídica</div>
                    </div>
                    <base-input v-if="$_companyPlantIssuer.nfse_send_regime_metadata" class="col-12 col-md-5" autocomplete="off">
                      <puzl-select
                        v-model="$_companyPlantIssuer.nfse_rule1_within_city_iss_legal_regime_metadata"
                        :items="taxRegimesMetadata"
                        customKey="code"
                        :labelMask="true"
                        label="$name$"
                        placeholder="Vazio"
                        :loading="loadingTaxRegimesMetadata"
                        :disabled="loadingTaxRegimesMetadata"
                      />
                    </base-input>
                    <div
                      class="col-12 d-flex justify-content-end"
                      :class="$_companyPlantIssuer.nfse_send_regime_metadata ? 'col-md-2' : 'col-md-7'"
                    >
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.nfse_rule1_within_city_iss_legal"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-0 mb-2 align-items-center">
                    <div class="col-12 col-md-5">
                      <div class="h4 text-black">
                        Reter para Pessoa Física
                      </div>
                    </div>
                    <base-input v-if="$_companyPlantIssuer.nfse_send_regime_metadata" class="col-12 col-md-5" autocomplete="off">
                      <puzl-select
                        v-model="$_companyPlantIssuer.nfse_rule1_within_city_iss_individual_regime_metadata"
                        :items="taxRegimesMetadata"
                        customKey="code"
                        :labelMask="true"
                        label="$name$"
                        placeholder="Vazio"
                        :loading="loadingTaxRegimesMetadata"
                        :disabled="loadingTaxRegimesMetadata"
                      />
                    </base-input>
                    <div
                      class="col-12 d-flex justify-content-end"
                      :class="$_companyPlantIssuer.nfse_send_regime_metadata ? 'col-md-2' : 'col-md-7'"
                    >
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.nfse_rule1_within_city_iss_individual"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-0 mb-2 align-items-center">
                    <div class="col-12 col-md-12">
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.nfse_rule1_within_city_esp"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                        <div class="row pl-3 ml-1">
                          <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                            Aplicar regra quando tomador
                          </div>
                          <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label ml-2 text-primary">
                            for substituto tributário = SIM
                          </div>
                        </div>
                      </base-input>
                    </div>
                  </div>
                  <div v-if="$_companyPlantIssuer.nfse_rule1_within_city_esp">
                    <div class="row mt-0 mb-2 ml-2 align-items-center">
                      <div class="col-12 col-md-5">
                        <div class="h4 text-black">Reter para Pessoa Jurídica</div>
                      </div>
                      <div class="col-12 col-md-7 d-flex justify-content-end">
                        <base-input group class="mb-0" autocomplete="off">
                          <base-switch
                            v-model="$_companyPlantIssuer.nfse_rule1_within_city_iss_legal_esp"
                            type="primary"
                            offText="Não"
                            onText="Sim"
                          ></base-switch>
                        </base-input>
                      </div>
                    </div>
                    <div class="row mt-0 mb-2 ml-2 align-items-center">
                      <div class="col-12 col-md-5">
                        <div class="h4 text-black">
                          Reter para Pessoa Física
                        </div>
                      </div>                    
                      <div class="col-12 col-md-7 d-flex justify-content-end">
                        <base-input group class="mb-0" autocomplete="off">
                          <base-switch
                            v-model="$_companyPlantIssuer.nfse_rule1_within_city_iss_individual_esp"
                            type="primary"
                            offText="Não"
                            onText="Sim"
                          ></base-switch>
                        </base-input>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-0 mb-2 align-items-center">
                    <div class="col-12 col-md-5">
                      <div class="row pl-3">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Tomador
                        </div>
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label ml-2 text-primary">
                          fora de
                        </div>
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label ml-2">
                          {{ $_companyPlantIssuer.city }}
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-7">
                      <hr class="new-default-black mt-2 mb-2">
                    </div>
                  </div>
                  <div class="row mt-0 mb-2 align-items-center">
                    <div class="col-12 col-md-5">
                      <div class="h4 text-black">
                        Reter para Pessoa Jurídica
                      </div>
                    </div>
                    <base-input v-if="$_companyPlantIssuer.nfse_send_regime_metadata" class="col-12 col-md-5" autocomplete="off">
                      <puzl-select
                        v-model="$_companyPlantIssuer.nfse_rule1_outside_city_iss_legal_regime_metadata"
                        :items="taxRegimesMetadata"
                        customKey="code"
                        :labelMask="true"
                        label="$name$"
                        placeholder="Vazio"
                        :loading="loadingTaxRegimesMetadata"
                        :disabled="loadingTaxRegimesMetadata"
                      />
                    </base-input>
                    <div
                      class="col-12 d-flex justify-content-end"
                      :class="$_companyPlantIssuer.nfse_send_regime_metadata ? 'col-md-2' : 'col-md-7'"
                    >
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.nfse_rule1_outside_city_iss_legal"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-0 mb-2 align-items-center">
                    <div class="col-12 col-md-5">
                      <div class="h4 text-black">
                        Reter para Pessoa Física
                      </div>
                    </div>
                    <base-input v-if="$_companyPlantIssuer.nfse_send_regime_metadata" class="col-12 col-md-5" autocomplete="off">
                      <puzl-select
                        v-model="$_companyPlantIssuer.nfse_rule1_outside_city_iss_individual_regime_metadata"
                        :items="taxRegimesMetadata"
                        customKey="code"
                        :labelMask="true"
                        label="$name$"
                        placeholder="Vazio"
                        :loading="loadingTaxRegimesMetadata"
                        :disabled="loadingTaxRegimesMetadata"
                      />
                    </base-input>
                    <div
                      class="col-12 d-flex justify-content-end"
                      :class="$_companyPlantIssuer.nfse_send_regime_metadata ? 'col-md-2' : 'col-md-7'"
                    >
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.nfse_rule1_outside_city_iss_individual"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>

                  <div class="row mt-3 mb-2 align-items-center">
                    <div class="col-12 col-md-12">
                      <div class="row pl-2">
                        <h5 class="h3 new-default-black-font p-1 mb-0">
                          <img height="25px" src="/img/icons/icons8/ios/logout-rounded-up_danger.png" class="mr-3"/>
                          Prestação de Serviço fora de
                        </h5>
                        <h5 class="h3 new-default-black-font p-1 mb-0 text-primary">
                          {{ $_companyPlantIssuer.city }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-0 mb-2 align-items-center">
                    <div class="col-12 col-md-5">
                      <div class="row pl-3">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Tomador
                        </div>
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label ml-2 text-primary">
                          em
                        </div>
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label ml-2">
                          {{ $_companyPlantIssuer.city }}
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-7">
                      <hr class="new-default-black mt-2 mb-2">
                    </div>
                  </div>
                  <div class="row mt-0 mb-2 align-items-center">
                    <div class="col-12 col-md-5">
                      <div class="h4 text-black">
                        Reter para Pessoa Jurídica
                      </div>
                    </div>
                    <base-input v-if="$_companyPlantIssuer.nfse_send_regime_metadata" class="col-12 col-md-5" autocomplete="off">
                      <puzl-select
                        v-model="$_companyPlantIssuer.nfse_rule2_within_city_iss_legal_regime_metadata"
                        :items="taxRegimesMetadata"
                        customKey="code"
                        :labelMask="true"
                        label="$name$"
                        placeholder="Vazio"
                        :loading="loadingTaxRegimesMetadata"
                        :disabled="loadingTaxRegimesMetadata"
                      />
                    </base-input>
                    <div
                      class="col-12 d-flex justify-content-end"
                      :class="$_companyPlantIssuer.nfse_send_regime_metadata ? 'col-md-2' : 'col-md-7'"
                    >
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.nfse_rule2_within_city_iss_legal"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-0 mb-2 align-items-center">
                    <div class="col-12 col-md-5">
                      <div class="h4 text-black">
                        Reter para Pessoa Física
                      </div>
                    </div>
                    <base-input v-if="$_companyPlantIssuer.nfse_send_regime_metadata" class="col-12 col-md-5" autocomplete="off">
                      <puzl-select
                        v-model="$_companyPlantIssuer.nfse_rule2_within_city_iss_individual_regime_metadata"
                        :items="taxRegimesMetadata"
                        customKey="code"
                        :labelMask="true"
                        label="$name$"
                        placeholder="Vazio"
                        :loading="loadingTaxRegimesMetadata"
                        :disabled="loadingTaxRegimesMetadata"
                      />
                    </base-input>
                    <div
                      class="col-12 d-flex justify-content-end"
                      :class="$_companyPlantIssuer.nfse_send_regime_metadata ? 'col-md-2' : 'col-md-7'"
                    >
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.nfse_rule2_within_city_iss_individual"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-0 mb-2 align-items-center">
                    <div class="col-12 col-md-5">
                      <div class="row pl-3">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Tomador
                        </div>
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label ml-2 text-primary">
                          fora de
                        </div>
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label ml-2">
                          {{ $_companyPlantIssuer.city }}
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-7">
                      <hr class="new-default-black mt-2 mb-2">
                    </div>
                  </div>
                  <div class="row mt-0 mb-2 align-items-center">
                    <div class="col-12 col-md-5">
                      <div class="h4 text-black">
                        Reter para Pessoa Jurídica
                      </div>
                    </div>
                    <base-input v-if="$_companyPlantIssuer.nfse_send_regime_metadata" class="col-12 col-md-5" autocomplete="off">
                      <puzl-select
                        v-model="$_companyPlantIssuer.nfse_rule2_outside_city_iss_legal_regime_metadata"
                        :items="taxRegimesMetadata"
                        customKey="code"
                        :labelMask="true"
                        label="$name$"
                        placeholder="Vazio"
                        :loading="loadingTaxRegimesMetadata"
                        :disabled="loadingTaxRegimesMetadata"
                      />
                    </base-input>
                    <div
                      class="col-12 d-flex justify-content-end"
                      :class="$_companyPlantIssuer.nfse_send_regime_metadata ? 'col-md-2' : 'col-md-7'"
                    >
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.nfse_rule2_outside_city_iss_legal"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-0 mb-2 align-items-center">
                    <div class="col-12 col-md-5">
                      <div class="h4 text-black">
                        Reter para Pessoa Física
                      </div>
                    </div>
                    <base-input v-if="$_companyPlantIssuer.nfse_send_regime_metadata" class="col-12 col-md-5" autocomplete="off">
                      <puzl-select
                        v-model="$_companyPlantIssuer.nfse_rule2_outside_city_iss_individual_regime_metadata"
                        :items="taxRegimesMetadata"
                        customKey="code"
                        :labelMask="true"
                        label="$name$"
                        placeholder="Vazio"
                        :loading="loadingTaxRegimesMetadata"
                        :disabled="loadingTaxRegimesMetadata"
                      />
                    </base-input>
                    <div
                      class="col-12 d-flex justify-content-end"
                      :class="$_companyPlantIssuer.nfse_send_regime_metadata ? 'col-md-2' : 'col-md-7'"
                    >
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.nfse_rule2_outside_city_iss_individual"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-0 mb-2 align-items-center">
                    <div class="col-12 col-md-12">
                      <base-input group class="mb-0" autocomplete="off">
                        <base-switch
                          v-model="$_companyPlantIssuer.nfse_rule2_outside_city_esp"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                        <div class="row pl-3 ml-1">
                          <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                            Aplicar regra especial quando 
                          </div>
                          <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label ml-2 text-primary">
                            município do tomador = município da obra
                          </div>
                        </div>
                      </base-input>
                    </div>
                  </div>
                  <div v-if="$_companyPlantIssuer.nfse_rule2_outside_city_esp" class="row mt-0 mb-2 align-items-center">
                    <div class="col-12 col-md-7">
                      <div class="row pl-3">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Tomador
                        </div>
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label ml-2 text-primary">
                          fora de
                        </div>
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label ml-2">
                          {{ $_companyPlantIssuer.city }} (Com município fora da obra)
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <hr class="new-default-black mt-2 mb-2">
                    </div>
                  </div>
                  <div v-if="$_companyPlantIssuer.nfse_rule2_outside_city_esp">
                    <div class="row mt-0 mb-2 ml-2 align-items-center">
                      <div class="col-12 col-md-5">
                        <div class="h4 text-black">Reter para Pessoa Jurídica</div>
                      </div>
                      <div class="col-12 col-md-7 d-flex justify-content-end">
                        <base-input group class="mb-0" autocomplete="off">
                          <base-switch
                            v-model="$_companyPlantIssuer.nfse_rule2_outside_city_iss_legal_esp"
                            type="primary"
                            offText="Não"
                            onText="Sim"
                          ></base-switch>
                        </base-input>
                      </div>
                    </div>
                    <div class="row mt-0 mb-2 ml-2 align-items-center">
                      <div class="col-12 col-md-5">
                        <div class="h4 text-black">
                          Reter para Pessoa Física
                        </div>
                      </div>                    
                      <div class="col-12 col-md-7 d-flex justify-content-end">
                        <base-input group class="mb-0" autocomplete="off">
                          <base-switch
                            v-model="$_companyPlantIssuer.nfse_rule2_outside_city_iss_individual_esp"
                            type="primary"
                            offText="Não"
                            onText="Sim"
                          ></base-switch>
                        </base-input>
                      </div>
                    </div>
                  </div>
                </div>
                
                <!-- CARD - NATUREZA DE OPERACAO -->
                <div class="card p-4">
                  <div class="row">
                    <div class="col-6">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img height="30px" src="/img/icons/icons8/ios/project-setup_success.png" class="mr-3"/>
                        Natureza de Operação
                      </h5>
                    </div>
                    <div class="col-6 d-flex justify-content-end">
                      <base-input group class="mb-0" autocomplete="off">
                        <div class="pb-0 mt-n1 mr-2 new-default-black-font mb-1 col-form-label form-control-label">
                          Regra Especial
                        </div>
                        <base-switch
                          class="ml-2"
                          v-model="$_companyPlantIssuer.is_nfse_oper_const_city_issuer_special"
                          type="primary"
                          offText="Não"
                          onText="Sim"
                        ></base-switch>
                      </base-input>
                    </div>
                  </div>
                  <hr class="new-default-black mt-2 mb-2">
                  <div v-if="!$_companyPlantIssuer.is_nfse_oper_const_city_issuer_special">
                    <div class="row mt-3 mb-2 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Município da Obra Igual do Emissor
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                          v-model="$_companyPlantIssuer.nfse_operation_source_construction_city_equal_issuer"
                          :items="taxRegimesPuzl"
                          customKey="code"
                          :labelMask="true"
                          label="$name$"
                          placeholder="Vazio"
                          :loading="loadingTaxRegimesPuzl"
                          :disabled="loadingTaxRegimesPuzl"
                        />
                      </div>
                    </div>
                    <div class="row mt-3 mb-2 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Município da Obra Diferente do Emissor
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                          v-model="$_companyPlantIssuer.nfse_operation_source_construction_city_diff_issuer"
                          :items="taxRegimesPuzl"
                          customKey="code"
                          :labelMask="true"
                          label="$name$"
                          placeholder="Vazio"
                          :loading="loadingTaxRegimesPuzl"
                          :disabled="loadingTaxRegimesPuzl"
                        />
                      </div>
                    </div>
                  </div>                    

                  <div v-if="$_companyPlantIssuer.is_nfse_oper_const_city_issuer_special">
                    <div class="row mt-3 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Município da Obra Igual do Emissor
                        </div>
                      </div>
                    </div>
                    <div class="ml-4 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          PJ
                        </div>
                      </div>
                    </div>
                    <div class="ml-5 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Com Retenção de ISS
                        </div>
                      </div>
                    </div>
                    <div class="ml-6 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Com Dedução
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                          v-model="$_companyPlantIssuer.nfse_oper_const_city_eq_issuer_le_iss_ret_with_ded"
                          :items="taxRegimesPuzl"
                          customKey="code"
                          :labelMask="true"
                          label="$name$"
                          placeholder="Vazio"
                          :loading="loadingTaxRegimesPuzl"
                          :disabled="loadingTaxRegimesPuzl"
                        />
                      </div>
                    </div>
                    <div class="ml-6 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Sem Dedução
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                          v-model="$_companyPlantIssuer.nfse_oper_const_city_eq_issuer_le_iss_ret_no_ded"
                          :items="taxRegimesPuzl"
                          customKey="code"
                          :labelMask="true"
                          label="$name$"
                          placeholder="Vazio"
                          :loading="loadingTaxRegimesPuzl"
                          :disabled="loadingTaxRegimesPuzl"
                        />
                      </div>
                    </div>
                    <div class="ml-5 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Sem Retenção de ISS
                        </div>
                      </div>
                    </div>
                    <div class="ml-6 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Com Dedução
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                          v-model="$_companyPlantIssuer.nfse_oper_const_city_eq_issuer_le_no_iss_ret_with_ded"
                          :items="taxRegimesPuzl"
                          customKey="code"
                          :labelMask="true"
                          label="$name$"
                          placeholder="Vazio"
                          :loading="loadingTaxRegimesPuzl"
                          :disabled="loadingTaxRegimesPuzl"
                        />
                      </div>
                    </div>
                    <div class="ml-6 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Sem Dedução
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                          v-model="$_companyPlantIssuer.nfse_oper_const_city_eq_issuer_le_no_iss_ret_no_ded"
                          :items="taxRegimesPuzl"
                          customKey="code"
                          :labelMask="true"
                          label="$name$"
                          placeholder="Vazio"
                          :loading="loadingTaxRegimesPuzl"
                          :disabled="loadingTaxRegimesPuzl"
                        />
                      </div>
                    </div>
                    <div class="ml-4 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          PF
                        </div>
                      </div>
                    </div>
                    <div class="ml-5 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Com Retenção de ISS
                        </div>
                      </div>
                    </div>
                    <div class="ml-6 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Com Dedução
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                          v-model="$_companyPlantIssuer.nfse_oper_const_city_eq_issuer_ip_iss_ret_with_ded"
                          :items="taxRegimesPuzl"
                          customKey="code"
                          :labelMask="true"
                          label="$name$"
                          placeholder="Vazio"
                          :loading="loadingTaxRegimesPuzl"
                          :disabled="loadingTaxRegimesPuzl"
                        />
                      </div>
                    </div>
                    <div class="ml-6 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Sem Dedução
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                          v-model="$_companyPlantIssuer.nfse_oper_const_city_eq_issuer_ip_iss_ret_no_ded"
                          :items="taxRegimesPuzl"
                          customKey="code"
                          :labelMask="true"
                          label="$name$"
                          placeholder="Vazio"
                          :loading="loadingTaxRegimesPuzl"
                          :disabled="loadingTaxRegimesPuzl"
                        />
                      </div>
                    </div>
                    <div class="ml-5 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Sem Retenção de ISS
                        </div>
                      </div>
                    </div>
                    <div class="ml-6 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Com Dedução
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                          v-model="$_companyPlantIssuer.nfse_oper_const_city_eq_issuer_ip_no_iss_ret_with_ded"
                          :items="taxRegimesPuzl"
                          customKey="code"
                          :labelMask="true"
                          label="$name$"
                          placeholder="Vazio"
                          :loading="loadingTaxRegimesPuzl"
                          :disabled="loadingTaxRegimesPuzl"
                        />
                      </div>
                    </div>
                    <div class="ml-6 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Sem Dedução
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                          v-model="$_companyPlantIssuer.nfse_oper_const_city_eq_issuer_ip_no_iss_ret_no_ded"
                          :items="taxRegimesPuzl"
                          customKey="code"
                          :labelMask="true"
                          label="$name$"
                          placeholder="Vazio"
                          :loading="loadingTaxRegimesPuzl"
                          :disabled="loadingTaxRegimesPuzl"
                        />
                      </div>
                    </div>
  
                    <div class="row mt-3 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Município da Obra Diferente do Emissor
                        </div>
                      </div>
                    </div>
                    <div class="ml-4 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          PJ
                        </div>
                      </div>
                    </div>
                    <div class="ml-5 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Com Retenção de ISS
                        </div>
                      </div>
                    </div>
                    <div class="ml-6 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Com Dedução
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                          v-model="$_companyPlantIssuer.nfse_oper_const_city_diff_issuer_le_iss_ret_with_ded"
                          :items="taxRegimesPuzl"
                          customKey="code"
                          :labelMask="true"
                          label="$name$"
                          placeholder="Vazio"
                          :loading="loadingTaxRegimesPuzl"
                          :disabled="loadingTaxRegimesPuzl"
                        />
                      </div>
                    </div>
                    <div class="ml-6 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Sem Dedução
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                          v-model="$_companyPlantIssuer.nfse_oper_const_city_diff_issuer_le_iss_ret_no_ded"
                          :items="taxRegimesPuzl"
                          customKey="code"
                          :labelMask="true"
                          label="$name$"
                          placeholder="Vazio"
                          :loading="loadingTaxRegimesPuzl"
                          :disabled="loadingTaxRegimesPuzl"
                        />
                      </div>
                    </div>
                    <div class="ml-5 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Sem Retenção de ISS
                        </div>
                      </div>
                    </div>
                    <div class="ml-6 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Com Dedução
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                          v-model="$_companyPlantIssuer.nfse_oper_const_city_diff_issuer_le_no_iss_ret_with_ded"
                          :items="taxRegimesPuzl"
                          customKey="code"
                          :labelMask="true"
                          label="$name$"
                          placeholder="Vazio"
                          :loading="loadingTaxRegimesPuzl"
                          :disabled="loadingTaxRegimesPuzl"
                        />
                      </div>
                    </div>
                    <div class="ml-6 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Sem Dedução
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                          v-model="$_companyPlantIssuer.nfse_oper_const_city_diff_issuer_le_no_iss_ret_no_ded"
                          :items="taxRegimesPuzl"
                          customKey="code"
                          :labelMask="true"
                          label="$name$"
                          placeholder="Vazio"
                          :loading="loadingTaxRegimesPuzl"
                          :disabled="loadingTaxRegimesPuzl"
                        />
                      </div>
                    </div>
                    <div class="ml-4 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          PF
                        </div>
                      </div>
                    </div>
                    <div class="ml-5 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Com Retenção de ISS
                        </div>
                      </div>
                    </div>
                    <div class="ml-6 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Com Dedução
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                          v-model="$_companyPlantIssuer.nfse_oper_const_city_diff_issuer_ip_iss_ret_with_ded"
                          :items="taxRegimesPuzl"
                          customKey="code"
                          :labelMask="true"
                          label="$name$"
                          placeholder="Vazio"
                          :loading="loadingTaxRegimesPuzl"
                          :disabled="loadingTaxRegimesPuzl"
                        />
                      </div>
                    </div>
                    <div class="ml-6 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Sem Dedução
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                          v-model="$_companyPlantIssuer.nfse_oper_const_city_diff_issuer_ip_iss_ret_no_ded"
                          :items="taxRegimesPuzl"
                          customKey="code"
                          :labelMask="true"
                          label="$name$"
                          placeholder="Vazio"
                          :loading="loadingTaxRegimesPuzl"
                          :disabled="loadingTaxRegimesPuzl"
                        />
                      </div>
                    </div>
                    <div class="ml-5 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Sem Retenção de ISS
                        </div>
                      </div>
                    </div>
                    <div class="ml-6 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Com Dedução
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                          v-model="$_companyPlantIssuer.nfse_oper_const_city_diff_issuer_ip_no_iss_ret_with_ded"
                          :items="taxRegimesPuzl"
                          customKey="code"
                          :labelMask="true"
                          label="$name$"
                          placeholder="Vazio"
                          :loading="loadingTaxRegimesPuzl"
                          :disabled="loadingTaxRegimesPuzl"
                        />
                      </div>
                    </div>
                    <div class="ml-6 row align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          Sem Dedução
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                          v-model="$_companyPlantIssuer.nfse_oper_const_city_diff_issuer_ip_no_iss_ret_no_ded"
                          :items="taxRegimesPuzl"
                          customKey="code"
                          :labelMask="true"
                          label="$name$"
                          placeholder="Vazio"
                          :loading="loadingTaxRegimesPuzl"
                          :disabled="loadingTaxRegimesPuzl"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="offset-md-4 col-md-8 mt-n1">
                <div class="row d-flex justify-content-end show-md">
                  <div class="col-md-3 px-1">
                    <base-button
                      v-bind:disabled="invalid"
                      block
                      type="success"
                      size="md"
                      style="border-radius: 4px !important;"
                      @click="save()"
                    >
                      <img class="mr-2" src="/img/icons/save.png" width="19px">
                      SALVAR
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </card>
    </div>
    <modal-tax-regimes-by-ibge-code ref="ModalTaxRegimesByIbgeCode"></modal-tax-regimes-by-ibge-code>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import ModalTaxRegimesByIbgeCode from './_ModalTaxRegimesByIbgeCode';
import {formatErrorValidation} from "@/plugins";
import Mention from "@/components/Inputs/Mention";

export default {
  name: "IssuerDocumentNfse",
  components: {
    PuzlSelect,
    ModalTaxRegimesByIbgeCode,
    Mention,
  },
  data() {
    return {
      nfseBroadcastEnvironments: [
        {id: 0, name: 'Homologação'},
        {id: 1, name: 'Produção'}
      ],
      taxRegimesPuzl: [],
      loadingTaxRegimesPuzl: false,
      loadingTaxRegimesMetadata: false,
      loadingSpecialTaxRules: false,
      taxRegimesMetadata: [],
      loadingLinkNfseLogo: false,
    }
  },
  computed: {
    ...mapGetters({
      $_companyPlantIssuer: "companyPlantIssuer/show",
      $_mentions: "nfse/getListMentions",
    }),
  },
  mounted(){
    this.init();
  },
  methods: {
    init(){
      this.getTaxRegimesForMetadata();
      this.loadTaxRegimesPuzl();
      this.$store.dispatch("nfse/getMentions");
    },
    loadTaxRegimesPuzl() {
      try {
        this.loadingTaxRegimesPuzl = true;
        this.taxRegimesPuzl = [];
        switch (this.$_companyPlantIssuer.nfse_provider) {
          case 0: // ENOTAS
            this.taxRegimesPuzl.push({ code: '', name: 'Vazio' });
            for (let i = 0; i <= 20; i++) {
              const iStr = i.toString()
              this.taxRegimesPuzl.push({ code: iStr, name: iStr })
            }
            this.taxRegimesPuzl.push({ code: 'S', name: 'S' });
            this.taxRegimesPuzl.push({ code: 'N', name: 'N' });
            break;

          case 1: // MIGRATE
            if (!this.$_companyPlantIssuer.nfse_ms_company_id) {
              return;
            }
            let param = this.$_companyPlantIssuer.nfse_ms_company_id;
            this.$store.dispatch("nfse/getOperationSourcesByNfseMsCompanyId", param)
              .then(response => {
                this.taxRegimesPuzl = response.data.map(item => {
                  return {
                    code: item.provider_code,
                    name: item.description
                  };
                });
              }).catch((error) => {
                const errors = error.response.data.message;
                this.$notify({ type: "danger", message: errors });
              });
            break;
        }
      } finally {
        this.loadingTaxRegimesPuzl = false;
      }
    },
    goBack(){
      const path = (this.$route.query['create'] === 'true')
        ? '/configuration/plant/document/issuer-document/create?changing=true'
        : `/configuration/plant/document/issuer-document/edit/${this.$_companyPlantIssuer.id}?changing=true`;
      this.$router.push({path: path});
    },
    async handleOpenModalTaxRegimesByIbgeCode(cityName, ibgeCode) {
      if (!ibgeCode) {
        await this.checkIbgeCode();
        ibgeCode = this.$_companyPlantIssuer.ibge_code;
      }
      this.$refs.ModalTaxRegimesByIbgeCode.handleCreateModal(cityName, ibgeCode);
    },
    /**
     * Verificar se ibge_code foi preenchido.
     * Caso não esteja preenchido, tenta localizar ibgecode a partir de this.$_companyPlantIssuer.state e this.$_companyPlantIssuer.city
     */
    async checkIbgeCode() {
      if (this.$_companyPlantIssuer.state && this.$_companyPlantIssuer.city) {
        let loader = this.$loading.show();
        let params = {state: this.$_companyPlantIssuer.state, name: this.$_companyPlantIssuer.city}
        await this.$store.dispatch("city/showByStateAndCity", params)
          .then(response => {
            this.$_companyPlantIssuer.ibge_code = response.data.iso;
            this.$notify({type: response.error_type, message: response.message});
          }).catch((error) => {
            let errors = error.status
              ? error.data.message
              : formatErrorValidation(error.response.data.errors);
            this.$notify({type: "danger", message: errors});
          }).finally(() => {
            loader.hide();
          });
      }
    },
    async getTaxRegimesForMetadata(){
      try {
        this.loadingTaxRegimesMetadata = true;
        if (this.$_companyPlantIssuer.nfse_send_regime_metadata) {
          this.taxRegimesMetadata = await this.getSpecialTaxRules();
        }
      } finally {
        this.loadingTaxRegimesMetadata = false;
      }
    },
    async getSpecialTaxRules() {
      try {
        this.loadingSpecialTaxRules = true;
        switch (this.$_companyPlantIssuer.nfse_provider) {
          case 0: // ENOTAS
            if (!this.$_companyPlantIssuer.ibge_code) {
              return;
            }
            return await this.$store
              .dispatch("nfse/getTaxRegimesByIbgeCode", this.$_companyPlantIssuer.ibge_code)
              .then((response) => {
                return response.data['regimesEspecialTributacao'].map(item => ({
                  code: item.codigo,
                  name: item.nome
                }));
              })
              .catch((error) => {
                this.$notify({
                  type: "danger",
                  message: error.data.message,
                });
              });

          case 1: // MIGRATE
            if (!this.$_companyPlantIssuer.nfse_ms_company_id) {
              return;
            }
            let param = this.$_companyPlantIssuer.nfse_ms_company_id;
            return await this.$store
              .dispatch("nfse/getSpecialTaxRulesByNfseMsCompanyId", param)
              .then((response) => {
                return response.data.map(item => ({
                  code: item.code,
                  name: item.description
                }));
              })
              .catch((error) => {
                this.$notify({
                  type: "danger",
                  message: error.data.message,
                });
              });
        }
      } finally {
        this.loadingSpecialTaxRules = false;
      }
    },
    linkNfseLogo(){
      let loader = this.$loading.show();
      try {
        this.loadingLinkNfseLogo = true;
        this.$Progress.start();

        // Verificar arquivo selecionado
        const selectedFile = this.$refs.inputNfseLogo.files[0];
        if (!selectedFile) return;

        // Verificar extensões
        const allowedExtensions = ["jpg", "jpeg", "png"];
        const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
          this.$notify({type: 'danger', message: 'Por favor, selecione uma imagem PNG ou JPG.'});
          return;
        }

        // Enviar requisição
        const payload = {
          msCompanyId: this.$_companyPlantIssuer.nfse_ms_company_id,
          file: selectedFile,
        };
        return this.$store
          .dispatch("nfse/linkNfseLogo", payload)
          .then(() => this.$notify({type: 'success', message: 'Logo para NFS-e foi vinculado com sucesso!'}))
          .catch((error) => this.$notify({type: "danger", message: error.data.message}))
          .finally(() => this.$refs.inputNfseLogo.value = null);
      } finally {
        this.loadingLinkNfseLogo = false;
        loader.hide();
        this.$Progress.finish();
      }
    },
    async save() {
      // Garantir que ibge_code seja salvo
      if (!this.$_companyPlantIssuer.ibge_code) {
        await this.checkIbgeCode();
        if (!this.$_companyPlantIssuer.ibge_code) {
          this.$notify({type: "danger", message: 'Não foi possível obter o código IBGE da Cidade.'});
          return;
        }
      }
      this.isLoading = true;
      this.$Progress.start();
      this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
      this.$_companyPlantIssuer.send_auto_mail = Number(this.$_companyPlantIssuer.send_auto_mail);
      this.$_companyPlantIssuer.alias_name = this.$_companyPlantIssuer.business_name;
      this.$_companyPlantIssuer.certificate_password = this.certificate_password;

      this.$store
        .dispatch('companyPlantIssuer/update', {
          companyPlantIssuer: this.$_companyPlantIssuer,
          certificate_file: this.certificate_file
        })
        .then(({ error_type, message }) => {
          this.$notify({type: error_type, message});
          this.goBack();
        })
        .catch(error => {
          const errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
        }).finally(() => {
          this.$Progress.finish();
          this.isLoading = false;
        });
    },
    setObservationNfseDefault(){
      this.$_companyPlantIssuer.observation_nfse = 'CONTRATO @CONTRATO'+
        ' | FATURA @FATURA OBRA @OBRA @CONCRETO_SIMPLIFICADO @SERVIÇO |'+
        ' @PAGAMENTO @DATAS @COMPLEMENTO @IBPT ';
    },
  },
}
</script>

<style scoped>
.btn.dropdown-toggle::after {
  content: none;
}
</style>

import MdfeListPage from "../views/list/MdfeListPage.vue";

export default [
  {
    path: '/fiscal/mdfe',
    name: 'fiscal.mdfe.view',
    component: MdfeListPage,
    meta: {
      title: 'Fiscal',
      description: 'MDF-e',
      icon: 'fa fa-home',
      permission: 'fiscal.mdfe.index.view',
      breadcrumbs: [
        {title: 'Dashboard', name: 'fiscal.dashboard.index'},
        {title: 'MDF-e', name: null},
      ]
    },
  },
];

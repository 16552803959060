import { PagerType, initPagerType } from "../../../../../shared/types";

/**
 * Type para item da listagem de Equipment
 *
 * @typedef {Object} EquipmentListType
 * @property {number} id
 * @property {string} uuid
 * @property {number} status
 * @property {number} concrete_removal
 * @property {string} code
 * @property {string} plate
 * @property {?number} price
 * @property {?string} vehicle_tag
 * @property {?string} brand
 * @property {?string} model
 * @property {string} vehicle_description
 * @property {?string} chassis
 * @property {?string} vehicle_permit_code
 * @property {?number} equipment_year
 * @property {?number} equipment_manufacture_year
 * @property {?string} predominant_color
 * @property {?string} equipment_fuel
 * @property {?string} vehicle_license_name
 * @property {?string} vehicle_license_legal_entity
 * @property {string} state
 * @property {number} is_external_service
 * @property {Object} category
 * @property {Object} situation
 * @property {Object} type
 * @property {boolean} type.status
 * @property {string} type.description
 * @property {?string} type.totvs_external_code
 * @property {Array<Object>} type.category
 * @property {?string} measured_unit
 * @property {Array<Object>} allocation
 * @property {Array} buy_sale
 * @property {?string} entity_name
 * @property {?string} entity_document
 * @property {Array} equipments_restriction
 */
export const EquipmentListType = {};

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {( PagerType & { items: Array<EquipmentListType> } )} EquipmentListPagerType
 */
export const EquipmentListPagerType = {};

/**
 * Inicializar EquipmentListPagerType
 *
 * @returns {EquipmentListPagerType}
 */
export const initEquipmentListPagerType = () => {
  return {
    ...initPagerType(),
    items: [],
  };
};

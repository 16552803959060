<template>
<div>
  <AppFixedPageTitle
    title="Parâmetros de preços mínimo de concreto"
    icon="/img/icons/icons8/ios/physics_white.png"
  />
  <AppPageHeader>
    <template slot="search-bar">
      <AppSearchBar
        :searchBarFilter.sync="searchBarFilter"
        :showCompanyPlants="true"
        :isLoading="loadinGrid"
        @onSearchClick="init({})"
        @onClearClick="clearFilter"
      >
        <AppSearchBarFilterSection
          name="Dados"
          icon="/img/icons/icons8/ios/info-squared_gray.png"
        >
        </AppSearchBarFilterSection>
      </AppSearchBar>
    </template>
    <template slot="header-buttons">
      <AppPageHeaderActions :isBackButtonEnabled="true">
        <AppPageHeaderActionsButton
          @click.prevent="$router.push('/commercial/concrete-price/parameter/create')"
          text="novo"
          type="success"
          icon="/img/icons/plus-math--v1-white.png"
        />
        <AppPageHeaderActionsButton
          text="Acréscimos"
          :isDropdown="true"
          type="primary"
          icon="/img/icons/plus--v1.png"
        >
          <AppPageHeaderActionsButtonSectionDivider
            title="Geral"
          />
          <AppPageHeaderActionsButtonDropItem
            text="Tipo de formulação"
            icon=""
            @click="handleEdit"
          />
          <AppPageHeaderActionsButtonDropItem
            text="Peça a concretar"
            icon=""
            @click="handleCreateConcretedPiece"
          />
          <AppPageHeaderActionsButtonDropItem
            text="Puzl Admix"
            icon=""
            @click="handleCreatePuzlAdmixture"
          />
          <AppPageHeaderActionsButtonDropItem
            text="Slump final"
            icon=""
            @click="handleCreateSlumpFinal"
          />
          <AppPageHeaderActionsButtonDropItem
            text="Nível de aplicação"
            icon=""
            @click="handleCreateLevelResponsibility"
          />
        </AppPageHeaderActionsButton>
      </AppPageHeaderActions>
    </template>
  </AppPageHeader>
  <div class="container-fluid mt-4">
    <div class="row card-wrapper" v-show="loadinGrid">
      <SkeletonPuzlGrid v-for="(skeletons, index) in 3" :key="index"></SkeletonPuzlGrid>
    </div>
    <div class="row card-wrapper" v-show="!loadinGrid">
        <div class="col-lg-4"
          v-for="(item, index) in listParameter"
          :key="index"
        >
          <card>
            <el-popover trigger="click" placement="bottom" class="p-0 pr-0">
              <h4 class="text-dark">Central </h4>
              <hr style="background: rgba(0, 0, 0, 0.05) !important; width: 107%;" class="new-default-black mt-1 mb-3 ml-n2" />
              <div v-for="(itemPlant, indexPlant) in item.company_plants" :key="indexPlant">
                <div class="row">
                  <div class="col-md-12">
                    {{itemPlant.name}}
                  </div>
                </div>
              </div>
              <base-button outline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                <img class="mr-1" src="/img/icons/icons8/ios/chemical-plant-v1.png" width="23">
              </base-button>
            </el-popover>

            <el-popover trigger="click" placement="bottom" class="p-0">
              <base-button outline slot="reference" size="sm" type="secondary" class="p-0 m-0 shadow-none">
                <img src="/img/icons/icons8/ios/adjust_primary.png" width="23px" class="mr-2">
              </base-button>

              <div>
                <h4 class="text-dark">Parâmetros </h4>
                <hr style="background: rgba(0, 0, 0, 0.05) !important; width: 107%;" class="new-default-black mt-1 mb-3 ml-n2" />
                <!-- Geral -->
                <div class="card p-3 mb-3 border-0 shadow-none">
                  <h4 class="text-dark">GERAL</h4>
                  <div class="d-flex align-items-center justify-content-between pb-2">
                    <span>Validade</span>
                    <span class="flex-grow-1 border-bottom mx-2"></span>
                    <span>{{ item.expiration_months }} DIAS</span>
                  </div>
                  <div class="d-flex align-items-center justify-content-between pb-2 pt-2">
                    <span>Usinagem</span>
                    <span class="flex-grow-1 border-bottom mx-2"></span>
                    <span>R$ {{ item.machining_cost }} /m<sup>3</sup></span>
                  </div>
                  <div class="d-flex align-items-center justify-content-between pt-2">
                    <span>Impostos</span>
                    <span class="flex-grow-1 border-bottom mx-2"></span>
                    <span>{{ item.tax | percent(0) }}</span>
                  </div>
                </div>

                <!-- Transporte -->
                <div class="card p-3 mb-3 border-0 shadow-none">
                  <h4 class="text-dark">TRANSPORTE</h4>
                  <div class="d-flex align-items-center justify-content-between pb-2">
                    <span>Distância Máxima</span>
                    <span class="flex-grow-1 border-bottom mx-2"></span>
                    <span>{{ item.transport_maximum_distance }} KM</span>
                  </div>
                  <div class="d-flex align-items-center justify-content-between pb-2 pt-2">
                    <span>Adicional</span>
                    <span class="flex-grow-1 border-bottom mx-2"></span>
                    <span>R$ {{ item.transport_additional_cost }} /km (por m<sup>3</sup>)</span>
                  </div>
                  <div class="d-flex align-items-center justify-content-between pt-2">
                    <span>Custo Mínimo</span>
                    <span class="flex-grow-1 border-bottom mx-2"></span>
                    <span>R$ {{ item.transport_minimum_cost }} /m<sup>3</sup></span>
                  </div>
                </div>

                <!-- Ciclo -->
                <div class="card p-3 border-0 shadow-none">
                  <h4 class="text-dark">CICLO</h4>
                  <div class="d-flex align-items-center justify-content-between pb-2">
                    <span>Ciclo Máximo</span>
                    <span class="flex-grow-1 border-bottom mx-2"></span>
                    <span>{{ item.cycle_maximum_distance }} MINUTOS</span>
                  </div>
                  <div class="d-flex align-items-center justify-content-between pb-2 pt-2">
                    <span>Adicional</span>
                    <span class="flex-grow-1 border-bottom mx-2"></span>
                    <span>R$ {{ item.cycle_additional_cost }} /km (por m<sup>3</sup>)</span>
                  </div>
                  <div class="d-flex align-items-center justify-content-between pt-2">
                    <span>Custo Mínimo</span>
                    <span class="flex-grow-1 border-bottom mx-2"></span>
                    <span>R$ {{ item.cycle_minimum_cost }} /m<sup>3</sup></span>
                  </div>
                </div>
              </div>
            </el-popover>

            <div class="float-right mt-n1">
              <base-dropdown menuOnRight>
                <div slot="title-container" class="dropdown-toggle rounded m-0">
                  <img width="32px" src="/img/icons/icons8/ios/settings--v1_primary.png">
                </div>
                <router-link
                  :to="{
                    path:'/commercial/concrete-price/parameter/edit',
                    name:'Parâmetros de preços mínimo - editar formulação',
                    params: { uuid: item.uuid }
                  }"
                >
                  <a class="dropdown-item">
                    <img src="/img/icons/create-new.png" width="19px" height="19px">
                    Editar
                  </a>
                </router-link>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" @click="handleDelete(item.uuid)">
                  <img src="/img/icons/icons8/ios/delete--v1.png" width="19px" height="19px">
                  Excluir
                </a>
              </base-dropdown>
            </div>
           
            <div class="mt-4">
              <h5 class="h2 mb-0">
                {{item.description}}
              </h5>
            </div>
          </card>
        </div>
  </div>
</div>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-md-12 text-center">
        <PuzlEmptyData v-if="listParameter ==''"></PuzlEmptyData>
      </div>
    </div>
  </div>
</div>

</template>
<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import globalFilter from "@/mixins/globalFilter";
import {
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppPageHeaderActionsButtonSectionDivider,
  AppPageHeaderActionsButtonDropItem,
  AppSearchBar,
  AppSearchBarFilterSection,
  AppSelect,
  AppTabSelect, AppViewTrigger, initSearchBarFilterType
} from "@/components/AppGlobal";
import { ParameterListFilterType, initParameterListFilterType } from "../Types";

export default {
  mixins: [globalFilter],
  components: {
    SkeletonPuzlGrid,
    PuzlEmptyData,
    AppPageHeader,
    AppPageHeaderActionsButtonSectionDivider,
    AppPageHeaderActionsButtonDropItem,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppTabSelect,
    AppFixedPageTitle,
    AppSelect,
    AppSearchBar,
    AppSearchBarFilterSection,
    AppViewTrigger,
    initSearchBarFilterType,
  },
  data() {
    return {
      listParameter: {},
      loadinGrid: false,
      filter: initParameterListFilterType(),
      searchBarFilter: initSearchBarFilterType(),
    }
  },
  methods: {
    init(){
      this.$Progress.start();
      this.loadinGrid = true;
      this.prepareFilter();
      this.$axios.get("/commercial/concrete-price/minimum-costs", {params: {filter: this.filter}})
        .then(({data}) => {
          this.listParameter = data.data;
          this.loadinGrid = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loadinGrid = false;
          this.$Progress.finish();
        });
    },
    handleDelete(uuid){
      this.$Progress.start();
        this.$swal.fire({
          title: 'Você tem certeza?',
          text: 'Você não poderá recuperar este registro!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, apague isto!',
          cancelButtonText: 'Não, mantenha',
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
            this.$axios.delete("/commercial/concrete-price/minimum-costs/"+uuid)
              .then(({data}) => {
                this.$notify({ type: data.error_type, message: data.message });
                this.$Progress.finish()
                this.init()
              })
              .catch((error) => {
                this.$notify({ type: error.response.data.error_type, message: error.response.data.message });
              });
            }
            this.$Progress.finish();
        });
    },
    clearFilter(isRefreshList = true) {
      Object.assign(this.searchBarFilter, this.defaultSearchBarFilter());
      Object.assign(this.filter, this.defaultFilter());
      if (isRefreshList) {
        this.init({});
      }
    },
    prepareFilter() {
      this.filter.custom_search.values = this.searchBarFilter.custom_search_values;
      this.filter.company_plant_id = this.searchBarFilter.company_plant_selected;
    },
    /**
     * Padrão do filtro principal
     * @returns {ParameterListFilterType}
     */
    defaultFilter() {
      return {
        ...initParameterListFilterType(),
      };
    },
    /**
     * Padrão de filtro da barra de pesquisa
     * @returns {SearchBarFilterType}
     */
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
      };
    },
    handleEdit() {
      EventBus.$emit("handleCreateMixTypePercentage");
    },
    handleCreateConcretedPiece() {
      EventBus.$emit("handleCreateMixConcretedPiecesPercentage");
    },
    handleCreatePuzlAdmixture() {
      EventBus.$emit("handleCreateMixPuzlAdmixturePercentage");
    },
    handleCreateSlumpFinal() {
      EventBus.$emit("handleCreateSlumpFinal");
    },
    handleCreateLevelResponsibility() {
      EventBus.$emit("handleCreateLevelResponsibility");
    },
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped>
.card {
  box-shadow: none !important;
  border: 1px solid #dee2e6 !important;
}
</style>
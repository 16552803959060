<template>
  <div>
    <modal size="lg"
      :show.sync="show"
      :backgroundColor="'#E8E8E8'"
      :showClose="false"
    >
      <template>
        <template slot="header">
          <div class="d-flex align-items-center container ml-1 w-100" style="gap: 15px;">
            <div>
              <img src="/img/icons/icons8/ios/download-dark.png" alt="download-dark" width="24px">
            </div>
            <span class="text-title">
              {{ modal.title }}
            </span>
          </div>
        </template>
      </template>
      <template slot="close-button">
        <AppButton
          icon="close"
          width="auto"
          type="link"
          size="sm"
          :hasOutline="true"
          @click="closeModal()"
        />
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12" style="display: flex; align-items: center;">
                  <h4 class="m-0 mx-2" style="font-size: 16px; font-weight: 500; color: #2B2D32;">
                      DOWNLOAD
                  </h4>
                  <hr class="ml-3" style="width: 100%;">
                </div>
                <div class="col-12">
                  <div class="d-flex align-items-center ml-2">
                    <span style="font-size: 14px;font-weight: 400;color: #2B2D32;">
                      Data de Criação
                    </span>
                    <div class="ml-auto" style="width: 70%;">
                      <InputDatePicker size="small" ref="date-picker" @handleFilterDate="handleSetDate"
                      :default-range="filter.range" :disableBoxShadow="true" />
                    </div>
                  </div>
                </div>
                <div class="col-12 my-3">
                  <div class="d-flex align-items-center ml-2">
                    <span style="font-size: 14px;font-weight: 400;color: #2B2D32;">
                      CNPJ Emissor
                      <span class="text-danger">&nbsp;*</span>
                    </span>
                    <div class="ml-auto" style="width: 70%;">
                      <validation-provider rules="required">
                        <PuzlSelect :clearable="false" class="select-lg" label="$alias_name$ | $ein$" :label-mask="true"
                          v-model="filter.company_plant_issuer_id" :items="$_company_plant_issuers"
                          :loading="loadingCompanyPlantIssuers" :disabled="loadingCompanyPlantIssuers"
                          :disableBoxShadow="true" />
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button class="danger button" @click="closeModal">
                  <AppIcon icon="cancel" color="danger" width="18" />
                  Cancelar
                </button>
                <button
                  class="success button"
                  type="submit"
                  v-bind:disabled="invalid"
                  :loading="isLoading"
                  @click="save()"
                >
                  <AppIcon icon="save" color="success" width="18" />
                  Exportar
                </button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";
import AppIcon from "../../../../../../components/AppGlobal/AppIcon/AppIcon.vue";
import { AppButton } from "../../../../../../components/AppGlobal";

export default {
  name: "ModalExporter",
  components: {
    InputDatePicker,
    PuzlSelect,
    AppIcon,
    AppButton,
  },
  data() {
    return {
      modal: {
        title: "EXPORTAR NF-E",
      },
      isLoading: false,
      loadingCompanyPlantIssuers: false,
      filter: {
        company_plant_id: null,
        type: 0,
        range: {
          start: new Date(),
          end: new Date(),
        },
      },
      show: false,
    };
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
      $_company_plant_issuers: "companyPlantIssuer/fetch",
    }),
  },
  methods: {
    closeModal() {
      this.show = false;
    },
    async openModal() {
      this.filter.company_plant_id = null
      this.filter.range = {
        start: new Date(),
        end: new Date(),
      }
      await this.getCompanyPlantIssuers()
      this.show = true
    },
    handleSetDate(range) {
      this.filter.range = range
    },
    getCompanyPlantIssuers() {
      this.loadingCompanyPlantIssuer = true;
      this.$store
        .dispatch("companyPlantIssuer/fetchItems")
        .then(() => {
          this.loadingCompanyPlantIssuer = false;
        });
    },
    save() {
      this.isLoading = true
      this.$store.dispatch('exports/store', { filter: this.filter })
        .then(response => {
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.close()
          this.$emit('openProcessList')
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          });
        })
    },
  }
}
</script>

<style scoped>
.button-cancel {
  cursor: pointer;
  border: 0.5px solid #DB4539;
  border-radius: 8px;
  color: #DB4539;
  padding: 4px 20px;
}
.button-save {
  cursor: pointer;
  border: 0.5px solid #149E57;
  border-radius: 8px;
  color: #149E57;
  padding: 4px 30px;
}
</style>
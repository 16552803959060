<template>
<div>
  <modal :show.sync="modal.open">
    <template slot="header">
      <h5 class="modal-title">{{ modal.title }}</h5>
    </template>
    <div>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
          <div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-5 col-form-label form-control-label">
                Status
              </label>
              <div class="col-md-7 pt-2">
                <base-switch v-model="additionalClassification.status" type="success" offText="inativo" onText="ativo" class="success"></base-switch>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Descrição Técnica
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="additionalClassification.technical_description"
                      class="form-control form-control-sm"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      @input="() => commercialDescriptionManual
                        ? null 
                        : additionalClassification.commercial_description = additionalClassification.technical_description"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Descrição Comercial
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="additionalClassification.commercial_description"
                      class="form-control form-control-sm"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      @change="() => additionalClassification.commercial_description 
                        ? commercialDescriptionManual = true
                        : commercialDescriptionManual = false"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-5 pb-1 col-form-label form-control-label">
                Subtipo
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      :clearable="false"
                      v-model="additionalClassification.subtype_ids"
                      :items="[{ id: 'all', technical_description: 'Todos' }, ...subtypes]"
                      @input="setAll"
                      :multiple="true"
                      label="technical_description"
                      :disabled="loadingSubTypes"
                      :loading="loadingSubTypes" />
                  </base-input>
                </validation-provider>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <base-button type="secondary" @click="closeModal()">
              Cancelar
            </base-button>
            <base-button @click.prevent="save" type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingSave">
              Salvar
            </base-button>
          </div>
        </form>
      </validation-observer>
    </div>
  </modal>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import PuzlSelect from "@/components/PuzlSelect"

export default {
  name: "Create",
  components: { PuzlSelect },
  data () {
    return {
      modal: {
        title: 'Classificação Adicional',
        open: false,
      },
      additionalClassification: {
        status: true,
        technical_description: null,
        commercial_description: null,
        subtype_ids: [],
      },
      loadingSave: false,
      loadingSubTypes: true,
      commercialDescriptionManual: false,
    }
  },
  computed: {
    ...mapGetters({
      'subtypes': 'mixSubtype/fetch',
    }),
  },
  methods: {
    closeModal () {
      this.modal.open = false      
    },
    handleCreateModal () {
      this.modal.open = true
      this.additionalClassification = {
        status: true,
        technical_description: null,
        commercial_description: null,
        subtype_ids: [],
      }
    },
    setAll() {
      if (this.additionalClassification.subtype_ids.includes('all')) {
        this.additionalClassification.subtype_ids = []
        for(let subtype of this.subtypes) {
          this.additionalClassification.subtype_ids.push(subtype.id)
        }
      }
    },
    save () {
      this.$Progress.start()
      this.loadingSave = true
      this.$store.dispatch('mixAdditionalClassifications/add', this.additionalClassification)
        .then(response => {
          this.loadingSave = false
          this.modal.open = false
          this.additionalClassification = {
            status: true,
            technical_description: null,
            commercial_description: null,
            subtype_ids: [],
          }
          this.$Progress.finish()
          this.$notify({ type: response.error_type, message: response.message })
        })
        .catch(error =>{
          if(error.response.status===422){
            let errors = Object.values(error.response.data.errors)
            errors = errors.flat().toString().split(',').join("<br />")

            this.$notify({ type: 'danger', message: errors })
          }
          this.$Progress.finish()
          this.loadingSave = false
        })
    },
  },
  mounted () {
    this.$refs.formValidator.validate();
    this.$store.dispatch('mixSubtype/fetchItems').then(() => {
      this.loadingSubTypes = false;
    })
  },
  created () {
  }
}
</script>

<style scoped>

</style>

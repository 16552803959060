import IntegrationListPage from "../views/list/IntegrationListPage";

export default [
  {
    path: '/configuration/integration',
    name: 'configuration.integration.view',
    component: IntegrationListPage,
    meta: {
      title: 'Integração',
      description: 'Integrações',
      icon: 'fa fa-home',
      permission: 'configuration.integration.index.view',
      breadcrumbs: [
        {title: 'Dashboard', name: 'configuration.integration.index'},
        {title: 'Integrações', name: null},
      ]
    },
  },
];

import { BaseListFilterType, initBaseListFilterType, RangeDateType } from "../../../../../../shared/types";
import { RangeValueType, initRangeValueType } from "../../../../../../shared/types/RangeValueType";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} ReportFilterExtType
 * @property {boolean|null} with_hardened_state - Filtro de endurecido [true, false, null]
 * @property {boolean|null} without_hardened_state - Filtro de não endurecido [true, false, null]
 * @property {RangeValueType} os_number - Filtro de número da OS com intervalo mínimo e máximo
 * @property {RangeValueType} nfe_number - Filtro de número da NFE com intervalo mínimo e máximo
 * @property {RangeDateType|null} range - Intervalo entre datas (start e end)
 * @property {boolean|null} default_filterable - Se é filtrável por padrão
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType)} ReportListFilterType
 */
export const ReportListFilterType = {};

/**
 * Inicializar ReportListFilterType
 */
export const initReportListFilterType = () => ({
  ...initBaseListFilterType(),
  with_hardened_state: null,
  without_hardened_state: null,
  os_number: initRangeValueType(),
  nfe_number: initRangeValueType(),
  range: {
    start: null,
    end: null,
  },
  default_filterable: true, // Valor padrão inicial
});
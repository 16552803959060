const keys = Object.freeze({
  SAFRA: 1,
  REDE: 2,
  PARPAY: 3,
});

/**
 * @param {number} value
 * @returns {string}
 */
const getTitle = (value) => {
  switch (value) {
    case keys.SAFRA:
      return "Safra";
    case keys.REDE:
      return "Rede";
    case keys.PARPAY:
      return "Parpay";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle,
};

<template>
  <div>
    <div>
      <PuzlEmptyData
        v-if="!loadingSkeleton && !$_resumeAdditional.length"
      ></PuzlEmptyData>
      <div
        class="row card-wrapper"
        v-if="!loadingSkeleton && $_resumeAdditional.length"
      >
        <div
          class="col-lg-4"
          v-for="(item, index) in $_resumeAdditional"
          :key="index"
        >
          <div class="card main-card">
            <div class="d-flex" style="gap: 8px">
              <div class="mr-1">
                <base-dropdown
                  v-if="
                    (!item.billed && item.status) ||
                    (!item.billed && !item.status)
                  "
                >
                  <div
                    :class="
                      !item.billed && item.status
                        ? 'new-minicard-green'
                        : 'new-minicard-red'
                    "
                    slot="title-container"
                    style="display: inline-flex; align-items: center"
                  >
                    <img
                      class="mr-2"
                      width="16"
                      :src="getStatus(item.billed, item.status).icon"
                    />
                    <h4 class="font-weight-normal m-0 p-0 mr-2">
                      {{ getStatus(item.billed, item.status).text }}
                    </h4>
                    <img
                      :src="getStatus(item.billed, item.status).dropdown"
                      width="12"
                      alt=""
                    />
                  </div>
                  <div v-if="!item.billed && item.status">
                    <a
                      @click.prevent="handleItemStatusChange(item, index)"
                      class="dropdown-item"
                    >
                      Não cobrar
                    </a>
                  </div>
                  <div v-if="!item.billed && !item.status">
                    <a
                      @click.prevent="handleItemStatusChange(item, index)"
                      class="dropdown-item"
                    >
                      Cobrar
                    </a>
                  </div>
                </base-dropdown>
                <div
                  v-if="
                    (item.billed && item.status) ||
                    (item.billed && !item.status)
                  "
                  :class="
                    item.billed && item.status
                      ? 'new-minicard-green'
                      : 'new-minicard-red'
                  "
                  style="display: inline-flex; align-items: center"
                >
                  <img
                    class="mr-2"
                    width="16"
                    :src="getStatus(item.billed, item.status).icon"
                  />
                  <h4 class="font-weight-normal m-0 p-0 mr-2">
                    {{ getStatus(item.billed, item.status).text }}
                  </h4>
                </div>
              </div>
              <div>
                <el-popover trigger="click" placement="bottom">
                  <div class="font-weight-400 fs-14">CENTRAL</div>
                  <div
                    style="height: 0.5px; background-color: #e8e8e8"
                    class="mt-2 mb-2"
                  ></div>
                  <div class="font-weight-300 fs-12">
                    {{ item.send_company_plant_name }}
                  </div>
                  <img
                    slot="reference"
                    role="button"
                    width="26px"
                    src="/img/icons/icons8/ios/chemical-plant-v1.png"
                  />
                </el-popover>
              </div>
              <div>
                <el-popover trigger="click" placement="bottom">
                  <div style="max-width: 300px">
                    <div class="font-weight-400 fs-14">INFORMAÇÃO</div>
                    <div
                      style="height: 0.5px; background-color: #e8e8e8"
                      class="mt-2 mb-2"
                    ></div>
                    <div
                      class="font-weight-300 fs-12"
                      style="word-break: normal"
                    >
                      {{ item.description }}
                    </div>
                    <div class="font-weight-400 fs-12 mt-2">
                      PREÇO: {{ item.billing_unit_description }}
                    </div>
                  </div>
                  <img
                    slot="reference"
                    role="button"
                    width="26px"
                    src="/img/icons/info.png"
                  />
                </el-popover>
              </div>
            </div>
            <div class="font-weight-400 fs-14 mt-3">
              {{ item.short_description }}
            </div>
            <div
              class="font-weight-400 fs-14"
              style="color: #606062"
              v-if="item.billing_unit_id !== 0"
            >
              {{ format(item.calculation_base) || "-" }}
            </div>
            <div class="font-weight-400 fs-14" style="color: #606062" v-else>
              {{ $helper.toMoney(item.price) }}
            </div>
            <div class="font-weight-500 fs-15">
              R$ <span class="font-weight-500 fs-25">{{ item.price }}</span>
            </div>
            <div class="font-weight-400 fs-14" style="color: #606062">
              {{ $helper.parseDate(item.finished_at, "DD MMM YYYY HH:MM") }}
            </div>
            <div
              style="height: 0.5px; background-color: #e8e8e8"
              class="mt-2"
            ></div>
            <div class="font-weight-500 fs-18 mt-2">
              {{ item.code }}
            </div>
            <div class="font-weight-400 fs-14">{{ item.customer_name }}</div>
            <div class="font-weight-400 fs-14">
              {{ item.construction_name }}
            </div>
            <div class="mt-3 d-flex pr-3" style="gap: 16px">
              <div
                class="col-6 fs-12 font-weight-400 new-cardwithbox-shadow"
                style="height: 30px"
              >
                <img src="/img/icons/os.png" width="24" class="mr-1" />
                {{ item.number }} | {{ item.schedule_travel_volume }} m³
              </div>
              <div
                class="col-6 fs-12 font-weight-400 new-cardwithbox-shadow"
                style="height: 30px"
                v-show="item.xml_travel_number"
              >
                <img src="/img/icons/nfe.png" width="17" class="mr-1" />
                {{ item.xml_travel_number }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row card-wrapper" v-if="loadingSkeleton">
        <SkeletonPuzlGrid
          size="lg"
          v-for="index in 3"
          v-show="true"
          :key="index"
        ></SkeletonPuzlGrid>
      </div>
      <loading-pagination :show="loading && !loadingSkeleton" />
      <ModalJustification
        @reupdated="init({})"
        @updatedJustification="updatedJustification"
        ref="ModalJustification"
      />
    </div>
  </div>
</template>

<script>
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";
import ModalJustification from "./_ModalJustification";
import { SkeletonPuzl } from "@/components";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";

export default {
  name: "ResumeAdditional",
  props: {
    $_resumeAdditional: Array,
    loadingSkeleton: Boolean,
    // getStatus: { type: Function },
  },
  mixins: [cursorPaginate],
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    SkeletonPuzl,
    InputDatePicker,
    PuzlSelect,
    ModalJustification,
    BaseButtonHoverable,
    LoadingPagination,
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    getStatus(billed, status) {
      if (!billed && status) {
        return {
          text: "COBRAR",
          dropdown: "/img/icons/expand-arrow--v2_success.png",
          icon: "/img/icons/clock--v1.png",
        };
      }
      if (!billed && !status) {
        return {
          text: "CANCELADO",
          dropdown: "/img/icons/expand-arrow--v2_warning.png",
          icon: "/img/icons/cancel-red.png",
        };
      }
      if (billed && status) {
        return {
          text: "FATURADO",
          dropdown: "",
          icon: "/img/icons/cheque_success.png",
        };
      }
      if (billed && !status) {
        return {
          text: "FATURADO",
          dropdown: "",
          icon: "/img/icons/icons8/ios/check_danger.png",
        };
      }
    },

    format(value) {
      if (!isNaN(value)) {
        return this.$helper.secondsToTime(value * 60, true);
      }
      return value;
    },

    /**
     * @param {object} item
     * @param {number} index
     */
    async handleItemStatusChange(item, index) {
      this.index = index;
      if (!item.status) {
        item.status = !item.status;
        return this.onlySave(item);
      } else {
        let hasPermissionToCancelAdditional = true;
        await this.$store
          .dispatch("operationalPermission/getByUserId", this.$user.id)
          .then((response) => {
            if (!response.data.cancellation_of_additions) {
              this.$notify({
                type: "danger",
                message:
                  "Usuário não possui permissão para cancelar adicional.",
              });
              hasPermissionToCancelAdditional = false;
            }
          });
        if (hasPermissionToCancelAdditional) {
          this.$refs.ModalJustification.handleCreateModal(item);
        }
      }
    },
    onlySave(item) {
      let params = {
        justification: null,
        status: 1,
        uuid: item.uuid,
      };
      let loader = this.$loading.show();
      this.$Progress.start();
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$store
        .dispatch("scheduleAdditional/update", params)
        .then((response) => {
          this.$Progress.finish();
          loader.hide();
          this.$notify({ type: "success", message: response.message });
        })
        .catch((error) => {
          this.$notify({ type: "danger", message: error.data.message });
          this.$Progress.finish();
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
* {
  color: #2b2d32;
}

.card {
  box-shadow: 0px 5px 10px 0px #0000000d !important;
  padding: 16px;
  border-radius: 8px !important;
  border: 1px solid #ececec;
}

.new-cardwithbox-shadow {
  text-align: center;
  justify-content: center;
  box-shadow: 0 5px 10px 0 #f3f3f3;
  display: flex;
  border: 1px solid #e8e8e8;
  border-radius: 5px !important;
  align-items: center;
  padding: 4px 0px;
}

.new-minicard-green {
  background-color: #f2f7f3;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-red {
  background-color: #fcf3f3;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-yellow {
  background-color: #fef9f2;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-blue {
  background-color: #f2f4f9;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-gray {
  background-color: #646e7d2e;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-green h4 {
  color: #149e57;
}

.new-minicard-red h4 {
  color: #db4539;
}

.new-minicard-yellow h4 {
  color: #f2b532;
}

.new-minicard-blue h4 {
  color: #1b6eba;
}

.new-minicard-gray h4 {
  color: #66696c;
}

.new-minicard-red img:first-child,
.new-minicard-green img:first-child,
.new-minicard-yellow img:first-child,
.new-minicard-blue img:first-child,
.new-minicard-gray img:first-child {
  width: 18px;
}

.new-minicard-red h4,
.new-minicard-green h4,
.new-minicard-yellow h4,
.new-minicard-blue h4,
.new-minicard-gray h4 {
  font-size: 12px;
  margin: 0 6px 0 6px;
  font-weight: normal !important;
}
</style>

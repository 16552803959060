<template>
  <div>
    <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
      <form
        @submit.prevent="handleSubmit(onSubmitClick)"
        class="needs-validation"
        autocomplete="off"
      >
        <div class="container-fluid">
          <div class="row mt-2 mb-3">
            <div class="col-12" style="display: flex; align-items: center">
              <img
                src="/img/icons/icons8/ios/info-squared_warning.png"
                width="22"
              />
              <h4 class="new-default-black-font m-0 mx-3">Identificação</h4>
              <hr class="ml-3 m-0" style="width: 100%" />
            </div>
          </div>
          <div class="form-group">
            <div class="form-group row m-0 p-0 mb-2">
              <div class="col-md-5 my-1 px-0">
                <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                    CENTRAL
                    <span class="text-danger">&nbsp;*</span>
                </div>
              </div>
              <div class="col-md-7 mb-2 px-0">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      v-model="formData.company_plant_ids"
                      :items="companyPlants"
                      :disableBoxShadow="true"
                      :multiple="true"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-2">
              <div class="col-md-5 my-1 px-0">
                <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                    SLUMP FINAL
                    <span class="text-danger">&nbsp;*</span>
                </div>
              </div>
              <div class="col-md-7 mb-2 px-0">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      v-model="formData.slump_flow_id"
                      :items="slumpFlows"
                      label="nomenclature"
                      :disableBoxShadow="true"
                      :disabled="props.isEdit ? true : false"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-2">
              <div class="col-md-5 my-1 px-0">
                <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                    SLUMP REFERÊNCIA
                    <span class="text-danger">&nbsp;*</span>
                </div>
              </div>
              <div class="col-md-7 mb-2 px-0">
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      v-model="formData.ref_slump_flow_id"
                      :items="slumpFlows"
                      label="nomenclature"
                      :disableBoxShadow="true"
                      :disabled="props.isEdit ? true : false"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-2">
              <div class="col-md-5 my-1 px-0">
                <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                    OBSERVAÇÃO
                </div>
              </div>
              <div class="col-md-7 mb-2 px-0">
                <base-input input-group-classes="input-group-sm">
                  <textarea
                    v-model="formData.observation"
                    type="text"
                    rows="4"
                    class="form-control form-control-sm"
                  >
                  </textarea>
                </base-input>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="danger button" @click="onCancelClick">
              <AppIcon icon="cancel" color="danger" width="18" />
              Cancelar
            </button>
            <button
              class="success button"
              type="submit"
              v-bind:disabled="invalid"
              :isLoading="$_adjustment_final_slump_is_saving"
              :isDisable="invalid"
            >
              <AppIcon icon="save" color="success" width="18" />
              Salvar
            </button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script setup>
//#region Imports
import { defineEmits, defineProps, computed } from "vue";
import { AdjustmentFinalSlumpType } from "../../types";
import adjustmentFinalSlumpStore from "../../store/adjustmentFinalSlumpStore";
import { ValidationObserver } from "vee-validate";
import { useAdjustmentFinalSlumpHook } from "../../hooks/useAdjustmentFinalSlumpHook";
import PuzlSelect from "@/components/PuzlSelect";
import { AppButton } from "../../../../../components/AppGlobal";
import AppIcon from "../../../../../components/AppGlobal/AppIcon/AppIcon.vue";
//#endregion

//#region Data
const { companyPlants, slumpFlows } = useAdjustmentFinalSlumpHook();
//#endregion

//#region Props
/**
 * @typedef {Object} Props
 * @property {AdjustmentFinalSlumpType} formData
 * @property {boolean} isEdit
 */
/** @type {Props} */
const props = defineProps({
  formData: {
    type: Object,
    required: true,
  },
  isEdit: {
    type: Boolean,
    required: false,
    default: false
  }
});
//#endregion

//#region Emits
const emit = defineEmits([
  "onCancelClick", 
  "onSubmitClick"
]);
//#endregion

//#region Computeds
/** Store Getters */
const $_adjustment_final_slump_is_saving = computed(() => adjustmentFinalSlumpStore.getters.getIsSaving());
//#endregion

//#region Methods
function onCancelClick() {
  emit("onCancelClick");
}

function onSubmitClick() {
  emit("onSubmitClick");
}
//#endregion
</script>

<style scoped>
.text-title {
  font-size: 18px;
  font-weight: 500;
  color: #2B2D32;
}
.button-cancel {
  cursor: pointer;
  border: 0.5px solid #DB4539;
  border-radius: 8px;
  color: #DB4539;
  padding: 4px 20px;
}
.button-save {
  cursor: pointer;
  border: 0.5px solid #149E57;
  border-radius: 8px;
  color: #149E57;
  padding: 4px 30px;
}
.button-save:hover {
  background: #149E57;
  color: white;
}
.button-save:hover img {
  filter: brightness(0) invert(1);
}
.button-cancel:hover {
  background: #DB4539;
  color: white;
}
.button-cancel:hover img {
  filter: brightness(0) invert(1);
}
</style>
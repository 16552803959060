<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div>
              <div class="form-group row m-0 p-0">
                <div class="col-md-12 pt-2">
                  <div class="row" v-if="mixe.status===2">
                    <div class="col-md-12 p-0 m-0">
                      <base-button outline size="sm" type="light" class="col-sm-12 col-xsm-12 mb-2 active">
                        Rascunho
                      </base-button>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-md-4">
                      <base-button outline size="sm" type="success" class="col-sm-12 col-xsm-12 mb-2"
                                   :class="[mixe.status===1 ? 'active' : '',]" @click.prevent="mixe.status = 1">
                        Ativo
                      </base-button>
                    </div>
                    <div class="col-md-4">
                      <base-button outline size="sm" type="primary" class="col-sm-12 col-xsm-12 mb-2"
                                   :class="[mixe.status===5 ? 'active' : '',]" @click.prevent="mixe.status = 5">
                        Comercial
                      </base-button>
                    </div>
                    <div class="col-md-4">
                      <base-button outline size="sm" type="danger" class="col-sm-12 col-xsm-12 mb-2"
                                   :class="[mixe.status===0 ? 'active' : '',]" @click.prevent="mixe.status = 0">
                        Inativo
                      </base-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-2" v-for="(itemFeature, index) in mixDesigns.feature" :key="itemFeature.id">
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label align-middle">
                    {{ itemFeature.label }}
                    <span v-if="itemFeature.measured_unit && itemFeature.measured_unit.letter!='nenhum'">
                                                        {{ itemFeature.measured_unit.letter }}
                                                    </span>
                  </label>
                  <div class="col-md-7 pl-2 p-0" v-if="mixe.items[itemFeature.id]">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select v-model="mixe.items[itemFeature.id].item_id" filterable size="mini"
                                   placeholder="Selecione" @change="setStartValues(itemFeature.id)"
                                   :disabled="disabledMixe && clone.items[itemFeature.id].item_description != null">
                          <el-option label="Selecione" value="">
                          </el-option>
                          <el-option v-for="itemFeatureItem in itemFeature.item" :key="itemFeatureItem.id"
                                     :label="setLabel(itemFeature, itemFeatureItem)" :value="itemFeatureItem.id">
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1" v-if="itemFeature.type===1">
                  <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label align-middle">
                    &nbsp;
                  </label>
                  <div class="col-md-7 pl-2 p-0">
                    <div class="row">
                      <div class="col-md-6">
                        <span>Sd</span>
                        &nbsp;
                        <span v-if="itemFeature.measured_unit.letter!=='nenhum'">
                                                                {{ itemFeature.measured_unit.letter }}
                                                            </span>
                      </div>
                      <div class="col-md-6">
                        <base-input input-group-classes="input-group-sm">
                          <input v-model="mixe.items[itemFeature.id].value_sd"
                                 :disabled="!mixe.items[itemFeature.id].item_id" inputmode="numeric"
                                 class="form-control form-control-sm" v-mask="['#.##']"
                                 @change="calculateValueMedium(itemFeature.id)"/>
                        </base-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1" v-if="itemFeature.type===1">
                  <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label align-middle">
                    &nbsp;
                  </label>
                  <div class="col-md-7 pl-2 p-0">
                    <div class="row">
                      <div class="col-md-6">
                        <span>N.C.</span>
                      </div>
                      <div class="col-md-6">
                        <base-input input-group-classes="input-group-sm">
                          <el-select v-model="mixe.items[itemFeature.id].value_nc" placeholder="Selecione"
                                     :disabled="mixe.items[itemFeature.id].disabled || !mixe.items[itemFeature.id].item_id"
                                     @change="changeValueZ(itemFeature.id)"
                                     size="mini">
                            <el-option label="Selecione" value="">
                            </el-option>

                            <el-option v-for="(reliability_level, indexReliability) in reliability_levels"
                                       :key="indexReliability"
                                       :label="reliability_level.reliability+'%'" :value="reliability_level.value_z">
                            </el-option>
                          </el-select>
                        </base-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1" v-if="itemFeature.type===1">
                  <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label align-middle">&nbsp;
                  </label>
                  <div class="col-md-7 pl-2 p-0">
                    <div class="row">
                      <div class="col-md-6 pt-1">
                        <span>Z</span>
                      </div>
                      <div class="col-md-6">
                        <base-input input-group-classes="input-group-sm">
                          <input v-model="mixe.items[itemFeature.id].value_z" type="text"
                                 class="form-control form-control-sm" disabled/>
                        </base-input>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row m-0 p-0 mb-1" v-if="itemFeature.type===1">
                  <label class="col-md-5 pt-1 pb-0 col-form-label form-control-label align-middle">
                    &nbsp;
                  </label>
                  <div class="col-md-7 pl-2 p-0">
                    <div class="row">
                      <div class="col-md-6 pt-1">
                        <span>Vm</span>
                        &nbsp;
                        <span v-if="itemFeature.measured_unit.letter!=='nenhum'">
                                                                {{ itemFeature.measured_unit.letter }}
                                                            </span>
                      </div>
                      <div class="col-md-6">
                        <base-input input-group-classes="input-group-sm">
                          <input v-model="mixe.items[itemFeature.id].value_medium" type="text"
                                 class="form-control form-control-sm" disabled/>
                        </base-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button v-if="mixe.status===2" @click.prevent="handleSaveAndActive()" type="primary"
                           v-bind:disabled="invalid" :loading="loadingSave">
                Salvar e Ativar
              </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingSave">
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>

  </div>
</template>

<style></style>

<script>
import {
  mapGetters
} from 'vuex'
import Swal from 'sweetalert2'
import {
  formatErrorValidation
} from "@/plugins"

export default {
  components: {},
  name: "ModalEditMixe",
  data() {
    return {
      modal: false,
      title: "Responsabilidade",
      validated: false,
      mixDesigns: {},
      clone: {},
      mixe: {
        uuid: '',
        mix_design_uuid: '',
        mix_design_id: '',
        sizing: 1,
        status: 2,
        items: {}
      },
      loadingSave: false,
      disabledMixe: false,
    };
  },
  computed: {
    ...mapGetters({
      'reliability_levels': 'reliability_level/fetch',
    }),
  },
  methods: {
    setLabel(itemFeature, itemFeatureItem) {
      if (itemFeature.measured_unit && itemFeature.measured_unit.letter) {
        return itemFeatureItem.description + ' ' + itemFeature.measured_unit.letter
      } else {
        return itemFeatureItem.description
      }
    },
    closeModal() {
      this.modal = false
    },
    openModal() {
      this.modal = true
    },
    hasDisabled(status) {
      if (status !== 2) {
        return this.disabledMixe = true
      }
      this.disabledMixe = false
    },
    handleEditMixe(mixe, mixDesign) {
      let features = mixDesign.feature;
      this.mixDesigns = mixDesign;
      this.mixe = {
        mix_design_uuid: mixDesign.uuid,
        uuid: mixe.uuid,
        mix_design_id: mixDesign.id,
        sizing: 1,
        status: mixe.status,
        items: {}
      };
      this.clone = {
        mix_design_uuid: mixDesign.uuid,
        uuid: mixe.uuid,
        mix_design_id: mixDesign.id,
        sizing: 1,
        status: mixe.status,
        items: {}
      };
      //verifica se está ativo, comercial, e inativo se sim
      //não pode editar o item da responsabilidade
      this.hasDisabled(this.mixe.status)
      //Pego os items (responsabilidades) pelo id do Traço
      //Cada traço tem seu item escolhido da responsabilidade.
      let itemsMixe = mixe.items[mixe.id]
      for (let i = 0; i < features.length; i++) {
        let [itemSelected] = itemsMixe.filter((item) => item.mix_feature_id === features[i].id)
        if (itemSelected) {
          this.clone.items[features[i].id] = {
            uuid: itemSelected.uuid,
            feature_items: features[i].item,
            feature_id: features[i].id,
            item_id: itemSelected.item_id,
            item_description: itemSelected.item_description,
            value_sd: itemSelected.value_sd,
            nc_id: itemSelected.nc_id,
            value_nc: itemSelected.value_nc ? parseFloat(itemSelected.value_nc) : "",
            value_z: itemSelected.value_z,
            value_medium: itemSelected.value_medium,
          };
          this.mixe.items[features[i].id] = {
            uuid: itemSelected.uuid,
            feature_items: features[i].item,
            feature_id: features[i].id,
            item_id: itemSelected.item_id,
            item_description: itemSelected.item_description,
            value_sd: itemSelected.value_sd,
            nc_id: itemSelected.nc_id,
            value_nc: itemSelected.value_nc ? parseFloat(itemSelected.value_nc) : "",
            value_z: itemSelected.value_z,
            value_medium: itemSelected.value_medium,
          };
        }
      }
      this.openModal();
    },
    setStartValues(idFeature) {

      const id = idFeature
      if (this.mixe.items[id].item_id) {
        this.mixe.items[id].disabled = false
        const {
          feature_items
        } = this.mixe.items[id];
        const itemFeatureId = this.mixe.items[id].item_id
        const [itemFeatureSelected] = feature_items.filter(item => item.id === itemFeatureId)

        this.mixe.items[id].value_sd = parseFloat(itemFeatureSelected.value_sd) || 0
        this.mixe.items[id].value_nc = parseFloat(itemFeatureSelected.level_reliability) || 0
        this.mixe.items[id].value_medium = parseFloat(itemFeatureSelected.value_medium) || 0
        this.mixe.items[id].value_z = parseFloat(itemFeatureSelected.value_z) || 0
        this.calculateValueMedium(id)
      }

    },
    changeValueZ(idFeature) {
      const id = idFeature
      this.mixe.items[id].value_z = this.mixe.items[id].value_nc
      this.calculateValueMedium(id)
    },
    calculateValueMedium(idFeature) {
      this.$nextTick(() => {
        const id = idFeature
        const item_id = this.mixe.items[id].item_id
        const {
          feature_items
        } = this.mixe.items[id];
        const [itemFeatureSelected] = feature_items.filter(item => item.id === item_id)
        const description = itemFeatureSelected.description.toString()
        const value_sd = parseFloat(this.mixe.items[id].value_sd)
        const value_z = parseFloat(this.mixe.items[id].value_nc)
        const value_medium = Number(description) + Number(value_sd) * Number(value_z)

        this.mixe.items[id].item_description = description.toString() || 0
        this.mixe.items[id].value_medium = parseFloat(value_medium) || 0
      })
    },
    handleSaveAndActive() {
      this.mixe.save_and_activate = true
      this.save()
    },
    saveAndActivate() {
      this.$store.dispatch('MixDesignMixe/saveAndActivate', this.mixe)
        .then(response => {
          this.$parent.fetch()
          this.loadingSave = false
          this.modal = false
          this.$Progress.finish()
          this.$notify({
            type: response.error_type,
            message: response.message
          })
          this.$emit('submit')
        }).catch(error => {
        this.$notify({
          type: error.data.error_type,
          message: error.data.message
        })
        this.$Progress.finish()
        this.loadingSave = false
      })
    },
    save() {
      this.$Progress.start()
      this.loadingSave = true
      this.$store.dispatch('MixDesignMixe/update', this.mixe)
        .then(response => {
          this.loadingSave = false
          this.modal = false
          this.$Progress.finish()
          this.$notify({
            type: response.error_type,
            message: response.message
          })
          this.$emit('submit')
        })
        .catch(error => {
          if (error.data.confirm) {
            this.modal = false

            Swal.fire({
              title: error.data.message,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Sim, substituir!',
              cancelButtonText: 'Não, mantenha',
              customClass: {
                confirmButton: 'btn btn-success btn-fill',
                cancelButton: 'btn btn-danger btn-fill',
              },
              buttonsStyling: false,
            }).then(response => {
              if (response.isConfirmed) {
                this.saveAndActivate()
              } else {
                this.$Progress.finish()
                this.loadingSave = false
              }
              this.modal = true
            })
          } else {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
            this.$Progress.finish()
            this.loadingSave = false
          }
        })
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.$store.dispatch('reliability_level/fetchItems')
  }
};
</script>

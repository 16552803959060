const keys = Object.freeze({
  INTEGRATED: 0,
  PENDING: 1,
  ERROR: 2,
});

const getTitle = (value) => {
  switch (value) {
    case keys.INTEGRATED:
      return "Integrado";
    case keys.PENDING:
      return "Pendente";
    case keys.ERROR:
      return "Erro";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle,
};
import { BaseListFilterType, initBaseListFilterType } from "../../../../../../shared/types";

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType)} ParameterListFilterType
 */
export const ParameterListFilterType = {};

/**
 * Inicializar ParameterListFilterType
 */
export const initParameterListFilterType = () => ({
  ...initBaseListFilterType(),
});
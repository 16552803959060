import { HttpStatusEnum } from "../../../../shared/enums";
import httpClient from "../../../../shared/libraries/httpClient";
import store from "../../../../shared/libraries/store";
import { handleUnexpectedStatusCode } from "../../../../shared/services";
import { findSource } from "../../../../shared/store";
import { SyncHubEnum } from "../../shared/enums/SyncHubEnum";
import { SyncHubType } from "../types/SyncHubType";

/** URLS */
const urls = Object.freeze({
  BASE: "/sync/sync-hubs",
});

/** KEYS */
const keys = Object.freeze({
  ITEM: "SYNC_HUB_ITEM",
  IS_FINDING: "SYNC_HUB_IS_FINDING",
  IS_FINDING_ACTIVE_TITLE: "SYNC_HUB_IS_FINDING_ACTIVE_TITLE",
  IS_SAVING: "SYNC_HUB_IS_SAVING",
});

/** GETTERS */
const getters = {
  /** @returns {?SyncHubType} */
  getItem: () => store.getState(keys.ITEM),

  /** @returns {boolean} */
  getIsFinding: () => store.getState(keys.IS_FINDING) ?? false,

  /** @returns {boolean} */
  getIsFindingActiveTitle: () => store.getState(keys.IS_FINDING_ACTIVE_TITLE) ?? false,

  /** @returns {boolean} */
  getIsSaving: () => store.getState(keys.IS_SAVING) ?? false,
}

/** MUTATIONS */
const mutations = {
  /**
   * @param {SyncHubType} item
   * @returns {SyncHubType}
   */
  setItem: (item) => store.commit(keys.ITEM, item),
  
  /**
   * @param {boolean} isFinding
   * @returns {boolean}
   */
  setIsFinding: (isFinding) => store.commit(keys.IS_FINDING, isFinding),
  
  /**
   * @param {boolean} isFinding
   * @returns {boolean}
   */
  setIsFindingActiveTitle: (isFinding) => store.commit(keys.IS_FINDING_ACTIVE_TITLE, isFinding),
  
  /**
   * @param {boolean} isSaving
   * @returns {boolean}
   */
  setIsSaving: (isSaving) => store.commit(keys.IS_SAVING, isSaving),
  
  /** Resetar chaves de estado */
  resetStates: () => store.resetStates(Object.values(keys)),
}

/** ACTIONS */
const actions = {
  /**
   * Criar ou Atualizar
   *
   * @param {SyncHubType} data
   * @returns {Promise<SyncHubType>}
   */
  createOrUpdate: async (data) => {
    try {
      mutations.setIsSaving(true);
  
      const res = await httpClient.post(urls.BASE, data);
      switch (res.status) {
        case HttpStatusEnum.HTTP_OK: return res.data.data;
        default: handleUnexpectedStatusCode(res);
      }
    } finally {
      mutations.setIsSaving(false);
    }
  },

  /**
   * Localizar por SyncHubEnum
   *
   * @param {typeof SyncHubEnum.keys[keyof typeof SyncHubEnum.keys]} syncHub
   * @returns {Promise<?SyncHubType>}
   *
   */
  find: async (syncHub) => await findSource(syncHub, mutations, urls.BASE),
  
  /**
   * Localizar SyncHub que está ativo
   *
   * @returns {Promise<typeof SyncHubEnum.keys[keyof typeof SyncHubEnum.keys]|null>}
   *
   */
  findActiveTitle: async () => {
    try {
      mutations.setIsFindingActiveTitle(true);
  
      const url = `${urls.BASE}/is-active/title`;
      const res = await httpClient.get(url);
      switch (res.status) {
        case HttpStatusEnum.HTTP_OK: return res.data.data;  
        case HttpStatusEnum.HTTP_NOT_ACCEPTABLE: return null;
        default: handleUnexpectedStatusCode(res);
      }
      
    } finally {
      mutations.setIsFindingActiveTitle(false);
    }
  },
}

export default {
  actions,
  getters,
  mutations,
};
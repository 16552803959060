const keys = Object.freeze({
  RENTED: 5,
  DAMAGED: 6,
  OUT_OF_USE: 7,
  MAINTENANCE: 3,
  OPERATING: 1,
  SOLD: 4,
});

const getTitle = (value) => {
  switch (value) {
    case keys.RENTED:
      return "Alugado";
    case keys.DAMAGED:
      return "Danificado";
    case keys.OUT_OF_USE:
      return "Fora de uso";
    case keys.MAINTENANCE:
      return "Manutenção";
    case keys.OPERATING:
      return "Operando";
    case keys.SOLD:
      return "Vendido";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle,
};
<template>
  <div
    class="app-listing-counter"
  >
    <div
      class="listing-counter-nav"
    >
      <div
        class="nav-dropdown listing-type"
      >
        <AppSelect
          placeholder="Todos"
          :value="selectedItems"
          :items.sync="localItems"
          @onSelectItemClick="onSelectItemClick"
          variant="text-only"
        />
      </div>
      <div
        class="listing-navigation-items"
      >
        <div
          v-for="localItem in localItems"
          :key="localItem.id"
          class="listing-navigation-item"
        >
          <h4
            @click="onTabSelectItemClick(localItem)"
            class="m-0"
            :class="localItem.selected == true
              ? 'text-primary'
              : 'new-default-gray-font'"
          >
            {{ localItem.name }} <i>{{ localItem.suffix }}</i>
          </h4>
          <div
            class="vertical-listing-divider"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="listing-counter-nav"
      style="flex-direction: row-reverse"
    >
      <div
        class="nav-dropdown"
      >
        <div
          style="display: flex;align-items: center;"
        >
          <slot />
          <div v-if="props.isShowViewTypes && isScreenWidthBiggerThan992">
            <i
              @click.prevent="setViewType('cards')"
              :class="{'opacity-25': listType !== 'cards'}"
              class="pointer fa-regular fs-22 fa-hard-drive"
            />
            <i
              @click.prevent="setViewType('table')"
              :class="{'opacity-25': listType !== 'table'}"
              class="pointer fa-solid fs-22 fa-table-list ml-1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, watch, ref, onBeforeMount, computed, onMounted} from "vue";
import { AppSelect } from '../../AppGlobal';
import { TabSelectItemType } from "./types/AppTabSelectItemType";
import {setPreferredViewMode, getPreferredViewMode} from '@/plugins/session';

/**
 * Propriedades para o componente
 * @typedef {Object} Props
 * @property {Array<TabSelectItemType>} items
 * @property {Boolean} isMultiSelect
 * @property {Boolean} isAlwaysOneSelected
 * @property {Boolean} isShowViewTypes - Mostrar modos de visualização (Card e Tabela).
 */
/** @type {Props} */
const props = defineProps({
  items: {
    type: Array,
    default: () => [],
    required: false,
  },
  isMultiSelect: {
    type: Boolean,
    default: () => false,
    required: false,
  },
  isAlwaysOneSelected: {
    type: Boolean,
    default: () => false,
    required: false,
  },
  isShowViewTypes: {
    type: Boolean,
    default: () => true,
    required: false,
  },
  isHideTableOnMobileActive: {
    type: Boolean,
    default: () => false,
    required: false,
  }
});

/** Eventos emitidos */
const emit = defineEmits([
  "onTabSelectItemClick",
  "onViewTypeChange",
  "onClearClick",
]);

/** Itens locais */
const localItems = ref(props.items);

/** Screen Size Validator */
const isScreenWidthBiggerThan992 = ref(true);

/** Tipo de listagem */
const listType = ref(String);

/** Sincronização de localItems e items */
watch(() => props.items, (newValue) => localItems.value = newValue);
watch(localItems, (newValue) => emit('update:items', newValue));

/** Valor do AppSelect usado no mobile */
const selectedItems = computed(() => {
  const isAllSelected = localItems.value.filter(item => item.id !== null).every(item => item.selected);

  if(isAllSelected){
    return null;
  }

  const localItem = localItems.value.find(obj => obj.selected === true);
  return localItem && localItem.id;
});

/**
 * @param {TabSelectItemType} localItem
 */
function onTabSelectItemClick(localItem){
  if (!props.isMultiSelect) {
    localItems.value.forEach(item => item.selected = false);
  }

  if (props.isAlwaysOneSelected) {
    const selectedItems = localItems.value.filter(item => item.selected);
    if (selectedItems.length === 1 && selectedItems[0] === localItem) {
      return;
    }
  }

  localItem.selected = !localItem.selected;
  emit('onTabSelectItemClick', localItem);
}

function onSelectItemClick(localItemId){
  onTabSelectItemClick(localItems.value.find(obj => obj.id === localItemId));
}

function setViewType(type) {
  listType.value = type;
  setPreferredViewMode(type);
  emit('onViewTypeChange', type);
}

function onClearClick() {
  emit('onClearClick');
}

function checkScreenWidth() {
  if(window.innerWidth <= 992){
    isScreenWidthBiggerThan992.value = false;
    setViewType('cards');
  } else {
    isScreenWidthBiggerThan992.value = true;
  }
}

onBeforeMount(() => {
  listType.value = getPreferredViewMode() ?? 'cards';
  emit('onViewTypeChange', listType.value);
});

onMounted(() => {
  if(props.isHideTableOnMobileActive){
    checkScreenWidth();
    window.addEventListener('resize', checkScreenWidth);
  }
});
</script>

<style scoped src="./appTabSelectStyle.css"></style>

import { dreUrls } from '.';
import httpClient from '../../../../shared/libraries/httpClient';
import { handleUnexpectedStatusCode } from '../../../../shared/services';
import { HttpStatusEnum } from '../../../../shared/enums';

/**
 * Listar
 *
 * @param {?MdfeListFilterType} filter
 * @returns {Promise<StockLocationPagerType}
 */
const execute = async (filter = null) => {
  const res = await httpClient.get(dreUrls.BASE, { params: filter });

  switch (res.status) {
    case HttpStatusEnum.HTTP_OK: return res.data.data;
    default: handleUnexpectedStatusCode(res);
  }
};

export default { execute };

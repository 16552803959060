<template>
  <div>
    <div class="row card-wrapper" v-show="$_mdfe_is_listing">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"/>
    </div>
    <div class="row">
      <ShadedTable :headers-aligns="headersAligns" :length="$_mdfe_listed.items.length" :headers="headers"
                   :loading="$_mdfe_is_listing">
        <template v-for="(item, indexItem) in $_mdfe_listed.items" :slot="indexItem">
          <ShadedCol style="width: 15%;height: fit-content;">
            <div @click.prevent="reissueMDFe(item)" class="new-minicard-table text-center pointer" :class="statusText[Number(item.status)].class">
              <div>
                <AppIcon :icon="statusText[Number(item.status)].icon"
                         :color="statusText[Number(item.status)].color"
                         width="20" class="mr-2"/>
                <h4 class="font-weight-normal m-0 p-0 mr-2">
                  {{ statusText[Number(item.status)].text }} </h4></div>
            </div>
          </ShadedCol>
          <ShadedCol style="width: 8%;height: fit-content;">
              <span class="text-center" style="font-size: 13px;font-weight: 400;color: #2B2D32;">
                                  {{ (item.finished_at || item.created_at) | parseDate('DD/MM/YYYY') }}
                </span>
          </ShadedCol>
          <ShadedCol style="width: 8%;height: fit-content;">
              <span class="text-center" style="font-size: 13px;font-weight: 400;color: #2B2D32;"> {{ item.plate }}
                </span>
          </ShadedCol>
          <ShadedCol style="width: 8%;height: fit-content;">
            <div class="d-flex">
                <span class="text-left" style="font-size: 13px;font-weight: 400;color: #2B2D32;">
                  {{ item.mdfe_number }} - {{ item.mdfe_series }}
                </span>
              <div class="ml-auto">
                <a href="#" @click.prevent="copy(item.mdfe_key)">
                  <img style="width: 25px" src="/img/icons/icons8/ios/copy-2_primary.png"/>
                </a>
              </div>
            </div>
          </ShadedCol>
          <ShadedCol style="width: 8%;height: fit-content;">
            <div class="d-flex">
                <span class="text-left" style="font-size: 13px;font-weight: 400;color: #2B2D32;">
                  {{ item.number }} - {{ item.series }}
                </span>
              <div class="ml-auto">
                <a href="#" @click.prevent="copyKey(item.key)">
                  <img style="width: 25px" src="/img/icons/icons8/ios/copy-2_primary.png"/>
                </a>
              </div>
            </div>
          </ShadedCol>
          <ShadedCol style="width: 8%;height: fit-content;">
              <span class="text-left" style="font-size: 13px;font-weight: 400;color: #2B2D32;">
                {{ item.os_number }}
              </span>
          </ShadedCol>
          <ShadedCol style="width: 8%;height: fit-content;">
              <span class="text-left" style="font-size: 13px;font-weight: 400;color: #2B2D32;">
                {{ item.contract }}
              </span>
          </ShadedCol>
          <ShadedCol style="width: 8%;height: fit-content;">
              <span class="text-left" style="font-size: 13px;font-weight: 400;color: #2B2D32;">
                {{ item.entity_name }}
              </span>
          </ShadedCol>
          <ShadedCol style="width: 15%;height: fit-content;">
              <span class="text-center" style="font-size: 13px;font-weight: 400;color: #2B2D32;">
                {{ item.created_at | parseDate('DD/MM/YYYY') }}
              </span>
          </ShadedCol>
          <ShadedCol style="width: 5%;">
            <div class="text-center">
              <base-dropdown menuOnRight>
                <div slot="title-container" class="dropdown-toggle rounded m-0">
                  <img width="25px" src="/img/icons/icons8/ios/settings--v1_primary.png">
                </div>
                <span v-if="Number(item.status) === 1" class="dropdown-item"
                      @click.prevent="cancelMDFe(item)">
                      <img src="/img/icons/cancel-red.png" width="19px" height="19px">
                      CANCELAR
                    </span>
                <div v-if="Number(item.status) === 1" class="dropdown-divider p-0 m-0"></div>
                <span
                  @click.prevent="closeMDFe(item)"
                  v-if="Number(item.status) === 1" class="dropdown-item">
                      <img src="/img/icons/ok--v1.png" width="19px" height="19px">
                      ENCERRAR
                    </span>
                <span @click.prevent="reissueMDFe(item)" v-if="Number(item.status) === 2" class="dropdown-item">
                      <img src="/img/icons/icons8/ios/ok--v1_danger.png" width="19px" height="19px">
                      REEMITIR
                    </span>
                <div v-if="Number(item.status) !== 2" class="dropdown-divider p-0 m-0"></div>
                <span v-if="Number(item.status) !== 2" class="dropdown-item"
                      @click="download(item.pdf_file_path, item.mdfe_key, 'pdf')">
                      <img src="/img/icons/pdf-v2.png" width="19px" height="19px">
                      PDF
                    </span>
                <div v-if="Number(item.status) !== 2" class="dropdown-divider p-0 m-0"></div>
                <span v-if="Number(item.status) !== 2" class="dropdown-item"
                      @click="download(item.xml_file_path, item.mdfe_key, 'xml')">
                      <img src="/img/icons/icons8/ios/source-code.png" width="19px" height="19px">
                      XML
                    </span>
              </base-dropdown>
            </div>
          </ShadedCol>
        </template>
      </ShadedTable>
      <ModalStoreMDFe ref="modalStoreMDFe"/>
    </div>
  </div>
</template>

<script setup>
//#region Imports
import AppIcon from "../../../../../components/AppGlobal/AppIcon/AppIcon.vue";
import {computed, defineEmits, getCurrentInstance, ref} from "vue";
import {base_url_ms} from "@/plugins";
import {copy} from '@/helpers'
import mdfeStore from "../../store/mdfeStore";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid.vue";
import {dialogs, progress} from "../../../../../helpers";
import ShadedCol from "@/components/ShadedCol.vue";
import ShadedTable from "@/components/ShadedTable.vue";
import ModalStoreMDFe from '/src/views/Modules/Operational/Schedule/Charging/Shared/_ModalStoreMDFe.vue'
import { Ref } from 'vue';
//#endregion
const store = getCurrentInstance().proxy.$store;
//#region Computeds
/** Store Getters */
const $_mdfe_listed = computed(() => mdfeStore.getters.getListed());
const $_mdfe_is_listing = computed(() => mdfeStore.getters.getIsListing());
//#endregion

const emit = defineEmits(['fetch'])

/** @type {Ref<ModalStoreMDFe>} */
const modalStoreMDFe = ref(null);

const headers = ref(
  [
    "Status",
    "Data",
    "Placa",
    "MDF-E",
    "NF-E",
    "O.S",
    "Contrato",
    "Cliente",
    "Emissão",
    "Ação"
  ]
)

const headersAligns = ref([
  "center",
  "center",
  "center",
  "center",
  "center",
  "center",
  "center",
  "center",
  "center",
])

const statusText = ref({
  0: {
    text: "Pendente",
    class: "new-minicard-warning",
    icon: "progress-indicator",
    color: "warning",
  },
  1: {
    text: "Em andamento",
    class: "new-minicard-secondary",
    icon: "circled-right",
    color: "gray",
  },
  2: {
    text: "Rejeitado",
    class: "new-minicard-danger",
    icon: "vector",
    color: "danger",

  },
  3: {
    text: "Encerrado",
    class: "new-minicard-primary",
    icon: "double-tick",
    color: "primary",

  },
  4: {
    text: "Cancelado",
    class: "new-minicard-danger",
    icon: "vector",
    color: "danger",

  }
})

//#region Methods
/**
 * Editar item
 * @param {number} id
 */
function onEditItemClick(id) {
  emit("onEditItemClick", id);
}

/**
 * Remover item
 * @param {number} id
 */
function onRemoveItemClick(id) {
  emit("onRemoveItemClick", id);
}

function copyKey(key) {
  copy(key)
  dialogs.notify()
}

async function cancelMDFe(item) {
  const isConfirmed = await dialogs.confirmAction('Deseja realmente cancelar o MDF-e?');
  if (isConfirmed) {
    let loader = progress.showLoader()
    await mdfeStore.actions.cancel(item.id)
      .catch((error) => {
        dialogs.notify(dialogs.TYPE_ENUM.DANGER, error.message)
      });
    emit('fetch')
    loader.hide()
  }
}

async function closeMDFe(item) {
  const isConfirmed = await dialogs.confirmAction('Deseja realmente encerrar o MDF-e?');
  if (isConfirmed) {
    let loader = progress.showLoader()
    await mdfeStore.actions.close(item.id)
      .catch((error) => {
        dialogs.notify(dialogs.TYPE_ENUM.DANGER, error.message)
      });
    emit('fetch')
    loader.hide()
  }
}

async function reissueMDFe(item) {
  if (Number(item.status) !== 2) {
    return
  }
  modalStoreMDFe.value.openModal({
    schedule_travel_id: item.schedule_travel_id,
    name: item.driver,
    document: item.driver_document,
    reason: item.log
  })
}

function download(file_path, key, type) {
  let loader = progress.showLoader()
  const url = base_url_ms() + 'download-s3?url=' + file_path
  store.dispatch('exports/download', url).then(async response => {
    let blob = new Blob([response], {type: "application/pdf"});
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", key + '.' + type);
    await link.click()
  });
  dialogs.notify()
  loader.hide();
}

//#endregion
</script>

<style scoped>
.badge-table {
  padding: 6px 19px;
  border-radius: 16px;
  cursor: pointer;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.badge-table b {
  font-weight: 400;
  font-size: 12px;
}

.badge-table-danger {
  background-color: #F9E3E1;
  color: #DB4539;
}

.badge-table-success {
  background: #F2F7F3;
  color: #149E57;
}

.badge-table-primary {
  background-color: #3983C1;
  color: #fff;
}

.badge-table-secondary {
  background-color: grey;
  color: #fff;
}

.badge-table-warning {
  background-color: #F2B532;
  color: #fff;
}
</style>

<template>
  <div>
    <div class="row card-wrapper" v-if="!loadingSkeleton">
      <div
        class="col-lg-12"
        v-for="mix_design in mix_designs"
        :key="mix_design.id"
      >
        <div class="card mb-3">
          <div
            class="d-flex align-items-stretch flex-wrap mb-2"
            v-if="mix_design"
          >
            <div
              class="col-12 col-md-3 px-0 d-flex flex-column"
              style="gap: 24px"
            >
              <div class="d-flex">
                <div
                  slot=""
                  class="badge"
                  :style="getStatusBg(mix_design.status)"
                >
                  <img
                    class="pointer"
                    :src="getStatusIcon(mix_design.status)"
                    width="16px"
                    height="16px"
                  />
                  <h4
                    :style="getStatusColor(mix_design.status)"
                    class="font-weight-normal m-0 p-0 ml-1"
                  >
                    {{ getText(mix_design.status) }}
                  </h4>
                </div>
                <el-popover
                  :ref="'popoverTag' + mix_design.id"
                  trigger="click"
                  placement="bottom"
                  class="p-0 mr-2"
                  title="Ordenação"
                >
                  <div>
                    <card>
                      <h5 class="font-weight-500">Ordem</h5>
                      <hr class="mb-2 mt-1" />
                      <div class="row">
                        <div class="col">
                          <label
                            style="font-size: 12px"
                            class="form-control-label font-weight-400"
                            >Número</label
                          >
                        </div>
                        <div class="col">
                          <base-input
                            v-mask="'###'"
                            v-model="mix_design.tag"
                            size="sm"
                          />
                        </div>
                      </div>
                      <h5 class="font-weight-500 mt-3">Observação</h5>
                      <textarea
                        v-model="mix_design.tag_observation"
                        class="form-control"
                      ></textarea>
                    </card>
                    <div class="row mt-n3">
                      <div class="col text-right">
                        <base-button
                          style="width: 150px; background: white !important"
                          class="btn-sm border-success bg-white text-success btn base-button btn-secondary"
                          id="setting-save-button"
                          type="success"
                          size="sm"
                          @click.prevent="updateTag(mix_design)"
                          native-type="submit"
                        >
                          <div
                            class="font-weight-400"
                            style="display: inline-flex; align-items: center"
                          >
                            <img
                              src="/img/icons/save-green.png"
                              width="16px"
                              height="16px"
                              class="mr-2"
                            />
                            <i class="m-0 ml-1" style="font-style: normal">
                              SALVAR
                            </i>
                          </div>
                        </base-button>
                      </div>
                    </div>
                  </div>
                  <div
                    slot="reference"
                    size="sm"
                    type="secundary"
                    style="box-shadow: none"
                  >
                    <AppIcon
                      icon="numeric-selection"
                      height="26px"
                      width="26px"
                    />
                  </div>
                </el-popover>
                <el-popover
                  v-if="!mix_design.last_updated.is_today"
                  trigger="click"
                  placement="bottom"
                  class="p-0 mr-2"
                >
                  <div>
                    <div class="col-12">
                      <span>
                        <span class="h4"> ÚLTIMA ALTERAÇÃO </span>
                        <br />
                        <br />
                        <span>
                          {{ mix_design.last_updated.user }}
                        </span>
                        <br />
                        <span>
                          {{ mix_design.last_updated.updated_at | parseDate }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div
                    slot="reference"
                    size="sm"
                    type="secundary"
                    style="box-shadow: none"
                  >
                    <img
                      class="pointer"
                      src="/img/icons/icons8/ios/user-male-circle--v1.png"
                      width="26px"
                      height="26px"
                    />
                  </div>
                </el-popover>
                <el-popover trigger="click" placement="bottom" class="p-0 mr-2">
                  <span class="h4"> GRUPOS </span>
                  <br />
                  <br />
                  <div v-if="mix_design.groups">
                    <div
                      class="row align-items-center pb-0 mb-3"
                      v-for="(
                        category_group, indexCategoryGroup
                      ) in mix_design.groups"
                      :key="indexCategoryGroup"
                    >
                      <div class="col-md-12">
                        <div class="checklist-item checklist-item-primary">
                          <div class="checklist-info">
                            <h4 class="checklist-title mb-0">
                              {{ category_group.category_name }}
                            </h4>
                            <div
                              v-for="(
                                group, indexGroup
                              ) in category_group.groups"
                              :key="indexGroup"
                            >
                              <h5 class="checklist-title small">
                                {{ group }}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    slot="reference"
                    size="sm"
                    type="secundary"
                    style="box-shadow: none"
                  >
                    <img
                      class="pointer"
                      src="/img/icons/icons8/ios/parallel-tasks-blue.png"
                      width="26px"
                      height="26px"
                    />
                  </div>
                </el-popover>
                <el-popover trigger="click" placement="bottom" class="p-0">
                  <div class="col-md-12 mb-3">
                    <h3>TRAÇOS</h3>
                  </div>
                  <div
                    v-for="(mixes, index) in mix_design.mix_design_mixes"
                    :key="index"
                  >
                    <div class="row align-items-center pb-0">
                      <div class="col-md-12">
                        <div class="" :class="checkStatus(mixes)">
                          <h4
                            class="checklist-title"
                            v-for="(mixe, indexItem, mixeIndex) in mixes"
                            v-if="
                              mixe && indexItem != 'binder' && mixe.status === 1
                            "
                            :key="mixeIndex"
                          >
                            <div class="row">
                              <div class="col-9">
                                <span class="ml-4">
                                  {{ mixe.short_description }}
                                  {{
                                    format_mixe(mixe.period, mixe.period_type)
                                  }}
                                  {{ mixe.item_description }}
                                  {{ mixe.letter }}
                                </span>
                              </div>
                              <a
                                v-if="mixeIndex === 0"
                                @click.prevent="copy(`TR-${mixe.code_id}`)"
                                href="#"
                                class="col-3"
                              >
                                <badge
                                  v-if="mixe.code_id"
                                  style="font-size: 12px !important"
                                  title="Clique aqui para copiar"
                                  class="pointer font-weight-500 float-right mr-4"
                                  type="default"
                                >
                                  TR-{{ mixe.code_id }}
                                </badge>
                              </a>
                            </div>
                            <hr
                              v-if="
                                index !==
                                  mix_design.mix_design_mixes.length - 1 &&
                                mixe.status === 1
                              "
                              class="col-12 mb-2 mt-2"
                            />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    slot="reference"
                    size="sm"
                    type="secundary"
                    style="box-shadow: none"
                  >
                    <img
                      class="pointer"
                      src="/img/icons/icons8/ios/test-tube-blue.png"
                      width="24px"
                      height="24px"
                    />
                  </div>
                </el-popover>
                <el-popover
                  v-if="
                    mix_design.adjustment_blocks &&
                    mix_design.adjustment_blocks.length
                  "
                  trigger="click"
                  placement="bottom"
                  class="p-0 col-1"
                >
                  <div>
                    <h5 v-for="item in mix_design.adjustment_blocks_formatted">
                      {{ item }}
                    </h5>
                  </div>
                  <div
                    slot="reference"
                    size="sm"
                    type="secundary"
                    style="box-shadow: none"
                  >
                    <img
                      class="pointer ml-2"
                      src="/img/icons/icons8/ios/cancel-2_danger.png"
                      width="26px"
                      height="26px"
                    />
                  </div>
                </el-popover>
                <el-popover
                  v-if="mix_design.last_updated.is_today"
                  trigger="click"
                  placement="bottom"
                  class="p-0 col-1 ml-3"
                >
                  <div>
                    <div class="row" v-if="mix_design.last_updated.user">
                      <div
                        class="col-lg-12"
                        v-if="mix_design.last_updated.is_today"
                      >
                        <span class="h4">{{
                          mix_design.last_updated.user
                        }}</span>
                        -
                        <badge type="success">Hoje</badge>
                        <br />
                        <span style="color: #149e57">{{
                          mix_design.last_updated.updated_at | parseDate
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    slot="reference"
                    size="sm"
                    type="secundary"
                    style="box-shadow: none"
                  >
                    <img
                      class="pointer"
                      src="/img/icons/icons8/ios/checked--v1.png"
                      width="26px"
                      height="26px"
                    />
                  </div>
                </el-popover>
              </div>
              <h5 class="font-weight-400">
                {{ mix_design.plant.name }}
              </h5>
            </div>
            <div class="col-12 col-md-4 pl-0">
              <div class="d-flex">
                <div class="spacer"></div>
                <div style="flex: 1">
                  <div class="mt-2">
                    <span class="h3" v-if="mix_design.type">
                      {{ mix_design.type.technical_description || "" }}
                    </span>
                    <span v-if="mix_design.subtype">
                      | {{ mix_design.subtype.technical_description || "" }}
                    </span>
                    <span v-if="mix_design.classification">
                      |
                      {{
                        mix_design.classification.technical_description || ""
                      }}
                    </span>
                  </div>
                  <div class="d-block d-md-none hr"></div>

                  <div class="mt-3">
                    <h5
                      class="h3 mb-1"
                      v-for="(feature, index) in mix_design.feature"
                      :key="index"
                      style="display: inline-block"
                    >
                      <span
                        class="mt-n1 ml-1 mr-1"
                        v-if="feature.short_description && index !== 0"
                        style="font-size: 20px !important"
                      >
                        +</span
                      >
                      {{ feature.short_description }}
                      {{ format_mixe(feature.period, feature.period_type) }}
                      {{
                        feature.measured_unit && feature.measured_unit.letter
                      }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-4 col-12 d-flex justify-content-center align-items-center p-0 mt-2 mt-md-0"
              style="gap: 16px"
            >
              <div v-if="!mix_design.slump_final.length" style="flex: 1">
                <b
                  class="text-center responsive-font card-outline"
                  style="
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <img
                    class="mr-2"
                    src="/img/icons/icons8/ios/vlc.png"
                    height="23px"
                  />
                  {{ mix_design.slumpFlow.nomenclature }}
                </b>
              </div>
              <div v-else style="flex: 1">
                <div
                  class="d-flex d-flex justify-content-between align-items-center card-outline p-0"
                >
                  <b
                    class="text-center responsive-font mobile-fix-outline"
                    style="white-space: nowrap; padding: 8px 24px; flex-grow: 1"
                  >
                    <img
                      class="mr-2"
                      src="/img/icons/icons8/ios/vlc.png"
                      height="24px"
                    />
                    {{ mix_design.slumpFlow.nomenclature }}
                  </b>
                  <div
                    class="text-center align-items-center justify-content-end"
                    style="display: flex"
                  >
                    <el-popover
                      v-if="mix_design.slump_final.length"
                      trigger="click"
                      placement="bottom"
                      class="text-center"
                      id="formulation-button-reset"
                    >
                      <h5
                        class="text-center"
                        v-for="item in mix_design.slump_final"
                      >
                        {{ item.nomenclature }}
                      </h5>
                      <div
                        slot="reference"
                        size="sm"
                        type="secundary"
                        style="box-shadow: none; background-color: #f2f4f9"
                        class="button-reset mobile-puzl-icon-fix"
                      >
                        <img
                          class="align-items-center"
                          src="/img/icons/banks/0.png"
                          height="23px"
                        />
                      </div>
                    </el-popover>
                  </div>
                </div>
              </div>
              <div style="flex: 1">
                <b
                  class="col-12 responsive-font card-outline"
                  style="
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <img
                    src="/img/icons/icons8/ios/wheelbarrow.png"
                    height="25px"
                  />
                  {{ mix_design.maximum_size.description || "" }}
                </b>
              </div>
            </div>
            <div
              class="col-1 d-flex flex-column align-items-end mobile-position"
              style="gap: 16px"
            >
              <base-dropdown menuOnRight>
                <base-button
                  style="border: none !important; box-shadow: none !important"
                  slot="title-container"
                  type="invisible"
                  class="dropdown-toggle m-0"
                >
                  <AppIcon icon="settings" width="30" color="primary" />
                </base-button>
                <router-link
                  :to="{
                    name: 'Formulações Edição',
                    path: '/technology/mix/mixdesigns/edit',
                    params: { mix_design_uuid: mix_design.uuid },
                  }"
                >
                  <a class="dropdown-item">
                    <img
                      src="/img/icons/icons8/ios/create-new-yellow.png"
                      width="21px"
                      height="21px"
                    />
                    Editar
                  </a>
                </router-link>
                <div class="dropdown-divider p-0 m-0"></div>
                <router-link
                  class="float-right mr-1"
                  :to="{
                    path: '/technology/mix/resume/' + mix_design.uuid,
                  }"
                >
                  <a class="dropdown-item">
                    <img
                      src="/img/icons/icons8/ios/test-tube-blue.png"
                      width="21px"
                      height="21px"
                    />
                    Traços
                  </a>
                </router-link>
                <div class="dropdown-divider p-0 m-0"></div>

                <a
                  class="dropdown-item"
                  @click.prevent="handleReplicate(mix_design)"
                >
                  <img
                    src="/img/icons/icons8/ios/copy--v1-green.png"
                    width="21px"
                    height="21px"
                  />
                  Replicar
                </a>
                <div
                  v-if="mix_design.status == 2"
                  class="dropdown-divider p-0 m-0"
                ></div>

                <a
                  v-if="mix_design.status == 2"
                  class="dropdown-item mb-n1"
                  @click="handleDeleteMixDesign(mix_design.uuid)"
                >
                  <img
                    src="/img/icons/icons8/ios/delete-red.png"
                    width="21px"
                    height="21px"
                  />
                  Excluir
                </a>
              </base-dropdown>
              <router-link
                :to="{
                  path: '/technology/mix/resume/' + mix_design.uuid,
                }"
                style="margin-right: 12px"
                class="planilha"
              >
                <img
                  src="/img/icons/icons8/ios/health-graph-green.png"
                  width="25px"
                  height="25px"
                />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dialogs } from "../../../../../helpers";
import AppIcon from "../../../../../components/AppGlobal/AppIcon/AppIcon.vue";

export default {
  name: "TableMix",
  props: {
    mix_designs: null,
    loadingSkeleton: Boolean,
    format_mixe: Function,
    handleReplicate: Function,
    updateTag: Function,
  },
  components: {
    AppIcon,
  },
  components: {
    AppIcon,
  },
  data() {
    return {};
  },
  methods: {
    checkStatus(mixe) {
      let status = null;
      if (mixe[0]) {
        status = mixe[0].status;
      }
      switch (status) {
        case 2:
          return "checklist-item-gray";
        case 1:
          return "checklist-item-success";
        case 0:
          return "checklist-item-danger";
        case 5:
          return "checklist-item-purple";
        default:
          return "checklist-item-info";
          break;
      }
    },
    updateTag(mix_design) {
      let loader = this.$loading.show();
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$store
        .dispatch("MixDesigns/updateTag", {
          tag: mix_design.tag,
          mix_design_id: mix_design.id,
          tag_observation: mix_design.tag_observation,
        })
        .then(function (response) {
          loader.hide();
          dialogs.notify();
        });
    },

    getStatusIcon(status) {
      switch (status) {
        case 0:
          return "/img/icons/icons8/ios/shutdown--v1_danger.png";
        case 1:
          return "/img/icons/icons8/ios/ok-grenn.png";
        case 2:
          return "/img/icons/icons8/ios/vector-gray.png";
        default:
          return "/img/icons/icons8/ios/ok-grenn.png";
      }
    },

    getStatusColor(status) {
      switch (status) {
        case 0:
          return "color: #db4539";
        case 1:
          return "color: #149e57";
        case 2:
          return "color: #525f7f";
        default:
          return "color: #149e57";
      }
    },

    getText(status) {
      switch (status) {
        case 1:
          return "Ativo";
        case 2:
          return "rascunho";
        default:
          return "inativo";
      }
    },
    getStatusBg(status) {
      switch (status) {
        case 0:
          return "background-color: #FCF3F3";
        case 1:
          return "background-color: #F2F7F3";
        case 2:
          return "background-color: #E8E8E8";
        default:
          return "background-color: #F2F7F3";
      }
    },

    copy(val) {
      this.$helper.copy(val);
      this.$notify({
        type: "success",
        message: "Código do traço copiado com sucesso!",
      });
    },
  },
};
</script>
<style scoped lang="scss">
.spacer {
  @media screen and (min-width: 992px) {
    margin-left: -2rem;
  }
}

#formulation-button-reset .button-reset {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 8 !important;
  border-bottom-right-radius: 8 !important;
}

.hr {
  background-color: #e8e8e8;
  height: 1px;
  width: 100%;
  margin: 12px 0 16px;
}

.mobile-fix-outline {
  @media screen and (max-width: 500px) {
    padding-left: 0 !important;
    padding-right: 10px !important;
  }
}

.mobile-puzl-icon-fix {
  padding: 8px 36px;

  @media screen and (max-width: 500px) {
    padding: 8px 10px 8px 8px;
    margin-left: -12px;
  }
}

.mobile-position {
  @media screen and (max-width: 500px) {
    position: absolute;
    top: 16px;
    right: 0;

    .planilha {
      margin-top: 40px;
    }
  }
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  width: 130px;
  padding: 4px;
  border-radius: 16px;
}

.card-outline {
  padding: 8px 24px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  overflow: hidden;
}

.dropdown-toggle {
  padding: 0;
}

.card-blue-bottom {
  border-top-left-radius: 35px;
  background-color: #1b6eba;
  color: white;
  max-width: 140px;
  min-width: 140px;
  float: right;
}

.card {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
  padding: 16px;
  position: relative;
}

.card-head {
  width: 100%;
  border: none;
  font-size: 0.75rem;
  padding: 3px;
  padding-bottom: 4px;
  letter-spacing: 0.025em;
}

.custom-card {
  position: absolute;
  top: 0;
  right: 0;
  width: 35%;
  height: 100%;
  background-color: #d1e3f1;
  filter: blur(1px);
}

/** Diminuí a fonte em telas medias e deixa tamanho padrão em telas grandes ou celular */
@media (min-width: 767px) and (max-width: 1317px) {
  .responsive-font {
    font-size: 11px;
  }
}
@media (max-width: 766px), (min-width: 1318px) {
  .responsive-font {
    font-size: 13px;
  }
}
</style>

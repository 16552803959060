const keys = Object.freeze({
  INACTIVE: 0,
  ACTIVE: 1,
  UNAVAILABLE: 2,
});

const getTitle = (value) => {
  switch (value) {
    case keys.INACTIVE:
      return "Inativo";
    case keys.ACTIVE:
      return "Ativo";
    case keys.UNAVAILABLE:
      return "Indisponível";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle,
};
const keys = Object.freeze({
  PUZL: 'puzl',
  SAP: 'sap',
  SANKHYA: 'sankhya',
  VARUNA: 'varuna',
});

const getTitle = (value) => {
  switch (value) {
    case keys.PUZL:
      return "Puzl";
    case keys.SAP:
      return "SAP";
    case keys.TOTVS:
      return "Totvs";
    case keys.SANKHYA:
      return "Sankhya";
    default:
      return "";
  }
};

export const SyncHubEnum = {
  keys,
  getTitle
};
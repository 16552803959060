<template>
  <div>
    <modal :use-rerender="false" :show.sync="modal.create" size="xl">
      <template slot="header">
        <h5 class="modal-title pl-3">
          <img class="mr-2" src="/img/icons/icons8/ios/download-from-cloud--v1.png" width="27px"/>
          {{ modal.title }}
        </h5>
      </template>
      <div class="container">

        <button
          v-if="$hasPrivilege(1)"
          @click="exportDeleteOldData"
          type="button"
          class="btn btn-danger btn-sm mb-1"
          title="Deleta registros que foram criados há mais de uma semana"
        >
          <i class="fa-regular fa-trash-can"></i>
        </button>

        <div class="row show-md">
          <div class="col-11 pl-3 pr-1">
            <div class="d-flex w-100" style="height: 3rem;">
              <input @keydown.enter="push" @keypress.space="push" v-model="filtered" placeholder="Pesquisar"
                     class="form-control filtered-input"/>
              <img @click.prevent="push()" src="/img/icons/icons8/ios/search.png" height="18"
                   style="margin-top: 10px" class="default-search zoom pointer">
            </div>
          </div>
          <div class="col-1 pr-3 pl-1">
            <base-button @click.prevent="filter.global =[];init({});"
                         style="border: 1px solid #d7d6d6;height: 3rem;border-radius: 8px !important;" type="white"
                         block>
              <div style="margin-top: -4px">
                <img height="21" src="/img/icons/icons8/ios/synchronize.png" alt="synchronize"/>
              </div>
            </base-button>
          </div>
          <div class="col-12 pl-3 pr-1 mt-2">
            <transition name="el-zoom-in-center">
              <h3 v-if="filter.global && filter.global.length">
                <span 
                  class="badge badge-primary text-white mr-1 mt-n1"
                  style="background: #1A70B7"
                  v-for="(item, key) in filter.global"
                >
                  {{ item }} 
                  <a href="#">
                    <i @click.prevent="removeSelected(key)" class="fa-solid fa-xmark text-white"
                        style="font-size: 16px;opacity: 50%;margin-left: 2px;margin-right: 2px"></i>
                  </a>
                </span>
              </h3>
            </transition>
          </div>
        </div>
        <div class="row show-mobile">
          <div class="col-9 pl-3 pr-1">
            <div class="d-flex w-100" style="height: 3rem;">
              <input @keydown.enter="push" @keypress.space="push" v-model="filtered" placeholder="Pesquisar"
                     class="form-control filtered-input"/>
              <img @click.prevent="push()" src="/img/icons/icons8/ios/search.png" height="18"
                   style="margin-top: 10px" class="default-search zoom pointer">
            </div>
          </div>
          <div class="col-3 pr-3 pl-1">
            <base-button @click.prevent="filter.global =[];init({})"
                         style="border: 1px solid #d7d6d6;height: 3rem;border-radius: 8px !important;" type="white"
                         block>
              <div style="margin-top: -4px">
                <img height="21" src="/img/icons/icons8/ios/synchronize.png" alt="synchronize"/>
              </div>
            </base-button>
          </div>
          <div class="col-12 pl-3 pr-1 mt-2">
            <transition name="el-zoom-in-center">
              <h3 
              v-if="filter.global && filter.global.length">
              <span
                class="badge badge-primary text-white mr-1 mt-n1"
                style="background: #1A70B7"
                v-for="(item, key) in filter.global"
              > 
                {{ item }}
                <a href="#">
                  <i 
                    @click.prevent="removeSelected(key)" class="fa-solid fa-xmark text-white"
                    style="font-size: 16px;opacity: 50%;margin-left: 2px;margin-right: 2px"
                  ></i>
                </a>
              </span>
              </h3>
            </transition>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <div 
              id="list" class="card table-responsive"
              style="background: #f4f8fc !important;border-radius: 4px !important;height: 70vh"
            >
              <div class="card-body" style="padding: 0px">
                <ShadedTable 
                  class="px-4 pt-2"
                  :headers="headers"
                  :length="$_items.length"
                  :loading="loadingSkeleton"
                >
                  <template v-for="(item, key) in $_items" :slot="key">
                    <ShadedCol class="text-center" style="width: 10%">
                      <nav style="display: inline;">
                        <h5 class="new-default-black-font font-weight-normal m-0 mt-1"><i
                          style="font-style: normal; font-size: 13px;">
                          <badge class="mr-2" :type="getStatusClass[item.status]">{{ getStatusName[item.status] }}
                          </badge>
                        </i></h5>
                      </nav>
                    </ShadedCol>
                    <ShadedCol style="width: 10%">
                      <nav style="display: inline;"><h5 class="new-default-black-font font-weight-normal m-0 mt-1"><i
                        style="font-style: normal; font-size: 13px;">{{ item.module }}</i></h5></nav>
                    </ShadedCol>
                    <ShadedCol  style="width: 30%">
                      <nav style="display: inline;">
                        <h5 class="new-default-black-font font-weight-normal m-0 mt-1"><i
                          style="font-style: normal; font-size: 13px;">

                          <div class="row">
                            <div class="col-8"> {{ item.description }}</div>
                            <div class="col-4 text-center">
                              <img width="25" :src="format_icons[item.format]"/>
                            </div>
                          </div>
                        </i></h5>
                      </nav>
                    </ShadedCol>
                    <ShadedCol class="text-center" style="width: 10%">
                      <nav style="display: inline;"><h5 class="new-default-black-font font-weight-normal m-0 mt-1"><i
                        style="font-style: normal; font-size: 13px;">{{ item.company_plant_name }}</i></h5></nav>
                    </ShadedCol>
                    <ShadedCol class="text-center" style="width: 20%">
                      <nav v-if="item.filter && item.filter.dateFilter" style="display: inline;">
                        <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                          <i style="font-style: normal; font-size: 13px;">
                            {{ $helper.parseDate(item.filter.dateFilter, 'DD/MM/YYYY') }}
                          </i>
                        </h5>
                      </nav>
                      <nav
                        v-else-if="item.filter && item.filter.range"
                        style="display: inline;"
                      >
                        <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                          <i style="font-style: normal; font-size: 13px;">
                            {{ item.filter.range.start | parseDate('DD/MM/YYYY') }} -
                            {{ item.filter.range.end | parseDate('DD/MM/YYYY') }}
                          </i>
                        </h5>
                      </nav>
                      <nav v-else style="display: inline;">
                        <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                          <i style="font-style: normal; font-size: 13px;">
                            Total
                          </i>
                        </h5>
                      </nav>
                    </ShadedCol>
                    <ShadedCol class="text-center">
                      <nav style="display: inline;">
                        <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
                          <i style="font-style: normal; font-size: 13px;">
                            {{item.created_at | parseDate('DD/MM/YYYY H:mm')}}
                            <el-popover trigger="click" placement="bottom">
                              <span style="width: 200px;" class="row">
                                <div class="col-12 mb-n2 mr-n4">
                                  <h5 class="font-weight-normal" style="word-break: break-word;">
                                    {{ item.user_name }}
                                  </h5>
                                </div>
                              </span>
                              <base-button
                                outline slot="reference"
                                size="sm"
                                type="secundary"
                                class="p-0 m-0 mr-2 shadow-none text-indigo"
                              >
                                <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="25">
                              </base-button>
                            </el-popover>
                          </i>
                        </h5>
                      </nav>
                    </ShadedCol>
                    <ShadedCol class="text-center" style="width: 10%">
                      <div class="text-center">
                        <base-dropdown-v2 size="sm">
                          <base-dropdown-item @click="download(item.file_path, item.name)">
                            <img width="19px" height="19px" src="/img/icons/icons8/ios/download--v1_primary.png"/>
                            Download
                          </base-dropdown-item>
                          <base-dropdown-item @click="destroy(item.id)">
                            <img width="19px" height="19px" class="mr-2" src="/img/icons/icons8/ios/delete--v1_danger.png">
                            Excluir
                          </base-dropdown-item>
                        </base-dropdown-v2>
                      </div>
                    </ShadedCol>
                  </template>
                </ShadedTable>
                <PuzlEmptyData v-if="!$_items.length && !loadingSkeleton" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import BaseDropdownV2 from '@/components/Utils/BaseDropdown.vue'
import BaseDropdownItem from "@/components/Utils/BaseDropdownItem.vue";
import {mapGetters} from "vuex";
import {base_url_ms} from '@/plugins'
import cursorPaginate from "@/mixins/cursorPaginate";
import PuzlEmptyData from "@/components/PuzlEmptyData";

export default {
  components: {
    ShadedTable,
    ShadedCol,
    BaseDropdownV2,
    BaseDropdownItem,
    PuzlEmptyData,
  },
  mixins: [cursorPaginate],
  data() {
    return {
      modal: {
        create: false,
        title: 'Downloads'
      },
      params: {
        hasMore: false,
        per_page: 3,
        next_page: '',
        filter: {}
      },
      filter: {
        status: null,
        global: [],
      },
      loading: false,
      format_icons: Object.freeze({
        zip: '/img/icons/zip.png',
        pdf: '/img/icons/icons8/ios/pdf-2_danger.png',
        xlsx: '/img/icons/excel.png',
      }),
      getStatusName: Object.freeze({
        0: 'Pendente',
        1: 'Em andamento',
        2: 'Cancelado',
        3: 'Finalizado',
      }),
      getStatusClass: Object.freeze({
        0: 'yellow',
        1: 'warning',
        2: 'danger',
        3: 'success',
      }),
      loadingSkeleton: true,
      filtered: null,
      headers: [
        "Status",
        "Módulo",
        "Relatório",
        "Central",
        "Período",
        "Data de criação",
        "Ação",
      ],
      base_url_ms: base_url_ms(),
    }
  },
  computed: {
    ...mapGetters({
      $_items: "exports/fetch",
    })
  },
  mounted() {
    const list = document.getElementById('list')
    list.addEventListener("scroll", this.navigate)
    this.loadingSkeleton = true
  },
  methods: {
    navigateCalc(e) {
      const screenHeight = e.target.clientHeight;
      const scrolled = e.target.scrollTop;
      const scrollHeight = e.target.scrollHeight;
      if (scrollHeight - (screenHeight + scrolled) < 213) {
        return true
      }
      return false
    },
    navigate(e) {
      if (!this.loading && this.navigateCalc(e)) {
        if (this.params.hasMore) {
          this.init(this.filter)
        }
      }
    },
    init(filter = {}) {
      this.loading = true
      this.params.filter = filter
      if (Object.keys(filter).length === 0) {
        this.params.next_page = null
        this.params.hasMore = null
      }
      let loader = this.$loading.show()
      this.$store.dispatch('exports/fetchItems', this.params).then((response) => {
        this.params.hasMore = response.has_more
        this.params.next_page = response.next_page
      }).finally(() => {
        loader.hide()
        this.loadingSkeleton = false
        this.loading = false
      })
    },
    push() {
      if (!this.filtered) {
        return this.init(this.filter);
      }
      this.filtered.split(' ').map(function (item) {
        item = item.replaceAll('(', '')
        item = item.replaceAll(')', '')
        if (item != '') {
          this.filter.global.push(item);
        }
      }, this)
      this.filtered = null;
      this.init(this.filter);
    },
    removeSelected(key) {
      this.filter.global.splice(key, 1);
      this.init(this.filter);
    },
    destroy(id) {
      let loader = this.$loading.show()
      this.$store.dispatch('exports/destroy', id).then((response) => {
        this.$notify({
          type: response.error_type,
          message: response.message
        });

      }).finally(() => {
        this.init({})
        loader.hide()
      })
    },
    /**
     * @param file_path
     * @param file_name
     * @returns {Promise<void>}
     */
    async download(file_path, file_name) {
      try {
        var loader = this.$loading.show();
        const response = await this.$store.dispatch('exports/getTemporaryUrl', {
          urls: [{file_path: file_path, id: null, file_name}]
        });
        const temporaryUrl = response.data[0].file_path;
        window.open(temporaryUrl, '_blank');
        this.$notify({type: 'success', message: 'Download iniciado com sucesso!'});
      } catch (error) {
        this.$notify({type: 'error', message: 'Erro ao realizar o download. Entre em contato com o suporte.'});
      } finally {
        loader.hide();
      }
    },
    getCompleted() {
      this.$store.dispatch('exports/getCompleted')
    },
    async openModal() {
      let loader = this.$loading.show()
      this.modal.create = true
      loader.hide()
      await this.$store.dispatch('exports/readAll')
      this.getCompleted()
       this.init({})
    },
    exportDeleteOldData() {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$store.dispatch('exports/exportDeleteOldData').then(() => {
            this.init(this.filter);
            this.$Progress.finish();
          });
        }
      });
    },
  }
}
</script>
<style>
.filtered-input {
  height: 3rem;
  border-radius: 8px !important;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)
}
</style>

<template>
  <div>
    <div class="row">
      <ShadedTable :length="$_integration_listed.items.length" :headers="headers" :loading="$_integration_is_listing">
        <template v-for="(item, index) in $_integration_listed.items" :slot="index">
          <!-- Status -->
          <ShadedCol>        
              <div class="table-status" :class="getStatusClass(item.card_integration_status)">
                <span>{{ getStatusText(item.card_integration_status) }}</span>
              </div>
          </ShadedCol>

          <!-- Data -->
          <ShadedCol>
            <div class="table-value table-date">
              {{ $helper.parseDate(item.created_at, "DD MMM YYYY") }}
            </div>
          </ShadedCol>

          <!-- Integraçaos -->
          <ShadedCol>
            <div class="table-value">
              {{ item.payment_method }}
            </div>
          </ShadedCol>

          <!-- Cliente -->
          <ShadedCol>
            <div class="table-value">
              {{ item.company_plant }}
            </div>
          </ShadedCol>

          <!-- Ações -->
          <ShadedCol>
            <div class="table-value">
              {{ item.document }}
            </div>
          </ShadedCol>

          <!-- Ações -->
          <ShadedCol>
            <base-dropdown menuOnRight class="text-center">
              <div slot="title-container" class="dropdown-toggle rounded m-0">
                <img width="25" src="/img/icons/icons8/ios/settings--v1_primary.png" />
              </div>
              <a class="dropdown-item">
                <img width="22" src="/img/icons/create-new.png" />
                Editar
              </a>
              <div class="dropdown-divider" /> 
              <a class="dropdown-item">
                <img width="22" src="/img/icons/sorting-arrows-horizontal-red.png" />
                Transferir
              </a>
            </base-dropdown>
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
</template>


<script setup>
//#region Imports
import { computed, defineEmits, ref } from "vue";
import integrationRoute from "../../store/IntegrationStore";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid.vue";
import { dialogs } from '../../../../../../helpers';
import { hasPermission } from "@/plugins/permissions";
import store from "@/store/index";
import ShadedTable from "../../../../../../components/ShadedTable.vue";
import ShadedCol from "../../../../../../components/ShadedCol";
import IntegrationStatusEnum from "../../enums/IntegrationStatusEnum";

import { hasTotvs as getHasTotvs } from "@/plugins/microservices/totvs";

//#endregion

//#region Emits
const emit = defineEmits([
  "onEditItemClick",
]);
//#endregion

//#region === Data ===
const headers = ref([
  "Status",
  "Data",
  "integração",
  "cliente",
  "documento",
  "Ação",
]);

const hasTotvs = getHasTotvs();

//#region Computeds
/** Store Getters */
const $_integration_listed = computed(() => integrationRoute.getters.getListed());
const $_integration_is_listing = computed(() => integrationRoute.getters.getIsListing());
//#endregion

//#region Methods

/**
 * Retorna a classe CSS correspondente ao status do equipamento.
 * @param {number} status - ID do status do equipamento.
 * @returns {string} - Classe CSS.
 */
function getStatusClass(status) {
  switch (status) {
    case IntegrationStatusEnum.keys.INTEGRATED: return "operating-status";
    case IntegrationStatusEnum.keys.PENDING: return "operating-status";
    case IntegrationStatusEnum.keys.ERROR: return "operating-status";
    default: return "operating-status";
  }
}

function getStatusText(status) {
  switch (status) {
    case IntegrationStatusEnum.INTEGRATED: return "Integrado";
    case IntegrationStatusEnum.PENDING: return "Pendente";
    case IntegrationStatusEnum.ERROR: return "Erro";
    default: return "Integrado";
  }
}


//#endregion
</script>

<style scoped>
* {
  font-family: Fredoka;
}
.row >>> table thead th:first-child {
  text-align: center !important;
}
.row >>> table thead th {
  text-align: left !important;
}
.row >>> table thead th:last-child {
  text-align: center !important;
}
.table-value {
  font-weight: 400 !important;
  font-size: 0.8125rem;
  line-height: 20px;
  color: #2b2d32;
}

.table-date {
  font-size: 0.9rem;
}
.dropdown-item {
  align-items: center;
  display: flex;
  padding: 0.5rem 1rem !important;
}

.dropdown-divider {
  margin: 0;
}

.table-status {
  border-radius: 8px;
  border-radius: 15px;
  padding: 5px 12px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.table-status span {
  font-size: 12px !important;
  font-weight: 400 !important;
}

/* Status: Alugado */
.rented-status {
  background-color: #fef9f2;
  color: #F2B532;
}

/* Status: Danificado */
.damaged-status {
  background-color: #fef9f2;
  color: #F2B532;
}

/* Status: Fora de Uso */
.out-of-use-status {
  background-color: #FCF3F3;
  color: #DB4539;
}

/* Status: Operando */
.operating-status {
  background-color: #f2f7f3;
  color: #149E57;
}
</style>

<template>
  <div>
    <modal size="lg"
      :show.sync="modal"
      :backgroundColor="'#F2F4F9'"
    >
      <template slot="header">
        <div class="d-flex align-items-center container-fluid" style="gap: 12px; margin-left: -28px;">
          <div>
            <img src="/img/icons/icons8/ios/icon-plus-success.png" height="28" width="28">
          </div>
          <span class="text-title">
            {{ title }}
          </span>
        </div>
      </template>
      <template slot="close-button">
        <AppButton
          icon="close"
          width="auto"
          type="link"
          size="sm"
          :hasOutline="true"
          @click="openModal(false)"
        />
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="card p-2 m-2" style="margin-bottom: 15px !important;">
              <span class="ml-2 mb-2 label-title">
                <img height="30px" src="/img/icons/icons8/ios/info-squared.png" class="mr-2"/>
                Identificação do cliente
              </span>
              <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label label-item">
                  CPF/CNPJ
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider rules="required|min:14|document" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm" group class="mb-2">
                      <input 
                        type="text" 
                        v-model="entity.document" 
                        class="form-control form-control-sm"
                        v-mask="['###.###.###-##', '##.###.###/####-##']"
                        :class="entity.document.length > 0 && ! verifyDocument(entity.document) && 'is-invalid' || errors[0] ? 'is-invalid' : 'is-valid'"
                        @keyup="changeIsLegal($event.target.value)"
                        @change="checkIfCpf"
                        :disabled="isDocumentDisabled"
                      />
                      <div class="input-group-append" id="button-addon4">
                        <button class="btn btn-outline-primary btn-sm form-control-sm" type="button"
                                @click.prevent="getRecipeNet" :disabled="!showLegalEntity">
                          Pesquisar
                        </button>
                      </div>
                    </base-input>
                    <small v-show="entity.document.length > 0 && ! verifyDocument(entity.document)"><i
                      class="fas fa-exclamation-triangle text-danger mr-2"></i>
                      <strong class="" style="color: #7e7e7e">CPF/CNPJ Inválido!</strong>
                    </small>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label label-item">
                  Nome do cliente
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="entity.entity_name"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-0 col-form-label form-control-label label-item">
                  E-mail principal
                  <span v-show="!openFromEntryLaunch" class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider :rules="openFromEntryLaunch ? 'email' : 'required|email'" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm" group >
                      <input type="text" v-model="entity.email"
                            :class="errors[0] ? 'is-invalid' : 'is-valid'"
                            class="form-control form-control-sm"/>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-0 col-form-label form-control-label label-item">
                  Telefone principal
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="tel"
                        v-model="entity.phone"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        class="form-control form-control-sm"
                        v-mask="['(##) ####-####', '(##) #####-####']" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-0 pr-0 col-form-label form-control-label label-item">
                  Indicador Insc. Estadual
                  <span
                    class="text-danger">&nbsp;*
                  </span>
                </label>
                <div class="col-md-8">
                  <validation-provider  rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <el-select v-model="entity.state_registration" size="mini" filterable placeholder="Selecione"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'">
                        <el-option v-for="state_registration in option_state_registrations"
                                    :key="state_registration.value" :label="state_registration.label"
                                    :value="state_registration.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div 
                v-if="
                  entity.state_registration === stateRegistrationEnum.contributor.id ||
                  entity.state_registration === stateRegistrationEnum.exempt_contributor.id
                " 
                class="form-group row m-0 p-0 mb-1"
              >
                <label class="col-md-4 pb-0 col-form-label form-control-label label-item">
                  Inscrição Estadual
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider
                    :rules="
                      (
                        entity.state_registration === stateRegistrationEnum.contributor.id ||
                        entity.state_registration === stateRegistrationEnum.exempt_contributor.id
                      ) &&
                      'required' ||
                      ''
                    "
                  >
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="entity.contributing_number"
                        class="form-control form-control-sm"
                        @input="removeSpecialCharactersContributingNumber"/>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-0 col-form-label form-control-label label-item">
                  Inscrição Municipal
                </label>
                <div class="col-md-8">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="entity.municipal_registration"
                      class="form-control form-control-sm"
                      @input="removeSpecialCharactersMunicipalRegistration"/>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-0 pr-0 col-form-label form-control-label label-item">
                  Intermediador
                </label>
                <div class="col-md-8">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect 
                      v-model="entity.is_intermediary" 
                      :items="$_usersIntermediaries"  
                      :loading="loadingUsersIntermediaries"
                      :disabled="! $_commercial_permission.is_edit_intermediary_seller_field" 
                    />
                  </base-input>
                </div>
              </div>
            </div>

            <div class="card p-2 m-2" style="margin-bottom: 15px !important;">
              <div class="form-group row m-0 p-0">
                <label class="col-md-4 mt-n1 col-form-label form-control-label label-title">
                  <img 
                    height="32px" 
                    src="/img/icons/contacts.png" 
                    class="mr-2 mb-2" 
                  />
                  Contatos
                </label>
                <div class="col-md-8">
                  <span 
                    @click="openModalCreateEntityContact"
                    style="width: 80px;display: flex; align-items: center;"
                    class="btn btn-sm btn-outline-success p-1 mt-2 text-center float-right"
                  >
                    <i class="fas fa-plus ml-2 mr-2"></i> NOVO
                  </span>
                </div>
                <div style="width: 100%;">
                  <div
                    v-for="(item, index) in entityContacts" 
                    :key="index" 
                    style="width: 100%; margin-bottom: 8px;"
                  >
                    <div 
                      class="card"
                      style="
                        width: 100%;
                        padding: 1rem;
                        margin-bottom: 0.5rem;
                        background-color: #f8f9fa;
                        border: 1px solid #dee2e6;
                      "
                    >
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <div style="display: flex; align-items: center; flex-grow: 1;">
                          <span>{{ item.name }}</span>
                          <b class="text-dark" style="margin-left: 1.5rem; margin-right: 1.5rem;">|</b>
                          <span v-if="item.office">
                            {{ item.office }}
                            <b class="text-dark" style="margin-left: 1.5rem; margin-right: 1.5rem;">|</b>
                          </span>
                          <span v-if="item.email">
                            {{ item.email }}
                            <b class="text-dark" style="margin-left: 1.5rem; margin-right: 1.5rem;">|</b>
                          </span>
                          <span>{{ item.phone }}</span>
                        </div>
                        <div style="margin-left: 15px;">
                          <a href="#" @click="deleteEntityContact(item)">
                            <img 
                              height="23px" 
                              src="/img/icons/icons8/ios/delete_danger.png" 
                              alt="Deletar"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card p-2 m-2" style="margin-bottom: 15px !important;">
              <div class="form-group row m-0 p-0 mt-3">
                <label class="col-md-5 mt-n1 col-form-label form-control-label label-title">
                  <img 
                    height="32px" 
                    src="/img/icons/check.png" 
                    class="mr-2 mb-2" 
                  />
                  Observação para nfs-e
                </label>
                <label class="col-md-10 pb-0 mb-4 mt-n2 col-form-label form-control-label label-item">
                  Obrigatório
                </label>
                <div class="col-md-2">
                  <base-input input-group-classes="input-group-sm float-right" style="margin-right: -11px !important;">
                      <ToggleButton 
                        :initialValue="entity?.is_nfse_observations_required ?? false " 
                        @update:initialValue="entity.is_nfse_observations_required = $event"
                      />
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-3" v-if="entity.is_nfse_observations_required">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label label-item">
                  Observação
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider :rules="entity.is_nfse_observations_required ? 'required' : ''" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <textarea
                        v-model="entity.default_text"
                        maxlength="255"
                        class="form-control"
                        rows="3" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </div>

            <div class="card p-2 m-2 mt-3" style="margin-bottom: 15px !important;">
              <div class="form-group row m-0 p-0 mt-3">
                <span class="mr-3 h3 label-title">
                  <img height="32px" src="/img/icons/icons8/ios/order-delivered_primary.png" class="mr-2"/>
                  Endereço
                  <span 
                    class="btn btn-sm btn-outline-danger p-1 text-center mr-2 mt-n1 mb-1"
                    @click="setReplicateConstructionAddress"
                    v-if="construnctionAddress"
                  >
                    <span style="font-size: 10px;">REPLICAR ENDEREÇO DA OBRA</span>
                  </span>
                </span>
              </div>
              <div>
                <div class="form-group row m-0 p-0">
                  <div class="col-md-4">
                    <div class="pb-0 mb-1 col-form-label form-control-label label-item">
                      CEP<span class="text-danger">&nbsp;*</span>
                    </div>
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input inputmode="numeric" type="text" v-model="entity.postal_code"
                              class="form-control form-control-sm" v-mask="['#####-###']" v-on:blur="getAddressByCode"
                              :class="errors[0] ? 'is-invalid' : 'is-valid'"/>
                      </base-input>
                    </validation-provider>
                  </div>
                  <div class="col-md-4">
                    <div class="pb-0 mb-1 col-form-label form-control-label label-item">
                      UF<span class="text-danger">&nbsp;*</span>
                    </div>
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <puzl-select
                          v-model="entity.state"
                          :items="states"
                          @change="getCities()"
                          customKey="letter"
                          label="letter"/>
                      </base-input>
                    </validation-provider>
                  </div>
                  <div class="col-md-4">
                    <div class="pb-0 mb-1 pr-0 col-form-label form-control-label label-item">
                      Cidade <span class="text-danger">&nbsp;*</span>
                    </div>
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <puzl-select
                          v-model="entity.city"
                          :items="cities"
                          customKey="title"
                          label="title"
                          :disabled="loadingCities"
                          :loading="loadingCities"/>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <div class="col-md-12">
                    <div class="pb-0 mb-1 col-form-label form-control-label label-item">
                      Endereço<span class="text-danger">&nbsp;*</span>
                    </div>
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          type="text" v-model="entity.address" class="form-control form-control-sm"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"/>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2">
                  <div class="col-md-4">
                    <div class="pb-0 mb-1 col-form-label form-control-label label-item">
                      N°<span class="text-danger">&nbsp;*</span>
                    </div>
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input maxlength="5"
                              v-if="!without_number"
                              v-model="entity.number"
                              v-mask="'#####'"
                              inputmode="numeric"
                              class="form-control form-control-sm"
                        />
                        <input v-else maxlength="5"
                              v-model="entity.number"
                              disabled
                              type="text"
                              inputmode="numeric"
                              class="form-control form-control-sm"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0" style="text-transform: none">
                            <a href="#" @click.prevent="without_number = !without_number">
                              <i v-if="without_number" class="fa-solid fa-square-check"></i>
                              <i v-else class="fa-regular fa-square"></i>
                            </a> Sem número
                          </small>
                        </template>
                      </base-input>
                    </validation-provider>
                  </div>
                  <div class="col-md-4">
                    <div class="pb-0 col-form-label form-control-label label-item">
                      Complemento
                    </div>
                      <base-input input-group-classes="input-group-sm mt-1">
                        <input
                          type="text"
                          v-model="entity.complement"
                          maxlength="20"
                          class="form-control form-control-sm"
                        />
                      </base-input>
                  </div>
                  <div class="col-md-4">
                    <div class="pb-0 pr-0 col-form-label form-control-label label-item">
                      Bairro <span class="text-danger">&nbsp;*</span>
                    </div>
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          type="text"
                          v-model="entity.district"
                          class="form-control form-control-sm"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="danger button" @click="openModal(false)">
                <AppIcon icon="cancel" color="danger" width="18" />
                Cancelar
              </button>
              <button
                class="success button"
                type="submit"
                v-bind:disabled="invalid"
                :loading="loadingStore"
              >
                <AppIcon icon="save" color="success" width="18" />
                Salvar
              </button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
    <ModalCreateEntityContact ref="modalCreateEntityContact" @storedEntityContact="listEntityContacts" />
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {verifyDocument, viacep} from "@/helpers";
import PuzlSelect from "@/components/PuzlSelect";
import {entity as $_totvs}  from "@/plugins/microservices/totvs";
import ModalCreateEntityContact from "./_ModalCreateEntityContact";
import ToggleButton from '../../../../../components/ToggleButton';
import AppIcon from "../../../../../components/AppGlobal/AppIcon/AppIcon.vue";
const {formatErrorValidation} = require("@/plugins");
import { AppButton } from "../../../../../components/AppGlobal";

export default {
  name: "ModalCreateEntity",
  props: ["openFromEntryLaunch"],
  components: {
    PuzlSelect,
    ModalCreateEntityContact,
    ToggleButton,
    AppIcon,
    AppButton,
  },
  data() {
    return {
      title: 'Novo cliente/fornecedor',
      cantSave: true,
      modal: false,
      verifyDocument: verifyDocument,
      without_number: false,
      documentConstruction: '',
      entity: {
        status: true,
        customer: true,
        is_legal: '',
        types: [],
        entity_name: '',
        document: '',
        email: '',
        options: [],
        phone: '',
        birthday: '',
        alias_name: '',
        registration_situation: '',
        tax_regime: '',
        municipal_registration: '',
        state_registration: '',
        contributing_number: '',
        suframa_number: '',
        postal_code: '',
        state: '',
        address: '',
        number: '',
        complement: '',
        district: '',
        city: '',
        is_nfse_observations_required: false,
        default_text: '',
      },
      stateRegistrationEnum: {
        contributor: { id: 1, name: "Contribuinte" },
        exempt_contributor: { id: 2, name: "Contribuinte isento" },
        non_contributor: { id: 9, name: "Não Contribuinte" },
      },
      validated: false,
      showLegalEntity: false,
      showIndividualEntity: false,
      loadingStore: false,
      loadingCities: false,
      isDocumentDisabled: false,
      construnctionAddress: null,
      entityContacts: [],
      customerConstructionDocumentPending: null,
      loadingUsersIntermediaries: true,
    }
  },
  computed: {
    ...mapGetters({
      'states': 'localizations/states',
      'cities': 'localizations/cities',
      'option_types': 'entity/option_types',
      'option_situations': 'entity/option_situations',
      'option_tax_regimes': 'entity/option_tax_regimes',
      'option_state_registrations': 'entity/option_state_registrations',
      $_usersIntermediaries: 'user/getActiveUsersIntermediaries',
      $_commercial_permission: "commercialPermission/show",
    }),
  },
  watch: {
    'without_number': function (val) {
      if (val) {
        this.entity.number = 'SN'
      } else {
        this.entity.number = ''
      }
    },
  },
  methods: {
    setOption(option) {
      let index = this.entity.options.findIndex(item => item === option)
      if (index != -1) {
        return this.entity.options.splice(index, 1)
      }
      this.entity.options.push(option)
    },
    openModal(
      open, 
      customer = false, 
      params = null, 
      isDocumentDisabled = null, 
      customerConstruction = null, 
      construnctionAddress = null,
      customerConstructionDocumentPending = null,
    ) {
      this.getCommercialPermission();
      this.getAllUsersIntermediaries();

      this.entity = {
        status: true,
        customer: customer,
        is_legal: '',
        types: '',
        entity_name: params && params.customer_name || null,
        document: params && params.document || '',
        email: '',
        phone: '',
        birthday: '',
        alias_name: '',
        registration_situation: '',
        tax_regime: '',
        municipal_registration: '',
        state_registration: '',
        contributing_number: '',
        suframa_number: '',
        options: [1],
        postal_code: params && params.address_cpf_cnpj && params.address_cpf_cnpj.postal_code || '',
        state: params && params.address_cpf_cnpj && params.address_cpf_cnpj.state || '',
        address: params && params.address_cpf_cnpj && params.address_cpf_cnpj.address || '',
        number: params && params.address_cpf_cnpj && params.address_cpf_cnpj.number || '',
        complement: params && params.address_cpf_cnpj && params.address_cpf_cnpj.complement || '',
        district: params && params.address_cpf_cnpj && params.address_cpf_cnpj.district || '',
        city: params && params.address_cpf_cnpj && params.address_cpf_cnpj.city || '',
        is_nfse_observations_required: false,
        default_text: '',
      }
      if (params && params.document) {
        this.changeIsLegal(params.document);
        this.documentConstruction = params.document;
      }
      this.isDocumentDisabled = isDocumentDisabled ? true : false;

      // Quando for aberto pela tela de cadastro de propostas/contratos.
      if (customerConstruction) {
        this.changeIsLegal(customerConstruction.document || customerConstruction.customer_construction_document);
        this.entity.document = customerConstruction.document || customerConstruction.customer_construction_document;
        this.entity.entity_name = customerConstruction.customer_name;
        this.isDocumentDisabled = true;
        this.showLegalEntity = true; // libera o botão pesquisar documento
      }
      if (construnctionAddress) {
        this.construnctionAddress = construnctionAddress;
      }

      // Quando for aberto pela opção "gerar contrato" na modal "pesquisa por tomador".
      this.customerConstructionDocumentPending = customerConstructionDocumentPending;

      this.entityContacts = [];
      this.modal = open;
    },
    changeIsLegal(value) {
      if (value.length >= 14) {
        const isDocument = this.$helper.verifyDocument(value)
        this.isDocument = this.$helper.verifyDocument(value)
        if (isDocument) {
          this.cantSave = false
          if (value.length == 18) {
            this.entity.is_legal = 1;
            this.showIndividualEntity = false;
            this.showLegalEntity = true;
          } else if (value.length == 14) {
            this.entity.is_legal = 0;
            this.showLegalEntity = false;
            this.showIndividualEntity = true;
          } else {
            this.showIndividualEntity = false;
            this.showLegalEntity = false;
          }
        } else {
          this.cantSave = true
          this.showIndividualEntity = false;
          this.showLegalEntity = false;
        }
      } else {
        this.cantSave = true
        this.showIndividualEntity = false;
        this.showLegalEntity = false;
      }
    },
    getRecipeNet() {
      this.$Progress.start();
      this.$store.dispatch('recipe_net/fetch', {
        ein: this.entity.document
      })
        .then((response) => {
          this.entity.entity_name = response.data.nome || '';
          this.entity.alias_name = response.data.fantasia || response.data.nome;
          this.entity.email = response.data.email || '';
          this.entity.phone = response.data.telefone || '';
          this.entity.number = response.data.numero || '';
          this.entity.registration_situation = response.data.situacao || '';
          this.entity.postal_code = response.data.cep.replace(/[^\d]+/g, '') || '';
          if (this.entity.postal_code) {
            this.getAddressByCode()
          }
          this.$Progress.finish();
        })
    },
    getAddressByCode() {
      this.$Progress.start();
      let postal_code = this.entity.postal_code.replace(/[^\d]+/g, '')
      if (postal_code) {
        viacep(postal_code).then((response) => {
          if (response.data.logradouro != "") {
            this.getCities();
            this.entity.address = response.data.logradouro;
            this.entity.district = response.data.bairro;
          } else {
            this.entity.address = '';
            this.entity.number = '';
            this.entity.district = '';
          }
          this.entity.state = response.data.uf;
          this.entity.city = response.data.localidade;
          if (response.data.erro) {
            this.$notify({
              type: 'warning',
              message: 'Dados incompletos para o CEP fornecido. Preencha manualmente.'
            });
          } else {
            this.$notify({
              type: 'success',
              message: 'Dados carregados com sucesso.'
            });
          }
          this.$Progress.finish();
        }).catch((error) => {
          this.$notify({type: 'danger', message: 'Não foi possível encontrar o endereço.'});
        })
      }
    },
    getCities() {
      if (this.entity.state) {
        this.entity.city = '';
        this.$Progress.start();
        this.loadingCities = true;
        this.$store.dispatch('localizations/fetchCities', {
          uf: this.entity.state
        })
          .then((response) => {
            this.loadingCities = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            this.loadingCities = false;
            this.$Progress.finish();
          });
      }
    },
    store() {
      // Valida se o usuário digitou letras ou caracteres especiais.
      let regex = /[a-zA-Z]/;
      let specialRegex = /[!@#$%^&*(),.?":{}|<>]/g;
      if (regex.test(this.entity.contributing_number) || specialRegex.test(this.entity.contributing_number)) {
        this.$notify({type: 'warning', message: 'Número do contribuinte deve ter apenas números'});
        return;
      }
      if (regex.test(this.entity.municipal_registration) || specialRegex.test(this.entity.municipal_registration)) {
        this.$notify({type: 'warning', message: 'Insc. municipal deve ter apenas números'});
        return;
      }

      if (this.entity.entity_name.trim().split(" ").length < 2) {
        this.$notify({type: 'warning', message: 'Preencha nome e sobrenome'});
        return;
      }

      const isPostalCodeFormatValid = this.entity.postal_code.length === 9 && this.entity.postal_code !== '00000-000';
      if (!isPostalCodeFormatValid) {
        this.$notify({type: 'warning', message: 'O CEP informado não é válido'});
        return;
      }

      // Ao criar uma proposta, o cliente/obra cadastrado não possuí documento,
      // então ao gerar um contrato, caso o usuário esteja cadastrando um novo tomador,
      // o documento desse tomador será usado para atualizar o documento do cliente/obra.
      if (this.customerConstructionDocumentPending) {
        this.customerConstructionDocumentPending.document = this.entity.document;
        this.$store.dispatch('customerConstruction/update', this.customerConstructionDocumentPending);
      }

      this.$Progress.start();
      this.loadingStore = true;
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });

      if (this.documentConstruction && this.documentConstruction !== this.entity.document) {
        this.$notify({
          type: 'danger',
          message: 'O CPF/CNPJ do tomador precisa ser o mesmo do cliente.'
        });
        this.$Progress.finish();
        this.loadingStore = false;
      } 
      else {
        if (this.entityContacts.length) {
          this.entity.entityContacts = this.entityContacts;
        }
        this.$store.dispatch('entity/add', this.entity)
          .then(response => {
            this.loadingStore = false;
            this.modal = false;

            // Atualiza o nome do cliente de uma construção,
            // caso o CPF/CNPJ do tomador já esteja cadastrado em cliente/obra.
            const entityName = this.entity.entity_name;
            this.$store.dispatch("customerConstruction/getCustomerByDocument", {
              document: this.entity.document
            }).then(response => {
              if (response.data) {
                const customerConstructionId = response.data.id;
                this.$store.dispatch("customerConstruction/updateNameCustomerConstruction", {
                  id: customerConstructionId, 
                  entityName: entityName,
                }).then((response) => {
                  this.$emit('set_entity_name', entityName);
                });
              }
            });

            $_totvs.store(response.data);

            this.entity = {
              status: true,
              customer: true,
              is_legal: '',
              types: '',
              entity_name: '',
              document: '',
              email: '',
              phone: '',
              birthday: '',
              alias_name: '',
              registration_situation: '',
              tax_regime: '',
              municipal_registration: '',
              state_registration: '',
              contributing_number: '',
              suframa_number: '',
              postal_code: '',
              state: '',
              address: '',
              number: '',
              complement: '',
              district: '',
              city: '',
              is_nfse_observations_required: false,
              default_text: '',
            };
      
            this.$emit('set_entity', response.data);
            this.$Progress.finish();
            this.$notify({
              type: response.error_type,
              message: response.message
            });
          })
          .catch(error => {
            this.$Progress.finish()
            this.loadingStore = false
            if (error.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
            } else {
              this.$store.dispatch('entity/getEntityByDocument', {document: this.entity.document})
                .then((response) => {
                  response.data.foundEntity = true;
                  this.$emit('set_entity', response.data)
              })
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.modal = false
            }
          });
      }
    },
    removeSpecialCharactersContributingNumber(inputEvent) {
      let input = inputEvent.target.value;
      let regex = /^[0-9]*$/;
      if (!regex.test(input)) {
        this.entity.contributing_number = input.slice(0, -1);
      }
    },
    removeSpecialCharactersMunicipalRegistration(inputEvent) {
      let input = inputEvent.target.value;
      let regex = /^[0-9]*$/;
      if (!regex.test(input)) {
        this.entity.municipal_registration = input.slice(0, -1);
      }
    },
    checkIfCpf() {
      if (this.verifyDocument(this.entity.document) && this.entity.document.length === 14) {
        this.entity.state_registration = 9; // Indicador Insc. Estadual: Não contribuinte
      }
    },
    /** Replica o endereço da obra caso ela esteja preenchido */
    setReplicateConstructionAddress() {
      if (this.construnctionAddress) {
        this.entity.postal_code = this.construnctionAddress.postal_code;
        this.entity.district = this.construnctionAddress.district;
        this.entity.state = this.construnctionAddress.state;
        this.entity.city = this.construnctionAddress.city;
        this.entity.address = this.construnctionAddress.address;
        this.entity.number = this.construnctionAddress.number;
        if (this.construnctionAddress.number === 'SN') {
          this.entity.number = 'SN';
          this.without_number = true;
        } else {
          this.entity.number = this.construnctionAddress.number;
        }
      }
    },
    /**
     * Abre modal de cadastro de contatos do cliente/fornecedor
     */
    openModalCreateEntityContact() {
      this.$refs.modalCreateEntityContact.openModal(this.entityContacts);
    },
    /**
     * Lista contatos após o cadastro
    * @param {array} entityContacts
    */
    listEntityContacts(entityContacts) {
      this.entityContacts = entityContacts;
    },
    /**
    * @param {object} entityContact
    */
    deleteEntityContact(entityContact) {
      this.entityContacts.splice(this.entityContacts.indexOf(entityContact), 1);
    },
    getCommercialPermission() {
      this.$store.dispatch('commercialPermission/getByUserId', this.$user.id);
    },
    getAllUsersIntermediaries() {
      this.loadingUsersIntermediaries = true;
      this.$store.dispatch("user/userGetAllIntermediary").then(() => {
        this.loadingUsersIntermediaries = false;
      });
    },
  },
  mounted() {
    this.$refs.formValidator.validate()
    this.$store.dispatch('localizations/fetchStates')
  },
}
</script>

<style scoped>
.label-title {
  color: #2B2D32;
  font-family: Fredoka;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.label-item {
  color: #2B2D32;
  font-family: Fredoka;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>

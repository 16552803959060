import * as types from './mutation_types'
import {destroy, update, cursorPaginate} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";

const endPoint = '/commercial/contract-proposal+microservice'
export default {
  namespaced: true,
  state: {
    items: [],
    activeItems: [],
    item: [],
    max_code: null,
    customer_constructions: [],
    entities: [],
    comments: [],
    situations: [],
    price_validity: null,
    approvals: [],
    resume:{},
    selected: null
  },
  getters: {
    show: state => state.item,
    fetch: state => state.items,
    getComments: state => state.comments,
    getListApprovals: state => state.approvals,
    getMaxCode: state => state.max_code,
    getListEntities: state => state.entities,
    getListSituations: state => state.situations,
    getPriceValidity: state => state.price_validity,
    getListCustomerConstructions: state => state.customer_constructions,
    getResume: state => state.resume,
    activeItems(state) {
      return state.items.filter(item => item.status === true).sort((a, b) => a.name > b.name)
    },
  },
  mutations: {
    [types.PUSH](state, payload) {
      state.items.push(payload)
    },
    [types.PUSH_COMMENT](state, payload) {
      state.item.comments.push(payload)
    },
    [types.SHOW](state, payload) {
      state.item = payload
    },
    [types.DESTROY](state, uuid) {
      destroy(state, uuid)
    },
    [types.UPDATE](state, payload) {
      update(state, payload)
    },
    [types.FETCH_CODE](state, payload) {
      state.max_code = payload
    },
    [types.FETCH_ENTITIES](state, payload) {
      state.entities = payload
    },
    [types.FETCH_PRICE_VALIDITY](state, payload) {
      state.price_validity = payload
    },
    [types.FETCH_SITUATIONS](state, payload) {
      state.situations = payload
    },
    [types.FETCH_COMMERCIAL_APPROVALS](state, payload) {
      state.approvals = payload
    },
    [types.FETCH_CUSTOMER_CONSTRUCTION](state, payload) {
      state.customer_constructions = payload
    },
    [types.SET_GENERAL_RESUME](state, payload) {
      state.resume = payload
    },
    [types.SET](state, payload) {
      state.items = payload
    },
  },
  actions: {
    fetchItems({commit, state}, params) {
      return createAxios().get(params.next_page || endPoint + '/contract-proposal', {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data : data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getActiveContractsByEntity({commit}, entityUuid) {
      return createAxios().get('commercial/contract-proposal/active-contracts/' + entityUuid)
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return createAxios().post(endPoint + '/contract-proposal', payload)
        .then(({data}) => {
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    add_comment({commit, state}, payload) {
      return createAxios().post(endPoint + '/contract-proposal/comment', payload)
        .then(({data}) => {
          commit(types.PUSH_COMMENT, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    remove_comment({commit, state}, payload) {
      return createAxios().delete(`${endPoint}/contract-proposal/comment/${payload.id}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    update({commit}, payload) {
      return createAxios().put(`${endPoint}/contract-proposal/${payload.uuid}`, payload)
        .then(({data}) => {
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    show({commit, dispatch}, uuid) {
      return createAxios().get(`${endPoint}/contract-proposal/${uuid}`)
        .then(({data}) => {
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, uuid) {
      return createAxios().delete(`${endPoint}/contract-proposal/${uuid}`)
        .then(({data}) => {
          commit(types.DESTROY, uuid)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchCode({commit, dispatch}) {
      return createAxios().get(`/commercial/contract-proposal/max-code/+microservice`)
        .then(({data}) => {
          commit(types.FETCH_CODE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchCustomerConstruction({commit, dispatch}, params) {
      return createAxios().get(`/commercial/contract-proposal/customer-construction/+microservice`, {params: params})
        .then(({data}) => {
          commit(types.FETCH_CUSTOMER_CONSTRUCTION, data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchEntities({commit, dispatch}, params) {
      return createAxios().get(`/commercial/contract-proposal/entity/+microservice`, {params: params})
        .then(({data}) => {
          commit(types.FETCH_ENTITIES, data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchSituations({commit, dispatch}, params) {
      return createAxios().get(`/commercial/contract-proposal/situations/+microservice`, {params: params})
        .then(({data}) => {
          commit(types.FETCH_SITUATIONS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getGeneralResume({commit}, payload) {
      return createAxios().get(`/commercial/contract-proposal/resume/${payload}+microservice`)
        .then(({data}) => {
          commit(types.SET_GENERAL_RESUME, data.data);
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postCreateSituation({commit, dispatch}, params) {
      return createAxios().post(`/commercial/contract-proposal/situations/+microservice`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getPriceValidityByPlantId({commit, dispatch}, id) {
      return createAxios().get(`/commercial/contract-proposal/validity-price/${id}/+microservice`)
        .then(({data}) => {
          commit(types.FETCH_PRICE_VALIDITY, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getCommercialWithoutStatus({commit, dispatch}, uuid) {
      return createAxios().get(`/commercial/contract-proposal/without-status/${uuid}/+microservice`)
        .then(({data}) => {
          commit(types.FETCH_COMMERCIAL_APPROVALS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    ApprovalAllotments({commit, dispatch}, params) {
      return createAxios().post(`/commercial/contract-proposal/without-status/+microservice`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    putApprove({commit, dispatch}, params) {
      return createAxios().put(`/commercial/contract-proposal/approve/${params.id}/+microservice`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    download({commit}, payload) {
      return createAxios()
        .get(`${endPoint}/download/report`, {
          responseType: "blob",
          timeout: 180000,
          params: payload
        })
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getContractProposalByConstruction({commit, dispatch}, construction_uuid) {
      return createAxios().get(`/commercial/contract-proposal/by-construction/${construction_uuid}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getContractProposalHasTravelOrFormulation({commit, dispatch}, construction_uuid) {
      return createAxios().get(`/commercial/contract-proposal/check-contract-has-travel-or-formulation-by-construction/${construction_uuid}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getConstructionInContracts({}, construction_name) {
      return createAxios().post(`/commercial/contract-proposal/by-construction-name/`, construction_name)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getHasInvoice({commit, dispatch}, construction_uuid) {
      return createAxios().get(`/commercial/contract-proposal/invoices/by-construction/${construction_uuid}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getAllByEntity({commit}, payload) {
      return createAxios().get(`${endPoint}/by-entity/${Number.isInteger(payload) ? payload : payload.entity_id }`)
        .then(({data}) => {
          (Number.isInteger(payload) || payload.manipulate_state) && commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getAllByEntities({commit}, params) {
      return createAxios().get(`${endPoint}/by-entities/`, {params: params})
        .then(({data}) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getGuarantorEntityContractProposalById(_, id) {
      return createAxios().get(`/commercial/contract-proposal/guarantor-entity-by-id/${id}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    storeGuarantorEntity(_, params) {
      return createAxios().post(`/commercial/contract-proposal/store-guarantor-entity/${params.id}/${params.guarantor_entity_id}/${params.guarantor_entity_created_by_user_id}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    deleteGuarantorEntityContractProposalById(_, id) {
      return createAxios().put(`/commercial/contract-proposal/delete-guarantor-entity/${id}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getAllContractsForActiveSellersAllocatedByEntity(_, entityId) {
      return createAxios().get(`${endPoint}/all-contracts-active-sellers-allocated/by-entity/${entityId}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    updateIgnoreSignatureStatus({ commit, state }, payload) {
      return createAxios().put(`${endPoint}/ignore-signature/${payload.id}`, payload)
        .then(({ data }) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}

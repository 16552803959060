<template>
  <div>
    <modal size="xl" :show="show">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <validation-observer
        v-slot="{ invalid, handleSubmit }"
        ref="formValidator"
      >
        <template>
          <card>
            <div class="rom form-row p-2">
              <div class="col-md-4">
                <label>
                  Prensa/Equipamento <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-md-8">
                <validation-provider rules="required">
                  <PuzlSelect
                    :items="$_equipments"
                    v-model="import_file.hardened_state_equipment_id"
                    label="equipment_type"
                    :loading="loadingEquipments"
                    :disabled="loadingEquipments"
                  />
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <div class="col-md-12 px-2 mt-4">
                <validation-provider rules="required">
                  <InputFile :accept="'.txt'" :maxFiles="1" ref="files"/>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0 float-right text-right">
              <div class="col-md-12 px-2 mt-4 float-right text-right">
                <base-button type="success"
                             @click.prevent="save()"
                             :disabled="invalid"
                             native-type="submit" :loading="loadingSave">
                  <i class="fa-solid fa-upload mr-n1 pr-2"></i> Importar
                </base-button>
              </div>
            </div>
          </card>
        </template>
        <template>
          <div class="row p-2">
            <div class="col-md-12">
              <hr>
            </div>
            <div class="col-md-12 table-responsive" style="height: 350px">
              <table class="table table-bordered table-sm">
                <thead>
                <tr>
                  <th class="text-center">Data de importação</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Período de resultados</th>
                  <th class="text-center">Integrados</th>
                  <th class="text-center">Erros</th>
                  <th class="text-center">Ignorados</th>
                  <th class="text-center">Importado</th>
                  <th class="text-center">Resumo</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!$_imports">
                  <th colspan="3">
                    <PuzlEmptyData :with-card="false"/>
                  </th>
                </tr>
                <tr v-else v-for="item in $_imports">
                  <th class="text-center">
                    {{ item.created_at | parseDate('DD/MM/YYYY HH:mm') }}

                    <el-popover
                      class="p-0 float-right pr-0"
                      trigger="hover"
                    >
                      <div>
                              <span>
                         {{item.user_name}}
                              </span>
                      </div>
                      <base-button
                        slot="reference"
                        class="p-0 m-0 shadow-none"
                        outiline
                        size="sm"
                        type="secundary"
                      >
                        <img
                          src="/img/icons/icons8/ios/user-male-circle--v1.png"
                          width="22"
                          height="22"
                        />
                      </base-button>
                    </el-popover>
                  </th>
                  <template v-if="item.status">
                    <th class="text-success text-center">Concluído</th>
                  </template>
                  <template v-else>
                    <th class="text-warning text-center">Em andamento</th>
                  </template>
                  <template v-if="item.start_at">
                    <th class="text-center">
                      {{ item.start_at | parseDate('DD/MM/YYYY') }} - {{ item.end_at | parseDate('DD/MM/YYYY') }}
                    </th>
                  </template>
                  <template v-else>
                    <th class="text-center">-</th>
                  </template>
                  <th class="text-center">
                    {{ item.total || '-' }}
                  </th>
                  <th class="text-center text-danger">
                    {{ item.failed || '-' }}
                  </th>
                  <th class="text-center text-muted">
                    {{ item.bypass || '-' }}
                  </th>
                  <th class="text-center">
                    <img @click.prevent="handleDownload(item.file_path, item.id, 1)" src="/img/icons/icons8/ios/txt.png"
                         class="pointer" style="width: 16px">
                  </th>
                  <th class="text-center">
                    <img @click.prevent="handleDownload(item.resume_file_path, item.id, 2)" src="/img/icons/excel.png"
                         class="pointer" style="width: 16px;">
                  </th>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
        <div class="modal-footer">
          <base-button @click.prevent="cancel" type="danger"
                       style="width: 120px; display: flex; align-items: center;">
            <img class="float-right pointer mr-2" width="14" src="/img/icons/icons8/ios/cancel.png">
            Cancelar
          </base-button>
        </div>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe"
import PuzlSelect from "@/components/PuzlSelect";
import PuzlEmptyData from "../../../../../components/PuzlEmptyData.vue";
import {base_url_ms} from "@/plugins";
import InputFile from '@/components/Utils/InputFile';

export default {
  components: {
    PuzlEmptyData,
    PuzlSelect,
    InputFile,
  },
  name: "ModalDefaultMolding",
  data() {
    return {
      loadingSave: false,
      loadingImports: true,
      loadingEquipments: true,
      show: false,
      base_url_ms: base_url_ms(),
      import_file: {
        hardened_state_equipment_id: null,
      },
      equipment_id: null,
      title: 'Importação de arquivo de resultado de ensaios'
    }
  },
  computed: {
    ...mapGetters({
      $_equipments: "hardenedStateTestEquipment/fetch",
      $_imports: "hardenedStateTestImport/fetch",
    }),
  },
  methods: {
    cancel() {
      this.show = false
    },
    setAll() {
      const index = this.equipment.company_plant_ids.findIndex((item) => item == 'all');
      if (this.equipment.company_plant_ids.length > 1 && index === 0) {
        return this.equipment.company_plant_ids.splice(0, 1)
      }
      if (this.equipment.company_plant_ids.length > 1 && this.equipment.company_plant_ids.includes("all")) {
        this.equipment.company_plant_ids = []
        this.equipment.company_plant_ids.push("all")
      }
    },
    /**
     *
     * @param url
     */
    handleDownload(url, id, type) {
      if (!url) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Aviso',
          text: `Não foi possível realizar o download. Processo em andamento`,
        })
      }
      let name = null
      switch (type) {
        case 1:
          name = `Arquivo de importação ${id}.txt`
          break;
        case 2:
          name = `Relatório de importação ${id}.xlsx`
          break;
      }
      const path_url = this.base_url_ms + 'download-s3?url=' + url
      this.$store.dispatch('exports/download', path_url).then(async response => {
        let blob = new Blob([response]);
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", name);
        await link.click()
      });
    },
    save() {
      this.loadingSave = true
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      let loader = this.$loading.show()
      this.import_file.file = this.$refs.files.fileRecords[0].file
      this.$store.dispatch('hardenedStateTestImport/add', this.import_file).then((response) => {
        this.$notify({
          type: 'success',
          message: response.message
        });
      }).finally(() => {
        loader.hide()
        this.close()
        this.loadingSave = false
      })
    },
    close() {
      this.show = false
    },
    handleCreateModal() {
      this.loadData()
      this.import_file.hardened_state_equipment_id = null
      this.show = true
    },
    loadData() {
      this.loadingPlants = true;
      this.$store.dispatch('plant/getPlantsSimplified').then(() => {
        this.loadingPlants = false;
      });
      this.loadingImports = true;
      this.$store.dispatch('hardenedStateTestImport/fetchItems').then(() => {
        this.loadingImports = false;
      });
      this.loadingEquipments = true;
      this.$store.dispatch('hardenedStateTestEquipment/fetchItems').then(() => {
        this.loadingEquipments = false;
      });
    },
  }
}
</script>

<style scoped>

</style>

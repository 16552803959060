<template>
  <div>
    <AppFixedPageTitle title="Equipamentos" icon="/img/icons/icons8/ios/concrete-pump-white.png" />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar :searchBarFilter.sync="searchBarFilter" :isLoading="$_equipment_is_listing"
          @onSearchClick="listItems" @onClearClick="clearFilter" :showCompanyPlants="true">
          <AppSearchBarFilterSection name="status" icon="/img/icons/icons8/ios/progress-indicator_grey.png">
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label class="form-control-label fs-11 new-default-gray-font font-weight-400">
                Selecione
              </label>
              <PuzlSelect style="width: 100%" v-model.lazy="filter.status" :items="[
                { id: null, name: 'Todos' },
                { id: 8, name: 'Recebido e aprovado' },
                { id: 9, name: 'Negado' },
              ]" :disableBoxShadow="true" placeholder="Status"
                class="select-sm col-md-12 px-0 new-default-black-font" />
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton @click.prevent="$router.push('/equipment/equipment/create')" text="novo"
            type="success" icon="/img/icons/plus-math--v1-white.png" />
          <AppPageHeaderActionsButton text="Cadastros" type="warning" :isDropdown="true"
            icon="/img/icons/plus-math--v1-white.png">
            <AppPageHeaderActionsButtonDropItem text="Categoria"
              @click.prevent="$router.push('/equipment/equipment/category')" />
            <AppPageHeaderActionsButtonDropItem text="Tipo de equipamento"
              @click.prevent="$router.push('/equipment/equipment/type')" />
            <AppPageHeaderActionsButtonDropItem text="Situação do equipamento"
              @click.prevent="$router.push('/equipment/equipment/situation')" />
          </AppPageHeaderActionsButton>
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect :items="tabSelectItems" @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="(type) => listType = type">
      <AppSelect v-model="orderBy.selected" :items.sync="orderBy.items" @onSelectItemClick="listItems(false)"
        variant="text-only" />
    </AppTabSelect>
    <div class="container-fluid mt-4">
      <EquipmentListPageCards @onUpdateEquipmentSituationClick="updateEquipmentSituation"
        @onEditItemClick="listItems" v-if="listType === 'cards'" />
      <EquipmentListPageTable @onUpdateEquipmentSituationClick="updateEquipmentSituation"
        @onEditItemClick="listItems" v-else />
      <AppViewTrigger v-if="!$_equipment_is_listing" @onIntersected="listItems(true)" />
      <PuzlEmptyData v-if="!$_equipment_is_listing && !$_equipment_listed.items.length" />
    </div>
  </div>
</template>

<script setup>
//#region Imports
import { reactive, ref, computed, onMounted, onUnmounted } from 'vue';
import {
  AppFixedPageTitle,
  AppPageHeader,
  initSearchBarFilterType,
  SearchBarFilterType,
  AppSearchBar,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppViewTrigger,
  AppTabSelect,
  AppSelect,
  AppPageHeaderActionsButtonDropItem,
  TabSelectItemType,
  AppSearchBarFilterSection,
} from '../../../../../../components/AppGlobal';
import financialApprovalStore from "../../store/equipmentStore";
import { initEquipmentListFilterType, EquipmentListFilterType } from '../../types';
import { dialogs, date, moneyToFloat, strToNum } from '../../../../../../helpers';
import EquipmentListPageCards from './EquipmentListPageCards.vue';
import EquipmentListPageTable from './EquipmentListPageTable.vue';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import EquipmentTypeFilterStatusEnum from "../../enums/EquipmentTypeFilterStatusEnum";
import PuzlSelect from "@/components/PuzlSelect";
//#endregion

//#region ComponentRefs

//#endregion

//#region Data
/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());

/** @type {EquipmentListFilterType} - Filtro principal */
const filter = reactive(initEquipmentListFilterType());

const currentDate = date.make().format(date.FORMAT.ISO_8601);
const listType = ref('cards');


const typeColors = {
  neutral: "light",
  negative: "danger",
  approved: "approved",
  positive: "success",
};

let money = {
  decimal: ",",
  thousands: ".",
  prefix: "",
  suffix: "",
  precision: 2,
  masked: false
}

const orderBy = reactive({
  items: [
    {
      id: 0,
      name: "PADRÃO",
      selected_name: "ORDENAR",
      filter: 'created.asc',
    },
    {
      id: 1,
      name: "DO MAIS NOVO PARA O MAIS VELHO",
      selected_name: "MAIS NOVO",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: 'created.desc',
    },
    {
      id: 2,
      name: "DO MAIS VELHO PARA O MAIS NOVO",
      selected_name: "MAIS VELHO",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: 'date_at.asc',
    }
  ],
  selected: 0,
});
//#endregion

//#region Computeds
/** Store Getters */
const $_equipment_listed = computed(() => financialApprovalStore.getters.getListed());
const $_equipment_is_listing = computed(() => financialApprovalStore.getters.getIsListing());

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: 'Todos',
      selected: filter.status === null,
    },
    {
      id: EquipmentTypeFilterStatusEnum.keys.ACTIVE,
      name: EquipmentTypeFilterStatusEnum.getTitle(EquipmentTypeFilterStatusEnum.keys.ACTIVE),
      selected: filter.status === EquipmentTypeFilterStatusEnum.keys.ACTIVE,
    },
    {
      id: EquipmentTypeFilterStatusEnum.keys.INACTIVE,
      name: EquipmentTypeFilterStatusEnum.getTitle(EquipmentTypeFilterStatusEnum.keys.INACTIVE),
      selected: filter.status === EquipmentTypeFilterStatusEnum.keys.INACTIVE,
    },
    {
      id: EquipmentTypeFilterStatusEnum.keys.UNAVAILABLE,
      name: EquipmentTypeFilterStatusEnum.getTitle(EquipmentTypeFilterStatusEnum.keys.UNAVAILABLE),
      selected: filter.status === EquipmentTypeFilterStatusEnum.keys.UNAVAILABLE,
    }
  ]; d
});

//#endregion

//#region Methods
/**
 * Atualizar status do registro
 * @param {number} equipmentId
 * @param {number} situationId
 */
async function updateEquipmentSituation(equipmentId, situationId) {
  await financialApprovalStore.actions.updateEquipmentSituation(equipmentId, situationId);
  dialogs.notify();
  listItems();
};

/**
 * @param {TabSelectItemType} item
 */
function onTabSelectItemClick(item) {
  if (filter.status !== item.id) {
    filter.status = item.id;
    listItems();
  };
};

/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();

  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilters = withStoreFilters ? financialApprovalStore.getters.getPageState() : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);

  // Listar itens
  if (isRefreshList) {
    listItems();
  }
};

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return {
    ...initSearchBarFilterType(),
  }
};

/**
 * Padrão do filtro principal
 * @returns {EquipmentListFilterType}
 */
function defaultFilter() {
  return initEquipmentListFilterType();
};

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
function listItems(isAccumulateItems = false) {
  prepareFilter();
  financialApprovalStore.actions.list(filter, isAccumulateItems);
};

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.page = "";
  filter.custom_search.values = searchBarFilter.custom_search_values;
  filter.company_plant_id = searchBarFilter.company_plant_selected;
  filter.orderBy = orderBy.items[orderBy.selected].filter;
};
//#endregion

//#region Lifecycle
onMounted(() => {
  clearFilter(true, true);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  financialApprovalStore.mutations.resetStates();
  financialApprovalStore.mutations.setPageState({ filter, searchBarFilter });
});
//#endregion
</script>

<style scoped></style>

import { render, staticRenderFns } from "./IntegrationListPage.vue?vue&type=template&id=0b2f98b8&scoped=true"
import script from "./IntegrationListPage.vue?vue&type=script&setup=true&lang=js"
export * from "./IntegrationListPage.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b2f98b8",
  null
  
)

export default component.exports
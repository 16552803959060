<template>
  <div>
    <modal size="sm" :show.sync="modal.create" :backgroundColor="'#F2F4F9'">
      <template slot="header">
        <div class="d-flex align-items-center container-fluid" style="gap: 12px; margin-left: -28px;">
          <div>
            <img src="/img/icons/icons8/ios/add-primary.png" height="28" width="28">
          </div>
          <span class="text-title" style="font-size: 15.9px;">
            {{ modal.title }}
          </span>
        </div>
      </template>
      <template slot="close-button">
        <AppButton
          icon="close"
          width="auto"
          type="link"
          size="sm"
          :hasOutline="true"
          @click="closeModal('create')"
        />
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div v-show="isLoading">
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 m-0 p-0 mb-1 col-form-label form-control-label">
                  <SkeletonPuzl type="button"></SkeletonPuzl>
                </label>
                <div class="col-md-3">
                  <SkeletonPuzl type="button"></SkeletonPuzl>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 m-0 p-0 mb-1 col-form-label form-control-label">
                  <SkeletonPuzl type="button"></SkeletonPuzl>
                </label>
                <div class="col-md-3">
                  <SkeletonPuzl type="button"></SkeletonPuzl>
                </div>
              </div>
            </div>

            <div style="border: 1px solid #e0e0e0; border-radius: 5px; padding: 10px; margin: 15px; margin-top: 22px;">
              <div class="label-title mb-3">
                <img height="25" src="/img/icons/icons8/ios/slider.png" class="mr-2" />
                % Sobre o custo fixo
              </div>
              <hr class="my-1 mb-3" />
              <div style="height: 40vh; overflow-x: auto" class="pl-4">
                <div v-for="(item, index) in list" :key="index">
                  <div class="form-group row m-0 p-0 mb-1">
                    <div class="col-8">
                      <div class="row">
                        <div class="col-12">
                          <label class="text-center mt-n3 col-form-label form-control-label label-item">
                            {{ item.label }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 text-center" style="margin-top: 5px;">
                      <base-input input-group-classes="input-group-sm no-box-shadow">
                        <input-limit
                          :start_value="item.percentage"
                          :options="{
                            min: -50,
                            max: 99,
                          }"
                          v-model="item.percentage"
                          @value="item.percentage = $event"
                        >
                          <small>%</small>
                        </input-limit>
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer mt-n3">
              <AppButton
                label="Cancelar"
                type="danger"
                :isBlock="false"
                :hasOutline="true"
                @click="closeModal('create')"
              />
              <AppButton
                label="Salvar"
                type="success"
                :isBlock="false"
                :hasOutline="true"
                :isLoading="loading"
                :isDisabled="invalid"
                @click.prevent="handleSubmit(store)"
              />
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import SkeletonPuzl from '@/components/SkeletonPuzl';
import {checkNumberValue} from "@/helpers";
import {mask1} from "@/plugins";
import InputLimit from "@/components/Utils/InputLimit.vue";
import { AppButton } from "@/components/AppGlobal";

export default {
  components: {
    InputLimit,
    SkeletonPuzl,
    AppButton
  },
  name: "ModalCreateLevelResponsibility",
  data() {
    return {
      modal: {
        title: '',
        create: false,
      },
      filter: {},
      validated: false,
      list: {},
      percentage: {},
      isLoading: false,
      loading: false,
    };
  },
  methods: {
    closeModal(name) {
      this.modal[name] = false
    },
    openModal(name) {
      this.modal[name] = true
    },
    handleCreate() {
      this.init();
      this.modal.title = 'Acréscimo sobre nível de aplicação';
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        return checkNumberValue(mask1(value.toString(), decimals, '.', true), min, max);
      }
      return checkNumberValue(value, min, max);
    },
    store() {
      const percentages = {};
      const lists = this.list;
      lists.forEach(function (item, index) {
        percentages[item.value] = item.percentage || 0
      });
      this.$Progress.start();
      this.loading = true;
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('mixConcretePiece/updateLevelResponsibilityPercentage', {percentages: percentages})
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message
          })
          this.closeModal('create');
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.$notify({type: error.response.data.error_type, message: error.response.data.message});
          this.loading = false;
        });
    },
    init(filter = {}) {
      this.filter = filter;
      this.$Progress.start();
      this.isLoading = true;
      let loader = this.$loading.show()
      this.$store.dispatch('mixConcretePiece/getFeatureLevels').then(response => {
        this.isLoading = false
        this.list = response.data
        loader.hide()
        this.openModal('create');
        this.$Progress.finish()
      })
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
    EventBus.$on("handleCreateLevelResponsibility", () => {
      this.handleCreate();
    });
  }
};
</script>

<style scoped>
.label-title {
  width: 250px;
  height: 19px;
  gap: 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
}
.label-item {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
}

/** remove sombra dos input */
input.form-control.no-box-shadow {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

/** remove sobra do <template slot="append"> */
.no-box-shadow input, .no-box-shadow div span.input-group-text {
  box-shadow: none !important;
}
</style>
<template>
  <div>
    <AppFixedPageTitle
      title="Agenda de ensaios"
      icon="/img/icons/icons8/ios/physics_white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :showCompanyPlants="true"
          :isLoading="loadingSkeleton"
          @onSearchClick="init({})"
          @onClearClick="clearFilter"
        >
          <AppSearchBarFilterSection
            name="Dados"
            icon="/img/icons/icons8/ios/info-squared_gray.png"
          >
            <div class="col-md-12 mt-1 mb-3 px-0 text-left">
              <label class="form-control-label">
                Idade do ensaio
              </label>
              <div class="row">
                <div class="col-6 text-left pr-1">
                  <input v-model="filter.test_age.min" placeholder="de" class="form-control btn-first-child"/>
                </div>
                <div class="col-6 pl-1">
                  <input v-model="filter.test_age.max" placeholder="até" class="form-control btn-first-child"/>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label class="form-control-label">
                Número da O.S
              </label>
              <div class="row">
                <div class="col-6 text-left pr-1">
                  <input v-model="filter.os_number.min" placeholder="de" class="form-control btn-first-child"/>
                </div>
                <div class="col-6 pl-1">
                  <input v-model="filter.os_number.max" placeholder="até" class="form-control btn-first-child"/>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label class="form-control-label">
                Número da NF-E
              </label>
              <div class="row">
                <div class="col-6 text-left pr-1">
                  <input v-model="filter.nfe_number.min" placeholder="de" class="form-control btn-first-child"/>
                </div>
                <div class="col-6 pl-1">
                  <input v-model="filter.nfe_number.max" placeholder="até" class="form-control btn-first-child"/>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label class="form-control-label">
                Número do código
              </label>
              <div class="row">
                <div class="col-6 text-left pr-1">
                  <input v-model="filter.code_number.min" placeholder="de" class="form-control btn-first-child"/>
                </div>
                <div class="col-6 pl-1">
                  <input v-model="filter.code_number.min" placeholder="até" class="form-control btn-first-child"/>
                </div>
              </div>
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            @click.prevent="$router.push({name: 'technology.report.index'})"
            text="Remessas"
            type="primary"
            icon="/img/icons/icons8/ios/impossible-shapes.png"
          />
          <AppPageHeaderActionsButton
            text="Cadastros"
            :isDropdown="true"
            type="warning"
            icon="/img/icons/product-documents.png"
          >
            <AppPageHeaderActionsButtonSectionDivider
              title="Geral"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Estado Fresco"
              icon="/img/icons/icons8/ios/vlc.png"
              @click.prevent="$router.push({name: 'technology.fresh-state.index'})"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Estado endurecido"
              icon="/img/icons/icons8/ios/impossible-shapes_primary.png"
              @click.prevent="$router.push({name: 'technology.hardened-state.index'})"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Anomalias"
              icon="/img/icons/icons8/ios/question--v1.png"
              @click.prevent="$router.push({name: 'technology.anomaly.index'})"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Padrão de moldagem"
              icon="/img/icons/icons8/ios/impossible-shapes_primary.png"
              @click.prevent="handleShowModalDefaultMolding"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Cadastrar equipamento"
              icon="/img/icons/concrete-pump.png"
              @click.prevent="handleShowNewEquipment"
            />
          </AppPageHeaderActionsButton>
          <AppPageHeaderActionsButton
            text="Relatório"
            :isDropdown="true"
            type="dark"
            icon="/img/icons/icons8/ios/graph-report.png"
          >
            <AppPageHeaderActionsButtonSectionDivider
              title="Geral"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Resultado"
              icon="/img/icons/excel.png"
              @click.prevent="showModalTestReport"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Desempenho do concreto"
              icon="/img/icons/pdf-v2.png"
              @click.prevent="$refs.modalSearchContract.openModal(false, null, null)"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Importar resultados"
              icon="/img/icons/icons8/ios/svgs/import.svg"
              @click.prevent="$refs.ModalCreateImport.handleCreateModal()"
            />
          </AppPageHeaderActionsButton>
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>

    <AppTabSelect
      :items="tabSelectItems"
      :isShowViewTypes="true"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="(type) => listType = type"
    >
      <AppSelect
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="init({})"
        placeholder="ORDENAR"
        variant="text-only"
      />
    </AppTabSelect>

    <div class="container-fluid">
      <div>
      </div>
      <!-- Cards -->
      <Cards 
        v-if="listType === 'cards'"
        :hardened_states="hardened_states"
        :loadingSkeleton="loadingSkeleton"
        :technicalPermissions="technicalPermissions"
        @handleEdit="handleEdit"
      />
      <!-- Table -->
      <Table 
        v-if="listType === 'table'"
        :hardened_states="hardened_states"
        :loadingSkeleton="loadingSkeleton"
        :technicalPermissions="technicalPermissions"
        @handleEdit="handleEdit"
        @updateItem="updateItem"
      />
      <div
        class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <ModalEdit :refreshData="refreshData" ref="modalEdit"/>
      <ModalReport :show.sync="showModalReport" @close="showModalReport = false"/>
      <ModalSearchContract @setContractProposal="showModalReport = true" ref="modalSearchContract"/>
      <ModalTestReport ref="modalTestReport"/>
      <PuzlEmptyData v-if="!hardened_states.length && !loadingSkeleton"></PuzlEmptyData>
      <ModalDefaultMolding ref="ModalDefaultMolding"/>
      <ModalCreateEquipment ref="ModalCreateEquipment"/>
      <ModalCreateImport ref="ModalCreateImport"/>
    </div>
  </div>
</template>

<script>
import ModalSearchContract from "@/views/Modules/Operational/Schedule/Shared/_ModalSearchContract";
import {mapGetters} from 'vuex'
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import ModalEdit from "./Report/Shared/HardenedState/Shared/ModalEdit";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import cursorPaginate from "@/mixins/cursorPaginate";
import PuzlEmptyData from "../../../../components/PuzlEmptyData";
import ModalReport from './Shared/_ModalReport';
import ModalTestReport from "./_ModalTestReport";
import InputDatePicker from "@/components/InputDatePicker";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import Cards from "./Shared/_Cards";
import Table from "./Shared/_Table";
import {setPreferredViewMode, getPreferredViewMode} from '@/plugins/session';
import { debounce } from "@/plugins";
import ModalCreateEquipment from './Shared/_ModalCreateEquipment.vue';
import ModalDefaultMolding from './Shared/_ModalDefaultMolding.vue';
import ModalCreateImport from './Shared/_ModalCreateImport.vue';
import {
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppPageHeaderActionsButtonSectionDivider,
  AppPageHeaderActionsButtonDropItem,
  AppSearchBar,
  AppSearchBarFilterSection,
  AppSelect,
  AppTabSelect, AppViewTrigger, initSearchBarFilterType
} from "@/components/AppGlobal";
import { TestListFilterType, initTestListFilterType } from "./Types";
import { date } from "../../../../helpers";

export default {
  name: "IndexUser",
  mixins: [cursorPaginate],
  components: {
    PuzlEmptyData,
    MultiFilter,
    InputDatePicker,
    SkeletonPuzlGrid,
    ModalEdit,
    ModalReport,
    ModalSearchContract,
    ModalTestReport,
    BaseButtonHoverable,
    Cards,
    Table,
    ModalDefaultMolding,
    ModalCreateEquipment,
    ModalCreateImport,
    AppPageHeader,
    AppPageHeaderActionsButtonSectionDivider,
    AppPageHeaderActionsButtonDropItem,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppTabSelect,
    AppFixedPageTitle,
    AppSelect,
    AppSearchBar,
    AppSearchBarFilterSection,
    AppViewTrigger,
    initSearchBarFilterType,
  },
  computed: {
    ...mapGetters({
      'report': 'testReport/show',
      'hardened_states': 'travelConfiguration/showHardenedStates',
      $_user: 'auth/getUser',
    }),
    tabSelectItems() {
      return [
        {
          id: null,
          name: 'Todos',
          selected: 
            this.filter.molded === null && 
            this.filter.pending === null && 
            this.filter.unmolded === null,
        },
        {
          id: 'molded',
          name: "Ensaiados",
          selected: this.filter.molded == 1,
        },
        {
          id: 'pending',
          name: "Pendentes",
          selected: this.filter.pending == 1,
        },
        {
          id: 'unmolded',
          name: "Descartados",
          selected: this.filter.unmolded == 1,
        },
      ];
    },
  },
  data() {
    return {
      range: {
        start: new Date(),
        end: new Date(),
      },
      filter: initTestListFilterType(),
      currentDate: date.make().format(date.FORMAT.FULL_DATE),
      technicalPermissions: {},
      showModalReport: false,
      listType: 'cards',
      disruptions: [
        {
          id: 'A',
          text: 'Cônica e cônica afastada 25mm do capeamento'
        },
        {
          id: 'B',
          text: 'Cônica e bipartida e cônica e com mais de uma partição'
        },
        {
          id: 'C',
          text: 'Colunar com formação de cones'
        },
        {
          id: 'D',
          text: 'Cônica e cisalhada'
        },
        {
          id: 'E',
          text: 'Cisalhada'
        },
        {
          id: 'F',
          text: 'Fraturas no topo e/ou na base abaixo do capeamento'
        },
        {
          id: 'G',
          text: 'Similar ao tipo f com fraturas próximas ao topo'
        },
      ],
      healths: [
        'Total',
        'Parcial',
        'Nenhuma'
      ],
      format_mixe: period_types,
      added: false,
      loadingSkeleton: false,
      searchBarFilter: initSearchBarFilterType(),
      orderBy: {
        selected: 0,
        items: [
          {
            id: 0,
            name: "PADRÃO",
            selected_name: "ORDENAR",
            filter: [{ 
              column: "schedule_travels.id", 
              is_desc: false,
            }],
          },
        ]
      },
    }
  },
  methods: {
    updateItem(newItem) {
      let params = {...newItem}
      if (params.date_at) {
        params.date_at = this.$helper.parseDate(params.date_at, 'DD-MM-YYYY H:mm:ss')
      }
      params.provided_at = this.$helper.parseDate(params.provided_at, 'DD-MM-YYYY H:mm:ss')
      params.old_period = params.schedule_period
      params.old_result_expected = params.result_expected
      params.period = params.schedule_period
      params.period_type = params.schedule_period_type

      this.$store.dispatch('travelConfiguration/updateHardenedState', params)
        .then(response => {
            this.$notify({type: 'success', message: response.message});
            this.refreshData();
          }
        ).catch((error) => {
        this.$notify({
          type: response.error_type,
          message: response.message,
        });
      });
    },
    handleShowModalDefaultMolding() {
      this.$refs.ModalDefaultMolding.handleCreateModal()
    },
    handleShowNewEquipment() {
      this.$refs.ModalCreateEquipment.handleCreateModal()
    },
    setViewType(type) {
      this.listType = type;
      setPreferredViewMode(type);
    },
    updated: debounce(function () {
      this.init({})
    }, 200),
    /**
     * Responsável por esconder cards que já tiveram resultado real atualizado
     */
    refreshData() {
      if (this.filter.pending) this.init(this.filter);
    },
    fetchTechnicalPermissions(userId) {
      this.$store.dispatch('technicalPermission/getByUserId', userId).then((response) => {
        this.technicalPermissions = response.data
      });
    },
    clearFilter(isRefreshList = true) {
      // Reseta o filtro completamente
      this.searchBarFilter = JSON.parse(JSON.stringify(this.defaultSearchBarFilter()));
      this.filter = JSON.parse(JSON.stringify(this.defaultFilter()));

      this.orderBy.selected = 0;
      if (isRefreshList) {
        this.init({});
      }
    },
    classResult(item) {
      if (!item.real_period || !item.result) {
        return
      }
      /** idade real **/
      let real_period = item.real_period.replace(/[a-zA-Z]{1,3}/, '')
      real_period = real_period.slice(0, 2)
      real_period = real_period.split(' ')
      real_period = Number(real_period[0])
      /** resultado **/
      const result = Number(item.result)
      /** item da responsabilidade **/
      let item_period = item.item_description.replace(/[a-zA-Z]{1,3}/, '')
      item_period = Number(item_period.trim())
      /** ${item.period} idade da responsabilidade **/
      if (real_period < item.period) {
        // item da responsabilidade
        if (result > item_period) {
          return 'text-primary'
        }
        return null
      }
      if (real_period >= item.period) {
        if (result >= item_period && real_period >= item.period) {
          return 'text-success'
        }
        if (result < item_period && real_period >= item.period) {
          return 'text-danger'
        }
      }
    },
    handleFilterDate(filter) {
      this.range = filter
      this.init({})
    },
    formatPeriod(period) {
      const date_splited = period.split(' ')
      for (let date of date_splited) {
        if (date.includes('d')) {
          date = date.replace('d', '')
          return Math.round(date) > 1 ? `${date} dias` : `${date} dia`
        }
        if (date.includes('h')) {
          date = date.replace('h', '')
          return date > 1 ? `${date} horas` : `${date} hora`
        }
        if (date.includes('m')) {
          date = date.replace('min', '')
          date = date.replace('m', '')
          date = Math.round(date) / 60
          if (date < 1) {
            date = Math.round(date * 60)
            return `${date} minutos`
          }
          return date > 1 ? `${date} horas` : `${date} hora`
        }
      }
    },
    handleEdit(item) {
      this.$refs.modalEdit.openModal(item, item.schedule.contract_proposal_formulation, false, this.technicalPermissions)
    },
    prepareFilter() {
      this.filter.custom_search.values = this.searchBarFilter.custom_search_values;
      this.filter.company_plant_id = this.searchBarFilter.company_plant_selected;
      this.filter.range.start = this.searchBarFilter.range.start;
      this.filter.range.end = this.searchBarFilter.range.end;
      this.filter.order_by = this.orderBy.items[this.orderBy.selected].filter;
    },
    init(filter = null) {
      this.startCursor(filter);
      this.prepareFilter();
      this.$Progress.start();

      if (this.searchBarFilter.range.selected == 1) { // data de concretagem
        this.filter.concreting_date = 1;
      } else {
        this.filter.concreting_date = 0;
      }

      this.$store.dispatch('travelConfiguration/fetchItemsPaginate',
        {
          schedule_travel_uuid: this.$route.params.travel_uuid,
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then(response => {
          this.modal = true
          this.$Progress.finish()
          this.resolveCursor(response)
        })
        .catch(error => {
          this.$Progress.finish()
          this.resolveCursor()
        })
    },
    showModalTestReport() {
      this.$refs.modalTestReport.openModal();
    },
    handleViewTypeChange(type) {
      this.listType = type;
    },
    /**
     * @param {TabSelectItemType} item
     */
    onTabSelectItemClick(item) {
      // Verifica se o item já está filtrado
      const isAlreadyFiltered =
        (item.id === null && this.filter.molded === null && this.filter.pending === null && this.filter.unmolded === null) ||
        (item.id === 'molded' && this.filter.molded === 1) ||
        (item.id === 'pending' && this.filter.pending === 1) ||
        (item.id === 'unmolded' && this.filter.unmolded === 1);

      if (isAlreadyFiltered) {
        return;
      }

      // Reseta todos os filtros
      this.filter.molded = null;
      this.filter.pending = null;
      this.filter.unmolded = null;

      // Ativa o filtro selecionado
      if (item.id === 'molded') {
        this.filter.molded = 1;
      } else if (item.id === 'pending') {
        this.filter.pending = 1;
      } else if (item.id === 'unmolded') {
        this.filter.unmolded = 1;
      }

      this.init({});
    },
    /**
     * Padrão do filtro principal
     * @returns {TestListFilterType}
     */
    defaultFilter() {
      return {
        ...initTestListFilterType(),
      };
    },
    /**
     * Padrão de filtro da barra de pesquisa
     * @returns {SearchBarFilterType}
     */
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
        range: {
          items: [
            { id: 0, name: "Ensaio", selected_name: "Ensaio" },
            { id: 1, name: "Concretagem", selected_name: "Conc." },
          ],
          selected: 0,
          start: this.filter.range.start ?? this.currentDate,
          end: this.filter.range.end ?? this.currentDate,
        },
      };
    },
  },
  mounted() {
    this.clearFilter();
    this.listType = getPreferredViewMode() ?? 'cards';
    this.init({});
    this.fetchTechnicalPermissions(this.$_user.id);
  }
};
</script>

<style scoped>
.card {
  border-radius: 10px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
}

.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>

<template>
  <div>
    <AppFixedPageTitle title="Integrações" icon="/img/icons/icons8/ios/settings-3--v1.png" />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar :searchBarFilter.sync="searchBarFilter" :isLoading="$_integration_is_listing"
          @onSearchClick="listItems" @onClearClick="clearFilter" :showCompanyPlants="false">
          <AppSearchBarFilterSection name="dados" icon="/img/icons/icons8/ios/info-squared_gray.png">
            
          </AppSearchBarFilterSection>
          <AppSearchBarFilterSection name="Marcadores" icon="/img/icons/icons8/ios/push-pin_gray.png">
            
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
    </AppPageHeader>

    <AppTabSelect :items="tabSelectItems" @onTabSelectItemClick="onTabSelectItemClick" :isShowViewTypes="false"
      @onViewTypeChange="(type) => listType = type">
      <AppSelect v-model="orderBy.selected" :items.sync="orderBy.items" @onSelectItemClick="listItems(false)"
        variant="text-only" />
    </AppTabSelect>
    <div class="container-fluid">
      <IntegrationListPageTable @onEditItemClick="listItems" />
      <AppViewTrigger v-if="!$_integration_is_listing" @onIntersected="listItems(true)" />
      <PuzlEmptyData v-if="!$_integration_is_listing && !$_integration_listed.items.length" />
    </div>
  </div>
</template>

<script setup>
//#region Imports
import { reactive, ref, computed, onMounted, onUnmounted } from 'vue';
import {
  AppFixedPageTitle,
  AppPageHeader,
  initSearchBarFilterType,
  SearchBarFilterType,
  AppSearchBar,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppViewTrigger,
  AppTabSelect,
  AppSelect,
  TabSelectItemType,
  AppSearchBarFilterSection,
} from '../../../../../../components/AppGlobal';
import IntegrationStore from "../../store/IntegrationStore";
import { initIntegrationListFilterType, IntegrationListPagerType, initIntegrationListPagerType } from '../../types';
import { date, moneyToFloat, strToNum } from '../../../../../../helpers';
import IntegrationListPageTable from './IntegrationListPageTable.vue';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import IntegrationStatusEnum from "../../enums/IntegrationStatusEnum";
import PuzlSelect from "@/components/PuzlSelect";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
//#endregion

//#region Data
/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());

/** @type {IntegrationListPagerType} - Filtro principal */
const filter = reactive(initIntegrationListFilterType());

const currentDate = date.make().format(date.FORMAT.ISO_8601);

const listType = ref('cards');

let money = {
  decimal: ",",
  thousands: ".",
  prefix: "",
  suffix: "",
  precision: 2,
  masked: false
}

const orderBy = reactive({
  items: [
    {
      id: null,
      name: "PADRÃO",
      selected_name: "ORDENAR",
      filter: 'created.asc',
    },
    {
      id: 1,
      name: "DO MAIS VELHO PARA O MAIS NOVO",
      selected_name: "MAIS VELHO",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [{
        column: "stock_locations.created_at",
        is_desc: false,
      }],
    },
    {
      id: 2,
      name: "DO MAIS NOVO PARA O MAIS VELHO",
      selected_name: "MAIS NOVO",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [{
        column: "stock_locations.created_at",
        is_desc: true,
      }],
    },
  ],
  selected: 0,
});
//#endregion

//#region Computeds
/** Store Getters */
const $_integration_listed = computed(() => IntegrationStore.getters.getListed());
const $_integration_is_listing = computed(() => IntegrationStore.getters.getIsListing());

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: 'Todos',
      selected: filter.status === null,
    },
    {
      id: IntegrationStatusEnum.keys.INTEGRATED,
      name: IntegrationStatusEnum.getTitle(IntegrationStatusEnum.keys.INTEGRATED),
      selected: filter.status === IntegrationStatusEnum.keys.INTEGRATED,
    },
    {
      id: IntegrationStatusEnum.keys.PENDING,
      name: IntegrationStatusEnum.getTitle(IntegrationStatusEnum.keys.PENDING),
      selected: filter.status === IntegrationStatusEnum.keys.PENDING,
    },
    {
      id: IntegrationStatusEnum.keys.ERROR,
      name: IntegrationStatusEnum.getTitle(IntegrationStatusEnum.keys.ERROR),
      selected: filter.status === IntegrationStatusEnum.keys.ERROR,
    },
  ];
});

//#endregion

//#region Methods
/**
 * @param {TabSelectItemType} item
 */
function onTabSelectItemClick(item) {
  if (filter.status !== item.id) {
    filter.status = item.id;
    listItems();
  };
};

/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();

  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilters = withStoreFilters ? IntegrationStore.getters.getPageState() : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);

  // Listar itens
  if (isRefreshList) {
    listItems();
  }
};

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return {
    ...initSearchBarFilterType(),
    range: {
      start: '2023-12-29T16:12:30-03:00',
      end: filter.range.end ?? currentDate,
    }
  }
};


/**
 * Padrão do filtro principal
 * @returns {IntegrationListPagerType}
 */
function defaultFilter() {
  return initIntegrationListPagerType();
};

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
function listItems(isAccumulateItems = false) {
  prepareFilter();
  IntegrationStore.actions.list(filter, isAccumulateItems);
};

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.page = "";
  filter.custom_search.values = searchBarFilter.custom_search_values;
  filter.range = searchBarFilter.range;
  filter.order_list_by = orderBy.items[orderBy.selected].filter;
};
//#endregion

//#region Lifecycle
onMounted(() => {
  clearFilter(true, true);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  IntegrationStore.mutations.resetStates();
  IntegrationStore.mutations.setPageState({ filter, searchBarFilter });
});
//#endregion
</script>

<style scoped></style>

const keys = Object.freeze({
  ACTIVE: 1,
  INACTIVE: 0,
  UNAVAILABLE: 2,
});

const getTitle = (value) => {
  switch (value) {
    case keys.ACTIVE:
      return "Ativos";
    case keys.INACTIVE:
      return "Inativos";
    case keys.UNAVAILABLE:
      return "Indisponíveis";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle,
};

import store from "../../../../shared/libraries/store";
import { createSource, findSource, listSources, removeSource, updateSource } from "../../../../shared/store";
import httpClient from "../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../shared/enums";
import { handleUnexpectedStatusCode } from "../../../../shared/services";
import {MdfeListFilterType} from "@/modules/fiscal/mdfe/types";
import {initMdfeListFilterType} from "@/modules/fiscal/mdfe/types/MdfeListFilterType";

/** URLS */
const urls = Object.freeze({
  BASE: "/operational/schedule/schedule-travel/mdfe+microservice",
});

/** KEYS */
const keys = Object.freeze({
  ITEM: "MDFE_ITEM",
  LISTED: "MDFE_LISTED",
  IS_LISTING: "MDFE_IS_LISTING",
  IS_FINDING: "MDFE_IS_FINDING",
  IS_SAVING: "MDFE_IS_SAVING",
  IS_REMOVING: "MDFE_IS_REMOVING",
  PAGE_STATES: "MDFE_PAGE_STATES",
});

/** GETTERS */
const getters = {
  /** @returns {?MdfeType} */
  getItem: () => store.getState(keys.ITEM),

  /** @returns {MdfeListFilterType} */
  getListed: () => store.getState(keys.LISTED) ?? initMdfeListFilterType(),

  /** @returns {boolean} */
  getIsListing: () => store.getState(keys.IS_LISTING) ?? false,

  /** @returns {{  searchBarFilter: SearchBarFilterType, filter: MdfeListFilterType }} */
  getPageState: () => store.getState(keys.PAGE_STATES),
}

/** MUTATIONS */
const mutations = {
  /**
   * @param {MdfeType} item
   * @returns {MdfeType}
   */
  setItem: (item) => store.commit(keys.ITEM, item),

  setListMerge: (listed) => store.commitListMerge(keys, listed),
  /**
   * @param {boolean} isSaving
   * @returns {boolean}
   */
  setIsSaving: (isSaving) => store.commit(keys.IS_SAVING, isSaving),

  /**
   * @param {MdfeListFilterType} listed
   * @returns {initBaseListFilterType}
   */
  setListed: (listed) => store.commit(keys.LISTED, listed),

  /**
   * @param {boolean} isListing
   * @returns {boolean}
   */
  setIsListing: (isListing) => store.commit(keys.IS_LISTING, isListing),

  /**
   * @param {boolean} isFinding
   * @returns {boolean}
   */
  setIsFinding: (isFinding) => store.commit(keys.IS_FINDING, isFinding),
}

/** ACTIONS */
const actions = {
  /**
   * Listar
   *
   * @param {MdfeListFilterType} filter - Filtro de listagem
   * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
   * @returns {Promise<?StockLocationListPagerType>} - Retorno paginado
   */
  list: async (filter, isAccumulateItems = false) => await listSources(filter, isAccumulateItems, mutations, getters, urls.BASE),

  /**
   * Deletar por ID
   *
   * @param {number} id
   * @returns {Promise<void>}
   */
  remove: async (id) => await removeSource(id, mutations, urls.BASE),

  cancel: async (id) => {
    try {
      mutations.setIsSaving(true);

      const url = `${urls.BASE}/cancel/${id}`;
      const res = await httpClient.post(url);
      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }
      const updated = res.data.data;
      // return mutations.setUpdatedItem(updated);
    } finally {
      mutations.setIsSaving(false);
    }
  },
  close: async (id) => {
    try {
      mutations.setIsSaving(true);

      const url = `${urls.BASE}/${id}`;
      const res = await httpClient.remove(url);
      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }
      const updated = res.data.data;
      // return mutations.setUpdatedItem(updated);
    } finally {
      mutations.setIsSaving(false);
    }
  },
}

export default {
  actions,
  getters,
  mutations,
};

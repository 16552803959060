<template>
  <div>
    <AppFixedPageTitle
      title="Informe de ensaios"
      icon="/img/icons/icons8/ios/physics_white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :showCompanyPlants="true"
          :isLoading="loadingSkeleton"
          @onSearchClick="init({})"
          @onClearClick="clearFilter"
          @onFilterDate="getWidgets()"
        >
          <AppSearchBarFilterSection
            name="Dados"
            icon="/img/icons/icons8/ios/info-squared_gray.png"
          >
            <div class="col-md-12 mt-1 mb-3 px-0 text-left">
              <div class="col-md-12 mt-1 mb-2 px-0 text-left">
                <label class="form-control-label">
                  Número da O.S
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <input v-model="filter.os_number.min" placeholder="de" class="form-control btn-first-child"/>
                  </div>
                  <div class="col-6 pl-1">
                    <input v-model="filter.os_number.max" placeholder="até" class="form-control btn-first-child"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label class="form-control-label">
                Número da NF-E
              </label>
              <div class="row">
                <div class="col-6 text-left pr-1">
                  <input v-model="filter.nfe_number.min" placeholder="de" class="form-control btn-first-child"/>
                </div>
                <div class="col-6 pl-1">
                  <input v-model="filter.nfe_number.max" placeholder="até" class="form-control btn-first-child"/>
                </div>
              </div>
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions :isBackButtonEnabled="true"></AppPageHeaderActions>
      </template>
    </AppPageHeader>
    
    <AppTabSelect
      :items="tabSelectItems"
      :isShowViewTypes="false"
      @onTabSelectItemClick="onTabSelectItemClick"
    >
    </AppTabSelect>

    <base-header class="bg-gray-content"></base-header>
    <div class="container-fluid">
      <div class="row card-wrapper">
        <SkeletonPuzlGrid v-show="loadingSkeleton" v-for="(index) in 3" :key="index"/>
      </div>
      <PuzlEmptyData v-if="!reports.length && !loadingSkeleton"/>
      <div class="row card-wrapper" v-if="!loadingSkeleton && reports.length > 0">
        <div class="col-lg-4" v-for="(report, index) in reports" :key="index">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-10">
                  <h4 style="font-size: 1.2rem;padding-top: -5px" class="font-weight-600 mt-n2">
                    <a class="mr-2" href="#" @click.prevent="setHardenedState(index)">
                      <badge
                        class="active-badge px-3 py-2 badge-mobile"
                        style="border-radius: 100px !important;"
                        :style="
                          report.hardened_state
                            ? 'color: #149E57; background-color: #f2f7f3;'
                            : 'color: #DB4539; background-color: #FCF3F3;'
                        "
                      >
                        <span v-if="report.hardened_state" class="font-weight-400" style="font-size: 12px;">
                          <img height="17" src="/img/icons/icons8/ios/impossible-shapes_primary.png" class="mr-1 mt-n1"/>
                          C. moldagem
                        </span>
                        <span v-else class="font-weight-400" style="font-size: 12px;">
                          <img height="17" src="/img/icons/icons8/ios/impossible-shapes-danger.png" class="mr-1 mt-n1"/>
                          S. moldagem
                        </span>
                      </badge>
                    </a>
                    <el-popover trigger="hover" placement="bottom" class="ml-1">
                      <span class="popover-title"> Informações </span>
                      <hr class="my-1 mb-3" />
                      <div style="border: 1px solid #e0e0e0; border-radius: 5px; padding: 10px 10px 5px 10px;" class="mb-2">
                        <div class="label-title-popover">
                          <img src="/img/icons/test-tube.png" width="25">
                          Traço
                        </div>
                        <div 
                          v-if="!technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                          style="width: 170px; word-break: break-word;"
                        >
                          <h5 class="text-danger mt-2 px-3" style="font: 9.5px;">
                            Sem permissão
                          </h5>
                          <div class="new-default-black-font px-3 mt-n1" style="font-size: 9.5px;">
                            Visualização indisponível, solicite permissão ao gestor.
                          </div>
                        </div>
                        <div 
                          v-if="technicalPermissions.is_view_technical_data_of_traces_in_test_results_launch"
                          class="border-3 p-2 mt-1"
                        >
                          <h5 class="mb-0 label-formulation-test-tube px-2">
                            {{ report.contract_proposal_formulation.title }}
                          </h5>
                          <div class="mt-2 mb-1 label-popover px-2">
                            {{ report.contract_proposal_formulation.body }}
                          </div>
                          <div class="mt-2 mb-1 label-popover px-2" v-if="report.contract_proposal_formulation.footer">
                            {{ report.contract_proposal_formulation.footer }} 
                          </div>
                          <div class="mt-2 mb-1 label-popover px-2" v-if="report.schedule.piece">
                            {{ report.schedule.piece }}
                          </div>
                        </div>
                      </div>
                      <div style="border: 1px solid #e0e0e0; border-radius: 5px; padding: 10px 10px 5px 10px;" class="mb-2">
                        <div class="label-title-popover">
                          <img src="/img/icons/chemical-plant-black.png" width="25">
                          Ponto de carga
                        </div>
                          <div class="mt-2 mb-1 px-2 label-popover">
                            {{ report.charge_point }}
                          </div>
                          <div class="mt-2 mb-1 px-2 label-popover">
                            {{ report.plant }}
                          </div>
                      </div>
                      <div style="border: 1px solid #e0e0e0; border-radius: 5px; padding: 10px 10px 5px 10px;" class="mb-2">
                        <div class="label-title-popover">
                          <img src="/img/icons/interstate-truck.png" width="25">
                          Motorista
                        </div>
                        <div class="mt-2 mb-1 px-2 label-popover">
                          {{ report.driver }}
                        </div>
                        <div class="mt-2 mb-1 px-2 label-popover">
                          {{ report.equipment.code }} ({{ report.equipment.plate }})
                        </div>
                      </div>
                      <base-button 
                        outline slot="reference"
                        size="sm"
                        type="secundary"
                        class="p-0 m-0 shadow-none text-indigo"
                      >
                        <img class="mr-1" src="/img/icons/info.png"  width="26" />
                      </base-button>
                    </el-popover>
                    <a class="ml-n1" href="#" @click.prevent="showAdjustments(report.travel_uuid)">
                      <img style="height: 1.5rem" class="pointer" src="/img/icons/icons8/ios/puzzle-primary.png"/>
                    </a>
                    <a class="ml-1" @click.prevent="downloadReport(report)" href="#">
                      <img
                        v-if="!report.printing" style="height: 1.5rem" class="pr-2 pointer"
                        src="/img/icons/icons8/print.png"
                      />
                      <img v-else style="height: 1.5rem" class="pr-2 pointer" src="/img/icons/icons8/printer.gif"/>
                    </a>
                    <a href="#" v-show="report.hardened_state" @click.prevent="handleShowModalInfo(report)">
                      <img class="mr-1" src="/img/icons/icons8/ios/impossible-shapes_primary.png"  width="24" />
                    </a>
                  </h4>
                </div>
                <div class="col-2 text-right align-top mt-n2">
                  <base-dropdown menuOnRight>
                    <div slot="title-container" class="dropdown-toggle rounded m-0">
                      <img width="32px" src="/img/icons/icons8/ios/settings--v1_primary.png">
                    </div>
                    <!-- <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click.prevent="handleFreshStateTest(report.travel_uuid)"
                    >
                      <img width="22px" src="/img/icons/icons8/ios/penrose-square.png">
                      <span class="text-right">Ensaios - Estado endurecido</span>
                    </a> -->
                    <router-link
                      :to="{ name: 'technology.report.hardened.index', params: { travel_uuid: report.travel_uuid }}">
                      <a class="dropdown-item">
                        <i class="fas fa-cube fa-2x" style="color: #2d3748; opacity: 80%"></i>
                        <span class="text-right">Estado endurecido</span>
                      </a>
                    </router-link>
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click.prevent="handleFreshStateTest(report.travel_uuid)"
                    >
                      <img width="22px" src="/img/icons/icons8/ios/vlc_warning.png">
                      <span class="text-right">Estado fresco</span>
                    </a>
                  </base-dropdown>
                </div>
                <div class="col-md-12 mt-0">
                  <h4>{{ report.schedule.contract_proposal_code }}</h4>
                  <h4 class="font-weight-400">{{ report.schedule.customer }}</h4>
                  <h4 class="mt-n2 font-weight-400">{{ report.schedule.construction }}</h4>
                  <span class="mt-n2" style="font-size: 12px;">{{ report.updated_at | parseDate }}</span>
                </div>
              </div>
              <div class="mt-n1 mb-3"></div>
              <div class="row">
                <div class="col px-1">
                  <base-button
                    size="sm"
                    class="text-uppercase"
                    style="border-color: #E0E0E0; height: 40px;"
                    type="white"
                    block
                    outline
                  >
                    <h5 class="font-weight-400">
                      <img style="width: 33px" src="/img/icons/os.png" />
                      {{ report.index }}
                    </h5>
                  </base-button>
                </div>
                <div class="col px-1" v-if="report.xml">
                  <base-button
                    size="sm"
                    class="text-uppercase"
                    style="border-color: #E0E0E0; height: 40px;"
                    type="white"
                    block
                    outline
                  >
                    <h5 class="font-weight-400">
                      <img style="width: 22px" class="mr-1" src="/img/icons/nfe.png" />
                      {{ report.xml.number }}
                    </h5>
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingPagination :show="loading && !loadingSkeleton" :show-offset="!modal_shown"/>
      <ModalFreshStateTest ref="freshStateTest"/>
      <ModalAdjustment ref="ModalAdjustment"/>
      <ModalInfo ref="modalInfo"/>
      <ModalHardenedStateTest ref="hardenedStateTest"/>
    </div>
  </div>
</template>

<script>
import cursorPaginate from "@/mixins/cursorPaginate";
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "../../../../../components/SkeletonPuzlGrid";
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import { debounce } from "@/plugins";
import ModalFreshStateTest from "../../../Operational/Schedule/Weighing/Shared/_ModalFreshStateTest";
import LoadingPagination from "@/components/LoadingPagination";
import PuzlEmptyData from "../../../../../components/PuzlEmptyData";
import ModalInfo from './Shared/ModalInfo.vue'
import ModalAdjustment from '../../../Operational/Schedule/Charging/Shared/_ModalAdjustments.vue'
import ModalHardenedStateTest from "../../../Operational/Schedule/Weighing/Shared/_ModalHardenedStateTest.vue";
import {
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppPageHeaderActionsButtonDropItem,
  AppSearchBar,
  AppSearchBarFilterSection,
  AppSelect,
  AppTabSelect, AppViewTrigger, initSearchBarFilterType
} from "@/components/AppGlobal";
import { ReportListFilterType, initReportListFilterType } from "./Types";
import { date } from "../../../../../helpers";

export default {
  mixins: [cursorPaginate],
  name: "Index",
  components: {
    PuzlEmptyData,
    ModalFreshStateTest,
    SkeletonPuzlGrid,
    LoadingPagination,
    ModalInfo,
    ModalAdjustment,
    ModalHardenedStateTest,
    AppPageHeader,
    AppPageHeaderActionsButtonDropItem,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppTabSelect,
    AppFixedPageTitle,
    AppSelect,
    AppSearchBar,
    AppSearchBarFilterSection,
    AppViewTrigger,
    initSearchBarFilterType,
  },
  data() {
    return {
      format_mixe: period_types,
      loadingUsers: false,
      modal_shown: false,
      printing: false,
      loadingSkeleton: false,
      technicalPermissions: {},
      filter: initReportListFilterType(),
      hover_icon_with_hardened_state: 0,
      hover_icon_without_hardened_state: 0,
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      },
      searchBarFilter: initSearchBarFilterType(),
      currentDate: date.make().format(date.FORMAT.FULL_DATE),
    }
  },
  computed: {
    ...mapGetters({
      users: "user/fetch",
      reports: "testReport/fetch",
      $_user: 'auth/getUser',
      $_widgets: 'testReport/getWidgets',
    }),
    tabSelectItems() {
      return [
        {
          id: null, 
          name: 'Todos', 
          selected: 
            this.filter.with_hardened_state == null && 
            this.filter.without_hardened_state == null,
            suffix: this.$_widgets.total_records,
        },
        {
          id: true, 
          name: "Com moldagem",
          selected: this.filter.with_hardened_state == true,
          suffix: this.$_widgets.total_hardened_state,
        },
        {
          id: false, 
          name: "Sem moldagem",
          selected: this.filter.without_hardened_state == false,
          suffix: this.$_widgets.total_not_hardened_state,
        },
      ];
    },
  },
  methods: {
    fetchTechnicalPermissions(userId) {
      this.$store.dispatch('technicalPermission/getByUserId', userId).then((response) => {
        this.technicalPermissions = response.data
      });
    },
    /**
     * Realiza o download do relatório de carregamento
     * @param {Object} report - O objeto de relatório
     * @param {string} report.schedule_travel_uuid - O UUID da viagem associada ao relatório
     */
    downloadReport(report) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      let params = {
        uuid: report.travel_uuid,
        type: "report"
      };
      this.$store
        .dispatch("travels/download", params)
        .then(async response => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", 'Relatório de carregamento ' + report.index);
          await link.click()
          this.$snotify.success('Download iniciado com sucesso!', {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false
          });
          report.printing = false
        })
        .catch(error => {
          loader.hide();
        });
    },
    /**
     * Obtém os ajustes associados a uma viagem e exibe-os em um modal
     * @param {string} schedule_travel_uuid - O UUID da viagem
     */
    showAdjustments(schedule_travel_uuid) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      this.$store
        .dispatch("travels/getAdjustments",
          {schedule_travel_uuid: schedule_travel_uuid, mix_design_mixe_id: null}
        )
        .then(response => {
          this.$Progress.finish();
          this.$notify({type: "success", message: response.message});
          this.modal_shown = true
          this.$refs.ModalAdjustment.openModal();
        })
        .catch(error => {
          this.$Progress.finish();
        });
    },
    /**
     * Busca informações de coleta da viagem
     * @param report
     */
    handleShowModalInfo(report) {
      this.$refs.hardenedStateTest.openModal(report.travel_uuid)
    },
    /**
     /**
     * Atualizar status de moldagem da viagem
     * @param index
     */
    setHardenedState(index) {
      /**
       * coloca a moldagem diferente do valor atual
       * @type {boolean}
       */
      let report = this.reports[index]
      let hardened_state = !report.hardened_state
      /**
       * se o novo status for não, exibe mensagem de confirmação
       */
      if (!hardened_state) {
        this.$Swal.confirmAction('Deseja confirmar a exclusão das moldagens dessa viagem?', ['Sim', 'Não'])
          .then((result) => {
            if (result.isConfirmed) {
              this.updateHardenedState({
                travel_uuid: report.travel_uuid,
                hardened_state: hardened_state,
              }, index)
            }
          }).catch(() => this.$Progress.finish())
      } else {
        this.updateHardenedState({
          travel_uuid: report.travel_uuid,
          hardened_state: hardened_state,
        }, index)
      }
    },
    updateHardenedState(report, index) {
      let params = {
        schedule_travel_uuid: report.travel_uuid,
        hardened_state: report.hardened_state,
      };
      let loader = this.$loading.show()
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$store
        .dispatch("travelConfiguration/storeDefaultHardenedStates", params)
        .then(response => {
          if (report.hardened_state) {
            this.$refs.hardenedStateTest.openModal(report.travel_uuid)
          }
          this.reports[index].hardened_state = report.hardened_state
          this.$notify({type: "success", message: response.message});
        })
        .catch((error) => {
          this.$notify({type: "danger", message: error.data.message});
        })
        .finally(() => (loader.hide()));
    },
    handleFilterAdvancedStatus() {
      if (this.filter.with_hardened_state) {
        this.filter.with_hardened_state = 0
      } else {
        this.filter.with_hardened_state = 1
      }
      this.init()
    },
    handleFilterDate(filter) {
      this.range = filter
      this.loadingSkeleton = true
      this.init({})
    },
    handleHardenedSate(uuid) {
      this.$router.push({name: 'technology.report.hardened.index', params: {travel_uuid: uuid}})
    },
    handleFreshStateTest(uuid) {
      let loader = this.$loading.show()
      this.$store.dispatch("testFreshState/fetchItems").then(() => {
        loader.hide()
        this.$refs.freshStateTest.openModal(uuid);
      });
    },
    init(filter = null) {
      this.startCursor(filter);
      this.filter.range = this.range;
      this.prepareFilter();
      this.$store.dispatch('testReport/fetchItems',
        {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        }).then(response => {
        this.loadingSkeleton = false
        this.resolveCursor(response)
      }).catch(error => {
        this.resolveCursor()
        this.loadingSkeleton = false
      })
    },
    teste(report) {
      let params = {
        responsible: report.responsible,
        observation: report.observation,
        collect: report.collect,
        molder: report.molder,
        schedule_travel_uuid: report.travel_uuid
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show()
      this.$store.dispatch("testReport/add", params).then(response => {
        loader.hide()
        this.$notify({type: 'success', message: response.message});
      }).catch(() => loader.hide());
    },
    teste2: debounce(function (report) {
      let params = {
        observation: report.observation,
        schedule_travel_uuid: report.travel_uuid
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show()
      this.$store.dispatch("testReport/add", params).then(response => {
        loader.hide()
        this.$notify({type: 'success', message: response.message});
      }).catch(() => loader.hide());
    }, 500),
    getWidgets() {
      this.$store.dispatch("testReport/getWidgets", {filter: this.filter});
    },
    clearFilter(isRefreshList = true) {
      Object.assign(this.searchBarFilter, this.defaultSearchBarFilter());
      Object.assign(this.filter, this.defaultFilter());
      if (isRefreshList) {
        this.init({});
      }
    },
    prepareFilter() {
      this.filter.custom_search.values = this.searchBarFilter.custom_search_values;
      this.filter.company_plant_id = this.searchBarFilter.company_plant_selected;
      this.filter.range.start = this.searchBarFilter.range.start;
      this.filter.range.end = this.searchBarFilter.range.end;
    },
     /**
     * @param {TabSelectItemType} item
     */
    onTabSelectItemClick(item) {
      // Verifica se o filtro que o usuário está tentando aplicar já está ativo.
      const isAlreadyFiltered =
        (item.id === null && this.filter.with_hardened_state == null && this.filter.without_hardened_state == null) ||
        (item.id === true && this.filter.with_hardened_state === true) ||
        (item.id === false && this.filter.without_hardened_state === false);

      if (isAlreadyFiltered) {
        return;
      }

      // Atualiza os filtros de acordo com o item selecionado
      if (item.id === null) {
        this.filter.with_hardened_state = null;
        this.filter.without_hardened_state = null;
      } else if (item.id === true) {
        this.filter.with_hardened_state = true;
        this.filter.without_hardened_state = null;
      } else if (item.id === false) {
        this.filter.with_hardened_state = null;
        this.filter.without_hardened_state = false;
      }

      this.init({});
    },
    /**
     * Padrão do filtro principal
     * @returns {ReportListFilterType}
     */
    defaultFilter() {
      return {
        ...initReportListFilterType(),
      };
    },
    /**
     * Padrão de filtro da barra de pesquisa
     * @returns {SearchBarFilterType}
     */
     defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
        range: {
          start: this.filter.range.start ?? this.currentDate,
          end: this.filter.range.end ?? this.currentDate,
        },
      };
    },
  },
  mounted() {
    this.loadingSkeleton = true;
    this.loadingUsers = true;
    this.clearFilter();
    this.$store.dispatch("user/fetchItems").then(() => {
      this.loadingUsers = false;
    }).catch(() => this.loadingUsers = false);
    this.init();
    this.fetchTechnicalPermissions(this.$_user.id);
    this.getWidgets();
  }
}
</script>
<style scoped>
.el-input__suffix {
  width: 112%;
}

.popover-title {
  font-family: Fredoka;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2B2D32;
}
.label-title-popover {
  font-family: Fredoka;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2B2D32;
}
.label-popover {
  font-family: Fredoka;
  font-size: 10px;
  font-weight: 300;
  line-height: 15px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.label-formulation-test-tube {
  font-family: Fredoka;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
</style>

<template>
  <div>
    <modal :show.sync="modal.create" :backgroundColor="'#F2F4F9'">
      <template slot="header">
        <div class="d-flex align-items-center container-fluid" style="gap: 12px; margin-left: -28px;">
          <div>
            <img src="/img/icons/icons8/ios/add-primary.png" height="28" width="28">
          </div>
          <span class="text-title" style="font-size: 15.9px;">
            {{ modal.title }}
          </span>
        </div>
      </template>
      <template slot="close-button">
        <AppButton
          icon="close"
          width="auto"
          type="link"
          size="sm"
          :hasOutline="true"
          @click="closeModal('create')"
        />
      </template>
      <div>
        <div class="row" style="display: flex; justify-content: center; align-items: center; margin: 0px;">
          <div class="col-md-12 mb-n3">
            <AppSearchBar
              :searchBarFilter.sync="searchBarFilter"
              @onSearchClick="getMixConcretedPieceWithPercentage()"
            ></AppSearchBar>
          </div>
        </div>

        <AppTabSelect
          :items="tabSelectItem"
          :isShowViewTypes="false"
          @onTabSelectItemClick="onTabSelectItemClick"
        ></AppTabSelect>

        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" autocomplete="off">
            <div v-show="isLoading">
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 m-0 p-0 mb-1 col-form-label form-control-label">
                  <SkeletonPuzl type="button"></SkeletonPuzl>
                </label>
                <div class="col-md-3">
                  <SkeletonPuzl type="button"></SkeletonPuzl>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 m-0 p-0 mb-1 col-form-label form-control-label">
                  <SkeletonPuzl type="button"></SkeletonPuzl>
                </label>
                <div class="col-md-3">
                  <SkeletonPuzl type="button"></SkeletonPuzl>
                </div>
              </div>
            </div>

            <div style="border: 1px solid #e0e0e0; border-radius: 5px; padding: 10px; margin: 15px; margin-top: 22px;">
              <div class="label-title mb-3">
                <img height="25" src="/img/icons/icons8/ios/slider.png" class="mr-2" />
                % Sobre o custo de mcc
              </div>
              <hr class="my-1 mb-3" />
              <div v-for="(item, index) in listMixPercentage" :key="index" style="margin-bottom: 10px;" class="pl-4">
                <div class="form-group row m-0 p-0">
                  <label class="col-md-7 m-0 p-0 mb-1 mt-3 col-form-label form-control-label label-item" style="line-height: 1.6;">
                    {{ item.description }}
                  </label>
                  <div class="col-md-5" style="margin-top: 5px;">
                    <base-input input-group-classes="input-group-sm no-box-shadow">
                      <input
                        v-if="item.percentage"
                        v-model="item.percentage.percentage"
                        type="text"
                        class="form-control form-control-sm"
                        inputmode="numeric"
                        v-mask="['##']"
                      />
                      <template slot="append">
                        <small class="input-group-sm"> % </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer mt-n3">
              <AppButton
                label="Cancelar"
                type="danger"
                :isBlock="false"
                :hasOutline="true"
                @click="closeModal('create')"
              />
              <AppButton
                label="Salvar"
                type="success"
                :isBlock="false"
                :hasOutline="true"
                :isLoading="loading"
                :isDisabled="invalid"
                @click.prevent="handleSubmit(store)"
              />
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import SkeletonPuzl from '@/components/SkeletonPuzl';
import MultiFilter from "@/components/Utils/MultiFilterV2";
import {
  AppSearchBar,
  initSearchBarFilterType,
  AppTabSelect,
  AppButton,
} from "../../../../../../components/AppGlobal";

export default {
  components: {
    SkeletonPuzl,
    MultiFilter,
    AppSearchBar,
    AppTabSelect,
    AppButton,
  },
  name: "MixConcretedPiecesPercentage",
  computed: {
    tabSelectItem() {
      return [
        {
          id: null,
          name: "Todos",
          selected: this.filter.status == null,
        },
        {
          id: 1,
          name: "Ativo",
          selected: this.filter.status === 1,
        },
        {
          id: 0,
          name: "Inativos",
          selected: this.filter.status === 0,
        },
      ];
    },
  },
  data() {
    return {
      modal: {
        title: '',
        create: false,
      },
      filter: {},
      validated: false,
      listMixPercentage: {},
      percentage:{},
      isLoading: false,
      loading: false,
      searchBarFilter: initSearchBarFilterType(),
    };
  },
  methods: {
    closeModal(name) {
      this.modal[name] = false
    },
    openModal(name) {
      this.modal[name] = true
    },
    handleCreate() {
      this.getMixConcretedPieceWithPercentage();
      this.modal.title = 'Acréscimos sobre peça a concretar';
      this.openModal('create');
    },
    store() {
      const percentages = {};
      const lists = this.listMixPercentage;
      lists.forEach(function (item, index){
        percentages[item.id] = item.percentage ? item.percentage.percentage : 0
      });

      this.$Progress.start();
      this.loading = true;

      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$axios.post("/commercial/concrete-price/mix-concreted-piece-with-percentage", {percentages})
        .then(({data}) => {
          this.$notify({type: data.error_type, message: data.message});
          this.closeModal('create');
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          this.$notify({type: error.response.data.error_type, message: error.response.data.message});
          this.loading = false;
        });
    },
    getMixConcretedPieceWithPercentage(filter = {}) {
      this.filter = filter;
      this.$Progress.start();
      this.isLoading = true;
      this.$axios.get("/commercial/concrete-price/mix-concreted-piece-with-percentage", {
          params: {
            filter: this.filter
          }
        })
        .then(({data}) => {
          this.listMixPercentage = data.data;
          this.$Progress.finish();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
            this.isLoading = false;
          }
        });
    },
    onTabSelectItemClick(item) {
      this.filter.status = item.id;
      this.getMixConcretedPieceWithPercentage(this.filter);
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
    EventBus.$on("handleCreateMixConcretedPiecesPercentage", () => {
      this.handleCreate();
    });
  }
};
</script>

<style scoped>
.label-title {
  width: 250px;
  height: 19px;
  gap: 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
}
.label-item {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
}

/** remove sombra dos input */
input.form-control.no-box-shadow {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

/** remove sobra do <template slot="append"> */
.no-box-shadow input, .no-box-shadow div span.input-group-text {
  box-shadow: none !important;
}
</style>
<template>
  <modal size="lg" :show.sync="modal" class="pb-5">
    <form @submit.prevent="submitForm">
    <template slot="header">
      <h5 class="modal-title p-0 m-0">
        <img height="25" src="/img/icons/autograph.png" alt="autograph" />
        {{ title }}
      </h5>
    </template>
    <div class="p-3">
    <!-- Header -->
      <div class="d-flex align-items-center">
        <img width="25" height="25" src="/img/icons/info-squared-yellow.png" alt="info-squared"/>
        <span class="ml-2 mr-3 font-weight-bold" style="font-size: 16px;">Informação</span>
      </div>
    <!-- informações -->
      <div class="mt-3" style="display: grid;">
        <span style="font-weight: 500;" class="font-600">{{ contract_proposal.code }}</span>
        <span>{{ contract_proposal.seller_name }}</span>
        <span>{{ contract_proposal.construction.construction_name }}</span>
      </div>
      <div class="d-md-flex justify-content-between align-items-center mb-2 mt-2">
        <span>Contrato</span>
        <div class="px-3 py-2 py-sm-1 mt-1 mt-sm-0 pointer signature-info d-flex">
          <span class="text-truncate pr-2" style="font-size: 12px; max-width: 250px;">Concretagem</span>
        </div>
      </div>
      <div class="d-md-flex justify-content-between align-items-center mb-2">
        <span>Data limite de assinatura</span>
        <base-input input-group-classes="input-group-sm mt-1 mt-sm-0">
          <input-date-time-picker class="input-info w-100" :mode="'date'" v-model="document.date_limit_to_sign" />
        </base-input>
      </div>
      <!-- Destinatário -->
      <div v-if="!isContract" class="d-md-flex justify-content-between align-items-center mb-2">
        <div class="d-flex align-items-center justify-content-between">
          <span class="mr-2">
            Destinatário
          </span>
          <span
            class="d-inline btn btn-sm btn-outline-success p-1 text-center float-right d-flex align-items-center pr-3 mb-1 mb-sm-0 mt-1 mt-sm-0"
            @click.prevent="handleCreateContactsModal($_customer_construction.uuid)">
            <i class="fas fa-plus ml-2 mr-2"></i> Novo
          </span>
        </div>
        <PuzlSelect
            class="input-info"
            :disabled="loadingContacts"
            v-model="contact"
            :items=" $_customer_construction.contact"
            placeholder="Selecione"
          />
      </div>
    </div>
  <!-- Contratados -->
    <div class="px-3 mt-3 pb-2">
      <div class="d-flex align-items-center">
        <img width="25" class="mr-2" src="/img/icons/hand-with-pen-primary.png" alt="hand-with-pen"/>
        <span class="ml-2 mr-3 font-weight-bold text-nowrap" style="font-size: 16px;">Assinaturas</span>
        <div class="dropdown-divider" style="width: -webkit-fill-available;" />
      </div>
      <div class="d-flex justify-content-between align-items-center mt-1">
        <label>
          <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
            <circle cx="2.5" cy="2.5" r="2.25" stroke="#1A70B7" stroke-width="0.5"/>
          </svg>
          Número de contratados
        </label>
        <div style="width: 15%;">
          <InputLimit
            class="input-limit"
            :start_value="Math.max(this.config.min_contracted_signatures, this.contractedNumber || 1)"
            :options="{
                min: config.min_contracted_signatures,
                max: 10,
                decimals: 0
              }"
            @value="contractedNumber = Number($event)"
          />
        </div>
      </div>
      <!-- Dados do Contratado -->
      <div v-for="i in contractedNumber" :key="i" class="d-block d-sm-flex justify-content-between align-items-center mt-2">
        <base-input
          class="col-12 col-sm-6 pr-1 mb-2 mb-sm-0 pl-0 input-name-email-signatory"
          name="Email"
          placeholder="Nome"
          :required="true"
          v-model="getDataInput('contracted', i-1).name"
          @input="updateDataInput('contracted', i-1, 'name', $event)" />
        <base-input
          class="col-12 col-sm-6 pr-1 mb-2 mb-sm-0 pl-0 input-name-email-signatory mb-2 mb-sm-0"
          name="Email"
          placeholder="Email"
          :required="true"
          v-model="getDataInput('contracted', i-1).email"
          @input="updateDataInput('contracted', i-1, 'email', $event)" />
      </div>

    <!-- Contratantes -->
      <div class="d-flex justify-content-between align-items-center mt-3">
        <label>
          <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
            <circle cx="2.5" cy="2.5" r="2.25" stroke="#1A70B7" stroke-width="0.5"/>
          </svg>
          Número de Contratantes
        </label>
        <div style="width: 15%;">
          <InputLimit
            class="input-limit"
            :start_value="config.min_contractor_signatures || 1"
            :options="{
                min: config.min_contractor_signatures,
                max: 10,
                decimals: 0
              }"
            @value="contractorNumber = Number($event)"
          />
        </div>
      </div>
      <!-- Dados dos contratantes -->
      <div v-for="i in contractorNumber" :key="i" class="d-block d-sm-flex justify-content-between align-items-center mt-2">
        <base-input
          class="col-12 col-sm-6 pr-1 mb-2 mb-sm-0 pl-0 input-name-email-signatory"
          name="Name"
          placeholder="Nome"
          :required="true"
          v-model="getDataInput('contractor', i-1).name"
          @input="updateDataInput('contractor', i-1, 'name', $event)" />
        <base-input
          class="col-12 col-sm-6 pr-1 mb-2 mb-sm-0 pl-0 input-name-email-signatory mb-2 mb-sm-0"
          name="Email"
          placeholder="Email"
          :required="true"
          v-model="getDataInput('contractor', i-1).email"
          @input="updateDataInput('contractor', i-1, 'email', $event)" />
      </div>

    <!-- Testemunhas -->
      <div v-if="isContract" class="d-flex justify-content-between align-items-center mt-3">
        <div class="d-flex align-items-center">
          <label>
            <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
              <circle cx="2.5" cy="2.5" r="2.25" stroke="#1A70B7" stroke-width="0.5"/>
            </svg>
            {{ witness ? 'Número de ' : '' }} Testemunhas
          </label>
          <base-switch
            v-if="config.min_witness_signatures <= 0"
            v-model="witness"
            type="primary"
            offText="Não"
            onText="Sim"
            class="success ml-3" />
        </div>
        <div v-if="witness" style="width: 15%;">
          <InputLimit
            class="input-limit"
            :start_value="config.min_witness_signatures || 1"
            :options="{
                min: 1,
                max: 10,
                decimals: 0
              }"
            @value="witnessNumber = Number($event)"
          />
        </div>
      </div>
      <!-- Dados das Testemunhas -->
      <div v-if="witness && isContract" v-for="i in witnessNumber" :key="i" class="d-block d-sm-flex justify-content-between align-items-center mt-2">
        <base-input
          class="col-12 col-sm-6 pr-1 mb-2 mb-sm-0 pl-0 input-name-email-signatory"
          name="Name"
          placeholder="Nome"
          :required="true"
          v-model="getDataInput('witness', i-1).name"
          @input="updateDataInput('witness', i-1, 'name', $event)" />
        <base-input
          class="col-12 col-sm-6 pr-1 mb-2 mb-sm-0 pl-0 input-name-email-signatory mb-2 mb-sm-0"
          name="Email"
          placeholder="Email"
          :required="true"
          v-model="getDataInput('witness', i-1).email"
          @input="updateDataInput('witness', i-1, 'email', $event)" />
      </div>

      <!-- Fiadores-->
      <div v-if="isContract && guarantor"
        class="d-flex justify-content-between align-items-center mt-3">
        <div class="d-flex align-items-center">
          <label>
            <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
              <circle cx="2.5" cy="2.5" r="2.25" stroke="#1A70B7" stroke-width="0.5"/>
            </svg>
            Fiador
          </label>
        </div>
      </div>
      <!-- Dados do Fiador -->
      <div v-if="guarantor && isContract" class="d-block d-sm-flex justify-content-between align-items-center mt-2">
        <base-input
          class="col-12 col-sm-6 pr-1 mb-2 mb-sm-0 pl-0 input-name-email-signatory"
          name="Name"
          placeholder="Nome"
          disabled="true"
          v-model="getDataInput('guarantor', 0).name" />
        <base-input
          class="col-12 col-sm-6 pr-1 mb-2 mb-sm-0 pl-0 input-name-email-signatory mb-2 mb-sm-0"
          name="Email"
          placeholder="Email"
          disabled="true"
          v-model="getDataInput('guarantor', 0).email" />
      </div>
    </div>
    <div class="modal-footer pt-3">
      <base-button type="danger" @click="closeModal()">
        <img width="22" src="/img/icons/cancel.png" alt="info-squared" class="mr-1" />
        Cancelar
      </base-button>
      <base-button :disabled="!fieldsFilled" @click.prevent="save()" type="success" native-type="submit">
        <img width="22" src="/img/icons/save.png" alt="info-squared" class="mr-1" />
        Salvar
      </base-button>
    </div>

    <ModalCreateContact @addContact="listContacts" ref="createContact"></ModalCreateContact>
  </form>
  </modal>

</template>

<script>
import {mapGetters} from "vuex";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import InputLimit from "@/components/Utils/InputLimitV2.vue";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import ModalCreateContact from '@/views/Modules/Commercial/CustomerConstruction/Constructions/Contact/Shared/_Create'
import PuzlSelect from "@/components/PuzlSelect";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "ModalDigitalSignatureCreate",
  components: {
    InputLimit,
    InputDateTimePicker,
    ModalCreateContact,
    PuzlSelect
  },
  props: {
    contractProposal: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      $_user: 'auth/getUser',
      $_customer_construction: "customerConstructionConstructions/show",
    }),
  },
  data() {
    return {
      modal: false,
      title: '',
      config: null,
      documents: [],
      loadingContacts: false,
      fieldsFilled: false,
      contact: null,
      contacts: [],
      contract_proposal: null,
      witness: false,
      guarantor: false,
      isContract: false,
      contractorNumber: 0,
      contractedNumber: 0,
      witnessNumber: 0,
      document: {
        signers: {   // Signatarios
          contractor: [], // Contratante
          contracted: [], // Contratado
          witness: [],    // Testemunhas
          guarantor: [],  // Fiadores
        },
        base64_pdf: '',
        date_limit_to_sign: new Date(new Date().setMonth(new Date().getMonth() + 1)),
        created_by: null,
        external_id: null,
        folder_path: '/',
        name: '',
      },
    }
  },
  watch: {
    contractedNumber(value) {
      this.adjustArraySize(this.document.signers.contracted, value);
    },
    contractorNumber(value) {
      this.adjustArraySize(this.document.signers.contractor, value);
    },
    witnessNumber(value) {
      this.adjustArraySize(this.document.signers.witness, value);
    },
    witness(value) {
      if (!value || !this.isContract) {
        this.document.signers.witness = [];
      } else {
        this.adjustArraySize(this.document.signers.witness, value);
      }
      this.areAllFieldsFilled();
    },
    guarantor(value) {
      if (!value || !this.isContract) {
        this.document.signers.guarantor = [];
      } else {
        this.document.signers.guarantor = [
          {
            name: this.contract_proposal.guarantorEntity.entity_name,
            email: this.contract_proposal.guarantorEntity.email
          }
        ];
      }
    },
    'document.signers': {
      handler() {
        this.areAllFieldsFilled();
      },
      deep: true,
    },
  },
  methods: {
  // Modal
    handleCreateModal(data) {
      this.config = data.config;
      this.documents = data.documents;
      this.contract_proposal = data.contract_proposal;
      this.isContract = data.isContract;
      this.witness = this.config.min_witness_signatures > 0;
      this.guarantor = this.contract_proposal.guarantor_entity_id ? true : false;
      this.modal = true;
      this.title = this.isContract ? 'Assinaturas do contrato' : 'Assinaturas da proposta comercial';
      this.listContacts();
      if (this.config.default_signatory_company_plant) {
        this.getDefaultSignatories();
      }
    },
    closeModal() {
      this.modal = false
    },
    handleCreateContactsModal(constructionUuid) {
      this.$refs.createContact.handleCreateModal(constructionUuid);
    },
  // Inputs dinamicos
    // Salva os dados do input no seu determinado lugar no array d signatarios
    updateDataInput(type, index, field, value) {
      if (!this.document.signers[type][index]) {
        this.$set(this.document.signers[type], index, { name: '', email: '' });
      }
      this.document.signers[type][index][field] = value;
    },
    getDataInput(type, index) {
      return this.document.signers[type][index] || { name: '', email: '' };
    },
    listContacts() {
      this.loadingContacts = true;
      this.$store.dispatch('customerConstructionConstructions/show', this.contract_proposal.construction.uuid)
        .then(response => {
          this.contacts = response.data.contact.filter(item => item.email)
          this.loadingContacts = false
        })
        .catch(error => {
          this.loadingContacts = false
          this.$notify({
            type: error.data.error_type, message: error.data.message
          })
        })
    },
    adjustArraySize(array, value) {
      if (array.length < value) {
        let elementsToAdd = value - array.length;
        for (let i = 0; i < elementsToAdd; i++) {
          array.push({ "name": "", "email": "" });
        }
      } else {
        array.splice(value);
      }
    },
    areAllFieldsFilled() {
      this.fieldsFilled = true;
      for (let role in this.document.signers) {
        for (let i = 0; i < this.document.signers[role].length; i++) {
          if (!this.document.signers[role][i].name || !this.document.signers[role][i].email) {
            this.fieldsFilled = false;
          }
        }
      }
    },

  // Salvamento do documento
    async save() {
      this.$notify({type: 'info', message: 'Gerando ' + (this.isContract ? 'Contrato' : 'Proposta')});
      let loader = this.$loading.show();
      // this.deletePreviousDocument();

      const clientName = this.$helper.clientName().substring(0, 49);
      const plantName = this.contract_proposal.plant.name.substring(0, 49);
      const entityName = this.contract_proposal.entity.entity_name.substring(0, 49);
      const code = this.contract_proposal.code.substring(0, 49);

      const document = {
        created_by : this.$_user.id,
        signers : this.formatArraySigners(this.document),
        base64_pdf : await this.getBase64ContractProposal(),
        //cliente/planta/contratante/documento
        folder_path : clientName + '/' + plantName + '/' + entityName + '/' + code,
        name: this.isContract ? 'Contrato' : 'Proposta',
        external_id: uuidv4(),
      }
      this.$notify({type: 'info', message: 'Salvando '+document.name});
      this.$store.dispatch('digitalSignature/createDocument', document)
        .then((response) => {
          this.$notify({type: response.error_type, message: response.message});
        }).finally(() => {
          this.$emit('reloadDocuments')
          loader.hide();
          this.modal = false
      })
    },
    deletePreviousDocument() {
      const token = this.getPreviousDocumentToken();
      if (token) {
        this.$store.dispatch('digitalSignature/deleteDocument', token)
        .then(() => {
          this.$notify({type: 'info', message: (this.isContract ? 'Contrato' : 'Proposta') + ' anterior excluído com sucesso'});
        });
      }
    },
    // Se existir, pega token do documento ativo anterior para cancelamento
    getPreviousDocumentToken() {
      const name = this.isContract ? 'Contrato' : 'Proposta'
      const item = this.documents.find(obj => obj.name === name && obj.deleted === false);
      return item ? item.token : null;
    },
    // Formata o array de signatarios dos inputs para o formato esperado pela api da zapsign
    formatArraySigners(obj) {
      let result = [];
      for (let qualification in obj.signers) {
        obj.signers[qualification].forEach((item) => {
          if (item) {
            const external_id = uuidv4();
            const send_automatic_email = true;
            result.push({ ...item, qualification, external_id, send_automatic_email });
          }
        });
      }
      return result;
    },
    getBase64ContractProposal() {
      return new Promise((resolve, reject) => {
        let baseURL = process.env.VUE_APP_BASE_URI_MS;
        if (!baseURL) {
          let host = window.location.host
          let subdomain = host.split('.')[0]
          baseURL = `https://${subdomain}.puzl.place/api/v1/`;
        }
        let params = {
          uuid: this.contract_proposal.uuid,
          type: this.isContract ? 1 : 0,
          atual_validity: 1,
        }
        if (!this.isContract) {
          params.contact = this.$_customer_construction.contact.find(item => item.uuid = this.contact)
          params.validity = Math.ceil(Math.abs(new Date() - new Date(this.document.date_limit_to_sign)) / (1000 * 60 * 60 * 24));
        }
        var reader = new FileReader();
        this.$store.dispatch('contractProposal/download', params)
          .then(response => {
            let blob = new Blob([response], {type: 'application/pdf'})
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
              resolve(reader.result);
            }
          }).catch(() => {
          loader.hide()
          reject();
        })
      });
    },
    getDefaultSignatories() {
      const filter = { companyPlantId: this.contract_proposal.company_plant_id };
      this.$store.dispatch('digitalSignatureConfiguration/showDefaultSignatory', { filter })
      .then((response) => {
      // Setar vendedor assinante padrao
        let signatorySeller = response.data.defaultSignatorySeller;

        // Caso exista vendedor assinante padrao
        if (this.hasDefaultSignatorySeller(signatorySeller)) {
          const sellerSignatory = signatorySeller.find(
            item => item.company_plant_id === this.contract_proposal.company_plant_id && item.use_seller_data === 1
          );
          if (sellerSignatory) {
            this.document.signers.contracted = [{
              name: this.contract_proposal.seller_name,
              email: this.contract_proposal.seller_email
            }];
            this.contractedNumber = 1;
          }

          if (response.data?.defaultSignatory?.length > 0) {
            this.document.signers.contracted.push(...response.data.defaultSignatory.map(item => {
              return { name: item.name, email: item.email };
            }));
            this.contractedNumber += response.data.defaultSignatory.length;
          }
          // Caso exista apenas assinantes padroes
        } else if (response.data.defaultSignatory && response.data.defaultSignatory.length > 0) {
            this.contractedNumber = response.data.defaultSignatory.length;
            this.document.signers.contracted = response.data.defaultSignatory.map(item => {
              return { name: item.name, email: item.email }
            });
          }
        this.contractedNumber = Math.max(this.config.min_contracted_signatures, this.contractedNumber);
      });
    },
    hasDefaultSignatorySeller(signatorySeller) {
      if (signatorySeller && signatorySeller.length > 0) {
        return signatorySeller.some(
          item => item.use_seller_data === 1 && item.company_plant_id === this.contract_proposal.company_plant_id
        );
      }
    },
  },
}
</script>

<style scoped>
label {
  margin-bottom: 0 !important;
}
.signature-info {
  border-radius: 5px;
  border: 1px solid #DCDFE6;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
}


/* .input-info >>> input {
  text-align: left !important;
  height: 27px !important;
  padding-left: 2.2rem !important;
  padding-top: 0.2rem !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
} */
.input-info >>> .el-input__inner {
  padding: 0 15px !important;
}
.input-name-email-signatory >>> input {
  height: 35px;
}

.input-limit >>> .input-group-sm {
  padding-bottom: 0 !important;

}

/* Desktop */
@media (min-width: 720px) {
  .signature-info, .input-info {
    min-width: 30rem;
  }
}
/* Mobile */
@media (max-width: 720px) {
  .input-name-email-signatory {
    display: inline-grid;
    padding: 0 !important;
  }
}

</style>

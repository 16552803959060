<template>
  <div>
    <modal v-if="show" size="lg" @close="close" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">Visualização de arquivos</h5>
      </template>
      <div v-if="selected.ext != 'pdf'" class="text-center" style="overflow: auto;max-height: 90vh">
        <p>
          <img :src="selected.url">
        </p>
      </div>
      <div v-else class="text-center" style="overflow: auto;max-height: 90vh">
        <div v-if="loading" class="loading-message">Carregando PDF...</div>
        <pdf
          ref="pdfComponent"
          :src="selected.url"
          :annotation="true"
          :page="currentPage"
          @loaded="onLoaded"
        />
        <div v-if="pageCount > 0" class="pdf-navigation mt-3">
          <button 
            class="btn btn-sm btn-primary mx-2" 
            @click="currentPage--" 
            :disabled="currentPage <= 1"
          >
            Página Anterior
          </button>
          <span>Página {{ currentPage }} de {{ pageCount }}</span>
          <button 
            class="btn btn-sm btn-primary mx-2" 
            @click="currentPage++" 
            :disabled="currentPage >= pageCount"
          >
            Próxima Página
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import pdf from 'pdfvuer';
import * as pdfjsLib from 'pdfjs-dist';

/**
 * Web Worker (pdfjsWorker):
 * Código para executar scripts que rodam em background, separados da thread principal do navegador.
 * Importante porque o processamento de PDFs pode ser pesado, e sem o Worker, poderia travar a interface do usuário.
 */

// (CDN) caso seja necessário:
// pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

// importando localmente
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default {
  components: {
    pdf
  },
  props: {
    selected: {
      type: Object,
      default: () => ({}),
      description: "Dados da imagem selecionada"
    },
    show: {
      type: Boolean,
      default: true,
      description: "Abrir modal"
    },
  },
  data() {
    return {
      currentPage: 1,
      pageCount: 0,
      loading: true,
      pdfDocument: null
    }
  },
  methods: {
    close() {
      this.$parent.closeModalImgView();
      this.currentPage = 1;
      this.pageCount = 0;
      if (this.pdfDocument) {
        this.pdfDocument.destroy();
        this.pdfDocument = null;
      }
    },
    async onDocumentLoaded() {
      try {
        const loadingTask = pdfjsLib.getDocument(this.selected.url);
        this.pdfDocument = await loadingTask.promise;
        this.pageCount = this.pdfDocument.numPages;
      } catch (error) {
        this.pageCount = 0;
      }
    },
    onLoaded() {
      this.loading = false;
    },
    async initializePDF() {
      this.loading = true;
      this.currentPage = 1;
      await this.onDocumentLoaded();
    }
  },
  watch: {
    show: {
      immediate: true,
      async handler(newVal) {
        if (newVal && this.selected.url) {
          await this.initializePDF();
        }
      }
    }
  }
}
</script>

<style scoped>
.pdf-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.loading-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.9);
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important; color: #606062 !important" class="text-primary">
            <img height="30px" src="/img/icons/icons8/ios/settings-3--v1_gray.png" class="mr-2 mb-1" />
            Permissões
          </h2>
        </div>
        <div class="col-md-8 pr-4">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mb-2 col-md-3 px-1">
             <base-button class="text-uppercase" block size="sm" type="success" @click="handleCreate()">
                <img src="/img/icons/plus-math--v1-white.png" width="23px" height="23px" /> Novo
              </base-button>
            </div>
            <div class="mb-md-0 mb-2 col-md-3 px-1" :class="{'d-none': !$hasPermission('records.user-permissions.index.view')}">
              <router-link :to="{ path:'/configuration/user'}">
                <base-button block size="sm" type="light" class="text-uppercase">
                  <img src="/img/icons/circled-left.png" class="mr-1" width="23px" height="23px" />
                  Voltar
                </base-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <ModalCreateRole @changePermission="handleChangePermission" ref="ModalCreateRole"/>
    <Permission ref="Permission" />
  </div>
</template>
<script>
import Permission from './Shared/Permission'
import ModalCreateRole from '../GroupPermission/Shared/_Create'

export default {
  components: {
    Permission,
    ModalCreateRole
  },
  data() {
    return {
    }
  },
  methods: {
    handleChangePermission(id) {
      this.$refs.Permission.selected = id;
    },
    handleCreate() {
      this.$refs.ModalCreateRole.handleCreateModal()
    },
  },
};
</script>
<style></style>

<template>
  <div>
    <div v-if="warningMensage && entityBudget.entity_can_edit" class="overlay">
      <div class="warning">
        <div class="header-warning">
          <div><img class="mr-2" src="/img/icons/icons8/ios/attention_white.png" width="19" /></div>
          <div>AVISO IMPORTANTE</div>
        </div>
        <div class="body-warning">
          Clique aqui para informar os impostos de cada produto e outras condições.
          <div class="button-warning" @click="warningMensage = !warningMensage">
            Fechar
          </div>
        </div>
      </div>
    </div>
    <div class="header">
      <div class="d-flex align-items-center pl-4">
        <img height="26px" src="/img/icons/icons8/ios/shopping_cart_white.png" class="mr-2" />
        <div>COTAÇÃO ONLINE</div>
      </div>
      <div class="d-flex align-items-center">
        <div class="p-4">
          <img class="mr-2" src="/img/icons/icons8/ios/mail.png" width="19" />
          <span>puzl@puzl.place</span>
        </div>
        <div class="p-4 mr-3">
          <img class="mr-2" src="/img/icons/icons8/ios/phone-primary.png" width="19" />
          <span>(11) 3042-2930</span>
        </div>
      </div>
    </div>
    <div class="d-block d-md-flex align-items-center justify-content-between" style="padding: 1.5rem 2rem">
      <div>
        <!-- INFORMAÇOES -->
        <div class="d-flex align-items-center">
          <img class="mr-1" width="25" height="25" src="/img/icons/info-squared-yellow.png" alt="info-squared" />
          <span style="font-size: 16px; color: #2B2D32; font-weight: 500" class="ml-2 mr-3">Informação</span>
          <div style="border:#E8E8E8 1px solid; width: 100%"></div>
        </div>
        <div class="mt-3 font-weight-600">
          <div style="font-size: 15px !important; color: #2B2D32">{{ entityBudget.entity_name }}</div>
          <div style="font-size: 15px !important; color: #2B2D32">{{ entityBudget.entity_document }}</div>
        </div>
      </div>
      <div class="d-flex mt-2 mt-md-0">
        <div class="header-cards mr-3" style="background-color: #f2f7f3;">
          <img class="mr-1" width="25" height="25" src="/img/icons/icons8/ios/cash-register_success.png"
            alt="info-squared" />
          <span style="font-size: 16px; color: #2B2D32; font-weight: 500" class="mt-2">Valor total dos
            produtos</span>
          <span style="font-size: 16px; color: #2B2D32; font-weight: 500">{{ grossValue | currency()
            }}</span>
        </div>
        <div class="header-cards mr-3" style="background-color: #f2f4f9;">
          <img class="mr-1" width="25" height="25" src="/img/icons/icons8/ios/chegue_primary.png" alt="info-squared" />
          <span style="font-size: 16px; color: #2B2D32; font-weight: 500" class="mt-2">valor total da nota</span>
          <span style="font-size: 16px; color: #2B2D32; font-weight: 500">{{ netValue | currency() }}</span>
        </div>
        <div class="header-cards mr-3" style="background-color: #fcf3f3;">
          <img class="mr-1" width="25" height="25" src="/img/icons/icons8/ios/receipt-dolar_warning.png"
            alt="info-squared" />
          <span style="font-size: 16px; color: #2B2D32; font-weight: 500" class="mt-2">Valor da nota + frete</span>
          <span style="font-size: 16px; color: #2B2D32; font-weight: 500">{{ finalValue | currency() }}</span>
        </div>
      </div>
    </div>
    <div class="m-1" style="border:#E8E8E8 1px solid; width: 100%"></div>
    <div class="d-block d-md-flex justify-content-around" style="padding: 2rem">
      <div style="width: -webkit-fill-available;">
        <!-- ITEMS -->
        <div>
          <div class="container-blocks mr-md-2" style="overflow: scroll; max-height: 500px">
            <div class="d-flex align-items-center">
              <img width="25" src="/img/icons/big-parcel-primary.png" class="mr-2" />
              <span style="font-size: 16px; color: #2B2D32; font-weight: 500" class="ml-2 mr-3">Itens</span>
              <div style="border:#E8E8E8 1px solid; width: 100%"></div>
            </div>

            <input :checked="priceWithThreeDecimalPlace" @change="handlePriceDecimalPlace" type="checkbox"
              class="mt-4" />
            <span class="text-dark"> Adotar preço com 3 casas decimais </span>

            <div class="d-flex align-items-center mt-3 mb-3 justify-content-between">
              <div>
                <span style="font-size: 16px; color: #2B2D32; font-weight: 500" class="mr-3">Item</span>
              </div>
              <div class="text-nowrap">
                <span class="header-table-items">QUANTIDADE</span>
                <span class="header-table-items">PREÇO UNIT.</span>
                <span class="header-table-items">PREÇO TOTAL</span>
              </div>
            </div>
            <div v-for="(item, index) in entityBudget.items" :key="index" class="d-flex justify-content-between mb-2">
              <div class="text-item-description">
                {{ item.description }}
              </div>
              <div class="d-flex">
                <div class="mr-3 mt-1 pointer" :class="warningMensage ? 'taxIcon' : ''"
                  @click="handleShowModal({ ref: 'modalTaxes', data: { ...item, index } })">
                  <img v-if="!warningMensage || !entityBudget.entity_can_edit"
                    src="/img/icons/icons8/ios/general-ledger_default.png" width="24" />
                  <img v-if="warningMensage && entityBudget.entity_can_edit"
                    src="/img/icons/icons8/ios/general-ledger_success.png" class="blinking" width="24" />
                </div>
                <!-- Quantidade -->
                <div class="mr-3" style="width: 8rem !important;">
                  <div class="p-2 text-right"
                    style="background-color: #E8E8E8; border-radius: 5px; color: #606062; font-size: 11px;">
                    {{ item.quantity }} {{ item.input_unit }}
                  </div>
                </div>
                <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                  <!-- Valor unitario -->
                  <div class="mr-3" style="width: 8rem !important;">
                    <base-input input-group-classes="input-group-sm">
                      <input v-money="money" v-model="entityBudget.items[index].unit_value" maxlength="13" type="text"
                        class="form-control form-control-sm" />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0"> R$ </small>
                      </template>
                    </base-input>
                  </div>
                </validation-observer>
                <!-- Valor bruto -->
                <div class="mr-3" style="width: 8rem !important;">
                  <div class="p-2"
                    style="background-color: #E8E8E8; border-radius: 5px; color: #606062; font-size: 11px;">
                    {{ calculateTotalCost(entityBudget.items[index].unit_value, item.quantity) | currency() }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- OBSERVACOES -->
        <div>
          <div class="container-blocks mr-md-2 mt-2 mb-2 mb-md-0">
            <div class="d-flex align-items-center">
              <img width="25" src="/img/icons/icons8/ios/speech-bubble-with-dots--v1.png" class="mr-2" />
              <span style="font-size: 16px; color: #2B2D32; font-weight: 500" class="ml-2 mr-3">OBSERVAÇÃO</span>
              <div style="border:#E8E8E8 1px solid; width: 100%"></div>
            </div>
            <div class="mt-3">
              <base-input input-group-classes="input-group-sm">
                <textarea type="text" rows="4" class="form-control form-control-sm" placeholder=" ...">
        </textarea>
              </base-input>
            </div>
          </div>
        </div>
      </div>
      <div class="container-blocks">
        <!-- TAXAS E DESCONTOS -->
        <div class="tax-container">
          <div class="d-flex align-items-center">
            <img height="30px" src="/img/icons/ledger.png" class="mr-2" />
            <span style="font-size: 16px; color: #2B2D32; font-weight: 500; text-wrap: nowrap;" class="ml-2 mr-3">
              TAXAS E DESCONTOS
            </span>
            <div style="border:#E8E8E8 1px solid; width: 100%"></div>
          </div>

          <div class="mt-3">
            <div class="d-flex justify-content-between mb-2" v-for="field in taxAndCosts" :key="field.value">
              <div class="mr-1">
                {{ field.text }}
              </div>
              <div>
                <div style="width: 10rem !important;">
                  <base-input input-group-classes="input-group-sm">
                    <input :disabled="true" v-money="money" :value="sumTaxesAndCosts(field.value)" maxlength="13"
                      type="text" class="form-control form-control-sm" />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- TRANSPORTE E FRETE -->
        <div class="mt-3">
          <div class="d-flex align-items-center">
            <img height="30px" src="/img/icons/icons8/ios/in-transit_primary.png" class="mr-2" />
            <span style="font-size: 16px; color: #2B2D32; font-weight: 500; text-wrap: nowrap;"
              class="ml-2 mr-3">TRANSPORTE E
              FRETE</span>
            <div style="border:#E8E8E8 1px solid; width: 100%"></div>
          </div>
          <div class="mt-3">
            <div class="d-flex justify-content-between mb-2">
              <div>
                Condição do frete
              </div>
              <div>
                <div style="width: 12rem !important;">
                  <PuzlSelect v-model="entityBudget.shipping_condition" customKey="type"
                    :items="[{ type: 0, name: 'CIF' }, { type: 1, name: 'FOB' }]" class="input-info"
                    placeholder="Selecione" />
                </div>
              </div>
            </div>
            <div v-if="entityBudget.shipping_condition !== 0" class="d-flex justify-content-between mb-2">
              <div>
                Frente por conta do
              </div>
              <div>
                <div style="width: 12rem !important;">
                  <PuzlSelect v-model="entityBudget.freight_modality_id" :items="freight_modalities" class="input-info"
                    placeholder="Selecione" />
                </div>
              </div>
            </div>
            <div v-if="entityBudget.freight_modality_id !== 0 && entityBudget.shipping_condition !== 0"
              class="d-flex justify-content-between mb-2">
              <div>
                Valor do frete
              </div>
              <div>
                <div style="width: 12rem !important;">
                  <base-input input-group-classes="input-group-sm">
                    <input v-money="money" v-model.lazy="entityBudget.delivery_cost" maxlength="13" type="text"
                      class="form-control form-control-sm" />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
              </div>
            </div>
            <div v-if="entityBudget.freight_modality_id !== 0 && entityBudget.shipping_condition !== 0"
              class="d-flex justify-content-between mb-2">
              <div>
                Transportadora
              </div>
              <div>
                <div style="width: 12rem !important;">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model.lazy="entityBudget.delivery_company_name" maxlength="90" type="text"
                      class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
            </div>
            <div v-if="entityBudget.freight_modality_id !== 0 && entityBudget.shipping_condition !== 0"
              class="d-flex justify-content-between mb-2">
              <div>
                cpf/cnpj
              </div>
              <div>
                <div style="width: 12rem !important;">
                  <base-input input-group-classes="input-group-sm">
                    <input v-mask="['###.###.###-##', '##.###.###/####-##']"
                      v-model="entityBudget.delivery_company_document" type="text"
                      @blur="notifyInvalidDocument(entityBudget.delivery_company_document)"
                      class="form-control form-control-sm" :style="entityBudget.delivery_company_document
                        ? { borderColor: validityDocument(entityBudget.delivery_company_document) ? '#149E57' : '#DB4539' }
                        : {}" />
                  </base-input>
                </div>
              </div>
            </div>
            <div v-if="entityBudget.freight_modality_id !== 0 && entityBudget.shipping_condition !== 0"
              class="d-flex justify-content-between mb-2">
              <div>
                Contato
              </div>
              <div>
                <div style="width: 12rem !important;">
                  <base-input input-group-classes="input-group-sm">
                    <input v-mask="['(##) ####-####', '(##) #####-####']" v-model.lazy="entityBudget.delivery_contact"
                      maxlength="30" type="text" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- CONDIÇÃO DE PAGAMENTO -->
        <div class="mt-4">
          <div class="d-flex align-items-center">
            <img height="30px" src="/img/icons/icons8/ios/estimate_success.png" class="mr-2" />
            <span style="font-size: 16px; color: #2B2D32; font-weight: 500; text-wrap: nowrap;"
              class="ml-2 mr-3">CONDIÇÃO
              DE PAGAMENTO</span>
            <div style="border:#E8E8E8 1px solid; width: 100%"></div>
          </div>
          <div class="mt-3">
            <div class="d-flex justify-content-between mb-2">
              <div class="mr-3" style="text-wrap: nowrap;">
                CONDIÇÃO DO PAGAMENTO
              </div>
              <div>
                <div style="width: 12rem !important;">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model.lazy="entityBudget.payment_method" type="text"
                      class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <div>
                PRAZO DE ENTREGA
              </div>
              <div>
                <div style="width: 12rem !important;">
                  <base-input input-group-classes="input-group-sm">
                    <InputDateTimePicker :disableMaxDate="false" :disableDate="new Date()"
                      v-model="entityBudget.delivery_deadline" class="input-info w-100" :mode="'date'" />
                  </base-input>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div>
                VALIDADE DA COTAÇÃO
              </div>
              <div>
                <div style="width: 12rem !important;">
                  <base-input input-group-classes="input-group-sm">
                    <InputDateTimePicker :disableMaxDate="false" :disableDate="new Date()"
                      v-model="entityBudget.quotation_validity" class="input-info w-100" :mode="'date'" />
                  </base-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer pb-1 mt-2">
          <div class="cancel-btn mr-3" @click="logout()">
            <img src="/img/icons/cancel-red.png" width="20px" class="mr-2" />
            Cancelar
          </div>
          <div class="send-btn" :class="!entityBudget.entity_can_edit ? 'btn-disabled' : ''"
            :disabled="entityBudget.entity_can_edit" @click="save()">
            <img src="/img/icons/paper_plane_primary.png" width="18px" class="mr-2" />
            Enviar
          </div>
        </div>
      </div>
    </div>
    <ModalTaxes @onSave="setItemTaxes" ref="modalTaxes" />
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilter.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import { moneyToFloat } from "@/helpers";
import { verifyDocument } from "@/helpers";
import ModalTaxes from "../Budget/Shared/Shared/ModalTaxes.vue";


export default {
  name: "EntityBudget",
  components: { MultiFilter, PuzlEmptyData, PuzlSelect, InputDateTimePicker, ModalTaxes },
  data() {
    return {
      cardOpenId: false,
      verifyDocument: verifyDocument,
      filter: {
        order_by: [
          {
            column: 'products_services.id',
          }
        ],
        type: null,
      },
      warningMensage: false,
      grossValue: 0,
      netValue: 0,
      finalValue: 0,
      freight_modalities: [
        { id: 1, name: "Por conta do emitente", code: "0" },
        { id: 2, name: "Por conta do destinatário/remetente", code: "1" },
        { id: 3, name: "Por conta de terceiros", code: "2" },
        { id: 4, name: "Transporte próprio por conta do remetente", code: "3" },
        { id: 5, name: "Transporte próprio por conta do destinatário", code: "4" },
        { id: 0, name: "Sem frete", code: "9" },
      ],
      entityBudget: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      priceWithThreeDecimalPlace: false,
      taxAndCosts: [
        { text: "Desconto", value: "discount_value" },
        { text: "IPI", value: "ipi_value" },
        { text: "ICMS", value: "icms_value" },
        { text: "ICMS com Sub. Tributária", value: "icms_with_replacement_value" },
        { text: "ICMS Exonerado", value: "icms_exonerated_value" },
        { text: "IPI Devolvido", value: "ipi_returned_value" },
        { text: "PIS", value: "pis_value" },
        { text: "COFINS", value: "cofins_value" },
        { text: "ISSQN", value: "issqn_value" },
        { text: "Valor de Importação", value: "import_value" },
        { text: "FCP com Sub. Tributária", value: "fcp_with_replacement_value" },
        { text: "Frete", value: "freight_value" },
        { text: "Seguro", value: "insurance_value" },
        { text: "Outros Valores", value: "other_value" },
        { text: "Valor do Serviço", value: "service_value" },
        { text: "Valor Unitário", value: "unit_value" },

      ]
    }
  },
  watch: {
    entityBudget: {
      handler() {
        this.setGrossValue();
        this.setNetValue();
        this.setFinalValue();
      },
      deep: true
    }
  },
  methods: {
    setItemTaxes(itemData) {
      this.entityBudget.items[itemData.index] = itemData;
      this.setGrossValue();
      this.setNetValue();
      this.setFinalValue();
    },
    async setEntityBudgetByToken(token) {
      let loader = this.$loading.show();
      this.$store.dispatch("purchaseBudget/getEntityPurchaseBudget", token)
        .then((response) => {
          response.data.items.forEach(item => {
            if (this.priceWithThreeDecimalPlace) {
              item.unit_value = `${item.unit_value}000`;
            }
          })
          this.entityBudget = response.data;
          this.setFinalValue();
          loader.hide()
          this.warningMensage = true;
        })
        .catch(() => {
          loader.hide();
          this.$snotify.warning('Cotação nao localizado, solicite um novo link', {
            timeout: 2000,
            icon: false,
            position: "rightTop",
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false
          });
          this.$router.push('/entity-login/' + this.entityBudget.code)
        });
    },
    save() {
      if (!this.entityBudget.entity_can_edit) {
        this.$snotify.warning('Cotação ja enviado, solicite permissão para novo envio!', {
          timeout: 2000,
          icon: false,
          position: "rightTop",
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false
        });
        return
      }
      if (this.requiredFieldsIsFilled()) {
        let loader = this.$loading.show();
        let params = this.formatValuesToSave(JSON.parse(JSON.stringify(this.entityBudget)));
        params.token = this.$route.params.token;
        params.gross_value = this.grossValue;
        params.net_value = this.netValue;
        params.final_value = this.finalValue;
        this.$store.dispatch('purchaseBudget/updateEntityBudget', params)
          .then(() => {
            this.setEntityBudgetByToken(this.$route.params.token);
            this.setGrossValue();
            this.setNetValue();
            this.setFinalValue();
            loader.hide();
            this.$snotify.success('Cotação salvo com sucesso', {
              timeout: 2000,
              icon: false,
              position: "rightTop",
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false
            });
          })
          .catch(() => {
            loader.hide();
            this.$snotify.warning('Erro ao enviar', {
              timeout: 2000,
              icon: false,
              position: "rightTop",
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false
            });
          });
      }
    },
    requiredFieldsIsFilled() {
      const requiredFields = [
        'payment_method',
      ];
      for (const field of requiredFields) {
        if (!this.entityBudget.hasOwnProperty(field) || !this.entityBudget[field]) {
          this.$snotify.warning(`Preencha os campos obrigatórios`, {
            timeout: 2000,
            icon: false,
            position: "rightTop",
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false
          });
          return false;
        }
      }
      return true;
    },
    formatValuesToSave(data) {
      data.delivery_cost = data.delivery_cost ? data.delivery_cost : "0.00";
      data.delivery_cost = Number(data.delivery_cost.replaceAll(".", "").replace(",", "."));
      data.icms = Number(data.icms.replaceAll(".", "").replace(",", "."));
      data.ipi = Number(data.ipi.replaceAll(".", "").replace(",", "."));
      data.discount = Number(data.discount.replaceAll(".", "").replace(",", "."));

      data.items.forEach(item => {
        this.taxAndCosts.forEach(field => {
          if (item[field.value]) {
            const value = Number(item[field.value].replaceAll(".", "").replace(",", "."));
            item[field.value] = value;
          }
        });
      });

      return data;
    },
    validityDocument(document) {
      if (document !== '' && verifyDocument(document)) {
        return true
      }
      return false;
    },
    notifyInvalidDocument(document) {
      if (this.validityDocument(document)) {
        return
      }
      this.$snotify.warning(`O documento ${document} é inválido!`, {
        timeout: 2000,
        icon: false,
        position: "rightTop",
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false
      });
    },
    handlePriceDecimalPlace() {
      this.money.precision = this.money.precision === 2 ? 3 : 2;
      this.priceWithThreeDecimalPlace = !this.priceWithThreeDecimalPlace;
    },
    // Calculos de valor total, liquido, bruto e final
    calculateTotalCost(price, quantity) {
      const FloatPrice = price ? moneyToFloat(price.toString()) : 0;
      const NumQuantity = quantity ? parseFloat(quantity.toString().replace(',', '.')) : 0;
      return (FloatPrice * NumQuantity).toFixed(2);
    },
    setGrossValue() {
      let grossValue = 0;
      this.entityBudget?.items?.forEach(item => {
        if (item.unit_value && item.quantity) {
          const unitValue = moneyToFloat(item.unit_value.toString());
          const quantity = parseFloat(item.quantity.toString().replace(',', '.'));
          grossValue += unitValue * quantity;
        }
      });
      this.grossValue = grossValue.toFixed(2);
    },
    setNetValue() {
      let totalFinalValue = 0;

      if (this.entityBudget?.items) {
        this.entityBudget.items.forEach(item => {
          const totalValue = this.calculateTotalCost(item.unit_value, item.quantity);
          let newTotalValue = this.parseValue(totalValue);
          const additionalValues = [
            item.icms_with_replacement_value,
            item.ipi_value,
            item.ipi_returned_value,
            item.import_value,
            item.fcp_with_replacement_value,
            item.freight_value,
            item.insurance_value,
            item.other_value,
            item.service_value
          ];
          additionalValues.forEach(value => {
            newTotalValue += this.parseValue(value);
          });
          const deductions = [
            item.discount_value,
            item.icms_exonerated_value
          ];
          deductions.forEach(value => {
            newTotalValue -= this.parseValue(value);
          });
          item.final_value = newTotalValue.toFixed(2);
          const finalValue = moneyToFloat(item.final_value.toString());
          totalFinalValue += finalValue;
        });
      }

      this.netValue = (totalFinalValue / 100).toFixed(2);
    },
    parseValue(value) {
      if (!value) return 0;
      if (value.includes(",")) {
        value = value.replaceAll(".", "").replace(",", ".");
      }
      const parsedValue = parseFloat(value);
      return isNaN(parsedValue) ? 0 : parsedValue;
    },
    setFinalValue() {
      const netValue = this.formatValueToFloat(this.netValue);
      const deliveryCost = this.formatValueToFloat(this.entityBudget.delivery_cost || 0);
      this.finalValue = (netValue + deliveryCost).toFixed(2);
    },
    sumTaxesAndCosts(taxAndCostField) {
      let value = 0;
      this.entityBudget?.items?.forEach(item => {
        if (item[taxAndCostField]) {
          const fieldValue = this.formatValueToFloat(item[taxAndCostField]);
          value += fieldValue;
        }
      });
      return value.toFixed(2);
    },
    formatValueToFloat(value) {
      return value?.toString().includes(",") ? moneyToFloat(value) : parseFloat(value);
    },
    handleShowModal({ ref, data }) {
      this.$refs[ref].openModal(data);
    },
    logout() {
      let loader = this.$loading.show();
      this.$store.dispatch('purchaseBudget/logout', this.$route.params.token)
        .then(() => {
          loader.hide();
          this.$router.push('/entity-login/' + this.entityBudget.code)
        });
    }
  },
  beforeMount() {
    this.setEntityBudgetByToken(this.$route.params.token);
  }
}
</script>

<style scoped>
.header {
  background-color: #1A70B7 !important;
  color: #ffff;
  display: flex;
  justify-content: space-between;
}

.header-table-items {
  font-size: 18px;
  color: #2B2D32;
  font-weight: 500;
  width: 9rem;
  display: inline-flex;
}

.header-table-items:first-of-type {
  margin-left: 13.85rem;
}

.header-cards {
  display: grid;
  box-shadow: 0px 5px 10px 0px #0000000D;
  border: 1px solid #E8E8E8;
  padding: 12px 32px 12px 12px;
  border-radius: 8px;
}

.container-blocks {
  box-shadow: 0px 5px 10px 0px #0000001A;
  border: 0.5px solid #E8E8E8;
  border-radius: 12px;
  padding: 24px 16px;
  background-color: #FFFFFF !important;
}

.container-blocks .text-item-description {
  min-width: 200px;
  max-width: 600px;
  margin-right: 4rem;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.footer {
  background-color: #FFFFFF;
  display: flex;
  justify-content: end;
}

.cancel-btn,
.send-btn {
  width: 9rem;
  height: 2.5rem;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cancel-btn {
  border: 0.5px solid #DB4539;
  box-shadow: 0px 5px 10px 0px #0000000D;
  color: #DB4539;
}

.send-btn {
  border: 0.5px solid #1A70B7;
  box-shadow: 0px 5px 10px 0px #0000000D;
  color: #1A70B7;
}

.btn-disabled {
  opacity: 0.5;
}

.tax-container {
  overflow: auto;
  scrollbar-width: none;
  max-height: 158px;
}

.tax-container>>>span,
.tax-container>>>input {
  box-shadow: none !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  /* Fundo mais escuro */
  z-index: 9999999;
}

.warning {
  position: relative;
  z-index: 99999999;
  border-radius: 8px;
  text-align: center;
  right: 600px;
  margin-left: auto;
  top: -142px;
  width: 20rem;
}

.warning .header-warning {
  background-color: #db4539;
  z-index: 99999;
  height: 3rem;
  color: white;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.warning .header-warning div {
  display: flex;
  align-items: center;
}

.warning .header-warning img {
  margin-right: 0.5rem;
}

.warning .body-warning {
  background-color: white;
  border-radius: 0px 0px 5px 5px;
  width: 100%;
  padding: 1rem;
}

.button-warning {
  box-shadow: 0px 5px 10px 0px #0000000D;
  border: 0.5px solid #CFCFCF;
  border-radius: 5px;
  margin-top: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.warning span {
  font-size: 16px
}

.taxIcon {
  z-index: 999999999;
}

.blinking {
  animation: blink-animation 1s infinite;
}

@keyframes blink-animation {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}
</style>

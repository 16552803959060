<template>
  <div>
    <modal :backgroundColor="'#e8e8e8'" :show.sync="isOpen">
      <template slot="header">
        <div class="d-flex align-items-center">
          <h5 class="text-title mb-0">{{payload.reason ? "Reemitir MDF-e" : "Emitir MDF-e" }}</h5>
        </div>
      </template>
      <template slot="close-button">
        <img class="pointer mt-2" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window"
             width="17px"/>
      </template>
      <div class="mb-2 px-2 mx-1">
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <div class="row mt-3 pl-1" v-if="payload.reason">
            <label class="col-md-5 new-default-black-font"> Motivo </label>
            <div class="col-md-7">
              <base-input input-group-classes="input-group-sm">
                <textarea
                  rows="4"
                  type="text"
                  placeholder="Observações"
                  disabled
                  readonly
                  v-model="payload.reason"
                  class="form-control form-control-sm text-danger"
                />
              </base-input>
            </div>
          </div>
          <div class="row mt-3 pl-1">
            <div class="col-md-12" v-if="payload.reason">
              <hr class="mt-1 mb-3"/>
            </div>
          <label class="col-md-5 new-default-black-font"> Nome</label>
          <div class="col-md-7">
            <validation-provider v-slot="{errors}" rules="required">
              <base-input input-group-classes="input-group-sm">
                <input
                  type="text"
                  placeholder="Nome do motorista"
                  v-model="payload.name"
                  class="form-control form-control-sm new-default-black-font"
                  :class="errors[0] ? 'is-invalid' : 'is-valid'"/>
              </base-input>
            </validation-provider>
          </div>
        </div>
        <div class="row mt-3 pl-1">
          <label class="col-md-5 new-default-black-font"> CPF</label>
          <div class="col-md-7">
            <validation-provider v-slot="{errors}" rules="required|document">
              <base-input input-group-classes="input-group-sm">
                <input
                  type="text"
                  v-mask="'###.###.###-##'"
                  placeholder="CPF do motorista"
                  v-model="payload.document"
                  class="form-control form-control-sm new-default-black-font"
                  :class="errors[0] ? 'is-invalid' : 'is-valid'"/>
              </base-input>
            </validation-provider>
          </div>
        </div>
        <div class="row mt-3 pl-1">
          <label class="col-md-5 new-default-black-font"> Observações </label>
          <div class="col-md-7">
              <base-input input-group-classes="input-group-sm">
                <input
                  type="text"
                  placeholder="Observações"
                  v-model="payload.observation"
                  class="form-control form-control-sm new-default-black-font"
                 />
              </base-input>
          </div>
        </div>
        <div class="row d-flex justify-content-end mt-4 mb-4 mr-1">
          <div @click="closeModal" class="d-flex align-items-center button-cancel col-auto px-4" style="gap: 5px">
            <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px"/>
            <span style="font-weight: 500"> Cancelar </span>
          </div>
          <div @click="!invalid && save()"  class="d-flex align-items-center button-save col-auto py-1 ml-3 px-4" style="gap: 5px">
            <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px"/>
            <span style="font-weight: 500"> Salvar </span>
          </div>
        </div>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script setup>
import {ref, getCurrentInstance, computed} from "vue";
import {dialogs, progress} from "../../../../../../helpers";
import bankAccountStore from "@/modules/configuration/bank-account/store/bankAccountStore";

const isOpen = ref(false);
const payload = ref({
  name: undefined,
  document: undefined,
  observation: undefined,
  schedule_travel_id: undefined,
  reason: undefined
});
const store = getCurrentInstance().proxy.$store;
const { proxy } = getCurrentInstance()

const $_general_settings = computed(() =>
  store.getters["generalSetting/show"]
);

async function openModal(params) {
  let loader = progress.showLoader()
  store.dispatch('generalSetting/show').then(() => {
    loader.hide()
    if ($_general_settings.value.show_driver_mdfe) {
      payload.value.name = params.name
    }
    payload.value.document = params.document
    payload.value.schedule_travel_id = params.schedule_travel_id
    payload.value.reason = params.reason
    isOpen.value = true;
  })
}

async function save() {
  progress.start()
 let loader = progress.showLoader()
  store.dispatch('travels/postSendMDFe', {
    schedule_travel_id: payload.value.schedule_travel_id,
    driver: {
      name: payload.value.name,
      document: payload.value.document,
      observation: payload.value.observation,
    },
  }).then(response => {
    proxy.$emit('update', response.data)
    isOpen.value = false;
    dialogs.notify();
  })
    .catch(error => {
      dialogs.notifyError({ message: error.data.message })
    })
    .finally(() => {
    progress.finish()
    loader.hide()
  })
}

function closeModal() {
  isOpen.value = false;
}

defineExpose({
  openModal,
});
</script>
